import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
    Button,
    ButtonToolbar,
    CheckPicker, Checkbox,
    Col,
    Container, Content,
    Dropdown,
    FlexboxGrid,
    FormGroup, HelpBlock,
    Icon,
    IconButton,
    Loader,
    Table,
    Tag,
    Toggle,
    Tooltip,
    Whisper,
} from 'rsuite';

import { getEscopoTypes } from '../../../../services/EscopoService';
import * as LojaService from '../../../../services/LojaService';
import * as ProdutoService from '../../../../services/ProdutoService';
import { getTypes } from '../../../../services/TipoSolicitacaoService';
import * as ExtracaoService from './services';

import {
    addFiltroExtracao, deleteFiltroExtracao, updateFilterDataExtracao, updateFilterValue,
} from '../../../../actions/actionsExtracaoDados';
import { InputFilter, MoreFilterDropdown } from '../../../../components/CustomFilter';
import DataRangePicker from '../../../../components/DataRangePicker';
import { LoadingSpiner } from '../../../../components/LoadingSpinner';
import ModalNotification from '../../../../components/ModalNotification';

const {
    Column, HeaderCell, Cell, Pagination,
} = Table;

class ExtracaoDadosPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            produtos: [],
            lojas: [],
            data: [],
            cacheData: [],
            cacheProduto: [],
            itens: [],
            totalItens: null,
            ultimaAtualizacao: null,
            size: 20,
            showModalDownload: false,
            sortColumn: null,
            sortType: 'asc',
            chipsProduto: [],
            chipsLoja: [],
            pageSizeLoja: 100,
            valueColuna: [],
            indeterminate: false,
            checkAll: true,
            colunas: [],
            colunasPreview: [],
            allValue: [],
            cookie: null,
            errors: {},
            expurgo: true,
            indeterminateLojas: false,
            checkAllLojas: false,
        };

        this.changeInputLoja = this.changeInputLoja.bind(this);
        this.changeInputProduto = this.changeInputProduto.bind(this);
        this.getLastUpdate = this.getLastUpdate.bind(this);
        this.getPreview = this.getPreview.bind(this);
        this.getDados = this.getDados.bind(this);
        this.parseBoolean = this.parseBoolean.bind(this);
        this.handleSortColumn = this.handleSortColumn.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.close = this.close.bind(this);
        this.handleRequestAdd = this.handleRequestAdd.bind(this);
        this.handleRequestDelete = this.handleRequestDelete.bind(this);
        this.handleRequestAddLoja = this.handleRequestAddLoja.bind(this);
        this.handleRequestDeleteLoja = this.handleRequestDeleteLoja.bind(this);
        this.handleClearLoja = this.handleClearLoja.bind(this);
        this.handleClearProduto = this.handleClearProduto.bind(this);
        this.handleChangeColuna = this.handleChangeColuna.bind(this);
        this.handleCheckAll = this.handleCheckAll.bind(this);
        this.confirmColuns = this.confirmColuns.bind(this);
        this.setCookie = this.setCookie.bind(this);
        this.getCookie = this.getCookie.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
        this.handleExpurgo = this.handleExpurgo.bind(this);
        this.handleCheckAllLojas = this.handleCheckAllLojas.bind(this);
        this.handleCheckAllProdutos = this.handleCheckAllProdutos.bind(this);
        this.handleFilterLoja = this.handleFilterLoja.bind(this);
        this.handleChangeLoja = this.handleChangeLoja.bind(this);
        this.handleFilterProduto = this.handleFilterProduto.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleSelectMoreFilter = this.handleSelectMoreFilter.bind(this);
        this.getResponse = this.getResponse.bind(this);
        this.handleFilterData = this.handleFilterData.bind(this);
        this.handleFilterData = this.handleFilterData.bind(this);
        this.handleSelectLoja = this.handleSelectLoja.bind(this);
        this.mapFilterEscopo = this.mapFilterEscopo.bind(this);
    }

    getCookie(cname) {
        const name = `${cname}=`;
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i += 1) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                if (c.substring(name.length, c.length)) {
                    this.setState({
                        cookie: c.substring(name.length, c.length).split(','),
                    });
                }
            }
        }
    }

    componentDidMount() {
        this.searchLoja({
            query: '', rede: '', uf: '', cidade: '', size: this.state.pageSizeLoja,
        });
        this.searchRede({
            query: '', uf: '', cidade: '', size: this.state.pageSizeLoja,
        });
        this.searchUf({ query: '', rede: '', size: this.state.pageSizeLoja });
        this.getTiposSolicitacao();
        this.getEscopos();

        this.getLastUpdate(JSON.parse(localStorage.getItem('usuario'))?.email);

        ExtracaoService.getDimensoes().then((data) => {
            if (data?.length) {
                this.setState({
                    colunas: data.map((item, index) => ({
                        id: item.name,
                        value: item.name,
                        label: item.descricao,
                        objeto: item.objeto,
                        name: item.name,
                        descricao: item.descricao,
                        dica: item.dica,
                        obrigatorio: item.obrigatorio,
                    })),
                });
            }
        });

        this.getCookie('colunas');
    }

    getLastUpdate(usuario) {
        ExtracaoService.getUltimaAtualizacao(usuario).then((data) => {
            this.setState({
                ultimaAtualizacao: moment(data).fromNow(),
            });
        });
    }

    changeInputLoja(value) {
        this.setState({
            chipsLoja: [...this.state.chipsLoja, value],
        });
    }

    changeInputProduto(value) {
        this.setState({
            chipsProduto: [...this.state.chipsProduto, value],
        });
    }

    validDateInput() {
        const error = this.state.errors;
        error.data = null;
        this.setState({ errors: error });
        const data = document.getElementsByName('data')[0];
        if (data) {
            data.classList.remove('invalid-input');
        }
    }

    handleFilterData(value) {
        if (value) {
            this.validDateInput();
        }
        this.setState({
            data: value,
        });
    }

    handleChange(a, event) {
        this.setState({ value: a });
        if (a === null) {
            this.setState({
                cacheProduto: [],
                chipsProduto: [],
            });
        }
        if (a !== null && a.length !== this.state.cacheProduto.length) {
            const { cacheProduto } = this.state;

            const newCacheProduto = cacheProduto.filter((item) => a.indexOf(item.value) !== -1);
            this.setState({
                cacheProduto: newCacheProduto,
                chipsProduto: newCacheProduto.map((item) => item.identificadorCliente),
            });
        }
    }

    handleFilterProduto(inputValue, e) {
        if (inputValue) {
            ProdutoService.getProdutoCliente({ query: inputValue }).then((data) => {
                if (data.length) {
                    const responseProduto = this.state.cacheProduto.filter((item) => data.findIndex((produto) => produto.identificadorCliente === item.identificadorCliente) === -1).concat(
                        data.map((item) => ({
                            id: `${item.codigo}-${item.identificadorCliente}`,
                            value: item.codigoProdutoCliente,
                            label: `${item.identificadorCliente} - ${item.descricaoCliente}`,
                            children: data,
                            codigo: item.codigo,
                            identificadorCliente: item.identificadorCliente,
                        })),
                    );
                    this.setState({
                        produtos: responseProduto,
                    });
                }
            });
        }
    }

    searchLoja(filtros) {
        LojaService.getLojasCliente({
            query: filtros.query, rede: filtros.rede, uf: filtros.uf, cidade: filtros.cidade, size: this.state.pageSizeLoja,
        }).then((data) => {
            if (data) {
                this.setState({
                    lojas: this.state.cacheData.filter((item) => data.findIndex((loja) => loja.identificador === item.identificador) === -1).concat(
                        data.map((item) => ({
                            label: `${item.identificador} - ${item.descricao}`,
                            value: `${item.identificador} - ${item.descricao}`,
                            descricao: item.descricao,
                            identificador: item.identificador,
                            codigo: item.codigo,
                        })),
                    ).sort((a, b) => {
                        if (a.identificador > b.identificador) {
                            return 1;
                        }
                        if (a.identificador < b.identificador) {
                            return -1;
                        }
                        return 0;
                    }),
                    indeterminateLojas: false,
                    checkAllLojas: false,
                });
            }
        });
    }

    dispatchFilterData(name, data) {
        this.props.updateFilterDataExtracao(name, data);
    }

    searchRede(filtros) {
        LojaService.getRedes({
            query: filtros.query, cidade: filtros.cidade, uf: filtros.uf, size: filtros.size,
        }).then((data) => {
            if (data.length) {
                const redes = data.map((item) => ({
                    label: item,
                    value: item,
                }));

                this.dispatchFilterData('rede', redes);
            }
        });
    }

    searchUf(filtros) {
        LojaService.getUf({ query: filtros.query, rede: filtros.rede, size: filtros.size }).then((data) => {
            if (data.length) {
                const ufs = data.map((item) => ({
                    label: item,
                    value: item,
                }));

                this.dispatchFilterData('uf', ufs);
            }
        });
    }

    searchCidade(filtros) {
        LojaService.getCidade({
            query: filtros.query, rede: filtros.rede, uf: filtros.uf, size: filtros.size,
        }).then((data) => {
            if (data.length) {
                const cidades = data.map((item) => ({
                    label: item,
                    value: item,
                }));

                this.dispatchFilterData('cidade', cidades);
            }
        });
    }

    getTiposSolicitacao() {
        getTypes().then((data) => {
            if (data?.length) {
                const types = data.map((item) => ({
                    label: item.descricao,
                    value: item.name,
                }));

                this.dispatchFilterData('tipoSolicitacao', types);
            }
        });
    }

    getEscopos() {
        getEscopoTypes(JSON.parse(localStorage.getItem('cliente'))?.codigo).then((data) => {
            if (data?.length) {
                const escopoList = data.map((item) => ({
                    label: item.nomeEscopo,
                    value: item.codigoEscopo,
                }));

                this.dispatchFilterData('escopo', escopoList);
            }
        });
    }

    handleFilterLoja(inputValue, e) {
        this.setState({
            checkAllLojas: false,
        });

        const filtros = {
            query: typeof inputValue === 'string' ? inputValue : '',
            rede: this.props.filtersData.rede?.value?.length ? this.props.filtersData.rede?.value : '',
            uf: this.props.filtersData.uf?.value?.length ? this.props.filtersData.uf?.value : '',
            cidade: this.props.filtersData.cidade?.value?.length ? this.props.filtersData.cidade?.value : '',
        };

        this.searchLoja(filtros);
    }

    handleChangeLoja(a, event) {
        this.setState({ valueLojas: a });
        if (a === null) {
            this.setState({
                cacheData: [],
                chipsLoja: [],
            });
        }
        if (a !== null && a.length !== this.state.cacheData.length) {
            const newcacheDataca = this.state.cacheData.filter((item) => a.indexOf(item.label) !== -1);
            this.setState({
                cacheData: newcacheDataca,
                chipsLoja: newcacheDataca.map((item) => item.identificador),
            });
        }
    }

    handleSelectLoja(value, item, event) {
        const { cacheData } = this.state;
        cacheData.push(item);

        this.setState({
            cacheData,
            chipsLoja: cacheData.map((item) => item.identificador),
        });
    }

    handleSelect(value, item, event) {
        const { cacheProduto } = this.state;
        cacheProduto.push(item);
        this.setState({
            cacheProduto,
            chipsProduto: cacheProduto.map((item) => item.identificadorCliente),
        });
    }

    parseBoolean(item) {
        if (item === false) {
            return 'FALSO';
        }
        if (item === true) {
            return 'VERDADEIRO';
        }
        if (item === null) {
            return null;
        }
    }

    handleChangePage(e) {
        this.setState({
            page: e - 1,
        }, this.getPreview);
    }

    setPreviewProps() {
        const preview = [];

        this.state.colunas.forEach((coluna) => {
            if (this.state.cookie && this.state.cookie.findIndex((item) => coluna.name === item) !== -1) {
                preview.push(coluna);
            }
        });

        this.setState({
            itens: [],
            sortColumn: null,
            sortType: 'asc',
            allValue: this.state.colunas && this.state.colunas.map((item) => item.name),
            colunasPreview: preview.length ? preview : this.state.colunas,
            indeterminate: this.state.cookie && this.state.cookie.length > 0 && this.state.cookie.length < this.state.colunas.length,
            checkAll: this.state.cookie && this.state.cookie.length === this.state.colunas.length,
            valueColuna: this.state.cookie && this.state.cookie.length ? this.state.cookie : this.state.colunas.map((item) => item.name),
        });
    }

    mapFilterEscopo(values) {
        const escopoFilter = [];

        this.props.filtersData.escopo.data.forEach((escopo) => {
            if (values.some((value) => value === escopo.value)) {
                escopoFilter.push(escopo.label);
            }
        });

        return escopoFilter;
    }

    setPreviewPayload() {
        const payload = {
            login: JSON.parse(localStorage.getItem('usuario'))?.email,
            data_inicio: this.state.data[0],
            data_fim: this.state.data[1],
            incluir_expurgo: this.state.expurgo,
            rede: this.props.filtersData.rede?.value?.length ? this.props.filtersData.rede?.value : null,
            uf: this.props.filtersData.uf?.value?.length ? this.props.filtersData.uf?.value : null,
            cidade: this.props.filtersData.cidade?.value?.length ? this.props.filtersData.cidade?.value : null,
            filtros: [],
            tipos_solicitacao: this.props.filtersData.tipoSolicitacao?.value ? this.props.filtersData.tipoSolicitacao?.value : null,
            escopos: this.props.filtersData.escopo?.value ? this.mapFilterEscopo(this.props.filtersData.escopo.value) : null,
        };

        if (this.state.chipsLoja && this.state.chipsLoja.length) {
            this.state.chipsLoja.forEach((item) => {
                payload.filtros.push({
                    operador: 'E',
                    campo: 'LOJA_CLIENTE',
                    query: item,
                });
            });
        }

        if (this.state.chipsProduto && this.state.chipsProduto.length) {
            this.state.chipsProduto.forEach((item) => {
                payload.filtros.push({
                    operador: 'E',
                    campo: 'PRODUTO_CLIENTE',
                    query: item,
                });
            });
        }

        return payload;
    }

    getPreview() {
        if (this.handleValidation()) {
            this.setPreviewProps();

            const model = this.setPreviewPayload();

            ExtracaoService.getPreview(model, { size: this.state.size, page: this.state.page }).then((data) => {
                if (data && data.content && data.content.length) {
                    data.content.forEach(function (item) {
                        item.data_coleta = moment.utc(item.data_coleta).format('DD/MM/YYYY');
                        item.rebaixa_preco = this.parseBoolean(item.rebaixa_preco);
                        item.promocao_sugestao = this.parseBoolean(item.promocao_sugestao);
                        item.clube_desconto_sugestao = this.parseBoolean(item.clube_desconto_sugestao);
                        item.promocao = this.parseBoolean(item.promocao);
                        item.clube_desconto = this.parseBoolean(item.clube_desconto);
                        item.sugestao_expurgo = this.parseBoolean(item.sugestao_expurgo);
                    }, this);
                    this.setState({
                        itens: data.content,
                        totalItens: data.totalElements,
                        pagination: data.pageable,
                        emptyData: false,
                    });
                } else {
                    this.setState({
                        emptyData: true,
                    });
                }
            });
        }
    }

    getResponse() {
        this.setState({
            page: 0,
        }, this.getPreview);
    }

    getDados(extensao) {
        const model = {
            login: JSON.parse(localStorage.getItem('usuario'))?.email,
            extensao,
            data_inicio: this.state.data[0],
            data_fim: this.state.data[1],
            incluir_expurgo: this.state.expurgo,
            rede: this.props.filtersData.rede?.value?.length ? this.props.filtersData.rede?.value : null,
            uf: this.props.filtersData.uf?.value?.length ? this.props.filtersData.uf?.value : null,
            cidade: this.props.filtersData.cidade?.value?.length ? this.props.filtersData.cidade?.value : null,
            filtros: [],
            tipos_solicitacao: this.props.filtersData.tipoSolicitacao?.value ? this.props.filtersData.tipoSolicitacao?.value : null,
            escopos: this.props.filtersData.escopo?.value ? this.mapFilterEscopo(this.props.filtersData.escopo.value) : null,
            dimensoes: this.state.colunasPreview.map((item) => ({ name: item.name })),
        };
        if (this.state.chipsLoja && this.state.chipsLoja.length) {
            this.state.chipsLoja.forEach((item) => {
                model.filtros.push({
                    operador: 'E',
                    campo: 'LOJA_CLIENTE',
                    query: item,
                });
            });
        }

        if (this.state.chipsProduto && this.state.chipsProduto.length) {
            this.state.chipsProduto.forEach((item) => {
                model.filtros.push({
                    operador: 'E',
                    campo: 'PRODUTO_CLIENTE',
                    query: item,
                });
            });
        }

        ExtracaoService.getDownload(model).then((data) => {
            if (data && data.status === 202) {
                this.setState({
                    showModalDownload: true,
                });
            }
        });
    }

    close() {
        this.setState({
            showModalDownload: false,
        });
    }

    handleSortColumn(sortColumn, sortType) {
        if (sortColumn && sortType) {
            const lista = this.state.itens.sort((a, b) => {
                if (sortType === 'asc') {
                    if (a[sortColumn] > b[sortColumn]) {
                        return 1;
                    }
                    if (a[sortColumn] < b[sortColumn]) {
                        return -1;
                    }
                    return 0;
                }
                if (a[sortColumn] < b[sortColumn]) {
                    return 1;
                }
                if (a[sortColumn] > b[sortColumn]) {
                    return -1;
                }
                return 0;
            });
            this.setState({
                sortColumn,
                sortType,
                itens: lista,
            });
        }
    }

    handleRequestAdd(...chips) {
        this.setState({
            chipsProduto: [...this.state.chipsProduto, ...chips],
        });
    }

    handleRequestAddLoja(...chips) {
        this.setState({
            chipsLoja: [...this.state.chipsLoja, ...chips],
        });
    }

    handleRequestDelete(deletedChip) {
        this.setState({
            chipsProduto: this.state.chipsProduto.filter((c) => c !== deletedChip),
        });
    }

    handleRequestDeleteLoja(deletedChip) {
        this.setState({
            chipsLoja: this.state.chipsLoja.filter((c) => c !== deletedChip),
        });
    }

    handleClearLoja() {
        this.setState({ chipsLoja: [] });
    }

    handleClearProduto() {
        this.setState({ chipsProduto: [] });
    }

    handleChangeColuna(value) {
        this.setState({
            valueColuna: value,
            indeterminate: value.length > 0 && value.length < this.state.allValue.length,
            checkAll: value.length === this.state.allValue.length,
        });
    }

    handleCheckAll(value, checked) {
        const nextValue = checked ? this.state.allValue : this.state.allValue.filter((item) => item === 'DATA_COLETA' || item === 'LOJA' || item === 'PRODUTO');
        this.setState({
            valueColuna: nextValue,
            indeterminate: false,
            checkAll: checked,
        });
    }

    handleCheckAllLojas(value, checked) {
        let newCacheData = this.state.cacheData;

        if (checked) {
            this.state.lojas.forEach((item) => {
                if (newCacheData.findIndex((cache) => item.label === cache.label) === -1) {
                    newCacheData.push(item);
                }
            });
        } else {
            newCacheData = [];
        }

        this.setState({
            valueLojas: newCacheData.map((item) => item.value),
            chipsLoja: newCacheData.map((item) => item.identificador),
            indeterminateLojas: false,
            checkAllLojas: checked,
            cacheData: newCacheData,
        });
    }

    handleCheckAllProdutos(value, checked) {
        let newCacheProdutos = this.state.cacheProduto;

        if (checked) {
            this.state.produtos.forEach((item) => {
                if (newCacheProdutos.findIndex((cache) => item.label === cache.label) === -1) {
                    newCacheProdutos.push(item);
                }
            });
        } else {
            newCacheProdutos = [];
        }

        this.setState({
            value: newCacheProdutos.map((item) => item.value),
            chipsProduto: newCacheProdutos.map((item) => item.identificadorCliente),
            indeterminateProdutos: false,
            checkAllProdutos: checked,
            cacheProduto: newCacheProdutos,
        });
    }

    handleSelectMoreFilter(name, value) {
        this.props.updateFilterValue(name, value);

        if (name !== 'cidade') {
            this.searchCidade({
                query: '',
                uf: name === 'uf' ? value : this.props.filtersData.uf?.value?.length ? this.props.filtersData.uf?.value : '',
                rede: name === 'rede' ? value : this.props.filtersData.rede?.value?.length ? this.props.filtersData.rede?.value : '',
                size: this.state.pageSizeLoja,
            });
        }
        if (name !== 'uf' && name !== 'cidade') {
            this.searchUf({
                query: '',
                rede: name === 'rede' ? value : this.props.filtersData.rede?.value?.length ? this.props.filtersData.rede?.value : '',
                size: this.state.pageSizeLoja,
            });
        }

        if (name !== 'rede') {
            this.searchRede({
                query: '',
                uf: name === 'uf' ? value : this.props.filtersData.uf?.value?.length ? this.props.filtersData.uf?.value : '',
                cidade: name === 'cidade' ? value : value === '' ? '' : this.props.filtersData.cidade?.value?.length ? this.props.filtersData.cidade?.value : '',
                size: this.state.pageSizeLoja,
            });
        }

        this.searchLoja({
            query: '',
            rede: name === 'rede' ? value : this.props.filtersData.rede?.value?.length ? this.props.filtersData.rede?.value : '',
            cidade: name === 'cidade' ? value : value === '' ? '' : this.props.filtersData.cidade?.value?.length ? this.props.filtersData.cidade?.value : '',
            uf: name === 'uf' ? value : this.props.filtersData.uf?.value?.length ? this.props.filtersData.uf?.value : '',
            size: this.state.pageSizeLoja,
        });
    }

    handleExpurgo(value, checked) {
        const sugestaoExpurgo = !!checked;

        this.setState({
            expurgo: sugestaoExpurgo,
        });
    }

    handleValidation() {
        const errors = {};
        let formIsValid = true;

        if (!this.state.data.length) {
            formIsValid = false;
            errors.data = 'Campo obrigatório';
            const data = document.getElementsByName('data')[0];
            if (data) {
                data.classList.add('invalid-input');
            }
        }
        this.setState({ errors });
        return formIsValid;
    }

    setCookie(colunas) {
        const colunasJoin = colunas.join(',');
        const expires = moment().add(365, 'days').toDate();
        document.cookie = `colunas=${colunasJoin};expires=${expires.toUTCString()};path=/`;

        this.setState({
            cookie: colunas,
        });
    }

    confirmColuns() {
        const preview = [];

        this.state.colunas.forEach((coluna) => {
            if (this.state.valueColuna && this.state.valueColuna.findIndex((item) => coluna.name === item) !== -1) {
                preview.push(coluna);
            }
        });

        this.setState({
            colunasPreview: preview,
        });

        this.setCookie(this.state.valueColuna);
    }

    render() {
        const maxDate = moment().toDate();
        const minDate = moment().subtract(91, 'days').toDate();
        const {
            ultimaAtualizacao, showModalDownload, indeterminate, checkAll, colunas, colunasPreview, expurgo,
        } = this.state;
        return (
            <Container className="extracao-dados-page main-container">
                <div id="container">

                    <Content className="filtros">
                        <FlexboxGrid>
                            <FlexboxGrid.Item componentClass={Col} lg={18} md={16} xs={24}>
                                <h1>Extração de dados</h1>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item
                                style={{ padding: '0', textAlign: 'end' }}
                                componentClass={Col}
                                lg={6}
                                md={8}
                                sm={24}
                                xs={24}
                                className="mg-bottom-0"
                            >
                                <Tag style={{ fontWeight: '600' }} color="green">
                                    Dados atualizados:
                                    {' '}
                                    {ultimaAtualizacao ? <span>{ultimaAtualizacao}</span> : <Loader />}
                                </Tag>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                        <FlexboxGrid>
                            <FlexboxGrid.Item componentClass={Col} lg={5} md={6} sm={22} xs={22}>
                                <FormGroup>
                                    <DataRangePicker
                                        id="date-filter"
                                        maxDate={maxDate}
                                        minDate={minDate}
                                        name="data"
                                        placeholder="Data*"
                                        onChangeValue={this.handleFilterData}
                                        ranges={[
                                            {
                                                label: 'Hoje',
                                                value: [moment().toDate(), moment().toDate()],
                                            },
                                            {
                                                label: 'Ontem',
                                                value: [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
                                            },
                                            {
                                                label: 'Últimos 7 dias',
                                                value: [moment().subtract(7, 'days').toDate(), moment().toDate()],
                                            },
                                            {
                                                label: 'Últimos 30 dias',
                                                value: [moment().subtract(31, 'days').toDate(), moment().toDate()],
                                            },
                                        ]}
                                    />
                                    <HelpBlock className="invalid-field" name="erroData">{this.state.errors.data}</HelpBlock>
                                </FormGroup>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} lg={1} md={1} sm={2} xs={2}>
                                <Whisper placement="right" trigger="hover" speaker={<Tooltip>Data da realização da coleta</Tooltip>}>
                                    <Icon className="tooltip-info" icon="info" />
                                </Whisper>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} lg={8} md={8} sm={22} xs={22}>
                                <CheckPicker
                                    className="inf-picker"
                                    block
                                    id="e-product-filter"
                                    filter={false}
                                    value={this.state.value}
                                    data={this.state.produtos}
                                    locale={{
                                        noResultsText: 'Nenhum item encontrado',
                                        searchPlaceholder: 'Pesquise o produto que deseja filtrar',
                                    }}
                                    name="produtos"
                                    placeholder="Produtos"
                                    onSelect={this.handleSelect}
                                    onSearch={this.handleFilterProduto}
                                    onChange={this.handleChange}
                                    maxHeight={200}
                                    onOpen={() => {
                                        const input = document.getElementsByClassName('rs-picker-search-bar-input')[0];
                                        input.focus();
                                    }}
                                    renderExtraFooter={() => (
                                        <div style={footerStyles}>
                                            <Checkbox
                                                inline
                                                indeterminate={this.state.indeterminateProdutos}
                                                checked={this.state.checkAllProdutos}
                                                onChange={this.handleCheckAllProdutos}
                                            >
                                                Selecionar tudo
                                                {' '}
                                                {this.state.produtos && this.state.produtos.length ? `(${this.state.produtos.length})` : null}
                                            </Checkbox>
                                        </div>
                                    )}
                                />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} lg={1} md={1} sm={2} xs={2}>
                                <Whisper
                                    placement="top"
                                    trigger="hover"
                                    speaker={(
                                        <Tooltip>
                                            <p>Filtro de produto</p>
                                            <p>Pesquise o produto por parte de sua descrição ou pelo seu identificador exato</p>
                                        </Tooltip>
                                    )}
                                >
                                    <Icon className="tooltip-info" icon="info" />
                                </Whisper>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} lg={5} md={4} sm={12} xs={12}>
                                <Toggle style={{ marginRight: '10px' }} defaultChecked onChange={this.handleExpurgo} value={expurgo} />
                                <span style={{ fontSize: '13px' }}>Exibir dados fora do range</span>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} lg={3} md={4} sm={24} xs={24} lgPush={1}>
                                <Button id="obter-resposta" appearance="primary" justify="end" block onClick={this.getResponse}>Obter resposta</Button>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>

                        <FlexboxGrid>

                            <FlexboxGrid.Item componentClass={Col} lg={14} md={12} sm={22} xs={22} colspan={16}>
                                <CheckPicker
                                    className="inf-picker"
                                    block
                                    id="e-loja-filter"
                                    filter={false}
                                    data={this.state.lojas}
                                    value={this.state.valueLojas}
                                    locale={{
                                        noResultsText: 'Nenhum item encontrado',
                                        searchPlaceholder: 'Pesquise a loja que deseja filtrar',
                                    }}
                                    name="lojas"
                                    placeholder="Lojas"
                                    onClean={this.handleFilterLoja}
                                    onSelect={this.handleSelectLoja}
                                    onChange={this.handleChangeLoja}
                                    onSearch={this.handleFilterLoja}
                                    maxHeight={200}
                                    renderExtraFooter={() => (
                                        <div style={footerStyles}>
                                            <Checkbox
                                                inline
                                                indeterminate={this.state.indeterminateLojas}
                                                checked={this.state.checkAllLojas}
                                                onChange={this.handleCheckAllLojas}
                                            >
                                                Selecionar tudo
                                                {' '}
                                                {this.state.lojas && this.state.lojas.length ? `(${this.state.lojas.length})` : null}
                                            </Checkbox>
                                        </div>
                                    )}
                                />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} lg={1} md={1} sm={2} xs={2}>
                                <Whisper
                                    placement="top"
                                    trigger="hover"
                                    speaker={(
                                        <Tooltip>
                                            <p>Filtro de loja</p>
                                            <p>Pesquise a loja por parte de sua descrição ou pelo seu identificador exato</p>
                                        </Tooltip>
                                    )}
                                >
                                    <Icon className="tooltip-info" icon="info" />
                                </Whisper>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} colspan={1} lg={1} md={1} sm={2} xs={2}>
                                <Whisper
                                    trigger="click"
                                    placement="bottomStart"
                                    speaker={(
                                        <MoreFilterDropdown
                                            className="popover-filtro-dinamico"
                                            filtersType={this.props.filtersType}
                                            selectedFilters={this.props.selectedFilters}
                                            filters={this.props.filters}
                                            addFilter={this.props.addFiltroExtracao}

                                        />
                                    )}
                                >
                                    <ButtonToolbar>
                                        <IconButton id="add-filter" icon={<Icon icon="plus" />}>Adicionar filtros</IconButton>
                                    </ButtonToolbar>
                                </Whisper>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                        <FlexboxGrid>
                            {this.props.selectedFilters.length ? this.props.selectedFilters.map((filtro, index) => (
                                <InputFilter
                                    key={filtro.id}
                                    index={index}
                                    filter={filtro}
                                    data={this.props.filtersData}
                                    handleDeleteFiltro={this.props.deleteFiltroExtracao}
                                    selectValue={this.handleSelectMoreFilter}
                                />
                            )) : ''}
                        </FlexboxGrid>
                    </Content>
                </div>
                <LoadingSpiner size="md" />
                {this.state.itens && this.state.itens.length
                    ? (
                        <Content>
                            <FlexboxGrid>
                                <FlexboxGrid.Item className="pd-top-10" componentClass={Col} colspan={10} lg={10} md={10} sm={24} xs={24}>
                                    <div className="total-itens">
                                        Exibindo
                                        {' '}
                                        {this.state.totalItens > 20 ? 20 : this.state.totalItens}
                                        {' '}
                                        de
                                        {' '}
                                        {this.state.totalItens}
                                        {' '}
                                        {this.state.totalItens > 1 ? 'itens' : 'item'}
                                        .
                                        {' '}
                                        {this.state.totalItens > 20 ? 'Para visualizar todos os itens faça o download' : null}
                                    </div>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item componentClass={Col} colspan={14} lg={14} md={14} sm={24} xs={24} style={{ textAlign: 'end' }}>
                                    <CheckPicker
                                        cleanable={false}
                                        data={colunas}
                                        placeholder="Colunas"
                                        style={{ width: 155 }}
                                        ref={(ref) => {
                                            this.picker = ref;
                                        }}
                                        filter
                                        className="checkpicker-colunas"
                                        appearance="subtle"
                                        placement="bottomEnd"
                                        value={this.state.valueColuna}
                                        onChange={this.handleChangeColuna}
                                        disabledItemValues={['DATA_COLETA', 'LOJA', 'PRODUTO']}
                                        locale={{ searchPlaceholder: 'Pesquisar', noResultsText: 'Nenhum item encontrado' }}
                                        renderExtraFooter={() => (
                                            <div style={footerStyles}>
                                                <Checkbox
                                                    inline
                                                    indeterminate={indeterminate}
                                                    checked={checkAll}
                                                    onChange={this.handleCheckAll}
                                                >
                                                    Selecionar tudo
                                                </Checkbox>
                                                <Button
                                                    style={footerButtonStyle}
                                                    appearance="primary"
                                                    size="sm"
                                                    onClick={() => {
                                                        this.picker.close();
                                                        this.confirmColuns();
                                                    }}
                                                >
                                                    Confirmar
                                                </Button>
                                            </div>
                                        )}
                                        renderValue={(value, items, selectedElement) => (
                                            <span>
                                                <span style={{ color: '#8e8e93' }}>
                                                    Colunas:
                                                </span>
                                                {' '}
                                                <span style={{
                                                    margin: '0 4px',
                                                    background: '#3498ff',
                                                    color: '#fff',
                                                    borderRadius: '10px',
                                                    padding: '0 10px',
                                                    lineHeight: '20px',
                                                }}
                                                >
                                                    {selectedElement.props.selectedItems.length}
                                                </span>
                                            </span>
                                        )}
                                    />
                                    <Dropdown
                                        id="download"
                                        placement="bottomEnd"
                                        title="Download"
                                        icon={<Icon icon="cloud-download" />}
                                        renderTitle={() => (
                                            <Button block appearance="primary">
                                                <Icon icon="cloud-download" />
                                                {' '}
                                                Download
                                                {' '}
                                            </Button>
                                        )}
                                    >
                                        <Dropdown.Item
                                            eventKey="csv"
                                            id="download-csv"
                                            onSelect={this.getDados}
                                            icon={<Icon icon="file-text-o" />}
                                        >
                                            .csv

                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            eventKey="xlsx"
                                            id="download-xlsx"
                                            onSelect={this.getDados}
                                            icon={<Icon icon="file-excel-o" />}
                                        >
                                            .xlsx

                                        </Dropdown.Item>
                                    </Dropdown>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item componentClass={Col} colspan={24} lg={24} md={24} sm={24} xs={24}>
                                    <Table
                                        className="table"
                                        height={300}
                                        data={this.state.itens}
                                        sortColumn={this.state.sortColumn}
                                        sortType={this.state.sortType}
                                        onSortColumn={(sortColumn, sortType) => {
                                            this.handleSortColumn(sortColumn, sortType);
                                        }}
                                    >

                                        {(colunasPreview && colunasPreview.length) ? colunasPreview.map((coluna, index) => (
                                            <Column
                                                key={coluna.objeto}
                                                width={
                                                    coluna.descricao.length <= 12 ? 150
                                                        : coluna.descricao.length > 12
                                                            && coluna.descricao.length < 20 ? 170
                                                            : coluna.descricao.length > 20 ? 230 : 250
                                                }
                                                resizable
                                                sortable
                                            >
                                                <HeaderCell>
                                                    {coluna.descricao}
                                                    <Whisper
                                                        placement="top"
                                                        trigger="hover"
                                                        speaker={(
                                                            <Tooltip>
                                                                {coluna.dica}
                                                            </Tooltip>
                                                        )}
                                                    >
                                                        <Icon icon="info" />
                                                    </Whisper>
                                                </HeaderCell>
                                                <Cell dataKey={coluna.objeto}>

                                                    {(rowData) => (

                                                        <span title={`${rowData[coluna.objeto]}`}>{rowData[coluna.objeto]}</span>
                                                    )}
                                                </Cell>
                                            </Column>
                                        )) : null}
                                    </Table>
                                    <Pagination
                                        showInfo={false}
                                        showLengthMenu={false}
                                        activePage={this.state.pagination ? this.state.pagination.pageNumber + 1 : null}
                                        displayLength={20}
                                        total={this.state.totalItens ? this.state.totalItens : null}
                                        onChangePage={this.handleChangePage}
                                    />
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </Content>
                    )
                    : null}
                {
                    this.state.emptyData
                        ? (
                            <Container>
                                <Content className="content-empty-state">
                                    <div className="empty-state">
                                        <Icon icon="collasped-o" className="empty-state-icon" />
                                        <h3>Não encontramos resultados que corresponda a sua busca.</h3>
                                        <h4>Tente novamente.</h4>
                                    </div>
                                </Content>
                            </Container>
                        ) : null
                }

                <ModalNotification
                    show={showModalDownload}
                    onHide={this.close}
                    title="Extração de dados"
                    message="O arquivo que você deseja baixar contém mais de cinquenta mil linhas e
                    pode demorar um pouco para ficar pronto. Te enviaremos por e-mail assim que estiver tudo certo!"
                    close={this.close}
                />
            </Container>
        );
    }
}

const mapStateToProps = (store) => ({
    codigoCliente: store.clienteDataReducer.codigoCliente,
    selectedFilters: store.extracaoDadosDataReducer.selectedFilters,
    filters: store.extracaoDadosDataReducer.filters,
    filtersType: store.extracaoDadosDataReducer.filtersType,
    filtersData: store.extracaoDadosDataReducer.filtersData,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    addFiltroExtracao,
    deleteFiltroExtracao,
    updateFilterDataExtracao,
    updateFilterValue,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ExtracaoDadosPage));

const footerStyles = {
    padding: '10px 2px',
    borderTop: '1px solid #e5e5e5',
};
const footerButtonStyle = {
    float: 'right',
    marginRight: 10,
    marginTop: 2,
};

import classNames from 'classnames';
import React, { forwardRef } from 'react';
import { MdGroupWork, MdStars } from 'react-icons/md';
import { Tooltip, Whisper } from 'rsuite';
import { TableBadge } from '../../../../../../components';
import styles from './ProductCell.module.scss';

type Product = {
    productId: string;
    description: string;
    productFamilyId?: string;
    isProductMaster?: boolean;
};

export type ProductCellProps = {
    product: Product;
} & React.HtmlHTMLAttributes<HTMLDivElement>;

export const ProductCell = forwardRef<HTMLDivElement, ProductCellProps>(
    ({ className, product, ...props }) => {
        const {
            productId = '',
            description = '--',
            productFamilyId = undefined,
            isProductMaster = false,
        } = product;
        return (
            <div className={classNames(styles.container, className)} {...props}>
                <div className={styles.header}>
                    <span className={styles.product_id}>{productId}</span>
                    {productFamilyId && (
                        <Whisper
                            delayShow={0.2}
                            trigger="hover"
                            placement="right"
                            speaker={<Tooltip>{productFamilyId}</Tooltip>}
                        >
                            <TableBadge skin="light-blue">
                                <MdGroupWork />
                                {productFamilyId}
                            </TableBadge>
                        </Whisper>
                    )}
                    {isProductMaster && (
                        <TableBadge skin="blue">
                            <MdStars />
                            PAI
                        </TableBadge>
                    )}
                </div>
                <Whisper
                    delayShow={0.2}
                    trigger="hover"
                    placement="right"
                    speaker={<Tooltip>{description}</Tooltip>}
                >
                    <p className={styles.description}>{description}</p>
                </Whisper>
            </div>
        );
    },
);

import React, { HtmlHTMLAttributes, Ref } from 'react';
import { IconButton } from '.';
import { FilterButtonProps } from '..';

export const FilterButton = React.forwardRef(
    (
        { icon, ...props }: FilterButtonProps,
        ref: Ref<HtmlHTMLAttributes<HTMLButtonElement>>,
    ) => (
        <IconButton
            ref={ref}
            icon={icon}
            data-testid="filter-button"
            {...props}
        />
    ),
);

import React, { useEffect, useReducer } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Alert, Container, Icon, Toggle } from 'rsuite';

import { lead } from '../../../../services/EventLeadService';
import { setRequestChangeStatus } from './services';

import { changeRequestStatus, sortNameConversion } from './data';

import { NotificationMessage } from '../../../../components';
import { LoadingSpiner } from '../../../../components/LoadingSpinner';
import ModalConfirm from '../../../../components/ModalConfirm';
import {
    reducerOnDemandSelector,
    resetOnDemandFilters,
    setOnDemandState,
    setSingleRequestData,
} from '../../../../reducers/reducerOnDemand';
import DataTableFilters from './Components/DataTableFilters';
import { ModalEditRequest } from './Components/ModalEditRequest';
import { ModalOnDemandPlan } from './Components/ModalOnDemandPlan';
import PageHeader from './Components/PageHeader';
import RequestDetailContainer from './Components/RequestDetailContainer/RequestDetailContainer';
import { ResumoArea } from './Components/ResumoArea';
import { TableOnDemand } from './Components/TableOnDemand';
import { RequestDetailsContent } from './Content/RequestDetailsContent';
import { RequestEditContent } from './Content/RequestEditContent';
import useOnDemandDownload from './Hooks/useOnDemandDownload';
import useOnDemandEditRequest from './Hooks/useOnDemandEditRequest';
import useOnDemandSummary from './Hooks/useOnDemandSummary';
import useOnDemandTableRequest from './Hooks/useOnDemandTableRequest';
import useSystemRequestSelectRegion from './Hooks/useSystemRequestSelectRegion';
import { initialState, onDemandReducer } from './Reducer/OnDemandReducer';

const OnDemand = () => {
    const [state, dispatch] = useReducer(onDemandReducer, initialState);
    const reduceDispatch = useDispatch();

    const hasNoFilters =
        state?.filters &&
        Object.values(state?.filters).every((e) => e === null);

    const { clienteData } = useSelector(
        (state) => ({
            clienteData: state.clienteDataReducer?.clienteData,
        }),
        shallowEqual,
    );

    const { singleRequestData, canEditSave, tableRequestBody, checkedItems } =
        useSelector(reducerOnDemandSelector);

    const { tableData, refetch } = useOnDemandTableRequest();

    const { summaryData, handleGetSummary } = useOnDemandSummary();

    const { ufData, cidadeData, handleGetUF, handleGetCidade } =
        useSystemRequestSelectRegion();

    const { editHandlers, editState, editCep } = useOnDemandEditRequest();
    const { handleDownload, tableIndividualDownload } = useOnDemandDownload();

    const hasNotSummary =
        summaryData?.available <= 0 ||
        summaryData?.available === null ||
        summaryData?.available === undefined;

    const handleChangePage = (page) => {
        reduceDispatch(
            setOnDemandState({
                name: 'tableRequestBody',
                data: {
                    ...tableRequestBody,
                    page: page - 1,
                },
            }),
        );
        setTimeout(() => {
            refetch();
        }, 200);
    };

    const showResult = () => {
        refetch();
        setTimeout(() => {
            handleGetSummary();
        }, 300);
    };

    const handleConfirmChangeStatus = async (item) => {
        const data = {
            ids: item.ids,
            status: item.status,
        };

        if (item.type === '') {
            const response = await setRequestChangeStatus(data);

            if (response.status === 200) {
                NotificationMessage(
                    'success',
                    5000,
                    'Status da solicitação alterado com sucesso',
                );
                showResult();
            }
        }
    };

    const sendCTA = () => {
        const identificador = 'upsell_infopanel';

        lead(identificador, clienteData).then(() => {
            dispatch({
                type: 'SET_SHOW_MODAL_PLAN',
                showModalPlan: false,
            });
            Alert.success('Em breve entraremos em contato!');
        });
    };

    const handleChangeCheckboxAll = (value, check) => {
        if (check) {
            const ids = tableData?.content.map((item) => item.idSolicitacao);
            reduceDispatch(
                setOnDemandState({
                    name: 'checkedItems',
                    data: ids,
                }),
            );
        } else {
            reduceDispatch(
                setOnDemandState({
                    name: 'checkedItems',
                    data: [],
                }),
            );

            reduceDispatch(
                setOnDemandState({
                    name: 'tableRequestBody',
                    data: {
                        ...tableRequestBody,
                        requestdAll: false,
                    },
                }),
            );
        }
    };

    const handleChangeCheckbox = (value, checked) => {
        const ids = checked
            ? [...checkedItems, value]
            : checkedItems.filter((item) => item !== value);

        reduceDispatch(
            setOnDemandState({
                name: 'checkedItems',
                data: ids,
            }),
        );

        if (!checked) {
            reduceDispatch(
                setOnDemandState({
                    name: 'tableRequestBody',
                    data: {
                        ...tableRequestBody,
                        requestdAll: false,
                    },
                }),
            );
        }
    };

    const handleTableIndividualItem = (value, id) => {
        const type = {
            1: 'edit',
            2: 'CSV',
            3: 'XLSX',
        };

        if (value === 1) {
            const requestData = tableData.content.filter(
                (item) => item.idSolicitacao === id,
            );
            editState.setOpenEditModal(true);
            reduceDispatch(
                setSingleRequestData({
                    name: 'singleRequestData',
                    data: requestData[0],
                }),
            );

            setTimeout(() => {
                handleGetUF(requestData[0]?.cidade);
            }, 300);
            handleGetCidade(requestData[0]?.uf);
        } else {
            tableIndividualDownload(type[value], id);
        }
    };

    const handleSelectWholeList = () => {
        const ids = tableData?.content.map((item) => item.idSolicitacao);

        reduceDispatch(
            setOnDemandState({
                name: 'tableRequestBody',
                data: {
                    ...tableRequestBody,
                    requestdAll: true,
                },
            }),
        );
        reduceDispatch(
            setOnDemandState({
                name: 'checkedItems',
                data: ids,
            }),
        );
    };

    const handleSortTableColumn = (column, type) => {
        const orderType = {
            asc: 'ASC',
            desc: 'DESC',
        };
        reduceDispatch(
            setOnDemandState({
                name: 'tableRequestBody',
                data: {
                    ...tableRequestBody,
                    order: { [sortNameConversion[column]]: orderType[type] },
                },
            }),
        );
        setTimeout(() => {
            refetch();
        }, 100);
    };

    const handleShowChangeStatusModal = (id, status) => {
        if (id && hasNotSummary && status === true) {
            dispatch({
                type: 'SET_STATUS_CHANGE_MODAL_TYPE',
                statusChangeModalType: 'notAvailable',
            });
        }

        if (id && summaryData?.available > 0 && status === true) {
            dispatch({
                type: 'SET_STATUS_CHANGE_MODAL_TYPE',
                statusChangeModalType: 'statusTrue',
            });
        }

        if (id && status === false) {
            dispatch({
                type: 'SET_STATUS_CHANGE_MODAL_TYPE',
                statusChangeModalType: 'statusFalse',
            });
        }

        dispatch({
            type: 'SET_OPEN_STATUS_CHANGE_MODAL',
            openStatusChangeModal: true,
        });

        dispatch({
            type: 'SET_ITEM_DATA',
            itemData: {
                ...state.itemData,
                ids: [id],
                status,
                type:
                    hasNotSummary && state.toggleIsActive === true
                        ? 'notAvailable'
                        : '',
            },
        });
    };

    const handleBulkSelectionSave = () => {
        if (
            (hasNotSummary || checkedItems.length > summaryData?.available) &&
            state.toggleIsActive === true
        ) {
            dispatch({
                type: 'SET_STATUS_CHANGE_MODAL_TYPE',
                statusChangeModalType: 'notAvailable',
            });
            dispatch({
                type: 'SET_OPEN_STATUS_CHANGE_MODAL',
                openStatusChangeModal: true,
            });
            dispatch({
                type: 'SET_OPEN_BULK_SELECTION_MODAL',
                openBulkSelectionModal: false,
            });
        } else {
            handleConfirmChangeStatus(state.itemData);
            dispatch({
                type: 'SET_OPEN_BULK_SELECTION_MODAL',
                openBulkSelectionModal: false,
            });
            reduceDispatch(
                setOnDemandState({
                    name: 'checkedItems',
                    data: [],
                }),
            );
        }
    };

    const handleOnConfirmBulkStatusChangeModal = () => {
        handleConfirmChangeStatus(state.itemData);
        dispatch({
            type: 'SET_OPEN_STATUS_CHANGE_MODAL',
            openStatusChangeModal: false,
        });
        state.statusChangeModalType === 'notAvailable' &&
            window.open('https://infoprice-support.freshdesk.com/support/home');
    };

    useEffect(() => {
        setTimeout(() => {
            handleGetSummary();
        }, 300);

        return () => {
            reduceDispatch(resetOnDemandFilters());
        };
    }, []);

    useEffect(() => {
        if (checkedItems.length) {
            dispatch({
                type: 'SET_ITEM_DATA',
                itemData: {
                    ...state.itemData,
                    ids: checkedItems,
                    status: state.toggleIsActive,
                    type:
                        hasNotSummary && state.toggleIsActive === true
                            ? 'notAvailable'
                            : '',
                },
            });
        }
    }, [checkedItems, state.toggleIsActive, hasNotSummary]);

    return (
        <Container className="main-container on-demand-page">
            <LoadingSpiner size="md" />
            {/* <BannerOnDemand /> */}
            <PageHeader showResultList={showResult} />
            <ResumoArea
                requestSummaryData={summaryData}
                onClickShowModalPlan={() =>
                    dispatch({
                        type: 'SET_SHOW_MODAL_PLAN',
                        showModalPlan: true,
                    })
                }
            />
            <RequestDetailContainer
                requests={tableData}
                onChangePage={handleChangePage}
                download={(extension) =>
                    handleDownload(extension, tableData, checkedItems)
                }
                summaryData={summaryData}
                onConfirmStatusChange={handleConfirmChangeStatus}
                onClickSelectWholeList={handleSelectWholeList}
                onClickUpdateStatus={() =>
                    dispatch({
                        type: 'SET_OPEN_BULK_SELECTION_MODAL',
                        openBulkSelectionModal: true,
                    })
                }
                checkedItems={checkedItems}
                filterArea={<DataTableFilters />}
                tableArea={
                    <TableOnDemand
                        requests={tableData}
                        summaryData={summaryData}
                        onChangePage={handleChangePage}
                        onChangeCheckAll={handleChangeCheckboxAll}
                        onChangeCheck={handleChangeCheckbox}
                        onSelectTableIndividualItem={handleTableIndividualItem}
                        onSortColumn={handleSortTableColumn}
                        checkedItems={checkedItems}
                        onChangeIndividualStatus={handleShowChangeStatusModal}
                        onClickSelectWholeList={handleSelectWholeList}
                    />
                }
                // onSelectRowMenu={handleSelectTableRowMenu}
            />
            <ModalOnDemandPlan
                show={state?.showModalPlan}
                requestSummaryData={summaryData}
                onCancel={() =>
                    dispatch({
                        type: 'SET_SHOW_MODAL_PLAN',
                        showModalPlan: false,
                    })
                }
                onConfirm={sendCTA}
            />
            <ModalConfirm
                show={state.openStatusChangeModal}
                modalWidth="500px"
                titleFontSize="20px"
                textAlign="center"
                footerAlign="center"
                cancelButtonWidth={
                    changeRequestStatus[state.statusChangeModalType]
                        ?.cancelBtnWidth
                }
                confirmButtonWidth={
                    changeRequestStatus[state.statusChangeModalType]
                        ?.confirmBtnWidth
                }
                icon={changeRequestStatus[state.statusChangeModalType]?.icon}
                title={changeRequestStatus[state.statusChangeModalType]?.title}
                message={
                    changeRequestStatus[state.statusChangeModalType]?.message
                }
                confirmButton={
                    changeRequestStatus[state.statusChangeModalType]
                        ?.confirmButton
                }
                cancelButton={
                    changeRequestStatus[state.statusChangeModalType]
                        ?.cancelButton
                }
                onCancel={() => {
                    dispatch({
                        type: 'SET_OPEN_STATUS_CHANGE_MODAL',
                        openStatusChangeModal: false,
                    });
                }}
                onConfirm={() => handleOnConfirmBulkStatusChangeModal()}
            />
            <ModalConfirm
                show={state.openBulkSelectionModal}
                className="modal-confirm-bulk-selection"
                title="Alterar status das solicitações"
                message="Todas as solicitações selecionadas serão alteradas para:"
                confirmButton="SALVAR"
                cancelButton="CANCELAR"
                footerAlign="center"
                textAlign="center"
                confirmButtonWidth="183px"
                cancelButtonWidth="183px"
                size="xs"
                titleFontSize="20px"
                modalWidth="430px"
                onCancel={() =>
                    dispatch({
                        type: 'SET_OPEN_BULK_SELECTION_MODAL',
                        openBulkSelectionModal: false,
                    })
                }
                onConfirm={handleBulkSelectionSave}
            >
                <div className="modal-confirm-bulk-selection__body">
                    <span
                        style={{
                            fontWeight: !state.toggleIsActive
                                ? 'bold'
                                : 'normal',
                        }}
                    >
                        Inativas
                    </span>
                    <Toggle
                        checkedChildren={
                            <Icon
                                icon="check"
                                style={{
                                    fontSize: '10px',
                                    verticalAlign: 'unset',
                                }}
                            />
                        }
                        unCheckedChildren={
                            <Icon
                                icon="close"
                                style={{
                                    fontSize: '10px',
                                    verticalAlign: 'unset',
                                }}
                            />
                        }
                        checked={state.toggleIsActive}
                        onChange={(value) =>
                            dispatch({
                                type: 'SET_TOGGLE_IS_ACTIVE',
                                toggleIsActive: value,
                            })
                        }
                    />
                    <span
                        style={{
                            fontWeight: state.toggleIsActive
                                ? 'bold'
                                : 'normal',
                        }}
                    >
                        Ativas
                    </span>
                </div>
            </ModalConfirm>
            <ModalEditRequest
                show={editState.openEditModal}
                onConfirm={editHandlers.handleEditConfirm}
                onCancel={editHandlers.handleOnCancelEditModal}
                contentType={editState.modalEditContent}
                canSave={canEditSave}
                content1={
                    <RequestEditContent
                        dataUF={ufData}
                        dataCidade={cidadeData}
                        dataValue={singleRequestData}
                        errorCEP={editCep.isError}
                        cepLoading={editCep.isFetching}
                        onOpenUF={handleGetUF}
                        onOpenCidade={handleGetCidade}
                        onSearchCidade={handleGetCidade}
                        onSelectRegion={
                            editHandlers.handleChangeSingleRequestData
                        }
                        onChangeCep={editHandlers.handleOnChangeCep}
                        onChangeGTIN={
                            editHandlers.handleChangeSingleRequestData
                        }
                        onChangeKeyword={
                            editHandlers.handleChangeSingleRequestData
                        }
                        onChangeLogradouro={
                            editHandlers.handleChangeSingleRequestData
                        }
                        onChangeNumeroLogradouro={
                            editHandlers.handleChangeSingleRequestData
                        }
                        onChangeRaio={
                            editHandlers.handleChangeSingleRequestData
                        }
                        onChangeRadioProduct={
                            editHandlers.handleChangeProductType
                        }
                    />
                }
                content2={<RequestDetailsContent data={singleRequestData} />}
            />
        </Container>
    );
};

export default withRouter(OnDemand);

import classNames from 'classnames';
import React, { CSSProperties } from 'react';
import {
    ButtonGroup,
    Checkbox,
    CheckboxGroup,
    Dropdown,
    InputNumber,
} from 'rsuite';
import {
    CompetitorPriceSourcesDataList,
    ICompetitorPriceSources,
} from '../../../../Entities/CompetitorPriceSources';
import { Tag, TagProps } from '../../../../Lite';
import styles from './FilterSelectInterval.module.scss';
import { IconTypes } from './Icon';
import { IconButton } from './IconButton';

type FilterSelectIntervalProps = {
    icon?: IconTypes | React.ReactNode;
    prefixLabel?: string;
    unitLabel?: string;
    menuStyle: CSSProperties;
    skin?: 'neutral' | 'primary' | 'error';
    onClose?: () => void;
    maxValue?: number;
    defaultValue?: string;
    placeholder?: string;
    onClick?: (event: React.SyntheticEvent<Element, Event>) => void;
    onChange?:
        | ((
              value: string | number,
              event?: React.SyntheticEvent<any, Event> | undefined,
          ) => void)
        | undefined;
    onSelect?: (
        eventKey: any,
        event: React.MouseEvent<HTMLElement, MouseEvent>,
    ) => void;
    competitorPriceSources: {
        value: ICompetitorPriceSources[];
        onChange?: (value: ICompetitorPriceSources[]) => void;
    };
};

const TAGS: Record<
    ICompetitorPriceSources,
    (props: TagProps) => React.JSX.Element
> = {
    MPDV: (props) => <Tag {...props}>MPDV</Tag>,
    INFOPANEL: (props) => <Tag {...props}>INFOPANEL</Tag>,
    INDIRECT: (props) => <Tag {...props}>INFOPANEL</Tag>,
    PROPRIA: (props) => (
        <Tag {...props} skin="gray" className={styles['the-gray-one']}>
            EXTERNO
        </Tag>
    ),
};

export const FilterSelectInterval = ({
    icon = 'MdSelectAll',
    prefixLabel,
    unitLabel,
    menuStyle,
    placeholder,
    skin = 'neutral',
    onSelect,
    onClick,
    onClose,
    onChange,
    maxValue,
    defaultValue,
    competitorPriceSources,
}: FilterSelectIntervalProps) => {
    return (
        <ButtonGroup
            className={classNames(
                'quick-action-filter__button__container quick-action-filter__toggle',
                styles.container,
            )}
            data-testid="filter-select"
        >
            <IconButton
                icon={icon}
                placeholder={placeholder}
                skin={skin}
                onClick={onClick}
                data-testid="placeholder-icon"
            />
            <Dropdown
                activeKey="1"
                eventKey="1"
                menuStyle={menuStyle}
                onSelect={onSelect}
                placement="bottomEnd"
                onClose={onClose}
                renderTitle={() => (
                    <IconButton
                        icon="MdKeyboardArrowDown"
                        className="quick-action-filter__toggle__arrow"
                        skin={skin}
                    />
                )}
                className={styles['dropdown-container']}
            >
                <Dropdown.Item
                    panel
                    className="quick-action-filter__select-interval"
                >
                    <div className="quick-action-filter__select-interval__dropdown-item">
                        <p>{prefixLabel}</p>
                        <InputNumber
                            min={0}
                            max={maxValue}
                            defaultValue={defaultValue}
                            onChange={onChange}
                        />
                        <p>{unitLabel}</p>
                    </div>
                </Dropdown.Item>

                <Dropdown.Item panel>
                    <CheckboxGroup
                        name="competitorPriceSources"
                        value={competitorPriceSources.value}
                        onChange={competitorPriceSources.onChange}
                    >
                        <p>Origem do preço concorrente:</p>
                        {CompetitorPriceSourcesDataList.map((item) => {
                            const RenderTag = TAGS[item.value];
                            return (
                                <div className={styles['flex-item']}>
                                    <Checkbox value={item.value}>
                                        {item.label}
                                    </Checkbox>
                                    <RenderTag
                                        skin="blue"
                                        size="small"
                                        outlined
                                    />
                                </div>
                            );
                        })}
                    </CheckboxGroup>
                </Dropdown.Item>
            </Dropdown>
        </ButtonGroup>
    );
};

import classNames from 'classnames';
import React, { HtmlHTMLAttributes, useState } from 'react';
import {
    SetupMenu,
    SetupMenuItem,
    SetupMenuItemProps,
} from '../../../../../components';
import styles from './SetupIPA.module.scss';
import { SetupIPAForm } from './elements';

const MENU_ITEMS: SetupMenuItemProps[] = [
    {
        id: 'setup-menu__item-1',
        children: 'Dados do InfoPanel',
    },
];

const SETUP_PANELS = [<SetupIPAForm />];

export const SetupIPA = ({
    className,
    ...props
}: HtmlHTMLAttributes<HTMLDivElement>) => {
    const [selectedMenu, setSelectedMenu] = useState<number>(0);

    return (
        <div className={classNames(styles['setup-ipa'], className)} {...props}>
            <SetupMenu className={styles['setup-ipa__setup-menu']}>
                {MENU_ITEMS.map(({ id, ...item }, index) => (
                    <SetupMenuItem
                        key={id}
                        onClick={() => setSelectedMenu(index)}
                        isActive={selectedMenu === index}
                        {...item}
                    />
                ))}
            </SetupMenu>

            {SETUP_PANELS?.[selectedMenu]}
        </div>
    );
};

import { format } from 'date-fns';
import React from 'react';
import { useSelector } from 'react-redux';
import { Checkbox, DateRangePicker } from 'rsuite';
import DateRangePickerPill from '../../../../../components/DateRangePickerPill';
import { LoadingMenu } from '../../../../../components/LoadingSpinner';
import SelectPill from '../../../../../components/SelectPill';
import { reducerOnDemandSelector } from '../../../../../reducers/reducerOnDemand';
import useOnDemandSelectFilters from '../Hooks/useOnDemandSelectFilters';
import { activeFilter } from '../data';
import { dateLocale } from '../utils';

const DataTableFilters = () => {
    const { afterToday } = DateRangePicker;

    const { requestId, barCode, keyword, creator, uf, cities, radius } =
        useSelector(reducerOnDemandSelector);

    const {
        handleOnSearchOnDemandFilter,
        handleSelectOnDemandFilter,
        handleSelectOnDemandFilterStatus,
        handleOnCloseOnDemandFilter,
        handleClearOnDemandFilter,
        handleSelectAllFilter,
    } = useOnDemandSelectFilters();

    const renderMenu = (menu) => (
        <span>
            <LoadingMenu area="request-filter" />
            {menu}
        </span>
    );

    const renderFooter = (name) => {
        const indeterminate =
            name?.value?.length !== name?.data?.length &&
            name?.value?.length > 0;
        const checked =
            name?.data?.length && name?.value?.length === name?.data?.length;

        return (
            <div className="product-filter-menu__footer">
                <Checkbox
                    inline
                    indeterminate={indeterminate}
                    checked={checked}
                    onChange={(value, checked) =>
                        handleSelectAllFilter(name, checked)
                    }
                >
                    Selecionar tudo
                </Checkbox>
            </div>
        );
    };

    return (
        <div className="data-table-filters-container">
            <SelectPill
                type="check"
                id="check-picker-pill"
                placeholder="ID da solicitação"
                name="requestId"
                filter={false}
                value={requestId?.value}
                data={requestId?.data}
                onSelect={(value, item) =>
                    handleSelectOnDemandFilter('requestId', value, item)
                }
                onSearch={(e) => handleOnSearchOnDemandFilter('requestId', e)}
                onOpen={() => handleOnSearchOnDemandFilter('requestId', '')}
                onClean={() => handleClearOnDemandFilter('requestId')}
                onClose={() => handleOnCloseOnDemandFilter('requestId')}
                renderMenu={renderMenu}
                locale={{
                    noResultsText: 'Nenhum item encontrado',
                    searchPlaceholder: 'Pesquisar',
                }}
                renderExtraFooter={() => renderFooter('requestId')}
            />
            <SelectPill
                type="check"
                id="check-picker-pill"
                placeholder="Código de barras"
                name="barcode"
                filter={false}
                value={barCode?.value}
                data={barCode?.data}
                onSelect={(value, item) =>
                    handleSelectOnDemandFilter('barCode', value, item)
                }
                onSearch={(e) => handleOnSearchOnDemandFilter('barCode', e)}
                onOpen={() => handleOnSearchOnDemandFilter('barCode', '')}
                onClean={() => handleClearOnDemandFilter('barCode')}
                onClose={() => handleOnCloseOnDemandFilter('barCode')}
                renderMenu={renderMenu}
                locale={{
                    noResultsText: 'Nenhum item encontrado',
                    searchPlaceholder: 'Pesquisar',
                }}
                renderExtraFooter={() => renderFooter('barCode')}
            />
            <SelectPill
                type="check"
                id="check-picker-pill"
                placeholder="Palavra chave"
                name="keyword"
                filter={false}
                value={keyword?.value}
                data={keyword?.data}
                onSelect={(value, item) =>
                    handleSelectOnDemandFilter('keyword', value, item)
                }
                onSearch={(e) => handleOnSearchOnDemandFilter('keyword', e)}
                onOpen={() => handleOnSearchOnDemandFilter('keyword', '')}
                onClean={() => handleClearOnDemandFilter('keyword')}
                onClose={() => handleOnCloseOnDemandFilter('keyword')}
                renderMenu={renderMenu}
                locale={{
                    noResultsText: 'Nenhum item encontrado',
                    searchPlaceholder: 'Pesquisar',
                }}
                renderExtraFooter={() => renderFooter('keyword')}
            />
            <DateRangePickerPill
                id="date-range-picker"
                placeholder="Data criação"
                onOk={(value) => handleSelectOnDemandFilter(value, {}, 'date')}
                onClean={() => handleClearOnDemandFilter('date')}
                onClose={() => handleOnCloseOnDemandFilter('date')}
                locale={dateLocale}
                renderValue={(value) => (
                    <>
                        <span>{format(value[0], 'dd/MM/yyyy')}</span>
                        {' - '}
                        <span>{format(value[1], 'dd/MM/yyyy')}</span>
                    </>
                )}
                ranges={[]}
                disabledDate={afterToday()}
            />
            <SelectPill
                type="check"
                id="check-picker-pill"
                placeholder="Criador"
                name="creator"
                filter={false}
                value={creator?.value}
                data={creator?.data}
                onSelect={(value, item) =>
                    handleSelectOnDemandFilter('creator', value, item)
                }
                onSearch={(e) => handleOnSearchOnDemandFilter('creator', e)}
                onOpen={() => handleOnSearchOnDemandFilter('creator', '')}
                onClean={() => handleClearOnDemandFilter('creator')}
                onClose={() => handleOnCloseOnDemandFilter('creator')}
                renderMenu={renderMenu}
                locale={{
                    noResultsText: 'Nenhum item encontrado',
                    searchPlaceholder: 'Pesquisar',
                }}
                renderExtraFooter={() => renderFooter('creator')}
            />
            <SelectPill
                type="check"
                id="check-picker-pill"
                placeholder="UF"
                name="uf"
                filter={false}
                value={uf?.value}
                data={uf?.data}
                onSelect={(value, item) =>
                    handleSelectOnDemandFilter('uf', value, item)
                }
                onSearch={(e) => handleOnSearchOnDemandFilter('uf', e)}
                onOpen={() => handleOnSearchOnDemandFilter('uf', '')}
                onClean={() => handleClearOnDemandFilter('uf')}
                onClose={() => handleOnCloseOnDemandFilter('uf')}
                renderMenu={renderMenu}
                locale={{
                    noResultsText: 'Nenhum item encontrado',
                    searchPlaceholder: 'Pesquisar',
                }}
                renderExtraFooter={() => renderFooter('uf')}
            />
            <SelectPill
                type="check"
                id="check-picker-pill"
                placeholder="Cidade"
                name="cities"
                filter={false}
                value={cities?.value}
                data={cities?.data}
                onSelect={(value, item) =>
                    handleSelectOnDemandFilter('cities', value, item)
                }
                onSearch={(e) => handleOnSearchOnDemandFilter('cities', e)}
                onOpen={() => handleOnSearchOnDemandFilter('cities', '')}
                onClean={() => handleClearOnDemandFilter('cities')}
                onClose={() => handleOnCloseOnDemandFilter('cities')}
                renderMenu={renderMenu}
                locale={{
                    noResultsText: 'Nenhum item encontrado',
                    searchPlaceholder: 'Pesquisar',
                }}
                renderExtraFooter={() => renderFooter('cities')}
            />
            <SelectPill
                type="check"
                id="check-picker-pill"
                placeholder="Raio"
                name="radius"
                filter={false}
                value={radius?.value}
                data={radius?.data}
                onSelect={(value, item) =>
                    handleSelectOnDemandFilter('radius', value, item)
                }
                onOpen={() => handleOnSearchOnDemandFilter('radius', '')}
                onClean={() => handleClearOnDemandFilter('radius')}
                onClose={() => handleOnCloseOnDemandFilter('radius')}
                renderMenu={renderMenu}
                locale={{
                    noResultsText: 'Nenhum item encontrado',
                    searchPlaceholder: 'Pesquisar',
                }}
                renderExtraFooter={() => renderFooter('radius')}
            />
            <SelectPill
                id="select-picker-pill"
                placeholder="Status"
                searchable={false}
                data={activeFilter}
                onSelect={(value) =>
                    handleSelectOnDemandFilterStatus(value, {})
                }
                onClean={() => handleClearOnDemandFilter('status')}
            />
        </div>
    );
};

export default DataTableFilters;

import classNames from 'classnames';
import React from 'react';
import CurrencyFormat from 'react-currency-format';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { CompetitivenesIcon, ICONS_TYPE } from '../../CompetitivenesIcon';
import { LinkButton } from '../../LinkButton';
import { BaseCell } from '../BaseCell';
import { BaseCellProps } from '../BaseCell/BaseCell';
import styles from './CompetitorPriceCell.module.scss';

export type CompetitorPriceCellProps = BaseCellProps & {
    competitorsPrice: number | null;
    competitivenessIndicator: ICONS_TYPE;
    onOpenRow?: (isOpen?: boolean) => void;
    isOpen?: boolean;
};

const CompetitorPriceCell = ({
    className,
    competitorsPrice,
    competitivenessIndicator,
    onOpenRow,
    isOpen = false,
    ...props
}: CompetitorPriceCellProps) => {
    return (
        <BaseCell
            className={classNames(styles.container, className)}
            {...props}
        >
            <BaseCell.MainContainer>
                {competitorsPrice ? (
                    <CurrencyFormat
                        fixedDecimalScale
                        decimalScale={2}
                        value={competitorsPrice ?? null}
                        displayType="text"
                        prefix="R$ "
                        decimalSeparator=","
                        thousandSeparator="."
                    />
                ) : (
                    '--'
                )}
                <CompetitivenesIcon
                    type={
                        competitivenessIndicator ||
                        (competitorsPrice && 'RULE_CPI_AVG') ||
                        null
                    }
                />
            </BaseCell.MainContainer>
            <BaseCell.SubContainer>
                <LinkButton
                    onClick={() => {
                        onOpenRow?.(isOpen);
                    }}
                >
                    {isOpen ? (
                        <>
                            <MdKeyboardArrowUp size={10} />
                            <p>Fechar</p>
                        </>
                    ) : (
                        <>
                            <MdKeyboardArrowDown size={10} />
                            <p>Abrir</p>
                        </>
                    )}
                </LinkButton>
            </BaseCell.SubContainer>
        </BaseCell>
    );
};

export default CompetitorPriceCell;

import React, { useEffect, useReducer } from 'react';
import { Input, RadioGroup } from 'rsuite';
import Modal, { ModalProps } from 'rsuite/lib/Modal';
import { Label, Radio } from '../../../../../../../../components';
import { LoadingSpinerArea } from '../../../../../../../../components/LoadingSpinner';
import { ButtonPrimary } from '../../../../../../Lite';
import './style.scss';

export type SalvarFiltrosProps = ModalProps & {
    name?: string;
    onSubmit?: (name: string, selectedOption: 'edit' | 'save') => void;
};

const OPTIONS = [
    {
        id: 1,
        value: 'edit',
        label: 'Substituir filtro salvo',
    },
    {
        id: 2,
        value: 'save',
        label: 'Salvar como novo filtro',
    },
];

type Data = {
    name: string;
    option: 'edit' | 'save';
}

const initialData: Data = {
    name: '',
    option: 'save',
};

type Action = {
    type: 'RESET';
} | {
    type: 'SET_NAME';
    payload: Data['name'];
} | {
    type: 'SET_OPTION';
    payload: Data['option'];
};

const reducer = (data: Data, action: Action): Data => {
    switch (action.type) {
        case 'RESET':
            return initialData;
        case 'SET_NAME':
            return { ...data, name: action.payload };
        case 'SET_OPTION':
            return { ...data, option: action.payload };
        default:
            return data;
    }
};

export const SalvarFiltrosModal = ({
    className = '',
    name,
    onHide,
    onSubmit,
    ...props
}: SalvarFiltrosProps) => {
    const [data, dispatch] = useReducer(reducer, initialData);

    const setName = (name: Data['name']) => {
        dispatch({
            type: 'SET_NAME',
            payload: name,
        });
    };

    const setOption = (option: Data['option']) => {
        dispatch({
            type: 'SET_OPTION',
            payload: option,
        });
    };

    const IS_SAVED_FILTER = !!name?.length;

    const HAS_INPUT_VALUE = !!data.name.length;

    const IS_DISABLED = !HAS_INPUT_VALUE || !data.option;

    useEffect(() => {
        setName(name ?? '');
    }, [name]);

    useEffect(() => {
        if (IS_SAVED_FILTER) {
            setOption('edit');
            return;
        }
        setOption('save');
    }, [name]);

    return (
        <Modal
            className={['info-modal', 'modal-salvar-filtros', className].join(
                ' ',
            )}
            onHide={onHide}
            {...props}
        >
            <form onSubmit={(e) => e.preventDefault()}>
                <Modal.Header>
                    <Modal.Title className="font-size-200-semibold">
                        Salvar filtro
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {IS_SAVED_FILTER && (
                        <RadioGroup
                            value={data.option}
                            onChange={(value) => setOption(value)}
                        >
                            {OPTIONS.map(({
                                id, value, label, ...rest
                            }) => (
                                <Radio key={id} value={value} {...rest}>
                                    {label}
                                </Radio>
                            ))}
                        </RadioGroup>
                    )}
                    <div className="modal-salvar-filtros__filter-name-container">
                        <Label
                            htmlFor="filter-name"
                            className="font-size-100-regular"
                        >
                            Nome do filtro
                        </Label>
                        <Input
                            id="filter-name"
                            value={data.name}
                            onChange={(value) => {
                                setName(value);
                            }}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonPrimary
                        skin="blue"
                        theme="ghost"
                        type="button"
                        onClick={onHide}
                    >
                        FECHAR
                    </ButtonPrimary>
                    <ButtonPrimary
                        skin="blue"
                        theme="filled"
                        onClick={() => {
                            if (!data.option) return;
                            onSubmit?.(data.name, data.option);
                        }}
                        type="submit"
                        disabled={IS_DISABLED}
                    >
                        SALVAR
                    </ButtonPrimary>
                </Modal.Footer>
            </form>
            <LoadingSpinerArea size="sm" area="saved-filter" height={null} />
        </Modal>
    );
};

import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import api from '../../../../utils/API';

const apiOnDemand = axios.create({
    responseType: 'json',
});

apiOnDemand.interceptors.request.use(
    (config) => {
        config.headers = {
            Accept: config.download
                ? 'application/octet-stream'
                : 'application/json',
            Authorization: config.token
                ? `${config.uploadToken ? '' : 'Bearer '}${config.token}`
                : config.expectToken
                ? `Bearer ${localStorage.getItem('autenticacao.token')}`
                : config.uploadToken
                ? localStorage.getItem('autenticacao.token')
                : 'Basic bXktdHJ1c3RlZC1jbGllbnQ6c2VjcmV0',
            'Content-Type': config.headers.contentType
                ? config.headers.contentType
                : 'application/json; charset=utf-8',
        };
        return config;
    },
    (error) => Promise.reject(error),
);

apiOnDemand.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject(error),
);

export const getModelo = () =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_ON_DEMAND_URL}/ondemand/template`,
            method: 'GET',
            expectToken: true,
            responseType: 'arraybuffer',
        }).then((res) => res),
    );

export const setData = (client, file, setProgress, controller) =>
    trackPromise(
        apiOnDemand({
            url: `${process.env.REACT_APP_ON_DEMAND_URL}/ondemand/bulk`,
            method: 'POST',
            params: {
                client,
            },
            headers: {
                contentType: 'multipart/form-data',
            },
            data: file,
            expectToken: true,
            signal: controller.signal,
            onUploadProgress: (e) => {
                const progress = parseInt(
                    Math.round((e.loaded * 99) / e.total),
                    10,
                );
                setProgress(progress);
            },
        }).then((resp) => resp.data),
    );

export const getSummary = () =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_ON_DEMAND_URL}/ondemand/summary`,
            method: 'GET',
            expectToken: true,
        }).then((resp) => resp.data),
    );

export const getRequests = (clientId, data, pagination) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_ON_DEMAND_URL}/ondemand/requests/${clientId}`,
            params: {
                pageSize: pagination.size || null,
                pageNumber: pagination.page || 0,
            },
            method: 'POST',
            data: JSON.stringify(data),
            expectToken: true,
        }).then((resp) => resp.data),
    );

export const setRequestChangeStatus = (data) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_ON_DEMAND_URL}/ondemand/update/status`,
            method: 'PUT',
            data: JSON.stringify(data),
            expectToken: true,
        }).then((res) => res),
    );

export const getBarcode = (clientId, query) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_ON_DEMAND_URL}/ondemand/requests/filter/barcode`,
            method: 'GET',
            params: {
                query,
            },
            expectToken: true,
        }).then((resp) => resp.data),
        'request-filter',
    );

export const getCreator = (clientId, query) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_ON_DEMAND_URL}/ondemand/requests/filter/userLogin`,
            method: 'GET',
            params: {
                query,
            },
            expectToken: true,
        }).then((resp) => resp.data),
        'request-filter',
    );

export const getState = (clientId, query) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_ON_DEMAND_URL}/ondemand/requests/filter/state`,
            method: 'GET',
            params: {
                query,
            },
            expectToken: true,
        }).then((resp) => resp.data),
        'request-filter',
    );

export const getCity = (clientId, query) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_ON_DEMAND_URL}/ondemand/requests/filter/city`,
            method: 'GET',
            params: {
                query,
            },
            expectToken: true,
        }).then((resp) => resp.data),
        'request-filter',
    );

export const getRadius = (clientId) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_ON_DEMAND_URL}/ondemand/requests/filter/radius`,
            method: 'GET',
            expectToken: true,
        }).then((resp) => resp.data),
        'request-filter',
    );

export const download = (data) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_ON_DEMAND_URL}/ondemand/download`,
            method: 'POST',
            data: JSON.stringify(data),
            expectToken: true,
            responseType: 'arraybuffer',
        }).then((res) => res),
    );

export const downloadInfopanel = (data) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_ON_DEMAND_URL}/ondemand/download/infopanel`,
            method: 'POST',
            data: JSON.stringify(data),
            expectToken: true,
            responseType: 'arraybuffer',
        }).then((res) => res),
    );

export const getTableDetailRequest = (data) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_ON_DEMAND_URL}/ondemand/filters`,
            method: 'POST',
            data: JSON.stringify(data),
            expectToken: true,
        }).then((res) => res),
    );

export const getFiltersData = (data) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_ON_DEMAND_URL}/ondemand/filters/query`,
            method: 'POST',
            data: JSON.stringify(data),
            expectToken: true,
        }).then((resp) => resp.data),
        'request-filter',
    );

export const getBulkErrorFile = (client, file, controller) =>
    trackPromise(
        apiOnDemand({
            url: `${process.env.REACT_APP_ON_DEMAND_URL}/ondemand/bulk/error-file`,
            method: 'POST',
            params: {
                client,
            },
            headers: {
                contentType: 'multipart/form-data',
            },
            data: file,
            expectToken: true,
            signal: controller.signal,
            responseType: 'arraybuffer',
        }).then((resp) => resp.data),
    );

export const getBulkDuplicateFile = (client, file, controller) =>
    trackPromise(
        apiOnDemand({
            url: `${process.env.REACT_APP_ON_DEMAND_URL}/ondemand/bulk/duplicates-file`,
            method: 'POST',
            params: {
                client,
            },
            headers: {
                contentType: 'multipart/form-data',
            },
            data: file,
            expectToken: true,
            signal: controller.signal,
            responseType: 'arraybuffer',
        }).then((resp) => resp.data),
    );

export const getRequestBySystemAddress = (body) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_ON_DEMAND_URL}/filters/region`,
            method: 'POST',
            data: JSON.stringify(body),
            expectToken: true,
        }).then((resp) => resp.data),
        'request-filter',
    );

export const sendSystemRequest = (body) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_ON_DEMAND_URL}/ondemand/insert`,
            method: 'POST',
            data: JSON.stringify(body),
            expectToken: true,
        }).then((resp) => resp.data),
        'send-request',
    );

export const getCep = (query) =>
    trackPromise(
        api({
            url: `https://kj5goodoq9.execute-api.us-east-1.amazonaws.com/viacep/${query}`,
            method: 'GET',
            expectToken: true,
        }).then((resp) => resp.data),
    );

export const saveEditRequest = (body) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_ON_DEMAND_URL}/ondemand/update/${body.idSolicitacao}`,
            method: 'PUT',
            data: JSON.stringify(body),
            expectToken: true,
        }).then((resp) => resp.data),
    );

export const validateGtins = (gtins) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_ON_DEMAND_URL}/ondemand/validate/gtins`,
            method: 'GET',
            params: {
                gtins,
            },
            expectToken: true,
        }).then((resp) => resp.data),
        'send-request',
    );

export const getAvailableUFs = () =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_ON_DEMAND_URL}/ondemand/ufs`,
            method: 'GET',
            expectToken: true,
        }).then((resp) => resp.data),
        'send-request',
    );

export const getRequestPending = () =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_ON_DEMAND_URL}/ondemand/pending`,
            method: 'GET',
            expectToken: true,
        }).then((resp) => resp.data),
        'send-request',
    );

import React from 'react';
import {
    Button,
    Content,
    FlexboxGrid,
} from 'rsuite';
import { BigNumbersBox } from '../BigNumbersBox';
import styles from './styles.module.scss';

type RequestSummaryData = {
    available: number,
    medianPricesLastWeek: number,
    pricesLastWeek: number,
    pricesReturned: number,
    productsReturned: number,
    storesReturned: number,
    totalRequests: number,
}

export interface IResumoArea {
    onClickShowModalPlan():void;
    requestSummaryData: RequestSummaryData;
}

export const ResumoArea = ({ onClickShowModalPlan, requestSummaryData }: IResumoArea) => (
    <Content className={styles['resumo-content']}>
        <FlexboxGrid>
            <FlexboxGrid.Item colspan={5}>
                <h6 className={styles.title}>Resumo das solicitações</h6>
                <BigNumbersBox.Root marginRight="0">
                    <div className={styles.flex} style={{ alignItems: 'center' }}>
                        <BigNumbersBox.MainValue>{requestSummaryData?.available}</BigNumbersBox.MainValue>
                        <Button
                            appearance="link"
                            className={styles['link-text']}
                            onClick={onClickShowModalPlan}
                        >
                            Veja seu plano
                        </Button>
                    </div>
                    <BigNumbersBox.RegularText>solicitações disponíveis</BigNumbersBox.RegularText>
                    <div className={styles.flex} style={{ alignItems: 'baseline' }}>
                        <BigNumbersBox.RegularText>de</BigNumbersBox.RegularText>
                        <BigNumbersBox.BoldText style={{ marginLeft: '2px' }}>{requestSummaryData?.totalRequests}</BigNumbersBox.BoldText>
                    </div>
                </BigNumbersBox.Root>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={19}>
                <h6 className={styles.title}>Resumo dos resultados</h6>
                <div className={styles.flex} style={{ alignItems: 'baseline' }}>
                    <BigNumbersBox.Root>
                        <BigNumbersBox.MainValue>{requestSummaryData?.pricesReturned}</BigNumbersBox.MainValue>
                        <BigNumbersBox.RegularText>Total de preços</BigNumbersBox.RegularText>
                        <BigNumbersBox.RegularText>retornados</BigNumbersBox.RegularText>
                    </BigNumbersBox.Root>
                    <BigNumbersBox.Root>
                        <BigNumbersBox.MainValue>{requestSummaryData?.storesReturned}</BigNumbersBox.MainValue>
                        <BigNumbersBox.RegularText>Lojas distintas</BigNumbersBox.RegularText>
                        <BigNumbersBox.RegularText>retornadas</BigNumbersBox.RegularText>
                    </BigNumbersBox.Root>
                    <BigNumbersBox.Root>
                        <BigNumbersBox.MainValue>{requestSummaryData?.productsReturned}</BigNumbersBox.MainValue>
                        <BigNumbersBox.RegularText>Produtos distintos</BigNumbersBox.RegularText>
                        <BigNumbersBox.RegularText>retornados</BigNumbersBox.RegularText>
                    </BigNumbersBox.Root>
                    <BigNumbersBox.Root>
                        <div className={styles.flex} style={{ alignItems: 'center' }}>
                            <BigNumbersBox.MainValue>{requestSummaryData?.pricesLastWeek}</BigNumbersBox.MainValue>
                            <BigNumbersBox.Indicator
                                indicator={0.5}
                                // indicator={requestSummaryData?.variationPrices}
                            />
                        </div>
                        <BigNumbersBox.RegularText>Preços retornados</BigNumbersBox.RegularText>
                        <BigNumbersBox.RegularText>na última semana</BigNumbersBox.RegularText>
                    </BigNumbersBox.Root>
                    <BigNumbersBox.Root marginRight="0">
                        <div className={styles.flex} style={{ alignItems: 'center' }}>
                            <BigNumbersBox.MainValue>{requestSummaryData?.medianPricesLastWeek}</BigNumbersBox.MainValue>
                            <BigNumbersBox.Indicator
                                indicator={0.5}
                                // indicator={requestSummaryData?.variationMedianPrices}
                            />
                        </div>
                        <BigNumbersBox.RegularText>Média dos preços retornados</BigNumbersBox.RegularText>
                        <BigNumbersBox.RegularText>na última semana por solicitação</BigNumbersBox.RegularText>
                    </BigNumbersBox.Root>
                </div>
            </FlexboxGrid.Item>
        </FlexboxGrid>
    </Content>
  );

import React, { forwardRef } from 'react';
import styles from '../SetupIPA.module.scss';
import * as T from '../SetupIPA.types';

export const SetupIPAOption = forwardRef<HTMLDivElement, T.SetupIPAOptionProps>(
    ({ children, title, subtitle, className = '', ...props }, ref) => (
        <div
            className={[styles['setup-ipa__content'], className].join(' ')}
            ref={ref}
            {...props}
        >
            <div className={styles['setup-ipa__content__header']}>
                <h4>{title}</h4>
                <p>{subtitle}</p>
            </div>
            <div className={styles['setup-ipa__content__content']}>
                {children}
            </div>
        </div>
    ),
);

import React from 'react';
import arrowDownRedIcon from '../../../../../../assets/icons/icon_arrow_down_red.svg';
import arrowUpGreenIcon from '../../../../../../assets/icons/icon_arrow_up_green.svg';
import styles from './styles.module.scss';

export interface IBoxIndicator{
    indicator: number;
}

export const BoxIndicator = ({ indicator }: IBoxIndicator) => (
    <div className={styles['indicator-wrapper']}>
        <img src={indicator > 0 ? arrowUpGreenIcon : arrowDownRedIcon} alt="" />
        <p className={styles[indicator > 0 ? 'indicator-green' : 'indicator-red']}>
            {Number(indicator).toFixed(2).replace('.', ',')}
            {' '}
            %
        </p>
    </div>
);

import React, { useEffect, useState } from 'react';
import { Route, Redirect, withRouter, useLocation } from 'react-router-dom';
import { Container } from 'rsuite';
import { useMatomo } from '@datapunt/matomo-tracker-react';

import Menu from '../components/Menu';
import NavBar from '../components/NavBar';
import NavBarAdmin from '../components/NavBarAdmin';
import Warning from '../components/Warning';
import NavBarMobile from '../components/NavBarMobile';

import { getAlertas } from '../services/AlertaService';
import { getUserDimensions } from '../utils/MatomoConfig';
import { setPropertiesHeap } from '../utils/HeapConfig';
import { useNotificationReport } from '../hooks';

const PrivateLayout = ({ children }) => {
    const location = useLocation();
    const [alertas, setAlertas] = useState(null);

    const { getRequestNotificationReport } = useNotificationReport();

    const getAllAlertas = () => {
        getAlertas().then((response) => {
            setAlertas(response);
        });
    };

    useEffect(() => {
        getAllAlertas();

        setInterval(async () => {
            getAllAlertas();
        }, 300000);
    }, []);

    useEffect(() => {
        setInterval(async () => {
            getRequestNotificationReport();
        }, 60000);
    }, []);

    return (
        <div className="show-fake-browser sidebar-page">
            <Container
                className={
                    alertas?.some((item) =>
                        item.url.includes(location.pathname),
                    )
                        ? 'first-container has-warning'
                        : 'first-container'
                }
            >
                {children.props.adminArea ? (
                    <NavBarAdmin usuario={children?.props?.usuario} />
                ) : (
                    <>
                        <NavBar usuario={children?.props?.usuario} />
                        <NavBarMobile
                            url={location}
                            usuario={children?.props?.usuario}
                        />
                    </>
                )}
                {alertas?.some((item) =>
                    item.url.includes(location.pathname),
                ) ? (
                    <Warning
                        title="Atenção"
                        description={
                            alertas.find((item) =>
                                item.url.includes(location.pathname),
                            )
                                ? alertas.find((item) =>
                                      item.url.includes(location.pathname),
                                  ).mensagem
                                : null
                        }
                    />
                ) : null}
                <Menu usuario={children?.props?.usuario} />
                {children}
            </Container>
        </div>
    );
};

const PrivateRoute = ({ component: Component, history, ...rest }) => {
    const { trackPageView, pushInstruction } = useMatomo();

    pushInstruction(
        'setUserId',
        JSON.parse(localStorage.getItem('usuario'))?.email,
    );

    function handlePushIntruction(user) {
        const dimensions = getUserDimensions(user);

        dimensions.forEach((item) => {
            pushInstruction(
                item.instructionType,
                item.dimensionId,
                item.dimensionValue,
            );
        });
    }

    function restartHeap(user) {
        if (!window?.heap) {
            setPropertiesHeap(user);
        }
    }

    useEffect(() => {
        if (rest.usuario) {
            handlePushIntruction(rest.usuario);
        }
    }, [rest.usuario]);

    useEffect(() => {
        trackPageView();
        restartHeap(rest.usuario);
    }, []);

    return (
        <Route
            {...rest}
            render={(props) =>
                localStorage.getItem('autenticacao.token') &&
                localStorage.getItem('autenticacao.token') !== 'null' ? (
                    <PrivateLayout>
                        <Component {...props} {...rest} />
                    </PrivateLayout>
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: history.location.pathname },
                        }}
                        from={history.location.pathname}
                    />
                )
            }
        />
    );
};

export default withRouter(PrivateRoute);

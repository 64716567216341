/* eslint-disable camelcase */

import { addDays } from 'date-fns';
import { z } from 'zod';
import { aditionalMechanismTypeValue } from './data';
import {
    compareDates,
    daysOfWeek,
    mechanismType,
    monthlyRecurrenceType,
    priceCompetitorIndicator,
    recurrenceType,
} from './utils';

const required_error = 'Campo obrigatório';

type CreateSchemaNewRule = {
    isEdited?: boolean;
};

export const createSchemaNewRule = (options?: CreateSchemaNewRule) => {
    return z
        .object({
            name: z
                .string({ required_error })
                .min(2, 'O nome precisa ter ao menos 2 caracteres.')
                .max(255, 'O nome não pode ter mais de 255 caracteres.'),
            mechanismType: z.enum(mechanismType, {
                invalid_type_error: 'Selecione uma opção válida.',
                required_error,
            }),
            factor: z.number().optional(),
            priceCompetitorIndicator: z
                .enum(priceCompetitorIndicator)
                .nullish(),
            recurrenceType: z.enum(recurrenceType, {
                required_error,
            }),
            minimumVariation: z.number({ required_error }),
            productFamily: z.boolean({ required_error }),
            startingDate: z.date({ required_error }),
            expirationDate: z.date({ required_error }).optional(),
            enableExpirationDate: z
                .boolean({ required_error })
                .optional()
                .default(false),
            daysOfWeek: z.array(z.enum(daysOfWeek)).optional().nullish(),
            monthlyRecurrenceType: z
                .enum(monthlyRecurrenceType)
                .optional()
                .nullish(),
            monthlyRecurrenceDayOfWeek: z.enum(daysOfWeek).optional().nullish(),
            hasAditionalMechanism: z.boolean({ required_error }),
            aditionalMechanismType: z
                .enum(aditionalMechanismTypeValue)
                .optional()
                .nullish(),
            additionalMechanismFactor: z.number().optional().nullish(),
        })
        .refine(
            ({ startingDate: today }) => {
                if (options?.isEdited) return true;
                return !compareDates(today, addDays(new Date(), 1));
            },
            {
                message:
                    'A data atual não pode ser menor do que o próximo dia.',
                path: ['startingDate'],
            },
        )
        .refine(
            ({ startingDate, expirationDate, enableExpirationDate }) => {
                if (!enableExpirationDate) return true;
                return !compareDates(expirationDate, startingDate);
            },
            {
                message: 'A data final não pode ser anterior a data inicial.',
                path: ['expirationDate'],
            },
        )
        .refine(
            ({ mechanismType, priceCompetitorIndicator }) => {
                if (mechanismType === 'COMPETITIVIDADE') {
                    return priceCompetitorIndicator !== undefined;
                }
                return true;
            },
            {
                message: required_error,
                path: ['priceCompetitorIndicator'],
            },
        )
        .superRefine(({ mechanismType, factor }, context) => {
            if (mechanismType === 'COMPETITIVIDADE' && (factor ?? 0) < 1) {
                context.addIssue({
                    code: z.ZodIssueCode.too_small,
                    inclusive: true,
                    type: 'number',
                    minimum: 1,
                    message: 'O valor de competitividade não pode ser zero.',
                    path: ['factor'],
                });
            }
            if (mechanismType === 'MARGEM' && (factor ?? 0) > 100) {
                context.addIssue({
                    code: z.ZodIssueCode.too_big,
                    inclusive: true,
                    type: 'number',
                    maximum: 100,
                    message: 'O valor de margem não pode ser maior que 100.',
                    path: ['factor'],
                });
            }
        });
};

const SchemaNewRule = createSchemaNewRule();

export type SchemaNewRuleProps = z.infer<typeof SchemaNewRule>;

export { SchemaNewRule };

import React, { HtmlHTMLAttributes, forwardRef } from 'react';
import image from '../../../../../../assets/image/no-results.svg';
import { ButtonPrimary } from '../../atoms';
import './style.scss';

export const NoDatapointResults = forwardRef<
    HTMLDivElement,
    HtmlHTMLAttributes<HTMLDivElement>
>(({ className = '', ...props }, ref) => (
    <div
        ref={ref}
        className={['no-data-results', className].join(' ')}
        {...props}
    >
        <img src={image} alt="Nenhum resultado encontrado" />
        <h5 className="font-size-100-semibold">
            Não encontramos dados de preços da sua loja
        </h5>
        <p className="font-size-100-regular">
            A sua loja é nova no InfoPanel por isso ainda não temos dados de
            preços.
        </p>

        <ButtonPrimary skin="blue">
            <a
                href="https://infoprice-support.freshdesk.com/support/home"
                target="_blank"
                rel="noopener noreferrer"
            >
                FALE CONOSCO
            </a>
        </ButtonPrimary>
    </div>
));

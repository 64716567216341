import classNames from 'classnames';
import React from 'react';
import Modal, { ModalProps } from 'rsuite/lib/Modal';
import { LoadingSpinerArea } from '../../../../../../components/LoadingSpinner';
import { ButtonPrimary } from '../../../../Lite';
import styles from './ExcluirNegociacao.module.scss';

export type ExcluirNegociacaoDataProps = {
    name: string;
};

export type ExcluirNegociacaoProps = ModalProps & {
    name: string;
    onSubmit?: () => void;
    onHide?: () => void;
};

export const ExcluirNegociacaoModal = ({
    className = '',
    onHide,
    onSubmit,
    name,
    ...props
}: ExcluirNegociacaoProps) => {
    return (
        <>
            <Modal
                className={classNames(
                    'info-modal',
                    styles['excluir-negociacao-modal'],
                    className,
                )}
                onHide={onHide}
                {...props}
            >
                <LoadingSpinerArea
                    height="100%"
                    size="md"
                    area="negotiation/delete-negotiation"
                />
                <form onSubmit={(e) => e.preventDefault()}>
                    <Modal.Header>
                        <Modal.Title
                            className={
                                styles['excluir-negociacao-modal__title']
                            }
                        >
                            Excluir negociação
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body
                        className={styles['excluir-negociacao-modal__body']}
                    >
                        <p>Tem certeza que deseja excluir o a negociação</p>
                        <strong>{name}</strong>
                        <br />
                        <br />
                        <p>A negociação será excluída permanentemente</p>
                    </Modal.Body>
                    <Modal.Footer
                        className={styles['excluir-negociacao-modal__footer']}
                    >
                        <ButtonPrimary
                            skin="blue"
                            theme="ghost"
                            type="button"
                            onClick={onHide}
                        >
                            CANCELAR
                        </ButtonPrimary>
                        <ButtonPrimary
                            skin="blue"
                            theme="filled"
                            type="submit"
                            onClick={() => onSubmit?.()}
                        >
                            EXCLUIR
                        </ButtonPrimary>
                    </Modal.Footer>
                </form>
            </Modal>
            <LoadingSpinerArea size="sm" area="saved-filter" height={null} />
        </>
    );
};

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type GerenciadorPrecosSavedFilterProps = {
    filterName: string;
    data: {
        label: string;
        value: string;
        name: string;
    };
};

const initialState: GerenciadorPrecosSavedFilterProps = {
    filterName: '',
    data: {
        name: '',
        value: '',
        label: '',
    },
};

const slice = createSlice({
    name: '/ipa/gerenciador-de-precos/savedFilter',
    initialState,
    reducers: {
        setSavedFilter(
            state,
            action: PayloadAction<{
                data: GerenciadorPrecosSavedFilterProps;
            }>,
        ) {
            return action.payload.data;
        },
        changeSavedFilter(
            state,
            action: PayloadAction<{
                key: keyof typeof initialState;
                data: GerenciadorPrecosSavedFilterProps['data'];
            }>,
        ) {
            state.data = action.payload.data;
        },
        setSavedFilterInputName(
            state,
            action: PayloadAction<{
                value: string;
            }>,
        ) {
            state.filterName = action.payload.value;
        },
        resetSavedFilter() {
            return initialState;
        },
    },
});

const {
    setSavedFilter,
    changeSavedFilter,
    setSavedFilterInputName,
    resetSavedFilter,
} = slice.actions;

export {
    changeSavedFilter,
    resetSavedFilter,
    setSavedFilter,
    setSavedFilterInputName,
};

export default slice.reducer;

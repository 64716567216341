import { addDays, format, isBefore, parse, parseISO, startOfDay } from 'date-fns';
import _ from 'lodash';
import plural from 'pluralize-ptbr';
import { listStores } from '../../../../../../components/FilterSection/services';
import { Segment } from '../../../../../../services/ProdutoService_ts';
import { GetRegra } from '../../../../RegrasPreco/Cadastro/services';
import data from './data';
import { SchemaNewRuleProps } from './schema';

export const segmentsCurve = [
    'infoPriceSensibility',
    'infoPriceAbcClass',
    'infoPriceGlobalAbcClass',
    'sensibilityType',
    'abcClass',
] as const;

export type SegmentsCurve = (typeof segmentsCurve)[number];

export const daysOfWeek = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'] as const;

export type DaysOfWeek = (typeof daysOfWeek)[number];

export const monthlyRecurrenceType = ['FIRST', 'SECOND', 'THIRD', 'FOURTH', 'LAST'] as const;

export type MonthlyRecurrenceType = (typeof monthlyRecurrenceType)[number];

export const recurrenceType = ['DAILY', 'WEEKLY', 'MONTHLY'] as const;

export type RecurrenceType = (typeof recurrenceType)[number];

export const priceCompetitorIndicator = ['AVERAGE', 'HIGHER', 'LOWER'] as const;

export type PriceCompetitorIndicator = (typeof priceCompetitorIndicator)[number];

export const mechanismType = ['COMPETITIVIDADE', 'MARGEM', 'MARGEM_OBJETIVA'] as const;

export type MechanismType = (typeof mechanismType)[number];

export const compareDates = (startDate?: string | Date, endDate?: string | Date) => {
    if (!startDate || !endDate) return false;
    const truncatedStartDate = startOfDay(new Date(startDate));
    const truncatedEndDate = startOfDay(new Date(endDate));
    return isBefore(truncatedStartDate, truncatedEndDate);
};

export const DESCRIPTIONS = {
    store: {
        'all-stores': 'Você também pode escolher lojas específicas',
        'single-store': 'Você também pode escolher todas lojas',
        undefined: '',
    },
} as const;

export const formatNumberWithZeroPrefix = (number: number) => {
    return number.toLocaleString('pt-BR', {
        minimumIntegerDigits: 2,
        useGrouping: false,
    });
};

export const includeLevelsInSegments = (segments: Segment[]): Segment[] => {
    return segments.map((segment) => ({
        ...segment,
        label: `${formatNumberWithZeroPrefix(segment.level)} - ${segment.name}`,
    }));
};

export const createData = () => {
    return [
        {
            key: '2',
            label: 'LOJAS',
            controller: 'categorizacao',
            filters: [
                [
                    {
                        dataKey: 'storeType',
                        placeholder: 'Escolha uma opção',
                        queryFn: data.storeType,
                    },
                ],
                [
                    {
                        dataKey: 'storeIds',
                        placeholder: 'Escolha uma opção',
                        queryFn: listStores,
                    },
                ],
            ],
        },
    ] as const;
};

export const parseSelectedText = (count = 0) => {
    const label = 'Item';
    return `${count > 1 ? plural(label) : label} ${count > 1 ? plural('selecionado') : 'selecionado'}`;
};

const parseNumberTwoDecimalsBrl = (number: number) => {
    return number.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
    });
};

export const parseEnumeratedSegments = (segments: Segment[]) => {
    return segments.map((segment) => ({
        ...segment,
        label: `${parseNumberTwoDecimalsBrl(segment.level as number)} - ${segment.name}`,
    }));
};

export const formatDate = (date: string) => {
    return parse(format(parseISO(date), 'yyyy-MM-dd'), 'yyyy-MM-dd', new Date());
};

export type RuleModel = {
    productFamily: boolean;
    name: SchemaNewRuleProps['name'];
    type: 'autoPrice';
    clientId: string;
    author: string;
    clusters: never[];

    mechanism: {
        mechanismType: SchemaNewRuleProps['mechanismType'];
        factor: SchemaNewRuleProps['factor'];
        priceCompetitorIndicator: SchemaNewRuleProps['priceCompetitorIndicator'];
        minimumVariation: SchemaNewRuleProps['minimumVariation'];
    };

    additionalMechanism?: {
        mechanismType: SchemaNewRuleProps['aditionalMechanismType'];
        factor: SchemaNewRuleProps['additionalMechanismFactor'];
    };

    recurrence: {
        dayOfMonth?: null;
        startingDate: string;
        recurrenceType: SchemaNewRuleProps['recurrenceType'];
        expirationDate?: string | null;
        daysOfWeek?: SchemaNewRuleProps['daysOfWeek'];
        monthlyRecurrenceType?: SchemaNewRuleProps['monthlyRecurrenceType'];
        monthlyRecurrenceDayOfWeek?: SchemaNewRuleProps['monthlyRecurrenceDayOfWeek'];
    };
};

export type RuleFiltersModel = {
    storeIds?: string[];
} & Record<SegmentsCurve, string[]> &
    Record<
        'segments',
        {
            level: Segment['level'];
            value: string;
            description?: string;
        }[]
    >;

export const getNewRuleModel = (data: SchemaNewRuleProps): RuleModel => {
    return {
        productFamily: data.productFamily,
        type: 'autoPrice',
        clientId: JSON.parse(localStorage.getItem('cliente') as string)?.codigo,
        author: JSON.parse(localStorage.getItem('usuario') as string)?.email,
        name: data.name,
        mechanism: {
            mechanismType: data.mechanismType,
            minimumVariation: data.minimumVariation / 100,
            ...(data.mechanismType === 'COMPETITIVIDADE' && {
                priceCompetitorIndicator: data.priceCompetitorIndicator,
            }),
            ...(data.mechanismType !== 'MARGEM_OBJETIVA' && {
                factor: (data.factor ?? 0) / 100,
            }),
        },
        additionalMechanism: {
            mechanismType: data.mechanismType === 'COMPETITIVIDADE' ? data.aditionalMechanismType : null,
            factor: data.additionalMechanismFactor,
        },
        recurrence: {
            dayOfMonth: null,
            startingDate: format(data.startingDate, 'yyyy-MM-dd'),
            recurrenceType: data.recurrenceType,
            expirationDate:
                data.enableExpirationDate && data.expirationDate ? format(data.expirationDate, 'yyyy-MM-dd') : null,
            daysOfWeek: data.daysOfWeek,
            monthlyRecurrenceType: data.monthlyRecurrenceType,
            monthlyRecurrenceDayOfWeek: data.monthlyRecurrenceDayOfWeek,
        },
        clusters: [],
    };
};

export const parseNewRuleModel = (data: GetRegra) => {
    const { name, productFamily, mechanism, recurrence, storeIds, segments, additionalMechanism } = data;

    const curves = _.pick(data, segmentsCurve);

    return {
        status: data.ruleStatus,
        form: {
            name,
            productFamily,
            mechanismType: mechanism.mechanismType,
            factor: (mechanism?.factor ?? 0) * 100,
            priceCompetitorIndicator: mechanism.priceCompetitorIndicator,
            minimumVariation: mechanism.minimumVariation * 100,
            startingDate: formatDate(recurrence.startingDate),
            expirationDate: recurrence.expirationDate ? formatDate(recurrence.expirationDate) : undefined,
            enableExpirationDate: !!recurrence.expirationDate,
            recurrenceType: recurrence.recurrenceType ?? undefined,
            daysOfWeek: recurrence.daysOfWeek,
            monthlyRecurrenceDayOfWeek: recurrence.monthlyRecurrenceDayOfWeek,
            monthlyRecurrenceType: recurrence.monthlyRecurrenceType,
            hasAditionalMechanism: !!additionalMechanism?.mechanismType,
            aditionalMechanismType: additionalMechanism?.mechanismType,
            additionalMechanismFactor: additionalMechanism?.factor,
        },
        segments,
        filters: {
            storeIds,
            ...curves,
        },
    } as const;
};

export const defaultFormValues: Partial<SchemaNewRuleProps> = {
    productFamily: true,
    enableExpirationDate: false,
    startingDate: addDays(new Date(), 1),
    priceCompetitorIndicator: 'AVERAGE',
    hasAditionalMechanism: false,
    aditionalMechanismType: null,
    expirationDate: undefined,
    factor: 0,
};

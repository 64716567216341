import { Buffer } from 'buffer';
import { add, differenceInDays, format } from 'date-fns';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
    Col, Container, FlexboxGrid, Loader, Notification,
} from 'rsuite';

import { getCookie } from '../../../../services/CookiesService';
import { getFree } from '../../../../services/FreemiumService';
import { getClienteCompleto } from '../../../../services/UsuarioService';
import * as PainelGeralService from './services';

import {
    getBoxPlotData,
    getItensFiltro,
    getMapData,
    getMarkersData,
    getProductDashboardSlide,
    getProductDashboardTable,
    getShareTokenData,
    getTimeEvolutionData,
    getTopFiveData,
    getTotalDatapoints,
    searchProdutos,
    setErro,
    setLimitProducts,
    setSortColumnBoxplot,
} from '../../../../actions/actionsPainelGeral';

import ChartsArea from './Components/ChartsArea';
import EmptyState from './Components/EmptyState';
import FiltersArea from './Components/FiltersArea';
import FixedBanner from './Components/FixedBanner';
import ModalBanner from './Components/ModalBanner';
import ModalPreviewDownload from './Components/ModalPreviewDownload';
import ModalProdutosSemDetalhes from './Components/ModalProdutosSemDetalhes';
import { ResultsButtonArea } from './Components/ResultsButtonArea';
import WarningProductsOutOfList from './Components/WarningProductsOutOfList';

import greenCheckIcon from '../../../../assets/icons/icon_check_circle_green.svg';
import noFilterImage from '../../../../assets/image/empty-state-1.svg';
import emptyStateImage from '../../../../assets/image/empty-state-3.svg';
import { LoadingSpinerArea } from '../../../../components/LoadingSpinner';
import ModalConfirm from '../../../../components/ModalConfirm';
import ModalNotification from '../../../../components/ModalNotification';
import { trackEvent } from '../../../../utils/MatomoConfig';
import AllProductsSelected from './Components/AllProductsSelected';
import AllProductsSelectedTable from './Components/AllProductsSelectedTable';
import Header from './Components/Header';
import ModalShareAnalyzedViews from './Components/ModalShareAnalyzedViews';
import PanelPlanTags from './Components/PanelPlanTags';
import ProductSlider from './Components/ProductSlider';
import { fileDownloadStatus } from './data';
import {
    disabledColumns,
    filterColumnsShoppingBrasil,
    getGroupBy,
    getMapComponentData,
    getModelDashboards,
    getModelPanel,
    getModelProduct,
    getModelProdutoRede,
    getOrder,
    getUserPlanType,
    isShoppingBrasilColumn,
} from './utils';

class InfoPanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            usuario: null,
            errors: {},
            columns: [],
            showModalDownload: false,
            showModalTrial: false,
            showPainel: false,
            showWarningNoGtin: false,
            showWarningToMuchProducts: false,
            showModalProdutosSemDetalhes: false,
            visulizationTime: 0,
            showBanner: false,
            freeData: null,
            showModalShareAnalyzedViews: false,
            fileDownloadStatusType: 'willReceiveByEmail',
            showMoreProductsSelected: false,
            timer: null,
            productQueryValue: '',
        };

        this.validDateInput = this.validDateInput.bind(this);
        this.getDownloadData = this.getDownloadData.bind(this);
        this.getIframe = this.getIframe.bind(this);
        this.close = this.close.bind(this);
        this.handleChangeColumn = this.handleChangeColumn.bind(this);
        this.handleCheckAllColumn = this.handleCheckAllColumn.bind(this);
        this.getPreview = this.getPreview.bind(this);
        this.confirmDowload = this.confirmDowload.bind(this);
        this.setDataViewFree = this.setDataViewFree.bind(this);
        this.getShareTokensModal = this.getShareTokensModal.bind(this);
        this.handleCopyLink = this.handleCopyLink.bind(this);
        this.analysisDeadline = this.analysisDeadline.bind(this);
        this.handleSendShareAnalysisLink
            = this.handleSendShareAnalysisLink.bind(this);
        this.mapColumnsByContract = this.mapColumnsByContract.bind(this);
        this.handleShowMoreProductsSelected
            = this.handleShowMoreProductsSelected.bind(this);
        this.handleSearchFilter = this.handleSearchFilter.bind(this);
        this.handleFilterProduto = this.handleFilterProduto.bind(this);
        this.handleOnOpenProduto = this.handleOnOpenProduto.bind(this);
        this.handleChangeMapProduct = this.handleChangeMapProduct.bind(this);
        this.handleCleanMapProduct = this.handleCleanMapProduct.bind(this);
        this.handleOnSelectTopFiveFilter
            = this.handleOnSelectTopFiveFilter.bind(this);
        this.handleVisualizationTimeEvolutionFilters = this.handleVisualizationTimeEvolutionFilters.bind(this);
        this.handleOnCleanTimeEvolution = this.handleOnCleanTimeEvolution.bind(this);
        this.handleOnCleanTopFiveProduct
            = this.handleOnCleanTopFiveProduct.bind(this);
        this.handleOnChangeVisualizationBoxplot
            = this.handleOnChangeVisualizationBoxplot.bind(this);
        this.handleOnChangeVisualizationTimeEvolution
            = this.handleOnChangeVisualizationTimeEvolution.bind(this);
        this.handleOnChangeVisualizationMap
            = this.handleOnChangeVisualizationMap.bind(this);
        this.handleOnClickProductDashboardDownload
            = this.handleOnClickProductDashboardDownload.bind(this);
        this.handleBoxplotDownload = this.handleBoxplotDownload.bind(this);
        this.productDashboardDownloadService
            = this.productDashboardDownloadService.bind(this);
        this.handleOnClickLoadMoreProduto
            = this.handleOnClickLoadMoreProduto.bind(this);
        this.handleOnClickProductDashboardTablePage
            = this.handleOnClickProductDashboardTablePage.bind(this);
    }

    componentDidMount() {
        const { usuario, produto } = this.props;
        this.getUserInformation();
    }

    shouldComponentUpdate(nextProps, nextState) {
        const {
            boxplotType,
            filtros,
            filtrosAdicionados,
            timeEvolutionPeriodo,
            timeEvolutionType,
            usuario,
            produto,
            rede,
            tipoLoja,
            fabricante,
            tipoProduto,
            secao,
            categoria,
            mecanica,
            grupoMidia,
            midia,
            uf,
            cidade,
            endereco,
            tipoPreco,
            origemPreco,
            raio,
            preco,
            canal,
            productDashboardList,
        } = this.props;

        return (
            nextProps !== boxplotType
            || nextProps !== filtros
            || nextProps !== filtrosAdicionados
            || nextProps !== timeEvolutionPeriodo
            || nextProps !== timeEvolutionType
            || nextProps !== usuario
            || nextProps !== produto
            || nextProps !== rede
            || nextProps.tipoLoja !== tipoLoja
            || nextProps.fabricante !== fabricante
            || nextProps.tipoProduto !== tipoProduto
            || nextProps.secao !== secao
            || nextProps.categoria !== categoria
            || nextProps.mecanica !== mecanica
            || nextProps.grupoMidia !== grupoMidia
            || nextProps.midia !== midia
            || nextProps.uf !== uf
            || nextProps.cidade !== cidade
            || nextProps.endereco !== endereco
            || nextProps.tipoPreco !== tipoPreco
            || nextProps.origemPreco !== origemPreco
            || nextProps.raio !== raio
            || nextProps.preco !== preco
            || nextProps.canal !== canal
            || nextProps.productDashboardList !== productDashboardList
            || nextState !== this.state
        );
    }

    handleChangeColumn(value) {
        const { allValue } = this.state;

        this.setState({
            columnsValue: value,
            indeterminateColumn:
                value.length > 0 && value.length < allValue.length,
            checkAllColumn: value.length === allValue.length,
        });
    }

    mapColumnsByContract(columns, selectAll) {
        const { usuario } = this.props;
        const hasCookieList = getCookie('colunasPanel')?.length > 1;
        const isUserSB = usuario?.tipoAdicionalContratos.PAN.includes('SB');

        if (!selectAll && hasCookieList) {
            return getCookie('colunasPanel');
        }

        if (!isUserSB) {
            return filterColumnsShoppingBrasil(columns).map(
                (item) => item.name,
            );
        }
        return columns.map((item) => item.name);
    }

    handleCheckAllColumn(value, checked) {
        const { columns } = this.state;
        const nextValue = checked
            ? this.mapColumnsByContract(columns, checked)
            : columns
                .map((item) => item.name)
                .filter(
                    (item) => item === 'DATA_PRECO'
                        || item === 'GTIN'
                        || item === 'CNPJ',
                );

        this.setState({
            columnsValue: nextValue,
            indeterminateColumn: false,
            checkAllColumn: checked,
        });
    }

    getUserInformation() {
        getClienteCompleto(
            JSON.parse(localStorage.getItem('usuario'))?.email,
        ).then((response) => {
            if (response) {
                if (response?.servicoPanel?.pacotePainel?.name === 'FREE') {
                    this.getConfigFree(response);
                }
                this.setState({ usuario: response }, this.getColumns);
            }
        });
    }

    getConfigFree() {
        getFree().then((response) => {
            this.setState({
                freeData: response,
            });
        });
    }

    getColumns() {
        PainelGeralService.getColumnsExportFile().then((data) => {
            if (data?.length) {
                this.setColumns(data);
            }
        });
    }

    setCookieColumn(colunas) {
        const colunasJoin = colunas.join(',');
        const expires = moment().add(365, 'days').toDate();
        document.cookie = `colunasPanel=${colunasJoin};expires=${expires.toUTCString()};path=/`;

        this.setState({
            columnsValue: colunas,
        });
    }

    setColumns(columns) {
        this.setState({
            columns: columns.map((item) => ({
                id: item.name,
                value: item.name,
                label: item.description,
                column: item.column,
                name: item.name,
                descricao: item.description,
                obrigatorio: item.required,
            })),
            columnsValue: this.mapColumnsByContract(columns),
        });
    }

    setDataProductsCards() {
        const { produto } = this.props;

        this.setState({
            productsSelected: produto.cache,
        });
    }

    getDataDashboard() {
        this.setState({
            showPainel: true,
            showWarningNoGtin: false,
            showWarningToMuchProducts: false,
        });
    }

    setNoGtinState() {
        this.setState({
            showPainel: true,
            showWarningNoGtin: true,
        });
    }

    setToMuchProductsState() {
        this.setState({
            showPainel: true,
            showWarningToMuchProducts: true,
        });
    }

    setDataView(page) {
        const {
            getTimeEvolutionData,
            timeEvolutionType,
            timeEvolutionPeriodo,
            mapType,
            getTopFiveData,
            getProductDashboardSlide,
            getProductDashboardTable,
            produto,
        } = this.props;
        const { usuario, showMoreProductsSelected } = this.state;

        if (usuario?.servicoPanel?.pacotePainel?.name === 'FREE') {
            this.setDataViewFree();
        } else if (usuario?.servicoPanel?.pacotePainel?.name === 'LIMIT') {
            this.setDataViewLimit();
        } else {
            getMapComponentData(mapType, this.props, '');
            getTimeEvolutionData(getModelDashboards(this.props), {
                type: timeEvolutionType,
                periodo: timeEvolutionPeriodo,
            });
            getTopFiveData(getModelDashboards(this.props));
            this.getDadosBoxPlot();
            this.setDataProductsCards();
            this.getDataDashboard();
        }

        if (showMoreProductsSelected) {
            getProductDashboardTable(
                { filter: getModelDashboards(this.props) },
                {
                    page: 0,
                    size: 10,
                },
                'table',
            );
        } else {
            getProductDashboardSlide(
                { filter: getModelDashboards(this.props) },
                {
                    page: null,
                    size: produto?.value?.length,
                },
                'slide',
            );
        }
    }

    setDataViewFree() {
        const {
            getTimeEvolutionData,
            timeEvolutionType,
            timeEvolutionPeriodo,
            boxplotType,
            getTopFiveData,
        } = this.props;

        if (
            timeEvolutionType !== 'rede'
            && timeEvolutionType !== 'fabricante'
        ) {
            getTimeEvolutionData(getModelDashboards(this.props), {
                type: timeEvolutionType,
                periodo: timeEvolutionPeriodo,
            });
        }

        if (
            boxplotType !== 'rede'
            && boxplotType !== 'produtoRede'
            && boxplotType !== 'fabricante'
        ) {
            this.getDadosBoxPlot();
        }

        getTopFiveData(getModelDashboards(this.props));
        this.setDataProductsCards();
        this.getDataDashboard();
    }

    setDataViewLimit() {
        const {
            getTimeEvolutionData,
            timeEvolutionType,
            timeEvolutionPeriodo,
            boxplotType,
            getTopFiveData,
            mapType,
            produto,
        } = this.props;

        if (
            ((timeEvolutionType === 'rede'
                || timeEvolutionType === 'fabricante')
                && produto?.cache.every((item) => item.limited === false))
            || (timeEvolutionType !== 'rede' && timeEvolutionType !== 'fabricante')
        ) {
            getTimeEvolutionData(getModelDashboards(this.props), {
                type: timeEvolutionType,
                periodo: timeEvolutionPeriodo,
            });
        }

        if (
            ((boxplotType === 'rede'
                || boxplotType === 'produtoRede'
                || timeEvolutionType === 'fabricante')
                && produto?.cache.every((item) => item.limited === false))
            || (boxplotType !== 'rede'
                && boxplotType !== 'produtoRede'
                && timeEvolutionType !== 'fabricante')
        ) {
            this.getDadosBoxPlot();
        }

        if (produto?.cache?.every((item) => item.limited === false)) {
            getMapComponentData(mapType, this.props, '');
        }

        getTopFiveData(getModelDashboards(this.props));
        this.setDataProductsCards();
        this.getDataDashboard();
    }

    getIframe(page) {
        const { produto, getTotalDatapoints } = this.props;

        if (this.validDateInput()) {
            this.setColumnsPreview();
            this.countVisualization();
            trackEvent('InfoPanel', 'Visualizar');

            getTotalDatapoints(getModelPanel(this.props));

            if (produto?.cache?.length && produto.cache.length <= 120) {
                this.setDataView(page);
            } else if (produto?.cache?.length && produto.cache.length > 120) {
                this.setToMuchProductsState();
            } else {
                this.setNoGtinState();
            }
        }
    }

    setColumnsPreview() {
        const { columns, columnsValue } = this.state;
        const preview = [];

        columns.forEach((column) => {
            if (
                columnsValue?.findIndex((item) => column.name === item) !== -1
            ) {
                preview.push(column);
            }
        });

        this.setState({
            allValue: columns && columns.map((item) => item.name),
            columnsPreview: preview.length ? preview : columns,
            checkAllColumn: columnsValue?.length === columns.length,
            columnsValue: columnsValue?.length
                ? columnsValue
                : this.mapColumnsByContract(columns),
        });
    }

    getDadosBoxPlot(type, page, size) {
        const { setSortColumnBoxplot, getBoxPlotData, boxplotType }
            = this.props;
        const pagination = { page: page || 0, size: size || 10 };

        setSortColumnBoxplot(null);
        getBoxPlotData(getModelDashboards(this.props), boxplotType, pagination);
    }

    isTotalDatapointsValid(totalDatapoints) {
        if (totalDatapoints > 1000000) {
            this.setState({
                showModalDownload: true,
                fileDownloadStatusType: 'willNotDownload',
            });
            return false;
        } if (totalDatapoints === 0) {
            this.setState({
                showModalDownload: true,
                fileDownloadStatusType: 'noDataForDownload',
            });
            return false;
        }
        return true;
    }

    getPreview(extension) {
        if (this.isTotalDatapointsValid(this.props.totalDatapoints)) {
            this.setColumnsPreview();

            PainelGeralService.getColumnsPreview().then((data) => {
                this.setState({
                    extension,
                    itensPreview: data,
                    showModalPreviewDownloadPanel: true,
                });
            });
        }
    }

    getDownloadData() {
        const {
            filtroSalvo, extension, columnsValue, usuario,
        } = this.state;

        if (this.validDateInput()) {
            if (usuario?.servicoPanel?.trial) {
                this.setState({
                    showModalTrial: true,
                });
            }

            trackEvent('InfoPanel', 'Download');
            const model = getModelPanel(this.props);
            model.filtroSalvo = filtroSalvo;
            model.extensao = extension;
            model.dimensoes = columnsValue.filter(
                (item) => item !== 'TIPO_PRECO',
            );

            PainelGeralService.painelDownload(model)
                .then((data) => {
                    if (data && data.status === 202) {
                        const responseData = JSON.parse(
                            Buffer.from(data.data).toString('utf8'),
                        );
                        let downloadStatus;

                        if (responseData.totalElements < 300_000) {
                            downloadStatus = 'willReceiveByEmail';
                        }
                        if (
                            responseData.totalElements > 300_000
                            && responseData.totalElements < 1_000_000
                        ) {
                            downloadStatus = 'willDelay';
                        }
                        this.setState({
                            showModalDownload: true,
                            fileDownloadStatusType: downloadStatus,
                        });
                    }
                });
        }
    }

    confirmDowload() {
        this.setState(
            {
                showModalPreviewDownloadPanel: false,
            },
            this.getDownloadData,
        );
    }

    validDateInput() {
        const { errors, usuario } = this.state;
        const {
            filtrosAdicionados,
            setErro,
            produto,
            dataInicio,
            dataFim,
            raio,
            endereco,
            rede,
        } = this.props;

        const data = document.getElementsByName('data')[0];
        let formIsValid = true;

        const data1 = dataInicio ? moment(dataInicio).format() : null;
        const data2 = dataFim ? moment(dataFim).format() : null;
        const diff = dataInicio
            ? moment(data2).diff(moment(data1), 'days') + 1
            : null;

        if (!dataInicio) {
            formIsValid = false;
            this.setState({ errors: { data: 'Campo obrigatório' } });
            if (data) {
                data.classList.add('filtros__input_invalid');
            }
        } else if (dataInicio && diff < 7) {
            formIsValid = false;
            this.setState({ errors: { data: 'O intervalo mínimo para a seleção de data deve ser de 7 dias' } });
            if (data) {
                data.classList.add('filtros__input_invalid');
            }
        } else {
            this.setState({ errors: { data: null } });
            if (data) {
                data.classList.remove('filtros__input_invalid');
            }
        }

        const enderecoElement = document.getElementsByName('endereco')[0];
        if (
            filtrosAdicionados?.findIndex(
                (filtro) => filtro.name === 'endereco',
            ) !== -1
            && raio.value
            && !endereco.value.length
        ) {
            formIsValid = false;
            setErro(
                'Para definir um raio, você deve definir também um endereço',
                'endereco',
            );
            if (enderecoElement) {
                enderecoElement.classList.add('invalid-input');
            }
        } else {
            setErro(null, 'endereco');
            if (enderecoElement) {
                enderecoElement.classList.remove('invalid-input');
            }
        }

        const redeElement = document.getElementsByName('rede')[0];
        if (
            usuario?.servicoPanel?.pacotePainel?.name === 'LIMIT'
            && rede.value.length
            && produto?.cache?.some((item) => item.limited === true)
        ) {
            formIsValid = false;
            setErro(
                'Não é possível fazer filtro de rede para produtos sem acesso completo',
                'rede',
            );
            if (redeElement) {
                redeElement.classList.add('invalid-input');
            }
        } else {
            setErro(null, 'rede');
            if (redeElement) {
                redeElement.classList.remove('invalid-input');
            }
        }

        return formIsValid;
    }

    confirmColumns() {
        const { columns, columnsValue } = this.state;
        const preview = [];

        columns.forEach((coluna) => {
            if (
                columnsValue?.findIndex((item) => coluna.name === item) !== -1
            ) {
                preview.push(coluna);
            }
        });

        this.setState({
            columnsPreview: preview,
        });

        this.setCookieColumn(columnsValue);
    }

    clearVisualizationTime() {
        this.setState({
            visulizationTime: 0,
        });
    }

    showFreemiumBanner() {
        const { visulizationTime } = this.state;

        if (visulizationTime > 2) {
            this.setState(
                {
                    showBanner: true,
                },
                this.clearVisualizationTime,
            );
        }
    }

    countVisualization() {
        const { visulizationTime, usuario } = this.state;

        if (usuario?.servicoPanel?.pacotePainel?.name === 'FREE') {
            this.setState(
                {
                    visulizationTime: visulizationTime + 1,
                },
                this.showFreemiumBanner,
            );
        }
    }

    close(stateName) {
        this.setState({
            [stateName]: false,
        });
    }

    getShareTokensModal() {
        const { usuario } = this.state;
        const {
            getShareTokenData,
            boxplotType,
            timeEvolutionPeriodo,
            timeEvolutionType,
            mapType,
            produto,
        } = this.props;

        const chartsData = {
            timeEvolution: {
                type: timeEvolutionType,
                periodo: timeEvolutionPeriodo,
            },
            boxplot: {
                type: boxplotType,
            },
            map: {
                type: mapType,
            },
        };

        const teType
            = timeEvolutionType === 'rede' || timeEvolutionType === 'fabricante';
        const bpType
            = boxplotType === 'rede'
            || boxplotType === 'produtoRede'
            || boxplotType === 'uf'
            || boxplotType === 'produtoUF'
            || boxplotType === 'fabricante';

        const plan
            = usuario?.servicoPanel?.pacotePainel?.name === 'FREE'
            || usuario?.servicoPanel?.pacotePainel?.name === 'LITE';
        const limit
            = usuario?.servicoPanel?.pacotePainel?.name === 'LIMIT'
            && produto?.cache.some((item) => item.limited === true);

        const mapLimit = mapType === 'loja' && limit;

        if (((teType || bpType) && (plan || limit)) || mapLimit) {
            Notification.error({
                title: 'Compartilhamento negado',
                description:
                    'Verifique o tipo de plano ou permissão a visualizações de cada gráfico',
            });
        } else {
            getShareTokenData(getModelDashboards(this.props), chartsData);
            this.setState({
                showModalShareAnalyzedViews: true,
            });
        }
    }

    handleCopyLink() {
        const { shareTokens } = this.props;

        const concatURL = `https://app.infoprice.co/share-analyze/#${shareTokens?.data}`;
        navigator.clipboard.writeText(concatURL);
        trackEvent('InfoPanel', 'click-compartilhar-relatorio');
    }

    analysisDeadline() {
        const { dataInicio } = this.props;
        const date = new Date();
        const today = new Date(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            0,
            0,
            0,
        );
        const ninetyDaysBeforeFromToday = add(today, { days: -91 });
        const filterInitialDate = dataInicio;
        const diffDays = differenceInDays(
            filterInitialDate,
            ninetyDaysBeforeFromToday,
        );

        if (diffDays <= 6) {
            const calcDeadline = add(today, { days: diffDays });
            return format(calcDeadline, 'dd/MM/yyyy');
        }
        return null;
    }

    handleSendShareAnalysisLink(data) {
        const { shareTokens } = this.props;

        const concatURL = `https://app.infoprice.co/share-analyze/#${shareTokens.data}`;

        const emailData = {
            emails: data.emails,
            message: data.message,
            link: concatURL,
        };

        PainelGeralService.sendShareAnalysisEmail(emailData).then(() => {
            Notification.success({
                title: 'Análise compartilhada!',
                description:
                    'Visualização de análise compartilhada com sucesso!',
            });
            this.setState({
                showModalShareAnalyzedViews: false,
            });
        });
    }

    handleShowMoreProductsSelected(show) {
        const { getProductDashboardTable, getProductDashboardSlide, produto }
            = this.props;
        this.setState((prevState) => ({
            showMoreProductsSelected: !prevState.showMoreProductsSelected,
        }));

        if (produto.cache.length <= 120) {
            if (show) {
                getProductDashboardTable(
                    { filter: getModelDashboards(this.props) },
                    {
                        page: 0,
                        size: 10,
                    },
                    'table',
                );
            } else {
                getProductDashboardSlide(
                    { filter: getModelDashboards(this.props) },
                    {
                        page: null,
                        size: produto?.value?.length,
                    },
                    'slide',
                );
            }
        }
    }

    handleSearchFilter(searchTerm, filter) {
        const { getItensFiltro } = this.props;
        const { timer } = this.state;

        clearTimeout(timer);

        const newTimer = setTimeout(() => {
            getItensFiltro(
                getModelPanel(this.props, searchTerm, filter.name),
                filter.name,
                'painel',
            );
        }, 500);

        this.setState({
            timer: newTimer,
        });
    }

    handleFilterProduto(inputValue) {
        const { searchProdutos } = this.props;
        const { timer } = this.state;
        let filtros;

        if (
            inputValue
            && typeof inputValue === 'string'
            && inputValue.match(/^\d+ \d+[\d+ ]*$/)
        ) {
            filtros = { query: '', identificadores: inputValue.split(' ') };
        } else {
            filtros = {
                query: typeof inputValue === 'string' ? inputValue : '',
            };
        }

        clearTimeout(timer);

        const newTimer = setTimeout(() => {
            searchProdutos(
                getModelPanel(
                    this.props,
                    filtros.query,
                    'produto',
                    filtros.identificadores ? filtros.identificadores : null,
                ),
            );
        }, 500);

        this.setState({
            timer: newTimer,
            productQueryValue: inputValue,
        });
    }

    handleOnOpenProduto() {
        const { searchProdutos } = this.props;
        searchProdutos(getModelPanel(this.props, '', 'produto'));
    }

    handleOnClickLoadMoreProduto() {
        const { searchProdutos, produto } = this.props;
        const { productQueryValue } = this.state;
        const productListLength = produto?.lista.length;

        if (productListLength < 1000) {
            searchProdutos(
                getModelPanel(this.props, productQueryValue, 'produto'),
                productListLength + 100,
            );
        }
    }

    handleChangeMapProduct(typeVisualization, identificador) {
        const { getMarkersData, getMapData } = this.props;
        const filters = getModelProduct(this.props, identificador);

        if (typeVisualization === 'loja') {
            getMarkersData(filters);
        } else {
            getMapData(filters);
        }
    }

    handleCleanMapProduct(typeVisualization) {
        const { getMarkersData, getMapData } = this.props;

        const filters = getModelDashboards(this.props);

        if (typeVisualization === 'loja') {
            getMarkersData(filters);
        } else {
            getMapData(filters);
        }
    }

    handleOnChangeVisualizationMap(typeVisualization, filteredProduct) {
        getMapComponentData(typeVisualization, this.props, filteredProduct);
    }

    handleOnSelectTopFiveFilter(filtroRede, filtroProduto) {
        const { getTopFiveData } = this.props;
        const filters = getModelProdutoRede(this.props, filtroProduto, filtroRede);

        getTopFiveData(filters);
    }

    handleOnCleanTopFiveProduct(filtroRede, filtroProduto) {
        const { getTopFiveData } = this.props;
        const filters = getModelProdutoRede(this.props, filtroProduto, filtroRede);

        getTopFiveData(filters);
    }

    handleOnChangeVisualizationBoxplot(typeVisualization, pagination, order) {
        const { getBoxPlotData } = this.props;
        getBoxPlotData(
            getModelDashboards(this.props),
            typeVisualization,
            pagination,
            order,
        );
    }

    handleOnCleanTimeEvolution(filtroRede, filtroProduto, chartOptions) {
        const { getTimeEvolutionData } = this.props;
        const filters = getModelProdutoRede(this.props, filtroProduto, filtroRede);

        getTimeEvolutionData(filters, {
            type: chartOptions.typeVisualization,
            periodo: chartOptions.periodo,
        });
    }

    handleVisualizationTimeEvolutionFilters(filtroRede, filtroProduto, chartOptions) {
        const { getTimeEvolutionData } = this.props;
        const filters = getModelProdutoRede(this.props, filtroProduto, filtroRede);

        getTimeEvolutionData(filters, {
            type: chartOptions.typeVisualization,
            periodo: chartOptions.periodo,
        });
    }

    handleOnChangeVisualizationTimeEvolution(typeVisualization, periodo) {
        const { getTimeEvolutionData } = this.props;
        getTimeEvolutionData(getModelDashboards(this.props), {
            type: typeVisualization,
            periodo,
        });
    }

    handleBoxplotDownload(type, sortColumn, sortType, typeVisualization) {
        const data = {
            filter: getModelDashboards(this.props),
            group: getGroupBy(typeVisualization),
            order: sortColumn
                ? [{ by: sortColumn, ascending: sortType === 'asc' }]
                : getOrder(typeVisualization),
            options: {
                download: {
                    extension: type,
                },
            },
        };

        PainelGeralService.downloadBoxplot(data).then((data) => {
            if (data && data.status === 202) {
                this.setState({
                    showModalDownload: true,
                });
            }
        });
    }

    async productDashboardDownloadService(data) {
        Notification.open({
            key: 'download-big-numbers',
            className: 'download-big-numbers',
            duration: 100000,
            description: (
                <>
                    <Loader />
                    <p>Download processando...</p>
                </>
            ),
        });
        const response
            = await PainelGeralService.productDashboardDownloadService(data);

        if (response.status === 200) {
            const blob = new Blob([response.data]);
            const hiddenElement = document.createElement('a');
            hiddenElement.href = window.URL.createObjectURL(blob);
            hiddenElement.target = '_blank';
            hiddenElement.download = `produto_dashboard.${data.options.download.extension}`;
            hiddenElement.click();

            Notification.close('download-big-numbers');
            Notification.open({
                key: 'download-big-numbers-success',
                className: 'download-big-numbers-success',
                duration: 5000,
                description: (
                    <>
                        <img src={greenCheckIcon} alt="" />
                        <p>Download concluído</p>
                    </>
                ),
            });
        } else {
            Notification.close('download-big-numbers');
            Notification.open({
                key: 'download-big-numbers-error',
                className: 'download-big-numbers-error',
                duration: 5000,
                description: <p>Ocorreu um erro!</p>,
            });
        }
    }

    handleOnClickProductDashboardDownload(type) {
        const data = {
            filter: getModelDashboards(this.props),
            options: {
                download: {
                    extension: type,
                },
                top_k_results: {
                    maximum_results: 10,
                    columns: ['GTIN'],
                },
            },
        };
        this.productDashboardDownloadService(data);
    }

    handleOnClickProductDashboardTablePage(page) {
        const { getProductDashboardTable } = this.props;

        getProductDashboardTable(
            { filter: getModelDashboards(this.props) },
            {
                page: typeof page === 'number' ? page : 0,
                size: 10,
            },
            'table',
        );
    }

    render() {
        const {
            totalDatapoints,
            produto,
            dataInicio,
            loadingProductDashboard,
            loadingProductDashboardTable,
            productDashboardList,
            productDashboardTableList,
        } = this.props;
        const {
            errors,
            showPainel,
            columns,
            columnsValue,
            indeterminateColumn,
            checkAllColumn,
            showWarningNoGtin,
            showWarningToMuchProducts,
            showModalTrial,
            showModalDownload,
            showModalProdutosSemDetalhes,
            showModalPreviewDownloadPanel,
            columnsPreview,
            itensPreview,
            showBanner,
            productsSelected,
            usuario,
            freeData,
            showModalShareAnalyzedViews,
            fileDownloadStatusType,
            showMoreProductsSelected,
        } = this.state;

        const userPlanType
            = usuario?.servicoPanelCliente?.pacotePainel?.description;

        const userNotAllowed
            = usuario?.servicoPanel?.pacotePainel?.name === 'FREE'
            || (usuario?.servicoPanel?.pacotePainel?.name === 'LIMIT'
                && produto?.cache.some((item) => item.limited === true));

        return (
            <Container className="main-container infopanel">
                <div id="container">
                    <Header
                        title="InfoPanel"
                        subtitle="Um ótimo resumo para entender o contexto dos preços!"
                        badgeLabel={userPlanType}
                        badgeClassName={getUserPlanType(userPlanType)}
                    />
                    <FiltersArea
                        getDashBoard={this.getIframe}
                        validInput={this.validDateInput}
                        errors={errors}
                        usuario={usuario}
                        onOpenCustomFilter={this.handleSearchFilter}
                        onSearchCustomFilter={this.handleSearchFilter}
                        onSearchProduto={this.handleFilterProduto}
                        onOpenProduto={this.handleOnOpenProduto}
                        onClickLoadMoreProduto={
                            this.handleOnClickLoadMoreProduto
                        }
                    />
                    <ResultsButtonArea
                        columns={columns}
                        showPainel={showPainel}
                        userHasPermission={userNotAllowed}
                        userIsLimit={usuario?.servicoPanel?.pacotePainel?.name === 'LIMIT'}
                        disabledItemValues={disabledColumns(
                            usuario?.tipoAdicionalContratos,
                        )}
                        columnsValue={columnsValue}
                        columnRef={(ref) => {
                            this.picker = ref;
                        }}
                        indeterminateColumn={indeterminateColumn}
                        checkAllColumn={checkAllColumn}
                        onClickViewResults={this.getIframe}
                        onChangeColumn={this.handleChangeColumn}
                        onClickDownload={this.getPreview}
                        isShoppingBrasilColumn={isShoppingBrasilColumn}
                        onChangeCheckAll={this.handleCheckAllColumn}
                        onClickColumnConfirm={() => {
                            this.picker.close();
                            this.confirmColumns();
                        }}
                        onClickShare={this.getShareTokensModal}
                        totalDatapoints={totalDatapoints}
                    />
                    {showPainel
                        && !showWarningToMuchProducts
                        && !showMoreProductsSelected
                        && !showWarningNoGtin ? (
                        <ProductSlider
                            loading={loadingProductDashboard}
                            products={productDashboardList?.datapoints}
                            onClickSeeAll={() => this.handleShowMoreProductsSelected(true)}
                        />
                    ) : null}
                    {showPainel
                        && !showWarningToMuchProducts
                        && showMoreProductsSelected
                        && !showWarningNoGtin ? (
                        <AllProductsSelected
                            loading={loadingProductDashboardTable}
                            canDownload={userNotAllowed}
                            selectedQuantity={
                                productDashboardTableList?.totalElements
                            }
                            onClickSeeLess={() => this.handleShowMoreProductsSelected(false)}
                            onClickDownload={
                                this.handleOnClickProductDashboardDownload
                            }
                        >
                            <AllProductsSelectedTable
                                products={productDashboardTableList}
                                onClickTablePage={
                                    this.handleOnClickProductDashboardTablePage
                                }
                            />
                        </AllProductsSelected>
                    ) : null}
                </div>
                <LoadingSpinerArea area="download-infopanel" size="md" />
                {showPainel ? (
                    <>
                        {usuario?.servicoPanel?.pacotePainel?.name
                            === 'FREE' ? (
                            <FixedBanner usuario={usuario} />
                        ) : null}

                        <FlexboxGrid>
                            {usuario?.servicoPanel?.pacotePainel?.name
                                === 'FREE' && freeData ? (
                                <PanelPlanTags plan={freeData} />
                            ) : null}
                        </FlexboxGrid>

                        <WarningProductsOutOfList
                            plan={usuario?.servicoPanel?.pacotePainel?.name}
                            productsOutOfList={produto?.cache?.some(
                                (item) => item.limited === true,
                            )}
                            productsLength={
                                produto?.cache?.filter(
                                    (item) => item.limited === true,
                                ).length
                            }
                            onShowModal={() => {
                                this.setState({
                                    showModalProdutosSemDetalhes: true,
                                });
                            }}
                        />

                        {showWarningNoGtin ? (
                            <FlexboxGrid>
                                <FlexboxGrid.Item
                                    colspan={24}
                                    componentClass={Col}
                                    xsHidden
                                    smHidden
                                >
                                    <EmptyState
                                        title="Apenas o download dos resultados está disponível"
                                        message="Para visualizar as análises selecione até 120 produtos."
                                        image={emptyStateImage}
                                    />
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item
                                    colspan={24}
                                    componentClass={Col}
                                    lgHidden
                                    mdHidden
                                >
                                    <EmptyState
                                        title="Ops! Gráficos não disponíveis"
                                        message="Para visualizar gráficos selecione um produto e tente novamente."
                                        image={noFilterImage}
                                    />
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        ) : showWarningToMuchProducts ? (
                            <FlexboxGrid>
                                <FlexboxGrid.Item colspan={24}>
                                    <EmptyState
                                        title={
                                            usuario?.servicoPanel?.pacotePainel
                                                ?.name === 'FULL'
                                                ? 'Apenas o download dos resultados está disponível'
                                                : 'Visualização indisponível'
                                        }
                                        message="Para visualizar as análises selecione até 120 produtos."
                                        image={emptyStateImage}
                                    />
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        ) : (
                            <ChartsArea
                                usuario={usuario}
                                productsSelected={productsSelected}
                                onSelectTimeEvolution={this.handleVisualizationTimeEvolutionFilters}
                                onCleanTimeEvolution={
                                    this.handleOnCleanTimeEvolution
                                }
                                onSelectTopFive={
                                    this.handleOnSelectTopFiveFilter
                                }
                                onCleanTopFive={
                                    this.handleOnCleanTopFiveProduct
                                }
                                onChangeMapProduct={this.handleChangeMapProduct}
                                onCleanMapProduct={this.handleCleanMapProduct}
                                onChangeVisualizationBoxplot={
                                    this.handleOnChangeVisualizationBoxplot
                                }
                                onChangeVisualizationTimeEvolution={
                                    this
                                        .handleOnChangeVisualizationTimeEvolution
                                }
                                onChangeVisualizationMap={
                                    this.handleOnChangeVisualizationMap
                                }
                                onClickDownload={this.handleBoxplotDownload}
                            />
                        )}
                    </>
                ) : null}
                <ModalPreviewDownload
                    show={showModalPreviewDownloadPanel}
                    close={() => this.close('showModalPreviewDownloadPanel')}
                    columnsPreview={columnsPreview}
                    itensPreview={itensPreview}
                    confirm={this.confirmDowload}
                />

                <ModalProdutosSemDetalhes
                    show={showModalProdutosSemDetalhes}
                    close={() => this.close('showModalProdutosSemDetalhes')}
                    produtos={
                        produto?.cache?.filter(
                            (item) => item.limited === true,
                        ) || []
                    }
                />
                <ModalConfirm
                    show={showModalDownload}
                    modalWidth="490px"
                    titleFontSize="16px"
                    textAlign="center"
                    footerAlign="center"
                    closeButton={
                        fileDownloadStatus[fileDownloadStatusType]?.closeButton
                    }
                    cancelButtonWidth={
                        fileDownloadStatus[fileDownloadStatusType]
                            ?.cancelBtnWidth
                    }
                    confirmButtonWidth={
                        fileDownloadStatus[fileDownloadStatusType]
                            ?.confirmBtnWidth
                    }
                    icon={fileDownloadStatus[fileDownloadStatusType]?.icon}
                    title={fileDownloadStatus[fileDownloadStatusType]?.title}
                    message={
                        fileDownloadStatus[fileDownloadStatusType]?.message
                    }
                    confirmButton={
                        fileDownloadStatus[fileDownloadStatusType]
                            ?.confirmButton
                    }
                    cancelButton={
                        fileDownloadStatus[fileDownloadStatusType]?.cancelButton
                    }
                    onHide={() => this.close('showModalDownload')}
                    onCancel={() => {
                        this.setState({
                            showModalDownload: false,
                        });
                    }}
                    onConfirm={() => {
                        this.setState({
                            showModalDownload: false,
                        });
                    }}
                />
                <ModalNotification
                    show={showModalTrial}
                    onHide={() => this.close('showModalTrial')}
                    title="Download"
                    message={
                        'Como você está em período de trial, cada download possui um limite de 10 linhas de dados. '
                        + 'Ao contratar o InfoPrice Panel, você terá acesso completo aos preços de todas as lojas disponíveis.'
                    }
                    close={() => this.close('showModalTrial')}
                />

                {
                    showBanner ? (
                        <ModalBanner
                            usuario={usuario}
                            show={showBanner}
                            onCancel={() => {
                                this.setState({
                                    showBanner: false,
                                });
                            }}
                        />
                    ) : null
                }
                <ModalShareAnalyzedViews
                    show={showModalShareAnalyzedViews}
                    onClickCopyLink={this.handleCopyLink}
                    setShareEmailData={this.handleSendShareAnalysisLink}
                    close={() => {
                        this.setState({
                            showModalShareAnalyzedViews: false,
                        });
                    }}
                    deadlineDate={dataInicio && this.analysisDeadline()}
                />
            </Container>
        );
    }
}

const mapStateToProps = (store) => ({
    filtros: store.painelGeralDataReducer.filtros,
    filtrosAdicionados: store.painelGeralDataReducer.filtrosAdicionados,
    produto: store.painelGeralDataReducer.produto,
    rede: store.painelGeralDataReducer.rede,
    boxplotType: store.painelGeralDataReducer.boxplot.type,
    timeEvolutionType: store.painelGeralDataReducer.timeEvolution.type,
    timeEvolutionPeriodo: store.painelGeralDataReducer.timeEvolution.periodo,
    mapType: store.painelGeralDataReducer.map.type,
    shareTokens: store.painelGeralDataReducer.shareTokens,
    totalDatapoints: store.painelGeralDataReducer.totalDatapoints,
    loadingProductDashboard:
        store.painelGeralDataReducer.loadingProductDashboard,
    loadingProductDashboardTable:
        store.painelGeralDataReducer.loadingProductDashboardTable,
    productDashboardList: store.painelGeralDataReducer.productDashboardList,
    productDashboardTableList:
        store.painelGeralDataReducer.productDashboardTableList,
    tipoLoja: store.painelGeralDataReducer.tipoLoja,
    fabricante: store.painelGeralDataReducer.fabricante,
    tipoProduto: store.painelGeralDataReducer.tipoProduto,
    secao: store.painelGeralDataReducer.secao,
    categoria: store.painelGeralDataReducer.categoria,
    mecanica: store.painelGeralDataReducer.mecanica,
    grupoMidia: store.painelGeralDataReducer.grupoMidia,
    midia: store.painelGeralDataReducer.midia,
    uf: store.painelGeralDataReducer.uf,
    cidade: store.painelGeralDataReducer.cidade,
    endereco: store.painelGeralDataReducer.endereco,
    tipoPreco: store.painelGeralDataReducer.tipoPreco,
    origemPreco: store.painelGeralDataReducer.origemPreco,
    dataInicio: store.painelGeralDataReducer.dataInicio,
    dataFim: store.painelGeralDataReducer.dataFim,
    raio: store.painelGeralDataReducer.raio,
    preco: store.painelGeralDataReducer.preco,
    canal: store.painelGeralDataReducer.canal,
    ad: store.painelGeralDataReducer.ad,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        setErro,
        setLimitProducts,
        getBoxPlotData,
        setSortColumnBoxplot,
        getTimeEvolutionData,
        getMapData,
        getMarkersData,
        getTopFiveData,
        getShareTokenData,
        getTotalDatapoints,
        getProductDashboardSlide,
        getProductDashboardTable,
        getItensFiltro,
        searchProdutos,
    },
    dispatch,
);

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(InfoPanel),
);

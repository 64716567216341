import classNames from 'classnames';
import React from 'react';
import { Whisper } from 'rsuite';
import { CurrencyInputProps } from '../../../../../../@types/CurrencyInput';
import {
    InputAddon,
    InputCurrency,
    InputGroup,
} from '../../../../../../components';
import { InputCurrencyProps } from '../../../../../../components/InputCurrency/InputCurrency';
import { isBrokenedPriceList, isChangedPrice } from '../../../../../../lib';
import { Tag } from '../../../../Lite';
import { VariationBoxIndicator } from '../../../../Negociacoes/pages/NegociacaoFornecedor/components';
import { GerenciadorPrecosDatapoint } from '../../../services';
import { QuebraLimitesInfo } from '../../QuebraLimitesInfo';
import BaseCell from '../BaseCell/BaseCell';

type RowData = GerenciadorPrecosDatapoint;

export type PrecoSugeridoCellProps = CurrencyInputProps & {
    rowData: RowData;
};

const getSkin = (rowData: RowData): InputCurrencyProps['skin'] => {
    if (isBrokenedPriceList(rowData)) return 'red';
    if (isChangedPrice(rowData)) return 'blue';
    return 'gray';
};

const PrecoSugeridoCell = ({
    className,
    value,
    rowData,
    onChangeEvent,
    ...props
}: PrecoSugeridoCellProps) => {
    const { priceType, price, retailPrice } = rowData;
    return (
        <BaseCell className={classNames(className)}>
            <BaseCell.MainContainer>
                <InputGroup>
                    <Whisper
                        trigger="click"
                        placement="bottomEnd"
                        // @ts-ignore
                        speaker={<QuebraLimitesInfo content={rowData} />}
                    >
                        <InputAddon>R$</InputAddon>
                    </Whisper>
                    <InputCurrency
                        className="novo-preco"
                        decimalSeparator=","
                        thousandSeparator="."
                        precision="2"
                        allowEmpty
                        allowNegative={false}
                        value={value}
                        onChangeEvent={onChangeEvent}
                        skin={getSkin(rowData)}
                        {...props}
                    />
                </InputGroup>
            </BaseCell.MainContainer>
            <BaseCell.SubContainer>
                <VariationBoxIndicator
                    positiveValueIsGood
                    value={(price ?? 0) / (retailPrice ?? 0) - 1}
                    suffix="%"
                />
                <Tag skin="blue" size="xsmall">
                    {priceType}
                </Tag>
            </BaseCell.SubContainer>
        </BaseCell>
    );
};

export default PrecoSugeridoCell;

import classNames from 'classnames';
import React from 'react';
import Modal, { ModalProps } from 'rsuite/lib/Modal';
import { LoadingSpinerArea } from '../../../../../../components/LoadingSpinner';
import { ButtonPrimary } from '../../../../Lite';
import styles from './FecharNegocioModal.module.scss';

export type FecharNegocioModalDataProps = {
    name: string;
    id: string;
};

export type FecharNegocioModalProps = ModalProps & {
    data: FecharNegocioModalDataProps;
    onSubmit?: (formData: FecharNegocioModalDataProps) => void;
    onHide?: () => void;
};

export const FecharNegocioModalModal = ({
    className = '',
    onHide,
    onSubmit,
    data,
    ...props
}: FecharNegocioModalProps) => (
    <>
        <Modal
            className={classNames(
                'info-modal',
                styles['fechar-negocio-modal'],
                className,
            )}
            onHide={onHide}
            {...props}
        >
            <LoadingSpinerArea
                height="100%"
                size="md"
                area="negotiation/close-negotiation"
            />
            <form onSubmit={(e) => e.preventDefault()}>
                <Modal.Header>
                    <Modal.Title
                        className={styles['excluir-negociacao-modal__title']}
                    >
                        Fechar negócio
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body
                    className={styles['excluir-negociacao-modal__body']}
                >
                    <p>Tem certeza que deseja fechar a negociação</p>
                    <strong>{data.name}</strong>
                    <br />
                    <br />
                    <p>A negociação não poderá mais ser alterada</p>
                </Modal.Body>
                <Modal.Footer
                    className={styles['excluir-negociacao-modal__footer']}
                >
                    <ButtonPrimary
                        skin="blue"
                        theme="ghost"
                        type="button"
                        onClick={onHide}
                    >
                        CANCELAR
                    </ButtonPrimary>
                    <ButtonPrimary
                        skin="blue"
                        theme="filled"
                        type="submit"
                        onClick={() => onSubmit?.(data)}
                    >
                        fechar negócio
                    </ButtonPrimary>
                </Modal.Footer>
            </form>
        </Modal>
        <LoadingSpinerArea size="sm" area="saved-filter" height={null} />
    </>
);

import React from 'react';
import { Loader } from 'rsuite';
import { Icon } from '../../../../RevisaoPrecos/Components/QuickActionFilter';
import './style.scss';

type BUTON_THEMES = 'filled' | 'ghost';
export type BUTONS_SKINS =
    | 'gray'
    | 'blue'
    | 'green'
    | 'red'
    | 'orange'
    | 'purple';
type BASE_SIZES = 'large' | 'medium' | 'small' | 'xsmall';

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    theme?: BUTON_THEMES;
    size?: BASE_SIZES;
    skin?: BUTONS_SKINS;
    isLoading?: boolean;
    fullWidth?: boolean;
};

const ButtonPrimary = ({
    children,
    className = '',
    theme = 'filled',
    skin = 'blue',
    size = 'medium',
    isLoading,
    disabled,
    fullWidth = false,
    ...props
}: ButtonProps) => (
    <button
        className={[
            'btn-primary',
            `btn-primary-${theme}`,
            `btn-primary-${size}`,
            `info-skin-${skin}`,
            theme === 'ghost' ? `info-skin-ghost-${skin}` : '',
            `info-skin-border-color-${skin}`,
            `typography-btn-${size}`,
            fullWidth ? 'btn-primary-full-width' : '',
            className,
        ].join(' ')}
        disabled={isLoading ? true : disabled}
        {...props}
    >
        {isLoading ? (
            <>
                <p>Carregando...</p>
                <Loader />
            </>
        ) : (
            children
        )}
    </button>
);

ButtonPrimary.Icon = Icon;

export { ButtonPrimary };

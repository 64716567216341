/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-indent */
/* eslint-disable operator-linebreak */
import classNames from 'classnames';
import React, { HtmlHTMLAttributes } from 'react';
import { Button, Checkbox } from 'rsuite';
import { ButtonProps } from 'rsuite/lib/Button';
import { CheckPickerProps } from 'rsuite/lib/CheckPicker';
import { CheckboxProps } from 'rsuite/lib/Checkbox';
import { SelectPickerProps } from 'rsuite/lib/SelectPicker';
import { SalvarFiltrosModal } from '../../pages/IPA/Negociacoes/pages/NegociacaoFornecedor/components';
import {
    ExcluirFiltroModal,
    Icon,
} from '../../pages/IPA/RevisaoPrecos/Components';
import { SelectPill } from '../../pages/IRA/Components/SelectPill';
import { SegmentsPlaceholder } from '../NewFilterBox/Components';
import styles from './FilterSection.module.scss';
import { IFilterItem, IUseFilter } from './hooks';

export type FilterSelectAllProps = CheckboxProps & {
    onSelectAll?: (checked: boolean) => void;
};

export const FilterSelectAll = ({
    className,
    checked,
    indeterminate,
    onChange,
    onSelectAll,
    ...props
}: FilterSelectAllProps) => {
    return (
        <div className={classNames('picker-pill-footer', className)}>
            <Checkbox
                inline
                onChange={(v, c, e) => {
                    onSelectAll?.(c);
                    onChange?.(v, c, e);
                }}
                checked={checked}
                indeterminate={checked ? false : indeterminate}
                {...props}
            >
                Selecionar todos
            </Checkbox>
        </div>
    );
};

export type CheckFilterProps = CheckPickerProps & Omit<IFilterItem, 'queryFn'>;

const Check = ({ className, type = 'check', ...props }: CheckFilterProps) => (
    <SelectPill
        id="check-picker-pill"
        className={className}
        type={type}
        {...props}
    />
);

type SelectFilterProps = SelectPickerProps & Omit<IFilterItem, 'queryFn'>;

const Select = ({
    className,
    type = 'select',
    ...props
}: SelectFilterProps) => (
    <SelectPill
        id="check-picker-pill"
        className={className}
        type={type}
        {...props}
    />
);

type BaseProps = {
    isLoading?: boolean;
    textLoading?: string;
    textSearch?: string;
    selectAll?: FilterSelectAllProps;
};

type CheckType = {
    type?: 'check';
} & CheckFilterProps &
    BaseProps;

type SelectType = {
    type?: 'select';
} & SelectFilterProps &
    BaseProps;

type InnerComponentProps = CheckType | SelectType;

const FILTERS = {
    check: (props: CheckFilterProps) => <Check {...props} />,
    select: (props: SelectFilterProps) => <Select {...props} />,
} as const;

export const InnerFilter = ({
    isLoading,
    data,
    value,
    textLoading,
    textSearch = 'Pesquisar',
    type = 'check',
    selectAll,
    ...props
}: InnerComponentProps) => {
    const Component = FILTERS[type] || null;

    return (
        <Component
            data={isLoading ? [] : data}
            value={value}
            locale={{
                noResultsText: isLoading
                    ? textLoading
                    : 'Nenhum item encontrado',
                searchPlaceholder: textSearch,
            }}
            renderExtraFooter={
                selectAll
                    ? () => (
                          <FilterSelectAll
                              checked={
                                  data?.length === value?.length &&
                                  !!value?.length
                              }
                              {...selectAll}
                          />
                      )
                    : undefined
            }
            {...props}
        />
    );
};

export const FilterButton = ({ className, ...props }: ButtonProps) => (
    <Button
        className={classNames(styles['filter-section__button'], className)}
        {...props}
    />
);

export type FilterSectionProps = IUseFilter &
    Omit<HtmlHTMLAttributes<HTMLDivElement>, 'onSelect'>;

const FilterSection = ({
    className,
    data = {},
    values = {},
    savedFilter,
    filters = [],
    isEmpty = true,
    modals,
    loading,
    onSearch,
    onSelect,
    onClearAll,
    onSelectAll,
    onOpenModal,
    onCloseModal,
    onSaveFilter,
    onDeleteFilter,
    onSelectSavedFilter,
    ...props
}: FilterSectionProps) => (
    <>
        <div
            className={classNames(styles['filter-section'], className)}
            {...props}
        >
            <div className={classNames(styles.row, styles.row1)}>
                <div>
                    {filters.map(
                        ({ key, placeholder, type, ...rest }) =>
                            !key.match(/category/) &&
                            !key.match(/savedFilters/) && (
                                <InnerFilter
                                    key={key}
                                    data={data[key] ?? []}
                                    value={values[key] ?? []}
                                    placeholder={placeholder as string}
                                    type={type}
                                    isLoading={loading?.[key]}
                                    textSearch={rest.textSearch}
                                    textLoading={rest.textLoading}
                                    onSearch={(query) => onSearch(key, query)}
                                    onSelect={(value) => onSelect(key, value)}
                                    onClose={() => onSearch(key, '')}
                                    onClean={() => {
                                        onSearch(key, '');
                                        onSelect(key, []);
                                    }}
                                    renderExtraFooter={
                                        rest.selectAll
                                            ? () => {
                                                  const isChecked =
                                                      data[key]?.length ===
                                                      values[key]?.length;
                                                  return (
                                                      <div className="picker-pill-footer">
                                                          <Checkbox
                                                              inline
                                                              indeterminate={
                                                                  !isChecked &&
                                                                  !!values[key]
                                                                      ?.length
                                                              }
                                                              checked={
                                                                  isChecked
                                                              }
                                                              onChange={(
                                                                  _v,
                                                                  checked,
                                                              ) =>
                                                                  onSelectAll?.(
                                                                      key,
                                                                      checked,
                                                                  )
                                                              }
                                                          >
                                                              Selecionar todos
                                                          </Checkbox>
                                                      </div>
                                                  );
                                              }
                                            : undefined
                                    }
                                />
                            ),
                    )}
                </div>
                {onClearAll && (
                    <Button
                        className={styles['filter-section__button']}
                        onClick={onClearAll}
                    >
                        Limpar filtros
                    </Button>
                )}
            </div>
            <div className={classNames(styles.row, styles.row2)}>
                <div>
                    {filters.map(
                        ({ key, placeholder, type, level, ...rest }) =>
                            key.match(/categoryLevel/) && (
                                <InnerFilter
                                    key={key}
                                    data={data[key] ?? []}
                                    value={values[key] ?? []}
                                    isLoading={loading?.[key]}
                                    textSearch={rest.textSearch}
                                    textLoading={rest.textLoading}
                                    type={type}
                                    onSearch={(query) => onSearch(key, query)}
                                    onSelect={(value) => onSelect(key, value)}
                                    onClose={() => onSearch(key, '')}
                                    onClean={() => {
                                        onSearch(key, '');
                                        onSelect(key, []);
                                    }}
                                    // @ts-ignore
                                    placeholder={
                                        <SegmentsPlaceholder
                                            level={level as number}
                                            placeholder={placeholder as string}
                                        />
                                    }
                                />
                            ),
                    )}
                </div>

                <div>
                    {!isEmpty && (
                        <Button onClick={() => onOpenModal?.('salvarFiltros')}>
                            <Icon icon="MdSave" />
                        </Button>
                    )}
                    {savedFilter.value && (
                        <Button
                            className="clear-filtros-btn salvar-filtros-btn"
                            onClick={() => onOpenModal?.('excluirFiltro')}
                        >
                            <Icon icon="MdDelete" />
                        </Button>
                    )}
                    {filters.map(
                        ({ key, placeholder, type, ...rest }) =>
                            key.match(/savedFilters/) && (
                                <InnerFilter
                                    key={key}
                                    data={data[key] ?? []}
                                    value={savedFilter.value ?? null}
                                    placeholder={placeholder as string}
                                    isLoading={loading?.[key]}
                                    textSearch={rest.textSearch}
                                    textLoading={rest.textLoading}
                                    type={type}
                                    onSearch={(query) => onSearch(key, query)}
                                    onSelect={(_v, item: any) =>
                                        onSelectSavedFilter(item)
                                    }
                                    onClose={() => onSearch(key, '')}
                                    onClean={onClearAll}
                                />
                            ),
                    )}
                </div>
            </div>
        </div>
        <SalvarFiltrosModal
            show={modals.salvarFiltros}
            onHide={() => onCloseModal?.('salvarFiltros')}
            name={savedFilter.label}
            onSubmit={(name, option) =>
                onSaveFilter?.(
                    {
                        ...savedFilter,
                        id: savedFilter.id,
                        name,
                    },
                    option,
                )
            }
        />
        <ExcluirFiltroModal
            show={modals.excluirFiltro}
            onHide={() => onCloseModal?.('excluirFiltro')}
            filterName={savedFilter.label ?? ''}
            onConfirm={(id) => onDeleteFilter?.(id)}
            savedFilter={{
                filterName: savedFilter.label,
                data: {
                    value: savedFilter.value,
                    label: savedFilter.label,
                    name: savedFilter.name,
                },
            }}
        />
    </>
);

export { FilterSection };

import { useDispatch, useSelector } from 'react-redux';

import { useState } from 'react';
import { useNewFiltros } from '../../../../hooks';
import {
    FiltersNameProps,
    addFilterState,
    clearFilters,
} from '../../../../reducers/gerenciadorPrecos/filtros';
import { selectorGerenciadorPrecos } from '../../../../reducers/gerenciadorPrecos/gerenciadorPrecosReducer';
import {
    FilterItemDataProps,
    FiltersOptions,
    addFiltersData,
} from '../../../../reducers/reducerFiltrosData';
import { QuickActionFilterState } from '../Components/QuickActionFilter';
import {
    GetSortedDataProps,
    camelToSnakeCase,
    getSortedData,
    getValidData,
} from '../utils';

export type FILTER_OBJECT_PROPS = {
    cache: {
        label: string;
        value: string;
    }[];
    value: string[] | Record<'type' | 'value', string>[];
    checkAll?: boolean;
    indeterminate?: boolean;
};

export type FILTERS_OPTIONS_PROPS =
    | 'productIds'
    | 'productFamilyIds'
    | 'storeIds'
    | 'priceType'
    | 'segmentacaoSelecionada'
    | 'categoryLevel1'
    | 'categoryLevel2'
    | 'categoryLevel3'
    | 'categoryLevel4'
    | 'categoryLevel5'
    | 'categoryLevel6'
    | 'categoryLevel7';

export type FILTER_STATE_PROPS = Record<
    FILTERS_OPTIONS_PROPS,
    FILTER_OBJECT_PROPS
>;

export type SENSIBILITIES =
    | 'infoPriceSensibility'
    | 'sensibilityType'
    | 'infoPriceAbcClass'
    | 'infoPriceGlobalAbcClass'
    | 'abcClass';

export type GetModelFiltrosProps = {
    pageSize?: number;
    pageNumber?: number;
} & Partial<GetSortedDataProps>;

export const useFiltrosState = () => {
    const [isSalvarFiltrosModalOpen, setIsSalvarFiltrosModalOpen] =
        useState(false);

    const { filtros, modals, savedFilter } = useSelector(
        selectorGerenciadorPrecos,
    );

    const dispatch = useDispatch();
    const { data } = useNewFiltros();

    const handleChangeFilter = (value: string[], name: FiltersNameProps) => {
        let cache: Record<'label' | 'value', string>[];

        if (value === null) {
            cache = [];

            dispatch(
                addFilterState({
                    name,
                    data: {
                        cache,
                    },
                }),
            );
            return;
        }

        const cacheData = filtros[name];

        if (value !== null && value.length !== cacheData?.cache?.length) {
            cache = data[name].filter(
                (item) => value.indexOf(item.value) !== -1,
            );

            dispatch(
                addFilterState({
                    name,
                    data: {
                        value,
                        cache,
                    },
                }),
            );
            return;
        }

        dispatch(
            addFilterState({
                name,
                data: {
                    value,
                },
            }),
        );
    };

    const handleClearFilters = () => {
        dispatch(clearFilters());
    };

    const handleAddFilterData = (
        data: FilterItemDataProps,
        name: FiltersOptions,
    ) => {
        if (data) {
            dispatch(
                addFiltersData({
                    data,
                    name,
                }),
            );
        }
    };

    function getSensibility(type: SENSIBILITIES) {
        return (
            filtros.sensibilities?.value
                ?.filter((item) => item?.type === type)
                .map((item) => item?.value) || null
        );
    }

    function getModelFiltros({
        sort = {},
        sortColumnsTypes,
        pageSize,
        pageNumber,
    }: GetModelFiltrosProps = {}) {
        const sortedData = getSortedData({ sort, sortColumnsTypes });
        const data = {
            productIds: filtros.productIds.value,
            storeIds: filtros.storeIds.value,
            productFamilyIds: filtros.productFamilyIds.value,
            priceType: filtros.priceType.value,
            sensibilities: getSensibility('infoPriceSensibility'),
            supplier: filtros.supplier.value,
            productBrand: filtros.productBrand.value,
            exclusive: getSensibility('sensibilityType'),
            curveAbcLocal: getSensibility('infoPriceAbcClass'),
            curveAbcGlobal: getSensibility('infoPriceGlobalAbcClass'),
            curve: getSensibility('abcClass'),
            categoryLevel1: filtros.categoryLevel1.value,
            categoryLevel2: filtros.categoryLevel2.value,
            categoryLevel3: filtros.categoryLevel3.value,
            categoryLevel4: filtros.categoryLevel4.value,
            categoryLevel5: filtros.categoryLevel5.value,
            categoryLevel6: filtros.categoryLevel6.value,
            categoryLevel7: filtros.categoryLevel7.value,
            sort: sortedData && camelToSnakeCase(sortedData),
            size: pageSize,
            page: pageNumber,
        };
        return getValidData<typeof data>(data);
    }

    const isEmptyFilters = (
        filtersData: FILTER_STATE_PROPS & QuickActionFilterState,
    ) => {
        let isEmpty = true;
        Object.entries(filtersData)?.forEach(([k, v]) => {
            if (Array.isArray(v) && v.length !== 0) {
                isEmpty = false;
            } else if (k === 'changedPrices') {
                isEmpty = !v;
            } else if (k === 'brokenLimit') {
                isEmpty = false;
            }
        });
        return isEmpty;
    };

    const handleOpenSalvarFiltrosModal = () => {
        setIsSalvarFiltrosModalOpen(true);
    };

    const handleCloseSalvarFiltrosModal = () => {
        setIsSalvarFiltrosModalOpen(false);
    };

    return {
        filtros,
        getModelFiltros,
        isEmptyFilters,
        isSalvarFiltrosModalOpen,
        handleOpenSalvarFiltrosModal,
        handleCloseSalvarFiltrosModal,
        handleClearFilters,
        handleChangeFilter,
        handleAddFilterData,
        savedFilter,
        modals,
    };
};

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

export type FilterDataProps = Record<string, unknown> & {
    label: string;
    value: string;
};

type FilterItemProps = {
    cache: FilterDataProps[];
    value: string[];
};

export type FiltersNameProps =
    | 'productIds'
    | 'productFamilyIds'
    | 'storeIds'
    | 'sensibilities'
    | 'priceType'
    | 'categoryLevel1'
    | 'categoryLevel2'
    | 'categoryLevel3'
    | 'categoryLevel4'
    | 'categoryLevel5'
    | 'categoryLevel6'
    | 'categoryLevel7'
    | 'supplier'
    | 'productBrand';

export type GerenciadorPrecosFiltrosStateProps = {
    productIds: FilterItemProps;
    productFamilyIds: FilterItemProps;
    storeIds: FilterItemProps;
    priceType: FilterItemProps;
    categoryLevel1: FilterItemProps;
    categoryLevel2: FilterItemProps;
    categoryLevel3: FilterItemProps;
    categoryLevel4: FilterItemProps;
    categoryLevel5: FilterItemProps;
    categoryLevel6: FilterItemProps;
    categoryLevel7: FilterItemProps;
    supplier: FilterItemProps;
    productBrand: FilterItemProps;
    sensibilities: {
        cache: FilterDataProps[];
        value: { type: string; value: string }[];
    };
};

export const initialState: GerenciadorPrecosFiltrosStateProps = {
    productIds: {
        cache: [],
        value: [],
    },
    productFamilyIds: {
        cache: [],
        value: [],
    },
    storeIds: {
        cache: [],
        value: [],
    },
    sensibilities: {
        cache: [],
        value: [],
    },
    priceType: {
        cache: [],
        value: [],
    },
    supplier: {
        cache: [],
        value: [],
    },
    productBrand: {
        cache: [],
        value: [],
    },
    categoryLevel1: {
        cache: [],
        value: [],
    },
    categoryLevel2: {
        cache: [],
        value: [],
    },
    categoryLevel3: {
        cache: [],
        value: [],
    },
    categoryLevel4: {
        cache: [],
        value: [],
    },
    categoryLevel5: {
        cache: [],
        value: [],
    },
    categoryLevel6: {
        cache: [],
        value: [],
    },
    categoryLevel7: {
        cache: [],
        value: [],
    },
};

const slice = createSlice({
    name: 'filtros-state',
    initialState,
    reducers: {
        setFilterValueList(
            state,
            {
                payload,
            }: PayloadAction<{
                name: FiltersNameProps;
                value: string[];
            }>,
        ) {
            state[payload.name].value = payload.value;
        },
        addFilterState(
            state,
            {
                payload,
            }: PayloadAction<{
                name: FiltersNameProps;
                data: Partial<FilterItemProps>;
            }>,
        ) {
            if (payload.data.value) {
                if (!state[payload.name]) return;
                state[payload.name].value = payload.data.value;
            }
            if (payload.data.cache) {
                if (!state[payload.name]) return;
                state[payload.name].cache = _.uniqBy(
                    [...state[payload.name].cache, ...payload.data.cache],
                    'value',
                );
            }
        },
        addSegmentValue(
            state,
            {
                payload,
            }: PayloadAction<{
                value: Record<'type' | 'value', string>[];
            }>,
        ) {
            state.sensibilities.value = [
                ...state.sensibilities.value,
                ...payload.value,
            ];
        },
        clearFilters() {
            return initialState;
        },
        resetFilters() {
            return initialState;
        },
    },
});

const {
    setFilterValueList,
    clearFilters,
    addFilterState,
    resetFilters,
    addSegmentValue,
} = slice.actions;

export {
    addFilterState,
    addSegmentValue,
    clearFilters,
    resetFilters,
    setFilterValueList
};

export default slice.reducer;

import React from 'react';
import { Button, Modal } from 'rsuite';
import { BigNumbersBox } from '../BigNumbersBox';
import styles from './styles.module.scss';

export interface IModalOnDemandPlan {
    onCancel():void;
    onConfirm():void;
    show: boolean;
    requestSummaryData: any;
}

export const ModalOnDemandPlan = ({
    show,
    onCancel,
    onConfirm,
    requestSummaryData,
}: IModalOnDemandPlan) => (
    <Modal
        overflow={false}
        backdrop="static"
        show={show}
        className={styles['plan-container']}
        onHide={onCancel}
    >
        <Modal.Header closeButton onHide={onCancel}>
            <div className={styles['header-title']}>
                <h1>
                    Veja o seu plano On Demand
                </h1>
            </div>
        </Modal.Header>
        <Modal.Body>
            <div className={styles['plan-content']}>
                <div className={styles['box-Wrapper']}>
                    <BigNumbersBox.Root>
                        <BigNumbersBox.MainValue>
                            {requestSummaryData?.requestsPlan === null ? 0 : requestSummaryData?.requestsPlan}
                        </BigNumbersBox.MainValue>
                        <BigNumbersBox.RegularText>Solicitações inclusas</BigNumbersBox.RegularText>
                        <BigNumbersBox.RegularText>no plano</BigNumbersBox.RegularText>
                    </BigNumbersBox.Root>
                    <BigNumbersBox.Root marginRight="32px">
                        <BigNumbersBox.MainValue>
                            {requestSummaryData?.requestsAdditionalPlan === null ? 0 : requestSummaryData?.requestsAdditionalPlan}
                        </BigNumbersBox.MainValue>
                        <BigNumbersBox.RegularText>Solicitações adicionais</BigNumbersBox.RegularText>
                        <BigNumbersBox.RegularText>ao plano</BigNumbersBox.RegularText>
                    </BigNumbersBox.Root>
                    <BigNumbersBox.Root>
                        <BigNumbersBox.MainValue>
                            {requestSummaryData ? (requestSummaryData?.totalRequests - requestSummaryData?.available) : 0}
                        </BigNumbersBox.MainValue>
                        <BigNumbersBox.RegularText>Solicitações</BigNumbersBox.RegularText>
                        <BigNumbersBox.RegularText>em uso</BigNumbersBox.RegularText>
                    </BigNumbersBox.Root>
                    <BigNumbersBox.Root type="available">
                        <BigNumbersBox.MainValue>{requestSummaryData?.available}</BigNumbersBox.MainValue>
                        <BigNumbersBox.RegularText>Solicitações</BigNumbersBox.RegularText>
                        <BigNumbersBox.RegularText>disponíveis</BigNumbersBox.RegularText>
                    </BigNumbersBox.Root>
                </div>
                <div className={styles['text-Wrapper']}>
                    <p>
                        A quantidade solicitações adicionais ao seu contrato pode aumentar conforme a sua necessidade.
                    </p>
                    <p className={styles['bottom-text']}>
                        Para isso, basta entrar em contato via CSM e conversar sobre a compra e/ou negociação de mais solicitações mensais.
                        Você pode fazer isso por e-mail ou clicar no botão "Quero mais solicitações" abaixo.
                    </p>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={onCancel} appearance="ghost">
                Fechar
            </Button>
            <Button onClick={onConfirm} appearance="primary">
                Quero mais solicitações
            </Button>
        </Modal.Footer>
    </Modal>
);

import { FetchStatus, useQueries, useQuery } from '@tanstack/react-query';
import { useContext, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
    FilterKeysProps,
    FiltersStateProps,
    PageOptions,
} from '../@types/FiltersTypes';
import {
    getFamiliaProdutos,
    getLojasRevisao,
} from '../pages/IPA/RevisaoPrecos/services';
import {
    GetSavedFiltersDataProps,
    getSavedFilters,
} from '../pages/IPA/RevisaoPrecos/services_ts';
import {
    FilterItemDataProps,
    addFiltersData,
    reducerDataFiltrosSelector,
    setFiltersData,
    setFiltersSearch,
} from '../reducers/reducerFiltrosData';
import {
    getItensSegmentoProduto,
    getSegmentos,
    getSegmentosClasses,
    getSegmentosSensibilities,
    searchFiltroProdutos,
} from '../services/ProdutoService';
import {
    getProductBrandsData,
    getProductSuppliersData,
} from '../services/ProdutoService_ts';
import { GlobalContext } from '../utils/Helpers';

export type UseNewFiltrosProps = {
    filtros?: FiltersStateProps;
    screen?: PageOptions;
};

const listProductBrands = async (
    search?: string,
): Promise<Record<'label' | 'value', string>[]> => {
    const data = await getProductBrandsData({ search });
    return data.map((item) => ({
        value: item.productBrand,
        label: item.productBrand,
    }));
};

const listSuppliers = async (
    search?: string,
): Promise<Record<'label' | 'value', string>[]> => {
    const data = await getProductSuppliersData({ search });
    return data.map((item) => ({
        value: item.supplier,
        label: item.supplier,
    }));
};

const checkIfItIsLoading = (fetchStatus: FetchStatus): boolean =>
    !(fetchStatus === 'idle');

export const useNewFiltros = ({
    screen = 'PRICE_MANAGER',
    ...options
}: UseNewFiltrosProps = {}) => {
    const { data, search } = useSelector(reducerDataFiltrosSelector);
    const dispatch = useDispatch();
    const location = useLocation();
    const [timer, setTimer] = useState<NodeJS.Timeout>();

    const contextFilter = useContext(GlobalContext);
    const filtros = options.filtros ?? contextFilter;

    async function listaTiposPreco() {
        return (
            [
                { value: 'AUTOMATIC', label: 'Auto' },
                { value: 'MANUAL', label: 'Manual' },
                { value: 'ERP', label: 'ERP' },
                { value: 'EDITED', label: 'Editado' },
            ] || []
        );
    }

    const listaSegmentacao = async () => {
        const resp = await getSegmentosSensibilities();
        const respAbcClass = await getSegmentosClasses();
        return [
            {
                label: 'Super Sensível',
                value: {
                    type: 'infoPriceSensibility',
                    value: 'SUPER_SENSITIVE',
                },
                role: 'Sensibilidade InfoPrice',
            },
            {
                label: 'Sensível',
                value: {
                    type: 'infoPriceSensibility',
                    value: 'SENSITIVE',
                },
                role: 'Sensibilidade InfoPrice',
            },
            {
                label: 'Não Sensível',
                value: {
                    type: 'infoPriceSensibility',
                    value: 'NOT_SENSITIVE',
                },
                role: 'Sensibilidade InfoPrice',
            },
            {
                label: 'Local A',
                value: {
                    type: 'infoPriceAbcClass',
                    value: 'A',
                },
                role: 'Curva ABC Local',
            },
            {
                label: 'Local B',
                value: {
                    type: 'infoPriceAbcClass',
                    value: 'B',
                },
                role: 'Curva ABC Local',
            },
            {
                label: 'Local C',
                value: {
                    type: 'infoPriceAbcClass',
                    value: 'C',
                },
                role: 'Curva ABC Local',
            },
            {
                label: 'Local D',
                value: {
                    type: 'infoPriceAbcClass',
                    value: 'D',
                },
                role: 'Curva ABC Local',
            },
            {
                label: 'Global A',
                value: {
                    type: 'infoPriceGlobalAbcClass',
                    value: 'A',
                },
                role: 'Curva ABC Global',
            },
            {
                label: 'Global B',
                value: {
                    type: 'infoPriceGlobalAbcClass',
                    value: 'B',
                },
                role: 'Curva ABC Global',
            },
            {
                label: 'Global C',
                value: {
                    type: 'infoPriceGlobalAbcClass',
                    value: 'C',
                },
                role: 'Curva ABC Global',
            },
            {
                label: 'Global D',
                value: {
                    type: 'infoPriceGlobalAbcClass',
                    value: 'D',
                },
                role: 'Curva ABC Global',
            },
            ...(resp.length
                ? resp.map((item: { sensibility_type: string }) => ({
                      label: item.sensibility_type,
                      value: {
                          type: 'sensibilityType',
                          value: item.sensibility_type,
                      },
                      role: 'Exclusiva',
                  }))
                : []),
            ...(respAbcClass.length
                ? respAbcClass.map((item: { abc_class: string }) => ({
                      label: item.abc_class,
                      value: { type: 'abcClass', value: item.abc_class },
                      role: 'Curva',
                  }))
                : []),
        ];
    };

    const setProdutosList = (list: any[]) => {
        const newList = list.map((item) => ({
            label: `${item.product_id} - ${item.description}`,
            value: item.product_id,
            gtin: item.gtin,
            product_id: item.product_id,
            description: item.description,
        }));

        const cacheList = filtros?.productIds?.cache;
        const removeDuplicate = newList.filter(
            (i) => !filtros?.productIds?.value.includes(i.value),
        );

        const concatList = filtros?.productIds?.value.length
            ? cacheList.concat(removeDuplicate)
            : newList;

        return concatList;
    };

    const setLojasList = (list: any[]) => {
        const newList = list.map(({ store_id, store }) => ({
            label: `${store_id} - ${store}`,
            value: store_id,
            store_id,
        }));

        const cacheList = filtros?.storeIds?.cache;
        const removeDuplicate = newList.filter(
            (i) => !filtros?.storeIds?.value.includes(i.value),
        );

        const concatList = filtros?.storeIds?.value.length
            ? cacheList.concat(removeDuplicate)
            : newList;

        return concatList;
    };

    const setFamiliasList = (list: any[]) => {
        const newList = list.map((item) => ({
            label: item,
            value: item,
        }));

        const cacheList = filtros?.productFamilyIds?.cache;
        const removeDuplicate = newList.filter(
            (i) => !filtros?.productFamilyIds?.value.includes(i.value),
        );
        const concatList = filtros?.productFamilyIds?.value.length
            ? cacheList.concat(removeDuplicate)
            : newList;

        return concatList;
    };

    const setSegmentosList = (list: any, item: any) => {
        const newList = list.map((item: any) => ({
            label: item,
            value: item,
        }));

        const cacheList = filtros[item]?.cache;
        const removeDuplicate = newList.filter(
            (i: { value: any }) => !filtros[item]?.value.includes(i.value),
        );
        const concatList = filtros[item]?.value.length
            ? cacheList.concat(removeDuplicate)
            : newList;

        dispatch(
            setFiltersData({
                name: item,
                data: concatList,
            }),
        );
    };

    const searchProdutos = async (
        query: string,
    ): Promise<FilterItemDataProps> => {
        const response = await searchFiltroProdutos({ query });
        if (response?.data) {
            return setProdutosList(response.data);
        }
        return [];
    };

    const searchLojas = async (query: string) => {
        const response = await getLojasRevisao(query);
        if (response) {
            return setLojasList(response);
        }
        return [];
    };

    const searchFamilias = async (query: string) => {
        const response = await getFamiliaProdutos(query);
        if (response?.product_family_ids) {
            return setFamiliasList(response.product_family_ids);
        }

        return [];
    };

    const listaSegmentos = async (): Promise<
        Array<{
            level: number;
            field: FilterKeysProps;
            name: string;
        }>
    > => {
        const resp = await getSegmentos();
        return resp.sort((a, b) => a.level - b.level);
    };

    const searchItensSegmento = async (nivel: string, query: string) => {
        const response = await getItensSegmentoProduto(nivel, { query });
        if (response) setSegmentosList(response, nivel);
    };

    const changeQuery = (value: string, item: string) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            switch (item) {
                case 'productIds':
                    dispatch(setFiltersSearch({ name: 'productIds', value }));
                    break;
                case 'productFamilyIds':
                    dispatch(
                        setFiltersSearch({ name: 'productFamilyIds', value }),
                    );
                    break;
                case 'storeIds':
                    dispatch(setFiltersSearch({ name: 'storeIds', value }));
                    break;
                case 'savedFilters':
                    dispatch(setFiltersSearch({ name: 'savedFilters', value }));
                    break;
                case 'productBrand':
                    dispatch(setFiltersSearch({ name: 'productBrand', value }));
                    break;
                case 'supplier':
                    dispatch(setFiltersSearch({ name: 'supplier', value }));
                    break;
                default:
                    searchItensSegmento(item, value);
                    break;
            }
        }, 500);
        setTimer(newTimer);
    };

    async function listFiltrosSalvos({
        search,
        screen,
    }: GetSavedFiltersDataProps) {
        const data = await getSavedFilters({
            search,
            screen,
        });
        return data.map((filter) => ({
            label: filter.name,
            value: filter.id,
            ...filter,
        }));
    }

    const { isLoading: loadingFiltrosSalvos, refetch: refetchSavedFilters } =
        useQuery({
            queryKey: ['get-filtros', search.savedFilters],
            retry: false,
            queryFn() {
                return trackPromise(
                    listFiltrosSalvos({
                        search: search.savedFilters,
                        screen,
                    }),
                    'saved-filter',
                );
            },
            onSuccess(data) {
                dispatch(
                    setFiltersData({
                        name: 'savedFilters',
                        data,
                    }),
                );
            },
        });

    const { isLoading: loadingProdutos } = useQuery({
        queryKey: [
            'filtroProdutosRevisao',
            search.productIds,
            ...((location.pathname.includes('ipa/regras-preco/') &&
                filtros?.productIds?.value) ||
                []),
        ],
        retry: false,
        queryFn: () => searchProdutos(search.productIds),
        onSuccess(data) {
            dispatch(
                setFiltersData({
                    name: 'productIds',
                    data,
                }),
            );
        },
    });

    const { isLoading: loadingFamilias } = useQuery({
        queryKey: ['filtroFamiliaRevisao', search.productFamilyIds],
        queryFn: () => searchFamilias(search.productFamilyIds),
        retry: false,
        onSuccess(data) {
            dispatch(
                setFiltersData({
                    name: 'productFamilyIds',
                    data,
                }),
            );
        },
    });

    const {
        isLoading: loadingLojas,
        isError,
        error,
    } = useQuery({
        queryKey: ['filtroLojasRevisao', search.storeIds],
        queryFn: () => searchLojas(search.storeIds),
        retry: false,
        onSuccess(data) {
            dispatch(
                setFiltersData({
                    name: 'storeIds',
                    data,
                }),
            );
        },
    });

    const { isLoading: loadingTiposPreco } = useQuery({
        queryKey: ['filtroTiposPrecoRevisao'],
        queryFn: listaTiposPreco,
        retry: false,
        onSuccess(data) {
            dispatch(
                setFiltersData({
                    name: 'priceType',
                    data,
                }),
            );
        },
    });

    useQuery({
        queryKey: ['filtroSegmentacaoRevisao'],
        queryFn: listaSegmentacao,
        retry: false,
        onSuccess(data) {
            dispatch(
                setFiltersData({
                    name: 'sensibilities',
                    data,
                }),
            );
        },
    });

    const { fetchStatus: loadingProductBrands } = useQuery({
        queryKey: ['filtroProductBrand', search.productBrand],
        queryFn: () => listProductBrands(search.productBrand),
        initialData: [],
        retry: false,
        onSuccess(data) {
            dispatch(
                setFiltersData({
                    name: 'productBrand',
                    data,
                }),
            );
        },
    });

    const { fetchStatus: loadingSuppliers } = useQuery({
        queryKey: ['filtroSuppliers', search.supplier],
        queryFn: () => listSuppliers(search.supplier),
        initialData: [],
        retry: false,
        onSuccess(data) {
            dispatch(
                setFiltersData({
                    name: 'supplier',
                    data,
                }),
            );
        },
    });

    const { data: segmentos, isLoading: loadingSegmentos } = useQuery({
        queryKey: ['filtroSegmentosRevisao'],
        queryFn: listaSegmentos,
        initialData: [],
        retry: false,
    });

    const niveis = segmentos.map((segment) => segment.field) || [];

    useQueries({
        queries: niveis.map((nivel) => ({
            queryKey: ['getItensSegmentoProduto', nivel],
            queryFn: () => {
                getItensSegmentoProduto(nivel, {}).then((response) => {
                    const data = response.map((item: string) => ({
                        label: item,
                        value: item,
                    }));
                    dispatch(
                        setFiltersData({
                            name: nivel,
                            data,
                        }),
                    );
                    return response;
                });
            },
        })),
    });

    // ======================== HANDLERS ========================
    const handleAddFiltroData = (
        filtros: FilterItemDataProps,
        filterOption: FilterKeysProps,
    ) => {
        dispatch(
            addFiltersData({
                name: filterOption,
                data: filtros,
            }),
        );
    };

    return {
        data,
        loading: {
            productBrand: checkIfItIsLoading(loadingProductBrands),
            supplier: checkIfItIsLoading(loadingSuppliers),
        },
        loadingLojas,
        loadingProdutos,
        loadingFamilias,
        loadingTiposPreco,
        loadingFiltrosSalvos,
        loadingSegmentos,
        loadingProductBrands: checkIfItIsLoading(loadingProductBrands),
        loadingSuppliers: checkIfItIsLoading(loadingSuppliers),
        error,
        isError,
        segmentos,
        changeQuery,
        handleAddFiltroData,
        refetchSavedFilters,
    };
};

import qs from 'qs';
import { trackPromise } from 'react-promise-tracker';
import { FiltersStateProps, PageOptions } from '../../../@types/FiltersTypes';
import { api } from '../../../lib';
import { AlterarPrecosSchema } from './Components';
import { SAVED_FILTERS_PROPS, getValidData } from './utils';

export type GetSavedFiltersDataProps = {
    search?: string;
    screen: PageOptions;
};

export function getSavedFilters({
    screen = 'PRICE_MANAGER',
    ...data
}: GetSavedFiltersDataProps): Promise<SAVED_FILTERS_PROPS> {
    return trackPromise(
        // @ts-ignore
        api({
            url: '/all-products/user-filters',
            method: 'GET',
            expectToken: true,
            params: getValidData({ screen, ...data }),
            paramsSerializer(params) {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        }).then((resp) => resp.data),
        'saved-filters',
    );
}

export type PostSavedFitersDataProps = {
    id?: string;
    name: string;
    screen: PageOptions;
    filters: Partial<Record<string, string[]>>;
};

export async function postSavedFilter({
    screen = 'PRICE_MANAGER',
    ...data
}: PostSavedFitersDataProps) {
    return trackPromise(
        // @ts-ignore
        api({
            url: '/all-products/user-filters',
            method: 'POST',
            expectToken: true,
            data: getValidData({
                screen,
                ...data,
            }),
        }).then((resp) => resp),
        'saved-filter',
    );
}

export async function putSavedFilter(data: PostSavedFitersDataProps) {
    return trackPromise(
        // @ts-ignore
        api({
            url: '/all-products/user-filters',
            method: 'PUT',
            expectToken: true,
            data,
        }).then((resp) => resp),
        'saved-filter',
    );
}

export async function deleteSavedFilter(id: string) {
    return trackPromise(
        // @ts-ignore
        api({
            url: `/all-products/user-filters/${id}`,
            method: 'DELETE',
            expectToken: true,
        }).then((resp) => resp),
        'saved-filter',
    );
}

export type IGetCompetitorsInfo = {
    productId: string;
    storeId: string;
    productFamilyId: string | null;
    competitorStoreId?: string[];
};

export async function getCompetitorsInfo(
    {
        productId = '',
        storeId = '1',
        productFamilyId = '',
        competitorStoreId = [],
    }: IGetCompetitorsInfo,
    loadingArea?: string,
) {
    return trackPromise(
        api({
            url: `/competitor/prices/${productId}`,
            method: 'GET',
            expectToken: true,
            params: getValidData({
                storeId,
                productFamilyId,
                competitorStoreId,
            }),
            paramsSerializer(params) {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        }).then((resp) => resp.data),
        loadingArea,
    );
}

export type ChangeMassicePrice = AlterarPrecosSchema & {
    products: Record<'selectedIds' | 'excludedIds', string[]>;
    selectedAll: boolean;
    filters: FiltersStateProps & {
        sort: string;
        page: number;
        size: number;
    };
};

export async function changeMassivePrice({
    filters: { sort, page, size, ...filters },
    ...data
}: ChangeMassicePrice): Promise<{ productsFailed: number }> {
    return trackPromise(
        api({
            url: '/all-products/update/price/bulk',
            method: 'POST',
            expectToken: true,
            params: {
                sort,
                page,
                size,
            },
            paramsSerializer(params) {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
            data: {
                ...data,
                filters,
            },
        }).then((res) => res.data),
        'ipa/gerenciador/alterar-precos',
    );
}

export type PriceEvent =
    | {
          eventType: 'ERP';
          price?: number | null;
      }
    | {
          eventType: 'RULE';
          price?: number;
          author: {
              name: string;
              id: string;
          };
      }
    | {
          eventType: 'MIN_VARIATION';
          brokenPrice: boolean;
      }
    | {
          eventType: 'RANGE';
          price?: number;
      }
    | {
          eventType: 'LIMIT';
          brokenPrice: boolean;
          author: {
              name: string;
              id: string;
          };
      }
    | {
          eventType: 'PRICE_LIST';
          brokenPrice: boolean;
      }
    | {
          eventType: 'PRICE';
          hour: string;
          price?: number;
      }
    | {
          eventType: 'EDITED_PRICE';
          hour: string;
          price?: number;
          author: {
              name: string;
          };
      }
    | {
          eventType: 'APPLIED_PRICE';
          hour: string;
          price?: number;
          author: {
              name: string;
          };
      };

export type PriceEventHistory = {
    date: string;
    occurrences: PriceEvent[];
}[];

export async function getPriceEventHistory(
    productsToBePricedId: string,
): Promise<PriceEventHistory> {
    const res = await trackPromise(
        api.get<PriceEventHistory>('/all-products/datapoint-event-history', {
            params: {
                productsToBePricedId,
            },
            expectToken: true,
        }),
        'price-composition',
    );

    return res.data;
}

import _ from 'lodash';
import React from 'react';
import { CheckPicker, SelectPicker } from 'rsuite';
import { CheckPickerProps } from 'rsuite/lib/CheckPicker';
import { SelectPickerProps } from 'rsuite/lib/SelectPicker';

// id = select-picker-pill (forma de pílula, para select normal sem checkbox)
// id = check-picker-pill (forma de pílula, para select com checkbox na lista e placeholder visível no check do item)
// id = check-picker-pill-default (forma de pílula, para select com checkbox na lista e placeholder escondido)
// type = check (para select com checkbox na lista, se essa props estiver vazia o select será uma lista normal)

type BaseProps = React.HtmlHTMLAttributes<HTMLElement> & {
    isLoading?: boolean;
};

type CheckPillProps = Omit<CheckPickerProps, 'placeholder'>;

export const CheckPill = ({
    id = 'check-picker-pill',
    data = [],
    cache = [],
    isLoading = false,
    block = true,
    ...props
}: CheckPillProps) => {
    const renderPlaceholder =
        id === 'check-picker-pill'
            ? (value: string | any[]) => (
                  <>
                      <span>{value && props?.placeholder}</span>
                      <span className="counter">{value && value?.length}</span>
                  </>
              )
            : props?.renderValue;

    const dataWithCache = _.uniqBy([...data, ...cache], 'value');

    return (
        <div className="picker-pill-wrapper">
            <CheckPicker
                id={id}
                renderValue={renderPlaceholder}
                data={isLoading ? [] : dataWithCache}
                cache={isLoading ? [] : cache}
                block={block}
                {...props}
            />
        </div>
    );
};

type SelectPillProps = SelectPickerProps & BaseProps;

export const SelectPill = ({
    data = [],
    cache = [],
    isLoading = false,
    block = true,
    ...props
}: SelectPillProps) => {
    const dataWithCache = Array.from(new Set([...data, ...cache]));
    return (
        <SelectPicker
            block={block}
            data={isLoading ? [] : dataWithCache}
            cache={isLoading ? [] : cache}
            {...props}
        />
    );
};

import { QueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Notification } from 'rsuite';
import { useNewFiltros } from '../../../../hooks';
import {
    closeModal,
    resetSavedFilter,
    selectorGerenciadorPrecos,
    setSavedFilter,
    setSavedFilterInputName,
} from '../../../../reducers/gerenciadorPrecos';
import {
    PostSavedFitersDataProps,
    deleteSavedFilter,
    postSavedFilter,
    putSavedFilter,
} from '../services_ts';
import { FILTERS_OPTIONS_PROPS } from './useFiltrosState';

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

type FILTROS_MODEL_PROPS = Record<FILTERS_OPTIONS_PROPS, string[]>;

export type SELECTED_FILTROS_DATA_PROPS = Record<
    'label' | 'value',
    string
> | null;

export const useSalvarFiltros = (
    filtrosModel: FILTROS_MODEL_PROPS &
        Record<'brokenLimit' | 'changedPrices', boolean | null>,
) => {
    const state = useSelector(selectorGerenciadorPrecos);
    const dispatch = useDispatch();

    const { refetchSavedFilters, data } = useNewFiltros();

    const model: PostSavedFitersDataProps = {
        id: state.savedFilter?.data?.value ?? '',
        name: state.savedFilter?.filterName ?? '',
        screen: 'PRICE_MANAGER',
        filters: filtrosModel,
    };

    const salvarFiltros = async () => {
        postSavedFilter(model)
            .then((response) => {
                if (response.status !== 200) return;
                refetchSavedFilters().then(() => {
                    Notification.success({
                        description: 'Filtro salvo com sucesso.',
                    });
                    dispatch(closeModal({ name: 'salvarFiltros' }));
                });
            })
            .catch((err) => {
                Alert.error(err.response.data._embedded.errors[0].message);
            });
    };

    const editarFiltros = async () => {
        putSavedFilter(model)
            .then((response) => {
                if (response.status !== 201) return;
                refetchSavedFilters().then(() => {
                    Notification.success({
                        description: 'Filtro editado com sucesso.',
                    });
                    dispatch(closeModal({ name: 'salvarFiltros' }));
                });
            })
            .catch((err) => {
                Alert.error(err.response.data._embedded.errors[0].message);
            });
    };

    const excluirFiltroSalvo = async (handleLimparFiltros?: () => void) => {
        deleteSavedFilter(state.savedFilter?.data?.value ?? '')
            .then((response) => {
                if (response.status !== 200) return;
                refetchSavedFilters().then(() => {
                    handleLimparFiltros?.();

                    dispatch(resetSavedFilter());
                    dispatch(setSavedFilterInputName({ value: '' }));
                    dispatch(closeModal({ name: 'excluirFiltro' }));

                    Notification.success({
                        description: 'Filtro excluido com sucesso.',
                    });
                });
            })
            .catch((err) => {
                Alert.error(err.response.data._embedded.errors[0].message);
            });
    };

    useEffect(() => {
        const filtroSalvo = data.savedFilters?.find(
            (item) => item.label === state.savedFilter.filterName,
        );
        if (filtroSalvo) {
            const { label, value } = filtroSalvo;
            dispatch(
                setSavedFilter({
                    data: {
                        filterName: label,
                        data: {
                            label,
                            value,
                            name: label,
                        },
                    },
                }),
            );
        }
    }, [data.savedFilters]);

    return {
        salvarFiltros,
        excluirFiltroSalvo,
        editarFiltros,
    };
};

import React, { HtmlHTMLAttributes, forwardRef } from 'react';
import { Icon } from 'rsuite';
import styles from './SetupMenu.module.scss';

export type SetupMenuProps = HtmlHTMLAttributes<HTMLUListElement>;

export const SetupMenu = forwardRef<HTMLUListElement, SetupMenuProps>(
    ({ children, className, ...props }, ref) => (
        <nav
            className={[styles['setup-menu'], className].join(' ')}
            ref={ref}
            {...props}
        >
            <ul>{children}</ul>
        </nav>
    ),
);

export type SetupMenuItemProps = {
    isActive?: boolean;
} & HtmlHTMLAttributes<HTMLLIElement>;

export const SetupMenuItem = forwardRef<HTMLLIElement, SetupMenuItemProps>(
    ({ className, children, isActive, ...props }, ref) => (
        <li
            className={[styles['setup-menu__item'], className].join(' ')}
            ref={ref}
            {...props}
        >
            <button
                className={isActive ? styles['setup-menu__item-active'] : ''}
            >
                {children}
                <Icon icon="arrow-right-line" size="lg" />
            </button>
        </li>
    ),
);

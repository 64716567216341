export const SAVED_FILTERS_DATA = [
    {
        id: '6515d3f7f09d49660d967e42',
        name: 'TESTE 1',
        user: null,
        client: null,
        clientId: null,
        screen: null,
        filters: {
            clientId: 123,
            client: 'null',
            productIds: ['8065069'],
            productFamilyIds: null,
            storeIds: ['1'],
            priceType: null,
            exclusive: null,
            sensibilities: null,
            curve: null,
            curveAbcLocal: null,
            curveAbcGlobal: null,
            categoryLevel1: null,
            categoryLevel2: null,
            categoryLevel3: null,
            categoryLevel4: null,
            categoryLevel5: null,
            categoryLevel6: null,
            categoryLevel7: null,
            applied: false,
            brokenLimit: null,
            limitOrigin: null,
            changedPrices: null,
        },
    },
];

export type SAVED_FILTER_ITEM = {
    id: string;
    name: string;
    label: string;
    value: string;
    filters: Record<
        | 'productIds'
        | 'productFamilyIds'
        | 'storeIds'
        | 'priceType'
        | 'clientId'
        | 'sensibilities'
        | 'categoryLevel1'
        | 'categoryLevel2'
        | 'categoryLevel3'
        | 'categoryLevel4'
        | 'categoryLevel5'
        | 'categoryLevel6'
        | 'categoryLevel7'
        | 'curve'
        | 'curveAbcLocal'
        | 'curveAbcGlobal'
        | 'exclusive'
        | 'client'
        | 'applied'
        | 'limitOrigin'
        | 'productBrand'
        | 'supplier',
        string[]
    > & {
        brokenLimit: boolean | null;
        changedPrices: boolean | null;
        products: Record<'id' | 'description', string>[];
        stores: Record<'id' | 'description', string>[];
    };
};

export type SAVED_FILTERS_PROPS = typeof SAVED_FILTERS_DATA;

export const GERENCIADOR_TABLE_DATA_MOCK = {
    content: [
        {
            id: null,
            productsToBePricedId: '42841967-9014-5f62-9ea8-e5406f29c84e',
            actionId: '17f7c860-4a34-4790-860e-bbed8f4d5b0e',
            clientId: null,
            client: null,
            description: 'DROPS HALLS 28G EXT FORTE',
            productId: '7641',
            gtin: null,
            storeId: '23',
            storeName: '23-LAVRAS',
            productFamilyId: '5556',
            price: 220.0,
            retailPrice: 1.89,
            suggestedPrice: null,
            pmz: 1.03,
            newMargin: 69.09,
            originalMargin: 31.22,
            competitorsPrice: 0.98,
            salesTaxPercentage: 29.58,
            expensesPercent: 1.0,
            cost: 0.72,
            maxCpi: null,
            minCpi: null,
            maxMargin: null,
            minMargin: null,
            maxPriceList: null,
            minPriceList: null,
            objectiveMargin: 18.6,
            priceType: 'EDITADO',
            priceOrigin: null,
            competitivenessIndicator: 'RULE_CPI_AVG',
            categoryLevel1: '201-MERCEARIA SECA',
            categoryLevel2: 'SECA DOCE',
            categoryLevel3: 'GULOSEIMAS',
            categoryLevel4: 'DROPS - PASTILHA',
            categoryLevel5: null,
            categoryLevel6: null,
            categoryLevel7: null,
            productFamily: false,
            author: null,
            dateTime: null,
            ruleId: null,
        },
    ],
    number: 0,
    size: 25,
    totalElements: 555651,
    hasContent: true,
    first: true,
    last: false,
    numberOfElements: 25,
    totalPages: 22227,
    pageable: {
        sort: [],
        offset: 0,
        pageNumber: 0,
        pageSize: 25,
        paged: true,
        unpaged: false,
    },
    empty: false,
};

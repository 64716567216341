import React from 'react';
import Modal, { ModalProps } from 'rsuite/lib/Modal';
import { LoadingSpinerArea } from '../../../../../components/LoadingSpinner';
import { GerenciadorPrecosSavedFilterProps } from '../../../../../reducers/gerenciadorPrecos';
import { NegociacaoFornecedorModalsStateProps } from '../../../../../reducers/negociacaoFornecedor/modals';
import { ButtonPrimary } from '../../../Lite';
import './style.scss';

export type ExcluirFiltroModalProps = Omit<ModalProps, 'onHide'> & {
    onConfirm?: (id: string) => void;
    filterName: string;
    savedFilter?: GerenciadorPrecosSavedFilterProps;
    onHide?: (name: keyof NegociacaoFornecedorModalsStateProps) => void;
};

export const ExcluirFiltroModal = ({
    className = '',
    onConfirm,
    onHide,
    filterName,
    savedFilter,
    ...props
}: ExcluirFiltroModalProps) => (
    <Modal
        className={['info-modal', 'modal-excluir-filtros', className].join(' ')}
        onHide={() => onHide?.('excluirFiltro')}
        {...props}
    >
        <form onSubmit={(e) => e.preventDefault()}>
            <Modal.Header>
                <Modal.Title className="font-size-200-semibold">
                    <h2 className="font-size-400-semibold">
                        Excluir filtro salvo?
                    </h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="font-size-100-regular">
                    Tem certeza que deseja excluir o filtro
                    {' '}
                    <strong>{savedFilter?.filterName ?? filterName}</strong>
                    ?
                    <br />
                    O filtro será excluído permanentemente
                </p>
            </Modal.Body>
            <Modal.Footer>
                <ButtonPrimary
                    skin="blue"
                    theme="ghost"
                    type="button"
                    onClick={() => {
                        onHide?.('excluirFiltro');
                    }}
                >
                    CANCELAR
                </ButtonPrimary>
                <ButtonPrimary
                    skin="blue"
                    theme="filled"
                    onClick={() => onConfirm?.(savedFilter?.data.value ?? '')}
                    type="submit"
                    autoFocus
                >
                    EXCLUIR
                </ButtonPrimary>
            </Modal.Footer>
        </form>
        <LoadingSpinerArea size="sm" area="saved-filter" height={null} />
    </Modal>
);

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExport from 'highcharts/modules/export-data';
import HighchartsExporting from 'highcharts/modules/exporting';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Alert,
    Button,
    Col,
    Content,
    Dropdown,
    FlexboxGrid,
    Icon,
    IconButton,
    SelectPicker,
    Tooltip,
    Whisper,
} from 'rsuite';

import iconEllipsis from '../../../../../assets/icons/icon_ellipsis-v.svg';
import lockIcon from '../../../../../assets/icons/icon_lock.svg';
import iconMaximize from '../../../../../assets/icons/icon_maximize.svg';
import iconMinimize from '../../../../../assets/icons/icon_minimize.svg';
import noDataImage from '../../../../../assets/image/empty-state-2.svg';

import {
    getTimeEvolutionData,
    setPeriodoTimeEvolution,
    setVisualizationTypeTimeEvolution,
} from '../../../../../actions/actionsPainelGeral';
import { LoadingSpinerLocal } from '../../../../../components/LoadingSpinner';
import MobileSelectOptionComponent from '../../../../../components/MobileSelectOptionComponent';
import ModalConfirmation from '../../../../../components/ModalConfirmation';
import { setClickToLocalStorage } from '../../../../../components/NavBar';
import { lead } from '../../../../../services/EventLeadService';
import { HighchartsOptions } from '../../../../../utils/HighchartsConfig';

import { trackEvent } from '../../../../../utils/MatomoConfig';
import EmptyState from './EmptyState';

HighchartsExporting(Highcharts);
HighchartsExport(Highcharts);

Highcharts.setOptions(HighchartsOptions);

const optionsGroupBy = [
    { label: 'Produto', value: 'produto' },
    { label: 'Rede', value: 'rede' },
    { label: 'Consolidado', value: 'semAgrupamento' },
    { label: 'Fabricante', value: 'fabricante' },
    { label: 'Tipo de loja', value: 'tipoLoja' },
];

const periodoOptions = [
    { label: 'Dia', value: 'DATA' },
    { label: 'Semana', value: 'SEMANA' },
    { label: 'Mês', value: 'MES' },
];

const seriesColors = [
    '#76D680',
    '#A371E2',
    '#DFA25B',
    '#F25E5E',
    '#609BCE',
    '#B30089',
    '#FFB46E',
    '#FFD76E',
    '#5FC8FF',
    '#FF73C3',
];

const groupByOptions = [
    { label: 'Produto', key: 'produto' },
    { label: 'Tipo de loja', key: 'tipoLoja' },
    { label: 'Rede', key: 'rede' },
    { label: 'Fabricante', key: 'fabricante' },
    { label: 'Consolidado', key: 'semAgrupamento' },
];

class TimeEvolutionChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            typeVisualization: 'produto',
            periodo: 'SEMANA',
            hasPermition: true,
            showCTA: false,
            expandTimeEvolution: true,
            openDialog: false,
            priceType: 'average',
            visualizationNameType: 'Produto',
            filtroSelecionadoProduto: null,
            filtroSelecionadoRede: null,
        };

        this.exportCSV = this.exportCSV.bind(this);
        this.exportXLS = this.exportXLS.bind(this);
        this.exportPNG = this.exportPNG.bind(this);
        this.print = this.print.bind(this);
        this.viewFullScreen = this.viewFullScreen.bind(this);
        this.sendCTA = this.sendCTA.bind(this);
        this.handleOpenDialog = this.handleOpenDialog.bind(this);
        this.changeVisualization = this.changeVisualization.bind(this);
        this.changePeriodo = this.changePeriodo.bind(this);
        this.mapDataChain = this.mapDataChain.bind(this);
        this.setNoGroup = this.setNoGroup.bind(this);
        this.handleSetPriceType = this.handleSetPriceType.bind(this);
        this.setShowModalConfirm = this.setShowModalConfirm.bind(this);
        this.handleNameOptions = this.handleNameOptions.bind(this);
        this.handleFiltrosSelecionados = this.handleFiltrosSelecionados.bind(this);
        this.handleCleanFilters = this.handleCleanFilters.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { data, loadingTimeEvolution } = this.props;
        const {
            typeVisualization,
            expandTimeEvolution,
            showCTA,
            openDialog,
            priceType,
        } = this.state;

        return (
            nextProps.data !== data
            || nextState.typeVisualization !== typeVisualization
            || nextState.expandTimeEvolution !== expandTimeEvolution
            || nextState.showCTA !== showCTA
            || nextState.openDialog !== openDialog
            || nextState.priceType !== priceType
            || nextState.loadingTimeEvolution !== loadingTimeEvolution
        );
    }

    handleOpenDialog() {
        this.setState({
            openDialog: true,
        });
    }

    handleSetPriceType(type) {
        this.setState({
            priceType: type,
        });
    }

    setNoGroup(data) {
        const { periodo, priceType } = this.state;
        const dataToMap = data?.content ? [...data.content] : [];

        const priceObject = {
            type: 'spline',
            name: 'Preço',
            yAxis: 1,
            zIndex: 1,
            data:
                periodo === 'SEMANA'
                    ? dataToMap.map((item) => ({
                        x: new Date(item.dia_semana).getTime(),
                        y: item[
                            priceType === 'average'
                                ? 'preco_medio'
                                : 'preco_moda'
                        ],
                    }))
                    : periodo === 'DATA'
                        ? dataToMap.map((item) => ({
                            x: new Date(item.data_preco).getTime(),
                            y: item[
                                priceType === 'average'
                                    ? 'preco_medio'
                                    : 'preco_moda'
                            ],
                        }))
                        : dataToMap.map((item) => ({
                            x: new Date(item.dia_mes).getTime(),
                            y: item[
                                priceType === 'average'
                                    ? 'preco_medio'
                                    : 'preco_moda'
                            ],
                        })),
        };

        const series
            = periodo === 'DATA'
                ? [priceObject]
                : [
                    priceObject,
                    this.setStoreColumn(data.totalsResult),
                    this.setChainColumn(data.totalsResult),
                ];
        return series;
    }

    setStoreColumn(data) {
        const dataToMap = data ? [...data] : [];
        return {
            type: 'column',
            name: 'Lojas',
            color: '#D6EEE6',
            yAxis: 0,
            zIndex: 0,
            states: {
                hover: {
                    color: '#33AB84',
                    borderColor: '#33AB84',
                },
            },
            data: this.mapDataStore(dataToMap),
        };
    }

    handleFiltrosSelecionados(item, type, chartOptions) {
        const { onSelectTimeEvolution } = this.props;
        const { filtroSelecionadoProduto, filtroSelecionadoRede } = this.state;
        if (type === 'produto') {
            this.setState({
                filtroSelecionadoProduto: item.identificador,
            }, () => onSelectTimeEvolution(filtroSelecionadoRede, item.identificador, chartOptions));
        }
        if (type === 'rede') {
            this.setState({
                filtroSelecionadoRede: item.value,
            }, () => onSelectTimeEvolution(item.value, filtroSelecionadoProduto, chartOptions));
        }
    }

    handleCleanFilters(type, chartOptions) {
        const { onCleanTimeEvolution } = this.props;
        const { filtroSelecionadoProduto, filtroSelecionadoRede } = this.state;
        if (type === 'produto') {
            this.setState({
                filtroSelecionadoProduto: null,
            }, () => onCleanTimeEvolution(filtroSelecionadoRede, null, chartOptions));
        }
        if (type === 'rede') {
            this.setState({
                filtroSelecionadoRede: null,
            }, () => onCleanTimeEvolution(null, filtroSelecionadoProduto, chartOptions));
        }
    }

    setChainColumn(data) {
        const dataToMap = data ? [...data] : [];
        return {
            type: 'column',
            name: 'Redes',
            color: '#E9DDF7',
            yAxis: 0,
            zIndex: 0,
            states: {
                hover: {
                    color: '#9256D9',
                    borderColor: '#9256D9',
                },
            },
            data: this.mapDataChain(dataToMap),
        };
    }

    setProductColumn(data) {
        const { typeVisualization, periodo, priceType } = this.state;

        if (
            typeVisualization === 'produto'
            && data.content.some((item) => item.descricao)
        ) {
            const products = data?.content ? [...data.content] : [];
            const productsObject = {};

            products.forEach((item, index) => {
                const dateKey
                    = periodo === 'SEMANA'
                        ? 'dia_semana'
                        : periodo === 'DATA'
                            ? 'data_preco'
                            : 'dia_mes';

                if (productsObject[item.gtin]) {
                    productsObject[products[index].gtin].data = [
                        ...productsObject[products[index].gtin].data,
                        {
                            x: new Date(item[dateKey]).getTime(),
                            y: products[index][
                                priceType === 'average'
                                    ? 'preco_medio'
                                    : 'preco_moda'
                            ],
                        },
                    ];
                } else {
                    productsObject[item.gtin] = {
                        type: 'spline',
                        name: item.descricao,
                        data: [],
                        yAxis: 1,
                        zIndex: 1,
                        color: seriesColors[index + 2],
                    };

                    productsObject[item.gtin].data = [
                        ...productsObject[item.gtin].data,
                        {
                            x: new Date(item[dateKey]).getTime(),
                            y: products[index][
                                priceType === 'average'
                                    ? 'preco_medio'
                                    : 'preco_moda'
                            ],
                        },
                    ];
                }
            });

            let series = Object.values(productsObject);
            series.forEach((item) => {
                item.data.sort((a, b) => new Date(a.x) - new Date(b.x));
            });
            series
                = periodo === 'DATA'
                    ? [...series]
                    : [
                        ...series,
                        this.setStoreColumn(data.totalsResult),
                        this.setChainColumn(data.totalsResult),
                    ];

            return series;
        }
        return [];
    }

    setFabricanteColumn(data) {
        const { typeVisualization, periodo, priceType } = this.state;

        if (
            typeVisualization === 'fabricante'
            && data.content.some((item) => item.fabricante)
        ) {
            const fabricantes = data?.content ? [...data.content] : [];
            const fabricantesObject = {};

            fabricantes.forEach((item, index) => {
                const dateKey
                    = periodo === 'SEMANA'
                        ? 'dia_semana'
                        : periodo === 'DATA'
                            ? 'data_preco'
                            : 'dia_mes';

                if (
                    fabricantesObject[item.fabricante]
                    || (!item.fabricante
                        && fabricantesObject['Sem informação de fabricante'])
                ) {
                    if (!item.fabricante) {
                        fabricantesObject['Sem informação de fabricante'].data
                            = [
                                ...fabricantesObject[
                                    'Sem informação de fabricante'
                                ].data,
                                {
                                    x: new Date(item[dateKey]).getTime(),
                                    y: fabricantes[index][
                                        priceType === 'average'
                                            ? 'preco_medio'
                                            : 'preco_moda'
                                    ],
                                },
                            ];
                    } else {
                        fabricantesObject[fabricantes[index].fabricante].data
                            = [
                                ...fabricantesObject[
                                    fabricantes[index].fabricante
                                ].data,
                                {
                                    x: new Date(item[dateKey]).getTime(),
                                    y: fabricantes[index][
                                        priceType === 'average'
                                            ? 'preco_medio'
                                            : 'preco_moda'
                                    ],
                                },
                            ];
                    }
                } else if (item.fabricante) {
                    fabricantesObject[item.fabricante] = {
                        type: 'spline',
                        name: item.fabricante,
                        data: [],
                        yAxis: 1,
                        zIndex: 1,
                        color: seriesColors[index + 2],
                    };

                    fabricantesObject[item.fabricante].data = [
                        ...fabricantesObject[item.fabricante].data,
                        {
                            x: new Date(item[dateKey]).getTime(),
                            y: fabricantes[index][
                                priceType === 'average'
                                    ? 'preco_medio'
                                    : 'preco_moda'
                            ],
                        },
                    ];
                } else {
                    fabricantesObject['Sem informação de fabricante'] = {
                        type: 'spline',
                        name: 'Sem informação de fabricante',
                        data: [],
                        yAxis: 1,
                        zIndex: 1,
                        color: seriesColors[index + 2],
                    };

                    fabricantesObject['Sem informação de fabricante'].data = [
                        ...fabricantesObject['Sem informação de fabricante']
                            .data,
                        {
                            x: new Date(item[dateKey]).getTime(),
                            y: fabricantes[index][
                                priceType === 'average'
                                    ? 'preco_medio'
                                    : 'preco_moda'
                            ],
                        },
                    ];
                }
            });

            let series = Object.values(fabricantesObject);
            series.forEach((item) => {
                item.data.sort((a, b) => new Date(a.x) - new Date(b.x));
            });
            series
                = periodo === 'DATA'
                    ? [...series]
                    : [
                        ...series,
                        this.setStoreColumn(data.totalsResult),
                        this.setChainColumn(data.totalsResult),
                    ];

            return series;
        }
        return [];
    }

    setChainNameColumn(data) {
        const { typeVisualization, periodo, priceType } = this.state;

        if (
            typeVisualization === 'rede'
            && data.content.some((item) => item.rede)
        ) {
            const chains = data?.content ? [...data.content] : [];
            const chainsObject = {};

            chains.forEach((item, index) => {
                const dateKey
                    = periodo === 'SEMANA'
                        ? 'dia_semana'
                        : periodo === 'DATA'
                            ? 'data_preco'
                            : 'dia_mes';

                if (chainsObject[item.rede]) {
                    chainsObject[chains[index].rede].data = [
                        ...chainsObject[chains[index].rede].data,
                        {
                            x: new Date(item[dateKey]).getTime(),
                            y: chains[index][
                                priceType === 'average'
                                    ? 'preco_medio'
                                    : 'preco_moda'
                            ],
                        },
                    ];
                } else {
                    chainsObject[item.rede] = {
                        type: 'spline',
                        name: item.rede,
                        data: [],
                        yAxis: 1,
                        zIndex: 1,
                        color: seriesColors[index + 2],
                    };

                    chainsObject[item.rede].data = [
                        ...chainsObject[item.rede].data,
                        {
                            x: new Date(item[dateKey]).getTime(),
                            y: chains[index][
                                priceType === 'average'
                                    ? 'preco_medio'
                                    : 'preco_moda'
                            ],
                        },
                    ];
                }
            });

            let series = Object.values(chainsObject);
            series.forEach((item) => {
                item.data.sort((a, b) => new Date(a.x) - new Date(b.x));
            });
            series
                = periodo === 'DATA'
                    ? [...series]
                    : [...series, this.setStoreColumn(data.totalsResult)];

            return series;
        }

        return [];
    }

    getNewData() {
        const { onChangeVisualizationTimeEvolution } = this.props;
        const { typeVisualization, periodo } = this.state;

        onChangeVisualizationTimeEvolution(typeVisualization, periodo);
    }

    setStoreTypeColumn(data) {
        const { typeVisualization, periodo, priceType } = this.state;

        if (
            typeVisualization === 'tipoLoja'
            && data.content.some((item) => item.tipo_loja)
        ) {
            const tiposLoja = data?.content ? [...data.content] : [];
            const tiposLojaObject = {};

            tiposLoja.forEach((item, index) => {
                const dateKey
                    = periodo === 'SEMANA'
                        ? 'dia_semana'
                        : periodo === 'DATA'
                            ? 'data_preco'
                            : 'dia_mes';

                if (tiposLojaObject[item.tipo_loja]) {
                    tiposLojaObject[tiposLoja[index].tipo_loja].data = [
                        ...tiposLojaObject[tiposLoja[index].tipo_loja].data,
                        {
                            x: new Date(item[dateKey]).getTime(),
                            y: tiposLoja[index][
                                priceType === 'average'
                                    ? 'preco_medio'
                                    : 'preco_moda'
                            ],
                        },
                    ];
                } else {
                    tiposLojaObject[item.tipo_loja] = {
                        type: 'spline',
                        name: item.tipo_loja,
                        data: [],
                        yAxis: 1,
                        zIndex: 1,
                        color: seriesColors[index + 2],
                    };
                    tiposLojaObject[item.tipo_loja].data = [
                        ...tiposLojaObject[item.tipo_loja].data,
                        {
                            x: new Date(item[dateKey]).getTime(),
                            y: tiposLoja[index][
                                priceType === 'average'
                                    ? 'preco_medio'
                                    : 'preco_moda'
                            ],
                        },
                    ];
                }
            });

            let series = Object.values(tiposLojaObject);
            series.forEach((item) => {
                item.data.sort((a, b) => new Date(a.x) - new Date(b.x));
            });
            series
                = periodo === 'DATA'
                    ? [...series]
                    : [
                        ...series,
                        this.setStoreColumn(data.totalsResult),
                        this.setChainColumn(data.totalsResult),
                    ];

            return series;
        }

        return [];
    }

    mapDataStore(data) {
        const { periodo } = this.state;
        let returnArray = [];

        data.forEach((item) => {
            const dateKey
                = periodo === 'SEMANA'
                    ? 'dia_semana'
                    : periodo === 'DATA'
                        ? 'data_preco'
                        : 'dia_mes';

            returnArray = [
                ...returnArray,
                {
                    x: new Date(item[dateKey]).getTime(),
                    y: item.cnpjs_distintos,
                },
            ];
        });

        return returnArray.sort((a, b) => new Date(a.x) - new Date(b.x));
    }

    mapDataChain(data) {
        const { periodo } = this.state;
        let returnArray = [];

        data.forEach((item) => {
            const dateKey
                = periodo === 'SEMANA'
                    ? 'dia_semana'
                    : periodo === 'DATA'
                        ? 'data_preco'
                        : 'dia_mes';

            returnArray = [
                ...returnArray,
                {
                    x: new Date(item[dateKey]).getTime(),
                    y: item.redes_distintas,
                },
            ];
        });

        return returnArray.sort((a, b) => new Date(a.x) - new Date(b.x));
    }

    mapDataSeries(data, type) {
        switch (type) {
            case 'produto':
                return this.setProductColumn(data);
            case 'rede':
                return this.setChainNameColumn(data);
            case 'tipoLoja':
                return this.setStoreTypeColumn(data);
            case 'fabricante':
                return this.setFabricanteColumn(data);
            case 'semAgrupamento':
                return this.setNoGroup(data);
            default:
                return [];
        }
    }

    handleNameOptions(label) {
        if (label) {
            this.setState({ visualizationNameType: label });
        }
    }

    changeVisualization(type, label) {
        this.handleNameOptions(label);
        const { usuario, setVisualizationTypeTimeEvolution, produto }
            = this.props;
        setVisualizationTypeTimeEvolution(type);

        if (
            (type === 'rede' || type === 'fabricante')
            && (usuario?.servicoPanel?.pacotePainel?.name === 'FREE'
                || usuario?.servicoPanel?.pacotePainel?.name === 'LITE'
                || (usuario?.servicoPanel?.pacotePainel?.name === 'LIMIT'
                    && produto?.cache.some((item) => item.limited === true)))
        ) {
            this.setState({
                typeVisualization: type,
                hasPermition: false,
            });
            if (usuario?.servicoPanel?.pacotePainel?.name === 'FREE') {
                setClickToLocalStorage(
                    'addBlockFree',
                    'clique-adblock',
                    usuario,
                );
            }
        } else {
            this.setState(
                {
                    typeVisualization: type,
                    hasPermition: true,
                },
                this.getNewData,
            );
        }
    }

    changePeriodo(periodo) {
        const { setPeriodoTimeEvolution } = this.props;
        setPeriodoTimeEvolution(periodo);

        this.setState(
            {
                periodo,
            },
            this.getNewData,
        );
    }

    sendCTA() {
        const { usuario } = this.props;
        const identificador = 'upsell_infopanel';

        lead(identificador, usuario).then(() => {
            this.setState({
                showCTA: false,
            });
            Alert.success('Em breve entraremos em contato!');
        });

        trackEvent('Upsell/Crossell', 'upsell-block-temporal');
        window.open(
            'https://wa.me/551137779670?text=Ol%C3%A1%21+Quero+saber+mais+sobre+os+planos+Flex+e+Pro+do+ISA+-+InfoPanel',
        );
    }

    setShowModalConfirm() {
        this.setState({ showCTA: true });
        trackEvent('Upsell/Crossell', 'click-funcao-bloqueada');
    }

    exportCSV() {
        if (this.chartTimeEvolution?.chart) {
            this.chartTimeEvolution.chart.downloadCSV();
        }
    }

    exportXLS() {
        if (this.chartTimeEvolution?.chart) {
            this.chartTimeEvolution.chart.downloadXLS();
        }
    }

    exportPNG() {
        if (this.chartTimeEvolution?.chart) {
            this.chartTimeEvolution.chart.exportChart({ type: 'image/png' });
        }
    }

    print() {
        if (this.chartTimeEvolution?.chart) {
            this.chartTimeEvolution.chart.print();
        }
    }

    viewFullScreen() {
        if (this.chartTimeEvolution?.chart) {
            this.chartTimeEvolution.chart.fullscreen.toggle();
        }
    }

    renderChart() {
        const { typeVisualization, periodo, priceType } = this.state;
        const { data } = this.props;

        return {
            chart: {
                type: 'column',
            },
            title: {
                text: '',
            },

            exporting: {
                enabled: false,
            },
            tooltip: {
                useHTML: true,
                shared: true,
                pointFormatter() {
                    let symbol = '';

                    if (this.graphic && this.graphic.symbolName) {
                        switch (this.graphic.symbolName) {
                            case 'circle':
                                symbol = '●';
                                break;
                            case 'diamond':
                                symbol = '♦';
                                break;
                            case 'square':
                                symbol = '■';
                                break;
                            case 'triangle':
                                symbol = '▲';
                                break;
                            case 'triangle-down':
                                symbol = '▼';
                                break;
                            default:
                                return null;
                        }
                    } else if (this.marker && this.marker.symbol) {
                        const url = this.marker.symbol.replace(
                            /^url\(|\)$/g,
                            '',
                        );
                        symbol = `<img src="${url}" alt="Marker" />`;
                    }

                    return `<span style="color:${this.series.color
                        }">${symbol}</span> ${this.series.name}: <b>${this.series.name !== 'Lojas'
                            && this.series.name !== 'Redes'
                            ? this.y.toFixed(2).replace('.', ',')
                            : this.y
                        }</b><br/>`;
                },
                headerFormat: '<b>{point.key}</b><br/>',
                // footerFormat: 'AQUI VAI O PRODUTO QUANDO INDUSTRIA',
                xDateFormat: periodo === 'MES' ? '%m/%Y' : '%d/%m/%Y',
            },
            legend: {
                symbolWidth: 30,
            },
            yAxis: [
                {
                    // Primary yAxis
                    title: {
                        text:
                            typeVisualization === 'rede'
                                ? 'QUANTIDADE DE LOJAS'
                                : 'QUANTIDADE DE REDES E LOJAS',
                    },
                    breakSize: 1,
                    alignTicks: false,
                    opposite: true,
                },
                {
                    // Secondary yAxis
                    title: {
                        text:
                            priceType === 'average'
                                ? 'PREÇO MÉDIO'
                                : 'PREÇO MAIS FREQUENTE',
                    },
                    labels: {
                        formatter() {
                            let price
                                = this.axis.defaultLabelFormatter.call(this);
                            if (price.includes('.')) {
                                price = price.replace('.', '');
                            }

                            if (price.includes(',')) {
                                price = price.replace(',', '.');
                            }
                            return `R$ ${Number(price)
                                .toFixed(2)
                                .replace('.', ',')}`;
                        },
                    },
                    alignTicks: false,
                },
            ],
            xAxis: {
                type: 'datetime',
                title: {
                    text: 'TEMPO',
                },
                startOfWeek: 0,
                labels: {
                    allowOverlap: true,
                    padding: 0,
                    rotation:
                        periodo === 'SEMANA'
                            ? -45
                            : periodo === 'DATA'
                                ? -45
                                : 0,
                    format:
                        periodo === 'MES' ? '{value: %B}' : '{value: %d/%m/%Y}',
                },
            },
            plotOptions: {
                series: {
                    lineWidth: 1,
                    marker: {
                        radius: 3,
                    },

                    states: {
                        hover: {
                            brightness: -0.3, // darken
                        },
                    },
                },
            },
            series: this.mapDataSeries(data, typeVisualization) || [],
        };
    }

    render() {
        const {
            data,
            usuario,
            loadingTimeEvolution,
            produto,
            rede,
            onCleanTimeEvolution,
        } = this.props;
        console.log(produto)
        const {
            typeVisualization,
            hasPermition,
            showCTA,
            expandTimeEvolution,
            openDialog,
            periodo,
            priceType,
            visualizationNameType,
            filtroSelecionadoProduto,
            filtroSelecionadoRede,
        } = this.state;

        return (
            <Content className="time-evolution-chart">
                <LoadingSpinerLocal loading={loadingTimeEvolution} size="md" />
                <FlexboxGrid className="header-area">
                    <FlexboxGrid.Item
                        componentClass={Col}
                        lg={22}
                        md={22}
                        sm={17}
                        xs={17}
                        className={!expandTimeEvolution ? 'minimized' : null}
                    >
                        <h4>EVOLUÇÃO TEMPORAL</h4>
                        <span className="header-area__info">
                            <Whisper
                                placement={
                                    window.innerWidth > 992 ? 'right' : 'bottom'
                                }
                                trigger="hover"
                                speaker={(
                                    <Tooltip>
                                        <p>
                                            O gráfico de evolução temporal exibe
                                            até 10 séries de preço por
                                            visualização, oferecendo uma melhor
                                            experiência para entendimento das
                                            informações. Caso não selecione um
                                            filtro de rede, serão exibidas as 10
                                            redes com maior quantidade de dados.
                                        </p>
                                    </Tooltip>
                                )}
                            >
                                <Icon className="tooltip-info" icon="info" />
                            </Whisper>
                        </span>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item
                        componentClass={Col}
                        lg={1}
                        md={1}
                        sm={3}
                        xs={3}
                        className={
                            !expandTimeEvolution
                                ? 'expand-section minimized'
                                : 'expand-section'
                        }
                    >
                        <Button
                            id="time-evolution-expand-btn"
                            appearance="subtle"
                            onClick={() => {
                                this.setState({
                                    expandTimeEvolution: !expandTimeEvolution,
                                });
                            }}
                        >
                            {expandTimeEvolution ? (
                                <img alt="Minimizar" src={iconMinimize} />
                            ) : (
                                <img alt="Maximizar" src={iconMaximize} />
                            )}
                        </Button>
                    </FlexboxGrid.Item>
                    {(typeVisualization === 'rede'
                        || typeVisualization === 'fabricante')
                        && (usuario?.servicoPanel?.pacotePainel?.name === 'LITE'
                            || (usuario?.servicoPanel?.pacotePainel?.name
                                === 'LIMIT'
                                && produto?.cache.some(
                                    (item) => item.limited === true,
                                ))) ? null : (
                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={1}
                            md={1}
                            sm={3}
                            xs={3}
                            className={
                                !expandTimeEvolution
                                    ? 'more-options-section minimized'
                                    : 'more-options-section'
                            }
                        >
                            <Dropdown
                                id="time-evolution-more-options"
                                placement="bottomEnd"
                                renderTitle={() => (
                                    <IconButton
                                        className="more-options-section__btn"
                                        appearance="subtle"
                                        icon={(
                                            <img
                                                alt="Mais opções"
                                                src={iconEllipsis}
                                            />
                                        )}
                                    />
                                )}
                            >
                                <Dropdown.Item
                                    onClick={this.viewFullScreen}
                                    id="btn-time-evolution-full-screen"
                                >
                                    Ver em tela cheia
                                </Dropdown.Item>
                                <Dropdown.Item
                                    disabled={
                                        usuario?.servicoPanel?.pacotePainel
                                            ?.name === 'FREE'
                                    }
                                    onClick={this.print}
                                    id="btn-time-evolution-print"
                                >
                                    Imprimir
                                </Dropdown.Item>
                                <Dropdown.Item divider />
                                <Dropdown.Item
                                    disabled={
                                        usuario?.servicoPanel?.pacotePainel
                                            ?.name === 'FREE'
                                    }
                                    onClick={this.exportPNG}
                                    id="btn-time-evolution-download-png"
                                >
                                    Download em PNG
                                </Dropdown.Item>
                                <Dropdown.Item
                                    disabled={
                                        usuario?.servicoPanel?.pacotePainel
                                            ?.name === 'FREE'
                                    }
                                    onClick={this.exportCSV}
                                    id="btn-time-evolution-download-csv"
                                >
                                    Download em CSV
                                </Dropdown.Item>
                                <Dropdown.Item
                                    disabled={
                                        usuario?.servicoPanel?.pacotePainel
                                            ?.name === 'FREE'
                                    }
                                    onClick={this.exportXLS}
                                    id="btn-time-evolution-download-xls"
                                >
                                    Download em XLS
                                </Dropdown.Item>
                            </Dropdown>
                        </FlexboxGrid.Item>
                    )}
                </FlexboxGrid>
                {expandTimeEvolution ? (
                    <>
                        <FlexboxGrid>
                            <FlexboxGrid.Item
                                componentClass={Col}
                                xsHidden
                                smHidden
                                className="select-price-section mg-right-28"
                            >
                                <span className="title-select-product-input">
                                    Exibir Preço:
                                    {' '}
                                </span>
                                <Button
                                    className="select-price-section__button"
                                    appearance={
                                        priceType === 'average'
                                            ? 'primary'
                                            : 'subtle'
                                    }
                                    onClick={() => this.handleSetPriceType('average')}
                                >
                                    Médio
                                </Button>
                                <Button
                                    className="select-price-section__button"
                                    appearance={
                                        priceType === 'frequent'
                                            ? 'primary'
                                            : 'subtle'
                                    }
                                    onClick={() => this.handleSetPriceType('frequent')}
                                >
                                    Mais frequente
                                </Button>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item
                                componentClass={Col}
                                xsHidden
                                smHidden
                                className="select-visualization-section mg-right-28"
                            >
                                <span>Agrupar por: </span>
                                <Dropdown title={visualizationNameType}>
                                    {groupByOptions.map((groupBy) => (
                                        <Dropdown.Item
                                            className="select-visualization-section__button"
                                            onClick={() => this.changeVisualization(
                                                groupBy.key,
                                                groupBy.label,
                                            )}
                                        >
                                            {groupBy.label}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item
                                componentClass={Col}
                                xsHidden
                                smHidden
                                className="select-visualization-section mg-right-28"
                            >
                                <span className="title-select-product-input">
                                    Quebra de período:
                                    {' '}
                                </span>
                                <SelectPicker
                                    appearance="subtle"
                                    onChange={this.changePeriodo}
                                    searchable={false}
                                    data={periodoOptions}
                                    value={periodo}
                                    cleanable={false}
                                />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item
                                componentClass={Col}
                                xsHidden
                                smHidden
                                className="select-visualization-section mg-right-28"
                            >
                                <span className="title-select-product-input">
                                    Produto:
                                    {' '}
                                </span>
                                <SelectPicker
                                    id="filter-produto-time-evolution"
                                    placeholder="Todos os produtos filtrados"
                                    className="select-product-input"
                                    appearance="subtle"
                                    onSelect={(value, item) => this.handleFiltrosSelecionados(item, 'produto', {
                                        typeVisualization,
                                        periodo,
                                    })}
                                    searchable={false}
                                    data={
                                        produto?.value?.length
                                            ? produto?.cache
                                            : []
                                    }
                                    onClean={() => this.handleCleanFilters('produto', {
                                        typeVisualization,
                                        periodo,
                                    })}
                                    cleanable
                                />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item
                                componentClass={Col}
                                xsHidden
                                smHidden
                                className="select-visualization-section mg-right-28"
                            >
                                <span className="title-select-product-input">
                                    Rede:
                                    {' '}
                                </span>
                                <SelectPicker
                                    id="filter-rede-time-evolution"
                                    placeholder="Todos as redes filtradas"
                                    className="select-product-input"
                                    appearance="subtle"
                                    onSelect={(value, item) => this.handleFiltrosSelecionados(item, 'rede', {
                                        filtroSelecionadoRede,
                                        filtroSelecionadoProduto,
                                        typeVisualization,
                                        periodo,
                                    })}
                                    searchable={false}
                                    data={
                                        rede?.value?.length ? rede?.cache : []
                                    }
                                    onClean={() => this.handleCleanFilters('rede', {
                                        typeVisualization,
                                        periodo,
                                    })}
                                    cleanable
                                />
                            </FlexboxGrid.Item>

                            <FlexboxGrid.Item
                                componentClass={Col}
                                xs={24}
                                sm={24}
                                lgHidden
                                mdHidden
                                className="select-visualization-section mg-right-28"
                            >
                                <IconButton
                                    className="select-visualization-section__dropdown"
                                    icon={<Icon icon="angle-down" />}
                                    onClick={this.handleOpenDialog}
                                    placement="right"
                                >
                                    Agrupar por:
                                    {' '}
                                    <span>
                                        {typeVisualization === 'produto'
                                            ? 'Produto'
                                            : typeVisualization === 'tipoLoja'
                                                ? 'Tipo de Loja'
                                                : typeVisualization === 'rede'
                                                    ? 'Rede'
                                                    : typeVisualization === 'fabricante'
                                                        ? 'Fabricante'
                                                        : typeVisualization
                                                            === 'semAgrupamento'
                                                            ? 'Sem agrupamento'
                                                            : null}
                                    </span>
                                </IconButton>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                        {typeVisualization === 'produto'
                            && produto?.value?.length > 10 ? (
                            <FlexboxGrid className="warning-visualization">
                                <FlexboxGrid.Item
                                    componentClass={Col}
                                    lg={24}
                                    md={24}
                                    sm={24}
                                    xs={24}
                                >
                                    <p>
                                        Este gráfico pode mostrar no máximo 10
                                        produtos simultaneamente para melhor
                                        visualização
                                    </p>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        ) : null}
                        {typeVisualization === 'rede'
                            && rede?.value?.length > 10 ? (
                            <FlexboxGrid>
                                <FlexboxGrid.Item
                                    componentClass={Col}
                                    lg={24}
                                    md={24}
                                    sm={24}
                                    xs={24}
                                    className="warning-visualization"
                                >
                                    <p>
                                        Este gráfico pode mostrar no máximo 10
                                        redes simultaneamente para melhor
                                        visualização
                                    </p>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        ) : null}
                        <FlexboxGrid>
                            {hasPermition ? (
                                <FlexboxGrid.Item
                                    componentClass={Col}
                                    lg={24}
                                    md={24}
                                    sm={24}
                                    xs={24}
                                    id="chart-area"
                                    className="chart-area"
                                >
                                    {data?.content?.length ? (
                                        <HighchartsReact
                                            ref={(ref) => {
                                                this.chartTimeEvolution = ref;
                                            }}
                                            highcharts={Highcharts}
                                            options={this.renderChart()}
                                        />
                                    ) : (
                                        <EmptyState
                                            title="Ops! Nenhum resultado encontrado"
                                            message="Sua busca não retornou nenhuma informação. Altere os filtros e tente novamente."
                                            image={noDataImage}
                                        />
                                    )}
                                </FlexboxGrid.Item>
                            ) : (
                                <FlexboxGrid.Item
                                    className="no-access"
                                    componentClass={Col}
                                    lg={24}
                                    md={24}
                                    sm={24}
                                    xs={24}
                                >
                                    <div className="no-access__main">
                                        <div>
                                            <img
                                                alt="Cadeado"
                                                className="no-access__icon"
                                                src={lockIcon}
                                            />
                                        </div>
                                        <div className="no-access__message">
                                            <p>
                                                {usuario?.servicoPanel
                                                    ?.pacotePainel?.name
                                                    === 'FREE' ? (
                                                    <span>
                                                        Este conteúdo pode ser
                                                        acessado somente por
                                                        assinantes.
                                                    </span>
                                                ) : (
                                                    <span>
                                                        Este conteúdo só pode
                                                        ser acessado por
                                                        assinantes do plano
                                                        {' '}
                                                        <b>Flex</b>
                                                        {' '}
                                                        (para
                                                        produtos escolhidos) ou
                                                        {' '}
                                                        <b>Pro</b>
                                                    </span>
                                                )}
                                            </p>
                                        </div>
                                        <div>
                                            <Button
                                                appearance="primary"
                                                onClick={() => this.setShowModalConfirm()}
                                            >
                                                {usuario?.servicoPanel
                                                    ?.pacotePainel?.name
                                                    === 'FREE' ? (
                                                    <span>
                                                        Quero ser assinante
                                                    </span>
                                                ) : (
                                                    <span>
                                                        Entrar em contato
                                                    </span>
                                                )}
                                            </Button>
                                        </div>
                                    </div>
                                </FlexboxGrid.Item>
                            )}
                        </FlexboxGrid>
                    </>
                ) : null}
                <ModalConfirmation
                    title="Informações para alteração do plano"
                    message="Caso deseje saber mais informações para evoluir o seu plano, clique em “Confirmar”"
                    show={showCTA}
                    btnConfirmClass="upsell"
                    buttonConfirm="Confirmar"
                    buttonCancel="Cancelar"
                    onConfirm={this.sendCTA}
                    onCancel={() => {
                        this.setState({
                            showCTA: false,
                        });
                    }}
                />
                <MobileSelectOptionComponent
                    open={openDialog}
                    handleClose={() => this.setState({ openDialog: false })}
                    options={optionsGroupBy}
                    optionSelected={typeVisualization}
                    handleSelect={this.changeVisualization}
                />
            </Content>
        );
    }
}

const mapStateToProps = (store) => ({
    data: store.painelGeralDataReducer.timeEvolution.data,
    rede: store.painelGeralDataReducer.rede,
    loadingTimeEvolution: store.painelGeralDataReducer.loadingTimeEvolution,
    produto: store.painelGeralDataReducer.produto,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        getTimeEvolutionData,
        setVisualizationTypeTimeEvolution,
        setPeriodoTimeEvolution,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(TimeEvolutionChart);

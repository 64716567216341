import classNames from 'classnames';
import React from 'react';
import CurrencyInput from 'react-currency-input';
import { CurrencyInputProps } from '../../@types/CurrencyInput';
import styles from './InputCurrency.module.scss';

export type InputCurrencyProps = CurrencyInputProps & {
    skin?: 'gray' | 'blue' | 'red';
};

const InputCurrency = ({
    className,
    skin = 'gray',
    ...props
}: InputCurrencyProps) => {
    return (
        <CurrencyInput
            className={classNames(styles.input, className)}
            data-skin={skin}
            {...props}
        />
    );
};

export default InputCurrency;

import classNames from 'classnames';
import React, {
    HTMLAttributes,
    HtmlHTMLAttributes,
    createContext,
    useContext,
    useMemo,
} from 'react';
import { Tooltip, Whisper } from 'rsuite';
import { ButtonPrimary, ButtonProps } from '../../pages/IPA/Lite';
import { SecondaryButton, SecondaryButtonProps } from '../SecondaryButton';
import styles from './TableHeading.module.scss';
import * as T from './TableHeading.types';

const TableHeadingContext = createContext<{ isSelected: boolean }>({
    isSelected: false,
});

const TableHeading = ({
    className,
    isSelected = false,
    ...props
}: T.TableHeadingProps) => {
    const value = useMemo(() => ({ isSelected }), [isSelected]);
    return (
        <TableHeadingContext.Provider value={value}>
            <div
                className={classNames(
                    styles['table-heading'],
                    isSelected && styles['table-heading-is-selected'],
                    className,
                )}
                {...props}
            />
        </TableHeadingContext.Provider>
    );
};

const Total = ({
    className,
    ...props
}: HtmlHTMLAttributes<HTMLSpanElement> & { total?: number }) => (
    <span
        className={classNames(styles['table-heading__total'], className)}
        {...props}
    />
);

const Container = ({
    className,
    ...props
}: HtmlHTMLAttributes<HTMLSpanElement>) => (
    <span
        className={classNames(styles['table-heading__container'], className)}
        {...props}
    />
);

const PrimaryButton = ({ className, ...props }: ButtonProps) => (
    <ButtonPrimary
        className={classNames(
            styles['table-heading__primary-button'],
            className,
        )}
        {...props}
    />
);

type Secondary = SecondaryButtonProps & {
    placeholder?: string;
};

const Secondary = ({ placeholder, ...props }: SecondaryButtonProps) => {
    if (!placeholder) return <SecondaryButton {...props} />;
    return (
        <Whisper
            delayShow={0.2}
            trigger="hover"
            placement="bottom"
            speaker={<Tooltip>{placeholder}</Tooltip>}
        >
            <SecondaryButton {...props} />
        </Whisper>
    );
};

type TotalProductsProps = HTMLAttributes<HTMLParagraphElement> & {
    total?: number;
    handleSelectAll?: () => void;
};

const TotalProducts = ({
    total = 0,
    className,
    ...props
}: TotalProductsProps) => {
    const { isSelected } = useContext(TableHeadingContext);

    if (total === 0) {
        return (
            <p
                className={classNames(
                    styles['table-heading__total-products-text'],
                    className,
                )}
                {...props}
            >
                Nenhum resultado encontrado
            </p>
        );
    }

    return (
        <p
            className={classNames(
                styles['table-heading__total-products-text'],
                className,
            )}
            {...props}
        >
            {isSelected ? (
                <>
                    <strong>
                        {total} resultado
                        {total > 1 ? 's ' : ' '}
                    </strong>
                    selecionado
                    {total > 1 ? 's ' : ' '}
                </>
            ) : (
                <>
                    Foram encontrados{' '}
                    <strong>
                        {total} resultado
                        {total > 1 ? 's ' : ' '}
                    </strong>
                    no total
                </>
            )}
        </p>
    );
};

TableHeading.Total = Total;
TableHeading.Container = Container;
TableHeading.PrimaryButton = PrimaryButton;
TableHeading.SecondaryButton = Secondary;
TableHeading.TotalProducts = TotalProducts;

export { TableHeading };

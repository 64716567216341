import { useState } from 'react';
import { getCompositionRule } from '../../../RegrasPreco/Cadastro/services';
import { RuleCompositionProps } from './CompositionRuleModal';

export const useCompositionRuleModal = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [data, setData] = useState<RuleCompositionProps | null>(null);

    function handleOpen(ruleId: string, productToBePricedId: string) {
        getCompositionRule(ruleId, productToBePricedId)
            .then((response) => {
                if (response) {
                    setData(response as RuleCompositionProps);
                }
            })
            .finally(() => setIsOpen(true));
    }

    function handleClose() {
        setIsOpen(false);
    }

    return {
        data,
        isOpen,
        setIsOpen,
        handleOpen,
        handleClose,
    };
};

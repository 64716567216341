import { FilterKeysProps, FiltersStateProps } from '../@types/FiltersTypes';
import { SortStateProps } from '../pages/IPA/Negociacoes';
import {
    camelToSnakeCase,
    getSortedData,
    getValidData,
} from '../pages/IPA/RevisaoPrecos/utils';

export type GetFilterStateModelProps = {
    filtersState?: FiltersStateProps;
    sort?: SortStateProps;
    pageSize?: number;
    pageNumber?: number;
};

export type FiltrosModelProps = {
    sort: string;
    size: number;
    page: number;
} & Partial<Record<FilterKeysProps, string[]>>;

type SortMode = 'snake' | 'camel';

export const getFiltrosModel = (
    {
        filtersState,
        sort,
        pageSize = 20,
        pageNumber = 0,
    }: GetFilterStateModelProps,
    sortMode: SortMode = 'snake',
): FiltrosModelProps => {
    const CURRENT_SORT = {
        snake: camelToSnakeCase(getSortedData({ sort }) ?? ''),
        camel: getSortedData({ sort }) ?? '',
    } as const;

    const data = {
        productIds: filtersState?.productIds?.value,
        storeIds: filtersState?.storeIds?.value,
        productFamilyIds: filtersState?.productFamilyIds?.value,
        priceType: filtersState?.priceType?.value,
        productBrand: filtersState?.productBrand?.value,
        supplier: filtersState?.supplier?.value,
        categoryLevel1: filtersState?.categoryLevel1?.value,
        categoryLevel2: filtersState?.categoryLevel2?.value,
        categoryLevel3: filtersState?.categoryLevel3?.value,
        categoryLevel4: filtersState?.categoryLevel4?.value,
        categoryLevel5: filtersState?.categoryLevel5?.value,
        categoryLevel6: filtersState?.categoryLevel6?.value,
        categoryLevel7: filtersState?.categoryLevel7?.value,
        sort: CURRENT_SORT[sortMode],
        size: pageSize,
        page: pageNumber <= 0 ? 0 : pageNumber - 1,
    } as const;

    return getValidData<typeof data>(data);
};

export const checkIfFiltersIsEmpty = (
    filterState: FiltersStateProps,
): boolean => {
    let isEmpty = true;
    Object.keys(filterState).forEach((key) => {
        const _key = key as keyof FiltersStateProps;
        if (filterState[_key]?.value.length) {
            isEmpty = false;
        }
    });
    return isEmpty;
};

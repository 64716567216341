import { AxiosResponse } from 'axios';
import qs from 'qs';
import { trackPromise } from 'react-promise-tracker';
import { IGerenciadorPrecosDatapoint as RowData } from '../../../@types';
import { api as newApi } from '../../../lib';
import api from '../../../utils/API';
import { GET_ALL_PRODUCTS } from './utils';
import { getValidData } from './utils/getValidData';

export const salvarSimulacao = (data) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/simulation/forecast/save`,
            method: 'POST',
            expectToken: true,
            data: JSON.stringify(data),
        }).then((resp) => resp),
    );

export const novoCalculoPrevisao = (
    currentPrice,
    newPrice,
    storeId,
    productId,
    pmz,
) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/simulation/forecast`,
            method: 'GET',
            expectToken: true,
            params: {
                currentPrice,
                newPrice,
                storeId,
                productId,
                pmz,
            },
        }).then((resp) => resp),
    );

export const savePrecificacaoBulk = (data, applyToFamily) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/all-products/apply`,
            method: 'PATCH',
            expectToken: true,
            data: JSON.stringify({ ...data, applyToFamily }),
        }).then((resp) => resp),
        'get-datapoints-revisao',
    );

export const getPriceComposition = ({ productId, storeId }) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/all-products/price/composition`,
            method: 'GET',
            expectToken: true,
            params: getValidData({
                productId,
                storeId,
            }),
            paramsSerializer(params) {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        }).then((response) => response.data),
        'price-composition',
    );

export const getCompetitorsInfo = ({
    productId = '',
    storeId = 1,
    loadingArea = '',
    productFamilyId = null,
    competitorStoreId = null,
}) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/competitor/prices/${productId}`,
            method: 'GET',
            expectToken: true,
            params: getValidData({
                storeId,
                productFamilyId,
                competitorStoreId,
            }),
            paramsSerializer(params) {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        }).then((resp) => resp.data),
        loadingArea,
    );

export const getFamiliaProdutos = (productFamilyId) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/products/families/id`,
            method: 'GET',
            expectToken: true,
            params: {
                size: 20,
                ...(productFamilyId && {
                    productFamilyId,
                }),
            },
        }).then((resp) => resp?.data),
    );

export const getLojasRevisao = (search) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/products/store`,
            method: 'GET',
            expectToken: true,
            params: {
                size: 20,
                ...(search && {
                    search,
                }),
            },
        }).then((resp) => resp?.data),
    );

type GetDatapointsRevisaoResponse = {
    content: RowData[];
};

export const getDatapointsRevisao = async (
    search: string,
    params: Record<string, unknown>,
): Promise<GetDatapointsRevisaoResponse> => {
    const { url, options } = GET_ALL_PRODUCTS(search, params);

    const { data } = await trackPromise(
        newApi.get<GetDatapointsRevisaoResponse>(url, options),
        'get-datapoints-revisao',
    );

    return data;
};

export type SaveEditedDatapoint = {
    data: Pick<
        RowData,
        'productId' | 'storeId' | 'price' | 'productsToBePricedId'
    >;
    response: AxiosResponse<RowData[]>;
};

export function saveEditedDatapoint({
    price,
    ...data
}: SaveEditedDatapoint['data']): Promise<SaveEditedDatapoint['response']> {
    return trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/all-products/update/price`,
            method: 'POST',
            expectToken: true,
            params: getValidData({ editedPrice: price, ...data }),
            paramsSerializer(params) {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        }).then((resp) => resp),
        `edit-datapoint-${data.productId}-${data.storeId}`,
    );
}

export function undoEditedDatapoint(params) {
    return trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/all-products/undo-price-change`,
            method: 'DELETE',
            expectToken: true,
            params,
            paramsSerializer(params) {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        }).then((resp) => resp),
        `edit-datapoint-${params.productId}-${params.storeId}`,
    );
}

export function getTotalPrices(params) {
    return trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/all-products/total-prices`,
            method: 'GET',
            expectToken: true,
            params,
            paramsSerializer(params) {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        }).then((resp) => resp),
        'total-price',
    );
}

export function getVariationPrices(params) {
    return trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/all-products/variation-price`,
            method: 'GET',
            expectToken: true,
            params,
            paramsSerializer(params) {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        }).then((resp) => resp),
        'variation-price',
    );
}

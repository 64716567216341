import QueryString from 'qs';
import { NewAPI } from '../../../../@types';
import { getValidData } from './getValidData';

export type ALL_PRODUCTS_PARAMS = Record<string, unknown>;

export function GET_ALL_PRODUCTS(
    search: string,
    params: ALL_PRODUCTS_PARAMS,
): NewAPI {
    return {
        url: '/all-products',
        options: {
            expectToken: true,
            params: getValidData({
                search,
                field: params.field,
                ...params,
            }),
            paramsSerializer(params: ALL_PRODUCTS_PARAMS) {
                return QueryString.stringify(params, { arrayFormat: 'repeat' });
            },
        },
    };
}

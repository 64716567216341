import classNames from 'classnames';
import React from 'react';
import styles from './InputGroup.module.scss';

export type InputGroupProps = React.HtmlHTMLAttributes<HTMLDivElement>;

export const InputGroupContext = React.createContext<{
    skin: string;
}>({ skin: 'gray' });

const InputGroup = ({ className, ...props }: InputGroupProps) => {
    const ref = React.useRef<HTMLDivElement>(null);

    const [skin, setSkin] = React.useState<string>('gray');

    React.useEffect(() => {
        if (!ref.current) return;
        const input = ref.current.querySelector('input[data-skin]');
        if (!input) return;
        const skin = input.attributes['data-skin' as any].value || 'gray';
        setSkin(skin);
    }, [ref]);

    const value = React.useMemo(() => ({ skin }), [skin]);

    return (
        <InputGroupContext.Provider value={value}>
            <div
                ref={ref}
                className={classNames(styles['input-group'], className)}
                data-skin={skin}
                {...props}
            />
        </InputGroupContext.Provider>
    );
};

export default InputGroup;

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type OnDemandOptions =
    | 'singleRequestData'
    | 'canEditSave'
    | 'filters'
    | 'barCode'
    | 'creator'
    | 'uf'
    | 'cities'
    | 'date'
    | 'active'
    | 'keyword'
    | 'requestId'
    | 'tableRequestBody'
    | 'downloadType'
    | 'checkedItems'
    | 'fakeFilterArray'
    | 'hasPendingRequest'

export type FilterItemDataProps = Array<
    Record<'label' | 'value', string> & Record<string, unknown>
>;

export type SingleRequestContentType = {
    cep?: string,
    cidade?: string,
    criador?: string,
    dataCriacao?: string,
    fullAddress?: string,
    gtin?: string,
    idSolicitacao?: string,
    latitude?: string,
    logradouro?: string,
    longitude?: string,
    numeroLogradouro?: string,
    palavraChave?: string,
    precosUltimaSemana?: string,
    raio?: number,
    status?: boolean,
    totalPrecosRetornados?: string,
    uf?: string,
}

type OnDemandFiltersType = {
    id?: string[],
    gtin?: string[],
    palavraChave?: string[],
    dataCriacao?: string[],
    criador?: string[],
    uf?: string[],
    cidade?: string[],
    raio?: string[],
    status?: boolean[],
    idsToExclude?: string[],
    fileExtension?: string,
}

type SelectListType = {
    data: any,
    value?: any,
    cache?: any
}

export type OnDemandDataProps = {
    singleRequestData: SingleRequestContentType,
    canEditSave: boolean,
    filters: OnDemandFiltersType,
    barCode: SelectListType,
    creator: SelectListType,
    uf: SelectListType,
    cities: SelectListType,
    radius: SelectListType,
    date: SelectListType,
    active: SelectListType,
    keyword: SelectListType,
    requestId: SelectListType,
    tableRequestBody: any,
    downloadType: string,
    checkedItems: string[],
    fakeFilterArray: OnDemandFiltersType,
    hasPendingRequest: boolean
};

const initialState: OnDemandDataProps = {
    singleRequestData: {
        cep: '',
        cidade: '',
        criador: '',
        dataCriacao: '',
        fullAddress: '',
        gtin: '',
        idSolicitacao: '',
        latitude: '',
        logradouro: '',
        longitude: '',
        numeroLogradouro: '',
        palavraChave: '',
        precosUltimaSemana: '',
        raio: 1,
        status: false,
        totalPrecosRetornados: '',
        uf: '',
    },
    canEditSave: false,
    filters: {
        id: [],
        gtin: [],
        palavraChave: [],
        dataCriacao: [],
        criador: [],
        uf: [],
        cidade: [],
        raio: [],
        status: [],
        idsToExclude: [],
        fileExtension: '',
    },
    barCode: {
        value: [],
        data: [],
        cache: [],
    },
    creator: {
        value: [],
        data: [],
        cache: [],
    },
    uf: {
        value: [],
        data: [],
        cache: [],
    },
    cities: {
        value: [],
        data: [],
        cache: [],
    },
    radius: {
        value: [],
        data: [],
        cache: [],
    },
    date: {
        value: [],
        data: [],
        cache: [],
    },
    status: {
        value: null,
        cache: [],
    },
    keyword: {
        value: [],
        data: [],
        cache: [],
    },
    requestId: {
        value: [],
        data: [],
        cache: [],
    },
    tableRequestBody: {
        id: null,
        gtin: null,
        palavraChave: null,
        dataCriacao: null,
        criador: null,
        uf: null,
        cidade: null,
        raio: null,
        status: null,
        page: 0,
        size: 20,
        selectdAll: false,
        order: null,
    },
    downloadType: 'list',
    checkedItems: [],
    fakeFilterArray: {
        barCode: [],
        creator: [],
        uf: [],
        cities: [],
        radius: [],
        date: [],
        status: [],
        keyword: [],
        requestId: [],
    },
    hasPendingRequest: true,
};

const slice = createSlice({
    name: 'onDemand/data',
    initialState,
    reducers: {
        setSingleRequestData: (
            state,
            action: PayloadAction<{
                name: OnDemandOptions;
                data: SingleRequestContentType;
            }>,
        ) => {
            state.singleRequestData = action.payload.data;
        },
        resetSingleRequestData: (
            state,
        ) => {
            state.singleRequestData = initialState.singleRequestData;
        },
        setCanEditSave: (
            state,
            action: PayloadAction<{
                name: OnDemandOptions;
                data: boolean;
            }>,
        ) => {
            state.canEditSave = action.payload.data;
        },
        setOnDemandState: (
            state,
            action: PayloadAction<{
                name: OnDemandOptions;
                data: any;
            }>,
        ) => {
            state[action.payload.name] = action.payload.data;
        },
        setOnDemandCleanFilter: (
            state,
            action: PayloadAction<{
                name: OnDemandOptions;
                tableRequestBodyName: any;
                data: any;
            }>,
        ) => {
            state[action.payload.name] = action.payload.data;
            state.tableRequestBody = {
                ...state.tableRequestBody,
                [action.payload.tableRequestBodyName]: null,
            };
            state.fakeFilterArray = {
                ...state.fakeFilterArray,
                [action.payload.name]: [],
            };
        },
        resetOnDemandFilters() {
            return initialState;
        },
    },
});

export const reducerOnDemandSelector = (
    state: Record<'onDemandReducer', OnDemandDataProps>,
) => state.onDemandReducer;

const {
 setSingleRequestData, resetSingleRequestData, setCanEditSave,
 setOnDemandState, resetOnDemandFilters, setOnDemandCleanFilter,
} = slice.actions;

export {
 resetSingleRequestData, setCanEditSave, setSingleRequestData,
 setOnDemandState, resetOnDemandFilters, setOnDemandCleanFilter,
};

export default slice.reducer;

import classNames from 'classnames';
import React from 'react';
import styles from './VariationBoxIndicator.module.scss';

export interface IVariationBoxIndicator {
    value: number;
    positiveValueIsGood: boolean;
    suffix?: string;
    className?: string;
}

export const VariationBoxIndicator = ({
    value,
    className,
    suffix = '',
    positiveValueIsGood,
}: IVariationBoxIndicator) => {
    let boxClass;

    const isValuePositive = value > 0;

    if (positiveValueIsGood) {
        boxClass = isValuePositive ? 'box-positive' : 'box-negative';
    } else {
        boxClass = isValuePositive ? 'box-negative' : 'box-positive';
    }

    const prefix = isValuePositive ? '+ ' : '- ';

    const absoluteValue = Math.abs(value);

    const parsedValue = absoluteValue.toFixed(2).toString().replace('.', ',');

    if (value === 0 || value.toFixed(1) === '0.0') {
        return (
            <div className={classNames(styles.box, className)}>SEM VAR.</div>
        );
    }

    return (
        <div className={classNames(styles.box, styles[boxClass], className)}>
            {`${prefix} ${parsedValue}${suffix}`}
        </div>
    );
};

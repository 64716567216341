import React from 'react';
import {
    ButtonGroup, Dropdown,
    Tooltip,
    Whisper,
} from 'rsuite';
import { ButtonPrimary, ButtonProps } from '../../../Lite';
import { IconButton } from '../QuickActionFilter';
import styles from './ButtonDropdown.module.scss';
import './style.scss';

export type ButtonDropdownProps = ButtonProps & {
    onSelectOption: (itemId: string) => void,
    options: {
        id: string | number;
        value: React.ReactNode | unknown;
        label: string;
        placeholder: string;
        disabled: boolean;
    }[];
};

const ButtonDropdown = ({
    onClick,
    isLoading,
    children,
    disabled,
    skin = 'blue',
    options,
    onSelectOption,
}: ButtonDropdownProps) => {
    return (
        <ButtonGroup
            className={styles.btn_group}
            disabled={isLoading ? true : disabled}
        >
            <ButtonPrimary
                onClick={onClick}
                skin={skin}
                disabled={isLoading ? true : disabled}
                className={styles.btn_primary}
            >
                {children}
            </ButtonPrimary>
            <Dropdown
                activeKey="1"
                eventKey="1"
                disabled={disabled}
                onSelect={onSelectOption}
                placement="bottomEnd"
                className={disabled ? [styles.dropdown_disabled, styles.dropdown].join(' ') : styles.dropdown}
                renderTitle={() => (
                    <IconButton
                        className={styles.icon_button}
                        icon="MdKeyboardArrowDown"
                        skin={skin}
                    />
                )}
            >
                {options.map(({
                    label = '', value = '', id = '', disabled, placeholder,
                    }) => {
                        const labelWrapped = (
                            <div>
                                {label}
                            </div>
                        );

                        return (
                            <Dropdown.Item key={id} eventKey={value} disabled={disabled}>
                                {disabled ? (
                                    <Whisper
                                        delayShow={0.2}
                                        trigger="hover"
                                        placement="bottom"
                                        speaker={<Tooltip>{placeholder}</Tooltip>}
                                    >
                                        {labelWrapped}
                                    </Whisper>
                                ) : labelWrapped}
                            </Dropdown.Item>
                        );
                })}
            </Dropdown>
        </ButtonGroup>
    );
};

export { ButtonDropdown };

import { useState } from 'react';

type FiltersProps = Record<
    | 'productIds'
    | 'productFamilyIds'
    | 'storeIds'
    | 'status'
    | 'exclusive'
    | 'sensibilities'
    | 'curve'
    | 'curveAbcLocal'
    | 'curveAbcGlobal'
    | 'categoryLevel1'
    | 'categoryLevel2'
    | 'categoryLevel3'
    | 'categoryLevel4'
    | 'categoryLevel5'
    | 'categoryLevel6'
    | 'categoryLevel7',
    string[] | number[]
> &
    Partial<Record<'maxMargin' | 'minMargin' | 'maxCpi' | 'minCpi', number>>;

type Contract = {
    selectedAllProducts: boolean | null;
    productsSelect?: {
        productId: string;
        storeId: string;
        price: number;
        recurrence?: Record<
            'startingDate' | 'recurrenceType' | 'expirationDate',
            string
        >;
    }[];
    productsUnSelect?: Record<'productId' | 'storeId', string>[];
    filters?: FiltersProps;
};

type ContentProps = {
    productsToBePricedId: string;
    productId: string;
    storeId: string;
    price: number;
    novoCalculo?: boolean;
};

type State = {
    selectedAllProducts: boolean;
    productsSelect: {
        cache: Partial<ContentProps>[];
        value: string[];
    };
    productsUnSelect: {
        cache: Partial<ContentProps>[];
        value: string[];
    };
};

type UpdateKeys = 'productsSelect' | 'productsUnSelect';

const initialState: State = {
    selectedAllProducts: false,
    productsSelect: {
        cache: [],
        value: [],
    },
    productsUnSelect: {
        cache: [],
        value: [],
    },
};

export const useSelectedDatapoints = () => {
    const [data, setData] = useState<State>(initialState);

    function toggleSelectedAllProductsOn() {
        setData((oldValue) => ({
            ...oldValue,
            selectedAllProducts: true,
        }));
    }

    function findDatapoint(
        datapoint: string,
        listToCheck: UpdateKeys = 'productsSelect',
    ): Partial<ContentProps> | undefined {
        return data[listToCheck].cache.find(
            ({ productsToBePricedId }) => productsToBePricedId === datapoint,
        );
    }

    function handleAddDatapoint(
        { productId, storeId, price, productsToBePricedId }: ContentProps,
        updateKey: UpdateKeys = 'productsSelect',
    ) {
        setData((oldValue) => {
            const valueSet = new Set(oldValue[updateKey].value);
            if (!valueSet.has(productsToBePricedId)) {
                return {
                    ...oldValue,
                    [updateKey]: {
                        cache: [
                            ...oldValue[updateKey].cache,
                            {
                                productId,
                                storeId,
                                price,
                                productsToBePricedId,
                            },
                        ],
                        value: [
                            ...oldValue[updateKey].value,
                            productsToBePricedId,
                        ],
                    },
                };
            }
            return oldValue;
        });
    }

    function handleRemoveProduct(
        productsToBePricedId: string,
        updateKey: UpdateKeys = 'productsSelect',
    ) {
        setData((oldValue) => ({
            ...oldValue,
            [updateKey]: {
                ...oldValue[updateKey],
                cache: oldValue[updateKey].cache.filter(
                    (product) =>
                        product.productsToBePricedId !== productsToBePricedId,
                ),
                value: oldValue[updateKey].value.filter(
                    (product) => product !== productsToBePricedId,
                ),
            },
        }));
    }

    function handleUpdateSelectedDatapointValue(
        datapoint: string,
        { productId, storeId, price, productsToBePricedId }: ContentProps,
        updateKey: UpdateKeys = 'productsSelect',
    ) {
        if (!findDatapoint(datapoint, updateKey)) return;
        const dataContent = data[updateKey].cache;
        const index = dataContent.findIndex(
            ({ productsToBePricedId }) => productsToBePricedId === datapoint,
        );
        dataContent[index] = {
            productId,
            storeId,
            price,
            productsToBePricedId,
        };

        setData((oldValue) => ({
            ...oldValue,
            dataContent,
        }));
    }

    function handleCheck(value = '', checked = false, rowData: ContentProps) {
        const { productId, storeId, price, productsToBePricedId } = rowData;
        if (data.selectedAllProducts) {
            if (!checked) {
                if (findDatapoint(value)) handleRemoveProduct(value);
                handleAddDatapoint(
                    {
                        productId,
                        storeId,
                        price,
                        productsToBePricedId: value ?? productsToBePricedId,
                    },
                    'productsUnSelect',
                );
            } else {
                handleRemoveProduct(value, 'productsUnSelect');
            }
        } else if (checked) {
            handleAddDatapoint({
                productId,
                storeId,
                price,
                productsToBePricedId: value ?? productsToBePricedId,
            });
        } else {
            handleRemoveProduct(value);
        }
    }

    function handleUnselectAll() {
        setData((oldValue) => ({
            ...oldValue,
            selectedAllProducts: false,
            productsSelect: {
                cache: [],
                value: [],
            },
            productsUnSelect: {
                cache: [],
                value: [],
            },
        }));
    }

    function handleSelectAllProducts(content: ContentProps[] = []) {
        const value = content.map(
            ({ productsToBePricedId }) => productsToBePricedId,
        );
        const cache = content.map(
            ({ productId, storeId, price, productsToBePricedId }) => ({
                productId,
                storeId,
                price,
                productsToBePricedId,
            }),
        );
        setData((oldValue) => ({
            ...oldValue,
            productsSelect: {
                cache,
                value,
            },
        }));
    }

    function handleCheckAll({
        checked,
        content,
    }: {
        checked: boolean;
        content: ContentProps[];
    }) {
        if (checked) {
            handleSelectAllProducts(content);
        } else {
            handleUnselectAll();
        }
    }

    function handleSelectAllFromAllPages(content: ContentProps[]) {
        content.forEach(
            ({
                novoCalculo,
                productId,
                storeId,
                price,
                productsToBePricedId,
            }) => {
                // verifica se existe preços que foram alterados
                if (novoCalculo) {
                    // caso existam preços alterados
                    // adiciona o datapoint para a lista selectedProducts
                    handleAddDatapoint({
                        productId,
                        storeId,
                        price,
                        productsToBePricedId,
                    });
                }
            },
        );
        toggleSelectedAllProductsOn();
    }

    function isToggleChecked(rowData: { productsToBePricedId: string }) {
        if (!rowData) return false;

        const selectedProductsIds = data.productsSelect.value;
        const unselectedProductsIds = data.productsUnSelect.value;

        const isChecked = selectedProductsIds.includes(
            rowData.productsToBePricedId,
        );

        if (data.selectedAllProducts) {
            return !unselectedProductsIds.includes(
                rowData.productsToBePricedId,
            );
        }

        return isChecked;
    }

    const isSelectedDatapointsEmpty = (() => {
        let isSelectedKeysEmpty = true;
        if (
            data.selectedAllProducts ||
            data.productsSelect.value.length > 0 ||
            data.productsUnSelect.value.length > 0
        ) {
            isSelectedKeysEmpty = false;
        }
        return isSelectedKeysEmpty;
    })();

    function getSelectedDatapointModel(filters?: FiltersProps): Contract {
        const productsUnSelect = data.productsUnSelect.cache.map(
            ({ productId = '', storeId = '' }) => ({
                productId,
                storeId,
            }),
        );
        const productsSelect = data.productsSelect.cache.map(
            ({ productId = '', storeId = '', price = 0 }) => ({
                productId,
                storeId,
                price,
            }),
        );
        if (data.selectedAllProducts) {
            return {
                selectedAllProducts: true,
                productsUnSelect,
                filters,
                productsSelect,
            };
        }
        return {
            selectedAllProducts: false,
            productsSelect,
        };
    }

    function resetSelectedDatapointsState() {
        setData(initialState);
    }

    return {
        data,
        handleCheck,
        handleCheckAll,
        handleSelectAllFromAllPages,
        handleUnselectAll,
        getSelectedDatapointModel,
        isToggleChecked,
        handleUpdateSelectedDatapointValue,
        isSelectedDatapointsEmpty,
        handleAddDatapoint,
        findDatapoint,
        resetSelectedDatapointsState,
    };
};

import classNames from 'classnames';
import React from 'react';
import { InputGroupContext } from '../InputGroup/InputGroup';
import styles from './InputAddon.module.scss';

type InputAddonProps = React.HtmlHTMLAttributes<HTMLSpanElement> & {
    skin?: 'gray' | 'blue' | 'red';
};

const InputAddon = ({ className, skin, ...props }: InputAddonProps) => {
    const { skin: inputSkin } = React.useContext(InputGroupContext);
    return (
        <span
            className={classNames(styles.addon, className)}
            data-skin={skin ?? inputSkin}
            {...props}
        />
    );
};

export default InputAddon;

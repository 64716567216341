import { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
    getAddress,
    getFiltersList,
    selectFilterValue,
    setFilterObject,
    setLojasConcorrentesFilterValue,
    setSingleReducer,
} from '../../../../../../actions/actionsLojaConcorrentes';
import { getPlace, getStoresByFilter } from '../../services';
import { getModel, getModelFilters } from '../../utils';

type SelectItem = {
    label: string,
    value: string,
    datapoints?: number,
    place_id?: string,
    token?: string,
}

type FiltersSelected = {
    rede: string[] | null,
    tipo_loja: string[] | null,
    uf: string[] | null,
    cep: string[] | null,
    latitude: string[] | null,
    longitude: string[] | null,
    cidade: string[] | null,
    endereco: string[] | null,
};

export const useMapFilterChange = () => {
    const [timer, setTimer] = useState(null);
    const [disabledRegion, setDisabledRegion] = useState(false);

    const { filtrosData, filtrosValue } = useSelector(
        (state) => ({
            filtrosData: state.lojasConcorrentesDataReducer.filtrosData,
            filtrosValue: state.lojasConcorrentesDataReducer.filtrosValue,
        }),
        shallowEqual,
    );

    const dispatch = useDispatch();

    const searchStoreByFilter = (filtersSelected: FiltersSelected) => {
        const filters = getModelFilters(filtersSelected);
        getStoresByFilter(filters).then((response) => {
            dispatch(setSingleReducer('stores', response.content));
            dispatch(setSingleReducer('recenter', true));
        });
    };

    const handleAddFilterValue = (name: string, value: string[], item: SelectItem) => {
        const addValue = value;
        const addCache = [...filtrosData[name].cache, item];
        const newObj = { cache: addCache, value: addValue };

        dispatch(selectFilterValue(name, newObj)); // atualiza o cache e o value no redux do filtrosData
        dispatch(setLojasConcorrentesFilterValue(name, value)); // atualiza o valor no redux filtrosValue
        searchStoreByFilter({ ...filtrosValue, [name]: value }); // atualiza o mapa com os dados do filtrosValue
    };

    const handleRemoveFilterValue = (name: string, value: string) => {
        const removeValue = filtrosData[name].value.filter((i: string) => i !== value);
        const removeCache = filtrosData[name].cache.filter(
            (item: SelectItem) => item.value !== value,
        );

        const newObj = { cache: removeCache, value: removeValue };
        dispatch(selectFilterValue(name, newObj));
        dispatch(setLojasConcorrentesFilterValue(name, removeValue));
        searchStoreByFilter({ ...filtrosValue, [name]: removeValue });
    };

    const handleSelectPickerFilterValue = (name: string, value: string, item: SelectItem) => {
        const newObj = { cache: [item], value: [value] };
        dispatch(selectFilterValue(name, newObj));
        dispatch(setLojasConcorrentesFilterValue(name, [value]));
        searchStoreByFilter({ ...filtrosValue, [name]: [value] });
    };

    const handleCheckPickerFilterValue = (name: string, value: string[], item: SelectItem) => {
        if (filtrosData[name].value.includes(item.value)) {
            handleRemoveFilterValue(name, item.value);
        } else {
            handleAddFilterValue(name, value, item);
        }
    };

    const handleSearchFilter = (name: string, query: string) => {
        clearTimeout(timer);

        const newTimer = setTimeout(() => {
            dispatch(getFiltersList(name, getModel(filtrosData, query, name)));
        }, 500);

        setTimer(newTimer);
    };

    const handleSearchAddress = (query: string) => {
        const obj = { query, token: null };
        clearTimeout(timer);

        const newTimer = setTimeout(() => {
            dispatch(getAddress(obj));
        }, 500);

        setTimer(newTimer);
    };

    const handleSelectAddress = (item: SelectItem) => {
        const newObj = { cache: [item], value: [item.value] };
        const initialState = { list: [], cache: [], value: [] };
        const placeObj = {
            place_id: item.place_id,
            token: item.token,
        };

        dispatch(selectFilterValue('endereco', newObj));
        setDisabledRegion(true);
        dispatch(setFilterObject('uf', initialState));
        dispatch(setFilterObject('cidade', initialState));

        getPlace(placeObj).then((data) => { // faz a busca por latitude e longitude pelo endereço fornecido
            const latLon = {
                latitude: data.result.geometry.location.lat,
                longitude: data.result.geometry.location.lng,
            };
            dispatch(setLojasConcorrentesFilterValue('endereco', latLon));
            searchStoreByFilter({ ...filtrosValue, endereco: latLon });
        });
    };

    const handleOpenAddress = () => {
        const newObj = { ...filtrosData.endereco, list: filtrosData.endereco.cache };
        if (filtrosData.endereco.value?.length) {
            dispatch(setFilterObject('endereco', newObj));
        }
    };

    const handleOpenFilter = (name: string) => {
        if (name === 'endereco') {
            handleOpenAddress();
        } else {
            dispatch(getFiltersList(name, getModel(filtrosData, '', name)));
        }
    };

    const handleCleanFilter = (name: string) => {
        const initialState = { cache: [], value: [] };
        dispatch(setLojasConcorrentesFilterValue(name, null));
        searchStoreByFilter({ ...filtrosValue, [name]: null });

        if (name === 'endereco') {
            dispatch(setFilterObject('endereco', { ...initialState, list: [], token: null }));
            setDisabledRegion(false);
        } else {
            dispatch(setFilterObject(name, initialState));
        }
    };

    return {
        handleOpenFilter,
        handleSelectPickerFilterValue,
        handleCheckPickerFilterValue,
        handleSearchFilter,
        handleSearchAddress,
        handleSelectAddress,
        handleOpenAddress,
        handleCleanFilter,
        searchStoreByFilter,
        disabledRegion,
    };
};

import _ from 'lodash';
import React from 'react';

type Item = boolean;

type State<K extends string = string> = Partial<Record<K, Item>>;

type Action =
    | {
          type: 'RESET';
      }
    | {
          type: 'SET';
          payload: Item;
          key: keyof State;
      }
    | {
          type: 'OMIT';
          key: keyof State | (keyof State)[];
      }
    | {
          type: 'TOGGLE_ON_MANY';
          keys: (keyof State)[];
      };

const initialState: State = {};

const reducer = <K extends string = string>(
    state: State<K>,
    action: Action,
): State<K> => {
    switch (action.type) {
        case 'RESET':
            return initialState;
        case 'SET':
            return {
                ...state,
                [action.key]: action.payload,
            };
        case 'OMIT': {
            return _.omit(state, action.key);
        }
        case 'TOGGLE_ON_MANY': {
            return _.mapValues(state, (value, key) => {
                if (action.keys.includes(key)) {
                    return !value;
                }
                return value;
            });
        }
        default:
            return state;
    }
};

export const useLoadingRows = <K extends string = string>() => {
    const [data, dispatch] = React.useReducer(
        reducer as (state: State<K>, action: Action) => State<K>,
        initialState as State,
    );

    return [
        data,
        {
            reset: () => dispatch({ type: 'RESET' }),
            set: (key: K, payload: Item) => {
                dispatch({ type: 'SET', key, payload });
            },
            toggle: (key: K, payload: Item = !data[key]) => {
                dispatch({
                    type: 'SET',
                    key,
                    payload,
                });
            },
            toggleMany: (keys: K[], payload?: Item) => {
                keys.forEach((key) => {
                    dispatch({
                        type: 'SET',
                        key,
                        payload: payload ?? !data[key],
                    });
                });
            },
            omit: (key: K | K[]) => dispatch({ type: 'OMIT', key }),
            check: (key: K) => !!data[key],
        },
    ] as const;
};

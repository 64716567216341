import classNames from 'classnames';
import React, { HtmlHTMLAttributes } from 'react';
import { ButtonPrimary, Layout } from '../../../Lite';
import { useNegociacoesSalvas } from './NegociacoesSalvas.hooks';
import styles from './NegociacoesSalvas.module.scss';
import { TableNegociacoesSalvas } from './components';

export type NegociacoesSalvasProps = HtmlHTMLAttributes<HTMLDivElement>

export const NegociacoesSalvas = ({ className, ...props }: NegociacoesSalvasProps) => {
    const { table, onNewNegotiation } = useNegociacoesSalvas();
    return (
        <Layout className={classNames(styles['negociacoes-salvas'], className)} {...props}>
            <Layout.Header className={styles['negociacoes-salvas__header']}>
                <Layout.Section>
                    <Layout.Title>
                        Negociações salvas
                    </Layout.Title>
                    <ButtonPrimary size="large" onClick={onNewNegotiation}>nova negociação</ButtonPrimary>
                </Layout.Section>
            </Layout.Header>
            <TableNegociacoesSalvas {...table} />
        </Layout>
    );
};

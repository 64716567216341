/* eslint-disable react/jsx-wrap-multilines */
import { format, parseISO } from 'date-fns';
import React from 'react';
import CurrencyFormat from 'react-currency-format';
import { Whisper } from 'rsuite';
import {
    Competitors,
    LoadingSpinerArea,
    PhotoModal,
    useModal,
} from '../../../../../components';
import { parseStringToPlural } from '../../../../../utils';
import {
    CompetitivenessIndicator,
    CompetitorPriceDTOProps,
    NegotiationDataItemProps,
} from '../../../Negociacoes';
import { ButtonsCompetitorPrice } from '../ButtonsCompetitorPrice';

type RowData = {
    competitorsInfo: NegotiationDataItemProps['competitorsInfo'];
    competitivenessIndicator: CompetitivenessIndicator;
    retailPrice: null | number;
} & Record<string, unknown>;

export type RowExpandedProps = {
    rowData: RowData;
    handleCalculoPreco: (
        price: number,
        rowData: RowData,
        competitivenessIndicator: CompetitivenessIndicator,
    ) => void;
    handleCalculoCompetitiviness: (
        price: number,
        rowData: RowData,
        competitivenessIndicator: CompetitivenessIndicator,
    ) => void;
    onToggleCompetitor: (
        checked: boolean,
        competitor: CompetitorPriceDTOProps,
    ) => void;
} & React.HtmlHTMLAttributes<HTMLDivElement>;

const originPrice = {
    'INFOPRICE - INFOPANEL': 'INFOPANEL',
    'INFOPRICE - MPDV': 'MPDV',
} as const;

export const RowExpanded = ({
    rowData,
    handleCalculoPreco,
    handleCalculoCompetitiviness,
    onToggleCompetitor,
    ...props
}: RowExpandedProps) => {
    const [modals, { onOpen, onHide }] = useModal();

    const [photo, setPhoto] = React.useState<null | string>(null);

    const HAS_COMPETITORS_DTO =
        rowData.competitorsInfo?.competitorPriceDTO?.length;

    const CARD_RESUMO_CONCORRENTE_DATA =
        rowData.competitorsInfo?.competitor ??
        rowData.competitorsInfo?.competitorInfoPanel;

    const total = CARD_RESUMO_CONCORRENTE_DATA?.countCompetitorStore ?? 0;

    const isDisabledToggle = (
        list?: CompetitorPriceDTOProps[],
        card?: CompetitorPriceDTOProps,
    ) => {
        const hasActiveItem = list?.some((item) => {
            return (
                item.competitorStoreId !== card?.competitorStoreId &&
                item.active
            );
        });
        if (card?.active && !hasActiveItem) {
            return true;
        }
        return false;
    };

    return (
        <>
            <div {...props}>
                <Competitors>
                    {!CARD_RESUMO_CONCORRENTE_DATA && (
                        <p className="competitor-empty">
                            Informações de concorrência indisponíveis
                        </p>
                    )}

                    {CARD_RESUMO_CONCORRENTE_DATA && (
                        <>
                            <Competitors.Section>
                                <Competitors.Header>
                                    <Competitors.Icon icon="MdDescInsights" />
                                    <Competitors.Title>
                                        Concorrentes
                                    </Competitors.Title>
                                </Competitors.Header>

                                <Competitors.Content>
                                    <Competitors.Card>
                                        <Competitors.CardHeader>
                                            <Competitors.Title>
                                                {rowData.competitorsInfo
                                                    ?.competitor
                                                    ? 'Concorrência direta'
                                                    : 'Concorrência Infopanel'}
                                            </Competitors.Title>
                                            <Competitors.Subtitle>
                                                Produto encontrado em{' '}
                                                <strong>
                                                    {total}{' '}
                                                    {parseStringToPlural(
                                                        'loja',
                                                        total,
                                                    )}
                                                </strong>
                                            </Competitors.Subtitle>
                                        </Competitors.CardHeader>
                                        <Competitors.CardContent>
                                            <Whisper
                                                trigger="click"
                                                placement="bottom"
                                                speaker={
                                                    <ButtonsCompetitorPrice
                                                        classNamecompetitor="buttons-competitor-price"
                                                        handleCalculoPreco={() => {
                                                            handleCalculoPreco(
                                                                CARD_RESUMO_CONCORRENTE_DATA?.competitorAverage ??
                                                                    0,
                                                                rowData,
                                                                'RULE_CPI_AVG',
                                                            );
                                                        }}
                                                        handleCalculoCompetitiviness={() => {
                                                            handleCalculoCompetitiviness(
                                                                CARD_RESUMO_CONCORRENTE_DATA?.competitorAverage ??
                                                                    0,
                                                                rowData,
                                                                'RULE_CPI_AVG',
                                                            );
                                                        }}
                                                    />
                                                }
                                            >
                                                <Competitors.InfoBox
                                                    isActive={
                                                        rowData.competitivenessIndicator ===
                                                        'RULE_CPI_AVG'
                                                    }
                                                >
                                                    <Competitors.Value>
                                                        <CurrencyFormat
                                                            fixedDecimalScale
                                                            decimalScale={2}
                                                            value={
                                                                CARD_RESUMO_CONCORRENTE_DATA.competitorAverage
                                                            }
                                                            displayType="text"
                                                            prefix=" R$"
                                                            decimalSeparator=","
                                                            thousandSeparator="."
                                                        />
                                                    </Competitors.Value>
                                                    <Competitors.Label>
                                                        x̄ Preço
                                                        <br />
                                                        Médio
                                                    </Competitors.Label>
                                                </Competitors.InfoBox>
                                            </Whisper>

                                            <Whisper
                                                trigger="click"
                                                placement="bottom"
                                                speaker={
                                                    <ButtonsCompetitorPrice
                                                        classNamecompetitor="buttons-competitor-price"
                                                        handleCalculoPreco={() => {
                                                            handleCalculoPreco(
                                                                CARD_RESUMO_CONCORRENTE_DATA?.competitorAverage ??
                                                                    0,
                                                                rowData,
                                                                'RULE_CPI_MIN',
                                                            );
                                                        }}
                                                        handleCalculoCompetitiviness={() => {
                                                            handleCalculoCompetitiviness(
                                                                CARD_RESUMO_CONCORRENTE_DATA?.competitorAverage ??
                                                                    0,
                                                                rowData,
                                                                'RULE_CPI_MIN',
                                                            );
                                                        }}
                                                    />
                                                }
                                            >
                                                <Competitors.InfoBox
                                                    isActive={
                                                        rowData.competitivenessIndicator ===
                                                        'RULE_CPI_MIN'
                                                    }
                                                >
                                                    <Competitors.Value>
                                                        <CurrencyFormat
                                                            fixedDecimalScale
                                                            decimalScale={2}
                                                            value={
                                                                CARD_RESUMO_CONCORRENTE_DATA.competitorLower
                                                            }
                                                            displayType="text"
                                                            prefix=" R$"
                                                            decimalSeparator=","
                                                            thousandSeparator="."
                                                        />
                                                    </Competitors.Value>
                                                    <Competitors.Label>
                                                        {'<'} Preço
                                                        <br />
                                                        Mais Baixo
                                                    </Competitors.Label>
                                                </Competitors.InfoBox>
                                            </Whisper>
                                            <Whisper
                                                trigger="click"
                                                placement="bottom"
                                                speaker={
                                                    <ButtonsCompetitorPrice
                                                        classNamecompetitor="buttons-competitor-price"
                                                        handleCalculoPreco={() => {
                                                            handleCalculoPreco(
                                                                CARD_RESUMO_CONCORRENTE_DATA?.competitorAverage ??
                                                                    0,
                                                                rowData,
                                                                'RULE_CPI_MAX',
                                                            );
                                                        }}
                                                        handleCalculoCompetitiviness={() => {
                                                            handleCalculoCompetitiviness(
                                                                CARD_RESUMO_CONCORRENTE_DATA?.competitorAverage ??
                                                                    0,
                                                                rowData,
                                                                'RULE_CPI_MAX',
                                                            );
                                                        }}
                                                    />
                                                }
                                            >
                                                <Competitors.InfoBox
                                                    isActive={
                                                        rowData.competitivenessIndicator ===
                                                        'RULE_CPI_MAX'
                                                    }
                                                >
                                                    <Competitors.Value>
                                                        <CurrencyFormat
                                                            fixedDecimalScale
                                                            decimalScale={2}
                                                            value={
                                                                CARD_RESUMO_CONCORRENTE_DATA.competitorHigher
                                                            }
                                                            displayType="text"
                                                            prefix=" R$"
                                                            decimalSeparator=","
                                                            thousandSeparator="."
                                                        />
                                                    </Competitors.Value>
                                                    <Competitors.Label>
                                                        {'>'} Preço
                                                        <br />
                                                        Mais Alto
                                                    </Competitors.Label>
                                                </Competitors.InfoBox>
                                            </Whisper>
                                        </Competitors.CardContent>
                                    </Competitors.Card>
                                </Competitors.Content>
                            </Competitors.Section>

                            {HAS_COMPETITORS_DTO && (
                                <>
                                    <Competitors.Separator />
                                    <Competitors.Section>
                                        <Competitors.Header>
                                            <Competitors.Icon icon="DescArrowForward" />
                                            <Competitors.Title>
                                                Lojas
                                            </Competitors.Title>
                                        </Competitors.Header>

                                        <Competitors.Content>
                                            {rowData.competitorsInfo?.competitorPriceDTO.map(
                                                (competitor) => (
                                                    <Competitors.Card>
                                                        <Competitors.CardHeader>
                                                            <Competitors.Title>
                                                                {
                                                                    competitor.competitorStore
                                                                }
                                                            </Competitors.Title>
                                                            <Competitors.Toggle
                                                                checked={
                                                                    competitor.active
                                                                }
                                                                onChange={(
                                                                    checked,
                                                                ) => {
                                                                    onToggleCompetitor(
                                                                        checked,
                                                                        competitor,
                                                                    );
                                                                }}
                                                                disabled={isDisabledToggle(
                                                                    rowData
                                                                        .competitorsInfo
                                                                        ?.competitorPriceDTO,
                                                                    competitor,
                                                                )}
                                                            />
                                                            <Competitors.CardHeaderAction>
                                                                <Competitors.Tag>
                                                                    {
                                                                        originPrice[
                                                                            competitor
                                                                                .origemPreco
                                                                        ]
                                                                    }
                                                                </Competitors.Tag>
                                                                <Competitors.Label>
                                                                    {
                                                                        competitor.competitorStoreType
                                                                    }
                                                                </Competitors.Label>
                                                                {competitor.photo && (
                                                                    <Competitors.PhotoIcon
                                                                        onClick={() => {
                                                                            if (
                                                                                competitor.photo
                                                                            ) {
                                                                                setPhoto(
                                                                                    competitor.photo,
                                                                                );
                                                                                onOpen(
                                                                                    'competitor-photo',
                                                                                );
                                                                            }
                                                                        }}
                                                                    />
                                                                )}
                                                            </Competitors.CardHeaderAction>
                                                        </Competitors.CardHeader>

                                                        <Competitors.CardContent>
                                                            <Competitors.InfoBox>
                                                                <Competitors.Label>
                                                                    Preço
                                                                </Competitors.Label>
                                                                <Competitors.Value>
                                                                    <CurrencyFormat
                                                                        fixedDecimalScale
                                                                        decimalScale={
                                                                            2
                                                                        }
                                                                        value={
                                                                            competitor?.retailPrice
                                                                        }
                                                                        displayType="text"
                                                                        prefix="R$"
                                                                        decimalSeparator=","
                                                                        thousandSeparator="."
                                                                    />
                                                                </Competitors.Value>
                                                            </Competitors.InfoBox>

                                                            <Competitors.InfoBox>
                                                                <Competitors.Label>
                                                                    Competitividade
                                                                </Competitors.Label>
                                                                <Competitors.Value>
                                                                    <CurrencyFormat
                                                                        fixedDecimalScale
                                                                        decimalScale={
                                                                            2
                                                                        }
                                                                        value={
                                                                            rowData &&
                                                                            competitor &&
                                                                            'retailPrice' in
                                                                                rowData &&
                                                                            'retailPrice' in
                                                                                competitor
                                                                                ? (rowData.retailPrice /
                                                                                      competitor.retailPrice) *
                                                                                  100
                                                                                : 0
                                                                        }
                                                                        displayType="text"
                                                                        suffix="%"
                                                                        decimalSeparator=","
                                                                        thousandSeparator="."
                                                                    />
                                                                </Competitors.Value>
                                                            </Competitors.InfoBox>
                                                            <Competitors.InfoBox>
                                                                <Competitors.Label>
                                                                    Data
                                                                </Competitors.Label>
                                                                <Competitors.Value>
                                                                    {competitor.date
                                                                        ? format(
                                                                              parseISO(
                                                                                  competitor.date,
                                                                              ),
                                                                              'dd/MM/yyyy',
                                                                          )
                                                                        : '--'}
                                                                </Competitors.Value>
                                                            </Competitors.InfoBox>
                                                        </Competitors.CardContent>
                                                    </Competitors.Card>
                                                ),
                                            )}
                                        </Competitors.Content>
                                    </Competitors.Section>
                                    <PhotoModal
                                        show={modals['competitor-photo']}
                                        onHide={() => {
                                            onHide('competitor-photo');
                                            setPhoto(null);
                                        }}
                                        photo={photo}
                                    />
                                </>
                            )}
                        </>
                    )}
                </Competitors>
            </div>
            <LoadingSpinerArea
                area={`competitor-row-${rowData.storeId}-${rowData.productId}`}
                height="100%"
                size="md"
            />
        </>
    );
};

import React, { useEffect, useReducer, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { withRouter } from 'react-router-dom';
import {
    Button,
    Col,
    Container,
    Content,
    FlexboxGrid,
    Icon,
    Table,
    Tag,
} from 'rsuite';
import { LoadingSpiner } from '../../../components/LoadingSpinner';
import ModalNotification from '../../../components/ModalNotification';
import { GlobalContext } from '../../../utils/Helpers';
import { datapointsDownload, getDatapointsExportacao } from './services';
// @ts-ignore
import { FilterBox } from '../../../components/FilterBox/index.tsx';
import { getStoreIds } from '../RevisaoPrecos/utils/getStoreIds.ts';

const { Column, HeaderCell, Cell, Pagination } = Table;

const ExtracaoPreco = () => {
    const [data, setData] = useState([]);
    const [showLimiteLinhas, setShowLimiteLinhas] = useState(false);
    const [pageSize, setPageSize] = useState(20);
    const [pageNumber, setPageNumber] = useState(0);
    const [filtros, setFiltros] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            produtosSelecionados: {
                cache: [],
                value: [],
            },
            lojasSelecionadas: {
                cache: [],
                value: [],
                checkAll: false,
                indeterminate: false,
            },
            categoryLevel1: {
                cache: [],
                value: [],
            },
            categoryLevel2: {
                cache: [],
                value: [],
            },
            categoryLevel3: {
                cache: [],
                value: [],
            },
            categoryLevel4: {
                cache: [],
                value: [],
            },
            categoryLevel5: {
                cache: [],
                value: [],
            },
            categoryLevel6: {
                cache: [],
                value: [],
            },
            categoryLevel7: {
                cache: [],
                value: [],
            },
        },
    );

    const limparFiltros = () => {
        setFiltros({
            produtosSelecionados: {
                cache: [],
                value: [],
            },
            lojasSelecionadas: {
                cache: [],
                value: [],
                checkAll: false,
                indeterminate: false,
            },
            categoryLevel1: {
                cache: [],
                value: [],
            },
            categoryLevel2: {
                cache: [],
                value: [],
            },
            categoryLevel3: {
                cache: [],
                value: [],
            },
            categoryLevel4: {
                cache: [],
                value: [],
            },
            categoryLevel5: {
                cache: [],
                value: [],
            },
            categoryLevel6: {
                cache: [],
                value: [],
            },
            categoryLevel7: {
                cache: [],
                value: [],
            },
        });
    };

    const changeFiltroCheckAll = (value, filtro) => {
        setFiltros({
            [filtro]: value,
        });
    };

    const selectFiltro = (item, filtro) => {
        setFiltros((oldState) => ({
            [filtro]: {
                ...oldState[filtro],
                cache: [...oldState[filtro].cache, item],
            },
        }));
    };

    const changeFiltros = (value, lista, filtro) => {
        let cache;

        if (value === null) {
            cache = [];
            setFiltros((oldState) => ({
                ...oldState,
                [filtro]: {
                    ...oldState[filtro],
                    cache,
                },
            }));
        }
        const cacheData = filtros[filtro];
        if (value !== null && value.length !== cacheData?.cache?.length) {
            cache = lista.filter((item) => value.indexOf(item.value) !== -1);

            setFiltros({
                [filtro]: {
                    ...filtros[filtro],
                    cache,
                    value,
                },
            });
        }
    };

    const closeModalLimiteLinha = () => {
        setShowLimiteLinhas(false);
    };

    const getModelFiltros = () => ({
        storeIds: getStoreIds(filtros.lojasSelecionadas.cache),
        productId: filtros?.produtosSelecionados?.value || null,
        page: pageNumber || 0,
        size: pageSize || 20,
        categoryLevel1: filtros?.categoryLevel1?.value || null,
        categoryLevel2: filtros?.categoryLevel2?.value || null,
        categoryLevel3: filtros?.categoryLevel3?.value || null,
        categoryLevel4: filtros?.categoryLevel4?.value || null,
        categoryLevel5: filtros?.categoryLevel5?.value || null,
        categoryLevel6: filtros?.categoryLevel6?.value || null,
        categoryLevel7: filtros?.categoryLevel7?.value || null,
    });

    const searchHistoricoPrecificacao = () => {
        const params = getModelFiltros();
        getDatapointsExportacao(params).then((response) => {
            if (response) {
                setData(response);
            }
        });
    };

    const handleChangePage = (e) => {
        setPageNumber(e - 1);
    };

    const handleChangeLength = (e) => {
        setPageSize(e);
    };

    const getDados = () => {
        const params = getModelFiltros();
        datapointsDownload({
            params,
            cliente: JSON.parse(localStorage.getItem('cliente'))?.codigo,
        }).then((resp) => resp);
    };

    useEffect(() => {
        searchHistoricoPrecificacao();
    }, [pageNumber, pageSize, filtros]);

    return (
        <Container className="main-container extracao-datapoint-page">
            <Content>
                <FlexboxGrid>
                    <FlexboxGrid.Item colspan={24}>
                        <GlobalContext.Provider value={filtros}>
                            <FilterBox
                                filtros={filtros}
                                selectFiltro={selectFiltro}
                                changeFiltros={changeFiltros}
                                changeFiltroCheckAll={changeFiltroCheckAll}
                                limparFiltros={limparFiltros}
                            />
                        </GlobalContext.Provider>
                        <FlexboxGrid className="botao-download-container">
                            <FlexboxGrid.Item
                                componentClass={Col}
                                className="download__wrapper"
                            >
                                <Button
                                    block
                                    onClick={getDados}
                                    appearance="default"
                                    disabled={!data?.content?.length}
                                >
                                    <Icon icon="cloud-download" /> Download
                                </Button>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>

                        <LoadingSpiner size="md" />

                        <Table height={400} data={data?.content} autoHeight>
                            <Column flexGrow={1}>
                                <HeaderCell>Identificador</HeaderCell>
                                <Cell dataKey="productId" />
                            </Column>
                            <Column flexGrow={3}>
                                <HeaderCell>Descrição</HeaderCell>
                                <Cell dataKey="description" />
                            </Column>
                            <Column flexGrow={3}>
                                <HeaderCell>Loja</HeaderCell>
                                <Cell dataKey="storeName" />
                            </Column>
                            <Column flexGrow={1}>
                                <HeaderCell>Preço Varejo</HeaderCell>
                                <Cell>
                                    {(rowData) => (
                                        <CurrencyFormat
                                            fixedDecimalScale
                                            decimalScale={2}
                                            value={rowData.price ?? ''}
                                            displayType="text"
                                            prefix="R$"
                                            decimalSeparator=","
                                            thousandSeparator="."
                                        />
                                    )}
                                </Cell>
                            </Column>
                            <Column flexGrow={2}>
                                <HeaderCell>Tipo de preço</HeaderCell>
                                <Cell>
                                    {(rowData) => (
                                        <Tag
                                            className="table__tag-tipo-preco"
                                            color="blue"
                                        >
                                            {rowData.priceType}
                                        </Tag>
                                    )}
                                </Cell>
                            </Column>
                        </Table>
                        <Pagination
                            lengthMenu={[
                                {
                                    value: 20,
                                    label: 20,
                                },
                                {
                                    value: 40,
                                    label: 40,
                                },
                            ]}
                            activePage={data.number + 1}
                            displayLength={data.size}
                            total={data.totalElements}
                            onChangePage={handleChangePage}
                            onChangeLength={handleChangeLength}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Content>

            <ModalNotification
                show={showLimiteLinhas}
                onHide={closeModalLimiteLinha}
                title="Extração de dados"
                message="O arquivo que você deseja baixar contém mais de
                cinquenta mil linhas e pode demorar um pouco para ficar pronto. Te enviaremos por e-mail assim que estiver tudo certo!"
                close={closeModalLimiteLinha}
            />
        </Container>
    );
};

export default withRouter(ExtracaoPreco);

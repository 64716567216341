import { useRef, useState } from 'react';

export const useFilterSearch = <K extends string = string>() => {
    const [search, setSearch] = useState<Partial<Record<K, string>>>({});

    const timer = useRef<NodeJS.Timeout>();

    const onSearch = (key: K, query: string) => {
        clearTimeout(timer.current);
        const newTimer = setTimeout(() => {
            setSearch((oldValue) => ({ ...oldValue, [key]: query }));
        }, 500);
        timer.current = newTimer;
    };

    return [search, { onSearch }] as const;
};

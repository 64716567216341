/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { Button, Checkbox, Col, FlexboxGrid } from 'rsuite';
import { useNewFiltros } from '../../hooks';
import {
    Icon,
    useQuickActionFilter,
} from '../../pages/IPA/RevisaoPrecos/Components/QuickActionFilter';
import { useFiltrosState } from '../../pages/IPA/RevisaoPrecos/hooks/useFiltrosState';
import { SAVED_FILTER_ITEM } from '../../pages/IPA/RevisaoPrecos/utils';
import { SelectPillProps } from '../../pages/IRA/Components/SelectPill';
import {
    FiltersNameProps,
    addSegmentValue,
    openModal,
    setSavedFilter,
} from '../../reducers/gerenciadorPrecos';
import { SegmentsPlaceholder } from './Components';
import { CheckPill, SelectPill } from './Components/SelectPill';

type NewFilterBoxProps = {
    isEmpty: boolean;
    handleClearFilters: () => void;
};

export const NewFilterBox = ({
    isEmpty = true,
    handleClearFilters,
}: NewFilterBoxProps) => {
    const { filtros, handleChangeFilter, handleAddFilterData, savedFilter } =
        useFiltrosState();

    const {
        data,
        loading,
        loadingLojas,
        loadingProdutos,
        loadingFamilias,
        loadingTiposPreco,
        segmentos,
        changeQuery,
        loadingFiltrosSalvos,
    } = useNewFiltros();

    const { handleToggleOnChangedPrices, handleSelectFilterLimits } =
        useQuickActionFilter();

    const dispatch = useDispatch();

    const FILTROS: SelectPillProps[] = [
        {
            id: 'check-picker-pill',
            placeholder: 'Produtos',
            type: 'check',
            data: data.productIds,
            cache: filtros.productIds.cache,
            value: filtros.productIds.value,
            sticky: true,
            isLoading: loadingProdutos,
            locale: {
                noResultsText: loadingProdutos
                    ? 'Carregando produtos...'
                    : 'Nenhum item encontrado',
                searchPlaceholder: 'Pesquisar',
            },
            onChange: (value) => {
                handleChangeFilter(value, 'productIds');
            },
            onSearch: (value) => {
                changeQuery(value, 'productIds');
            },
            onClean: () => {
                handleChangeFilter([], 'productIds');
            },
            onClose: () => {
                changeQuery('', 'productIds');
            },
        },
        {
            id: 'check-picker-pill',
            type: 'check',
            placeholder: 'Família',
            data: data.productFamilyIds,
            cache: filtros.productFamilyIds.cache,
            value: filtros.productFamilyIds.value,
            isLoading: loadingFamilias,
            onChange: (value) => {
                handleChangeFilter(value, 'productFamilyIds');
            },
            onSearch: (value) => {
                changeQuery(value, 'productFamilyIds');
            },
            onClean: () => {
                handleChangeFilter([], 'productFamilyIds');
            },
            onClose: () => {
                changeQuery('', 'productFamilyIds');
            },
            locale: {
                noResultsText: loadingFamilias
                    ? 'Carregando famílias...'
                    : 'Nenhum item encontrado',
                searchPlaceholder: 'Pesquisar',
            },
        },
        {
            id: 'check-picker-pill',
            type: 'check',
            placeholder: 'Lojas',
            data: data.storeIds,
            cache: filtros.storeIds.cache,
            value: filtros.storeIds.value,
            isLoading: loadingLojas,
            onChange: (value) => {
                handleChangeFilter(value, 'storeIds');
            },

            onSearch: (value) => {
                changeQuery(value, 'storeIds');
            },
            onClose: () => {
                changeQuery('', 'storeIds');
            },
            onClean: () => {
                handleChangeFilter([], 'storeIds');
            },
            locale: {
                noResultsText: loadingLojas
                    ? 'Carregando lojas...'
                    : 'Nenhum item encontrado',
                searchPlaceholder: 'Pesquisar',
            },
            renderExtraFooter: () => (
                <div className="picker-pill-footer">
                    <Checkbox
                        inline
                        indeterminate={
                            filtros.storeIds.value.length > 0 &&
                            filtros.storeIds.value.length !==
                                data.storeIds.length
                        }
                        checked={
                            data.storeIds.length ===
                            filtros.storeIds.value.length
                        }
                        onChange={(value: any, checked: boolean) => {
                            const nextValue = checked
                                ? data.storeIds.map(({ value }) => value)
                                : [];
                            handleChangeFilter(nextValue, 'storeIds');
                        }}
                    >
                        Selecionar todas as lojas
                    </Checkbox>
                </div>
            ),
        },
        {
            id: 'check-picker-pill',
            type: 'check',
            placeholder: 'Tipo de Preço',
            searchable: false,
            data: data.priceType,
            value: filtros.priceType.value,
            isLoading: loadingTiposPreco,
            onChange: (value) => {
                handleChangeFilter(value, 'priceType');
            },
            onClean: () => {
                handleChangeFilter([], 'priceType');
            },
            locale: {
                noResultsText: loadingTiposPreco
                    ? 'Carregando tipos de preço...'
                    : 'Nenhum item encontrado',
                searchPlaceholder: 'Pesquisar',
            },
        },
        {
            block: true,
            id: 'check-picker-pill',
            placeholder: 'Segmentação',
            searchable: false,
            data: data.sensibilities,
            groupBy: 'role',
            value: filtros.sensibilities.value,
            onChange: (value) => {
                handleChangeFilter(value, 'sensibilities');
            },
            onClean: () => {
                handleChangeFilter([], 'sensibilities');
            },
            locale: {
                searchPlaceholder: 'Pesquisar',
            },
        },
        {
            id: 'check-picker-pill',
            placeholder: 'Marca',
            type: 'check',
            data: data.productBrand,
            cache: filtros.productBrand.cache,
            value: filtros.productBrand.value,
            isLoading: loading.productBrand,
            sticky: true,
            locale: {
                noResultsText: loading.productBrand
                    ? 'Carregando marcas...'
                    : 'Nenhuma marca encontrada',
                searchPlaceholder: 'Pesquisar',
            },
            onChange: (value) => {
                handleChangeFilter(value, 'productBrand');
            },
            onSearch: (value) => {
                changeQuery(value, 'productBrand');
            },
            onClean: () => {
                handleChangeFilter([], 'productBrand');
            },
            onClose: () => {
                changeQuery('', 'productBrand');
            },
        },
        {
            id: 'check-picker-pill',
            placeholder: 'Fornecedor',
            type: 'check',
            data: data.supplier,
            cache: filtros.supplier.cache,
            value: filtros.supplier.value,
            sticky: true,
            isLoading: loading.supplier,
            locale: {
                noResultsText: loading.supplier
                    ? 'Carregando fornecedores...'
                    : 'Nenhum fornecedor encontrado',
                searchPlaceholder: 'Pesquisar',
            },
            onChange: (value) => {
                handleChangeFilter(value, 'supplier');
            },
            onSearch: (value) => {
                changeQuery(value, 'supplier');
            },
            onClean: () => {
                handleChangeFilter([], 'supplier');
            },
            onClose: () => {
                changeQuery('', 'supplier');
            },
        },
    ];

    const handleSelectSavedFilter = ({
        filters,
        id,
        name,
    }: SAVED_FILTER_ITEM) => {
        handleClearFilters();

        const data = {
            filterName: name,
            data: {
                label: name,
                value: id,
                name,
            },
        };

        dispatch(
            setSavedFilter({
                data,
            }),
        );

        Object.keys(filters)?.forEach((key) => {
            if (filters[key as keyof typeof filters] === null) return;
            if (key === 'productIds') {
                const cache = filters.products?.map(
                    ({ id, description, ...filtro }) => ({
                        label: `${id} - ${description}`,
                        value: id,
                        ...filtro,
                    }),
                );
                const value = filters.productIds;
                handleAddFilterData(cache, 'productIds');
                handleChangeFilter(value, 'productIds');
            }
            if (key === 'storeIds') {
                const cache = filters.stores?.map(({ id, description }) => ({
                    label: `${id} - ${description}`,
                    value: id,
                }));
                const value = filters.storeIds;
                handleAddFilterData(cache, 'storeIds');
                handleChangeFilter(value, 'storeIds');
            }
            if (key === 'productFamilyIds') {
                const cache = filters[key]?.map((item) => ({
                    label: item,
                    value: item,
                }));
                const value = filters[key];
                handleAddFilterData(cache, 'productFamilyIds');
                handleChangeFilter(value, 'productFamilyIds');
            }
            if (key === 'priceType') {
                const cache = filters[key]?.map((item) => ({
                    label: item,
                    value: item,
                }));
                const value = filters[key];
                handleAddFilterData(cache, 'priceType');
                handleChangeFilter(value, 'priceType');
            }
            if (key === 'sensibilities') {
                const newValue = filters.sensibilities?.map((value) => ({
                    type: 'infoPriceSensibility',
                    value,
                }));
                dispatch(
                    addSegmentValue({
                        value: newValue,
                    }),
                );
            }
            if (key === 'exclusive') {
                const newValue = filters.exclusive?.map((value) => ({
                    type: 'sensibilityType',
                    value,
                }));
                dispatch(
                    addSegmentValue({
                        value: newValue,
                    }),
                );
            }
            if (key === 'curveAbcLocal') {
                const newValue = filters.curveAbcLocal?.map((value) => ({
                    type: 'infoPriceAbcClass',
                    value,
                }));
                dispatch(
                    addSegmentValue({
                        value: newValue,
                    }),
                );
            }
            if (key === 'curveAbcGlobal') {
                const newValue = filters.curveAbcGlobal?.map((value) => ({
                    type: 'infoPriceGlobalAbcClass',
                    value,
                }));
                dispatch(
                    addSegmentValue({
                        value: newValue,
                    }),
                );
            }
            if (key === 'supplier') {
                const cache = filters.supplier.map((item) => ({
                    label: item,
                    value: item,
                }));
                const value = filters.supplier;
                handleAddFilterData(cache, 'supplier');
                handleChangeFilter(value, 'supplier');
            }
            if (key === 'productBrand') {
                const cache = filters.productBrand.map((item) => ({
                    label: item,
                    value: item,
                }));
                const value = filters.productBrand;
                handleAddFilterData(cache, 'productBrand');
                handleChangeFilter(value, 'productBrand');
            }
            if (
                key === 'categoryLevel1' ||
                key === 'categoryLevel2' ||
                key === 'categoryLevel3' ||
                key === 'categoryLevel4' ||
                key === 'categoryLevel5' ||
                key === 'categoryLevel6' ||
                key === 'categoryLevel7'
            ) {
                const cache = filters[key]?.map((item) => ({
                    label: item,
                    value: item,
                }));
                const value = filters[key];
                handleAddFilterData(cache, key);
                handleChangeFilter(value, key);
            }
            if (key === 'changedPrices' && filters.changedPrices === true) {
                handleToggleOnChangedPrices();
            }
            if (key === 'brokenLimit') {
                handleSelectFilterLimits(filters.brokenLimit);
            }
        });
    };

    const isGerenciadorPrecosRoute = useRouteMatch(
        '/ipa/gerenciador-de-precos',
    )?.isExact;

    return (
        <FlexboxGrid justify="space-between" className="filter-box-container">
            <FlexboxGrid.Item colspan={16}>
                <FlexboxGrid className="filtros-container">
                    {FILTROS.map((filtro) => (
                        <FlexboxGrid.Item
                            key={filtro.placeholder}
                            componentClass={Col}
                        >
                            <CheckPill {...filtro} />
                        </FlexboxGrid.Item>
                    ))}
                </FlexboxGrid>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item>
                <Button
                    className="clear-filtros-btn"
                    onClick={handleClearFilters}
                >
                    Limpar filtros
                </Button>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item componentClass={Col} colspan={24}>
                <FlexboxGrid>
                    <FlexboxGrid.Item colspan={24}>
                        <FlexboxGrid className="sub-menu">
                            <FlexboxGrid className="filtros-container">
                                {segmentos?.map(
                                    (segmento: {
                                        name: string;
                                        field: FiltersNameProps;
                                        level: number;
                                    }) => (
                                        <FlexboxGrid.Item
                                            componentClass={Col}
                                            key={segmento.name}
                                        >
                                            <CheckPill
                                                key={segmento.level}
                                                id="check-picker-pill"
                                                type="check"
                                                placeholder={
                                                    <SegmentsPlaceholder
                                                        level={segmento.level}
                                                        placeholder={
                                                            segmento.name
                                                        }
                                                    />
                                                }
                                                name={segmento.field}
                                                data={data[segmento.field]}
                                                cache={
                                                    filtros[segmento.field]
                                                        .cache
                                                }
                                                value={
                                                    filtros[segmento.field]
                                                        .value
                                                }
                                                onChange={(value: string[]) => {
                                                    handleChangeFilter(
                                                        value,
                                                        segmento.field,
                                                    );
                                                }}
                                                onSearch={(value: string) => {
                                                    changeQuery(
                                                        value,
                                                        segmento.field,
                                                    );
                                                }}
                                                onClean={() => {
                                                    handleChangeFilter(
                                                        [],
                                                        segmento.field,
                                                    );
                                                }}
                                                onClose={() => {
                                                    changeQuery(
                                                        '',
                                                        segmento.field,
                                                    );
                                                }}
                                                locale={{
                                                    noResultsText:
                                                        loadingProdutos
                                                            ? 'Carregando produtos...'
                                                            : 'Nenhum item encontrado',
                                                    searchPlaceholder: `Buscar por ${segmento.name}`,
                                                }}
                                            />
                                        </FlexboxGrid.Item>
                                    ),
                                )}
                            </FlexboxGrid>
                            {isGerenciadorPrecosRoute && (
                                <FlexboxGrid
                                    align="middle"
                                    className="salvar-filtros sub-menu filtros-container"
                                >
                                    <SelectPill
                                        id="check-picker-pill"
                                        data={data.savedFilters}
                                        value={savedFilter?.data?.value}
                                        placeholder="Filtros salvos"
                                        placement="bottomEnd"
                                        isLoading={loadingFiltrosSalvos}
                                        locale={{
                                            noResultsText: loadingFiltrosSalvos
                                                ? 'Carregando filtros...'
                                                : 'Nenhum filtro encontrado',
                                            searchPlaceholder: 'Pesquisar',
                                        }}
                                        onClose={() => {
                                            changeQuery('', 'savedFilters');
                                        }}
                                        onSearch={(value) => {
                                            changeQuery(value, 'savedFilters');
                                        }}
                                        // @ts-ignore
                                        onSelect={(value, item) => {
                                            handleSelectSavedFilter(item);
                                        }}
                                        onClean={handleClearFilters}
                                    />
                                    {!isEmpty && (
                                        <Button
                                            className="clear-filtros-btn salvar-filtros-btn"
                                            onClick={() => {
                                                dispatch(
                                                    openModal({
                                                        name: 'salvarFiltros',
                                                    }),
                                                );
                                            }}
                                        >
                                            <Icon icon="MdSave" />
                                        </Button>
                                    )}

                                    {savedFilter.data?.value?.length > 0 && (
                                        <Button
                                            className="clear-filtros-btn salvar-filtros-btn"
                                            onClick={() => {
                                                dispatch(
                                                    openModal({
                                                        name: 'excluirFiltro',
                                                    }),
                                                );
                                            }}
                                        >
                                            <Icon icon="MdDelete" />
                                        </Button>
                                    )}
                                </FlexboxGrid>
                            )}
                        </FlexboxGrid>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </FlexboxGrid.Item>
        </FlexboxGrid>
    );
};

/* eslint-disable no-unsafe-optional-chaining */
import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded';
import ArrowUpwardRoundedIcon from '@material-ui/icons/ArrowUpwardRounded';
import axios from 'axios';
import classNames from 'classnames';
import { addDays, format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { MdFlag, MdStars } from 'react-icons/md';
import { usePromiseTracker } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    Alert,
    Button,
    Checkbox,
    Icon,
    Loader,
    Notification,
    Table,
    Tooltip,
    Whisper,
} from 'rsuite';
import { IGerenciadorPrecosDatapoint as RowData } from '../../../@types';
import { Sort } from '../../../@types/sort';
import { searchSegmentos } from '../../../actions/actionsRevisao';
import {
    ContextMenu,
    ContextMenuContent,
    ContextMenuItem,
    ContextMenuSeparator,
    ContextMenuTrigger,
    EngineWarning,
    InputAddon,
    InputCurrency,
    InputGroup,
    Layout,
    LoadingSpinerArea,
    ModalPriceEventHistory,
    NewFilterBox,
    TableHeading,
} from '../../../components';
import { useLoadingRows, usePagination } from '../../../hooks';
import {
    closeModal,
    resetFilters,
    resetSavedFilter,
    selectorGerenciadorPrecos,
    setSavedFilterInputName,
} from '../../../reducers/gerenciadorPrecos';
import { GlobalContext } from '../../../utils/Helpers';
import { ButtonPrimary } from '../Lite';
import { VariationBoxIndicator } from '../Negociacoes/pages/NegociacaoFornecedor/components';
import {
    AlterarPrecosSchema,
    BaseCell,
    CompetitivenessLimitInfo,
    CompetitorPriceCell,
    ComposicaoPrecoModal,
    CompositionRuleModal,
    DataUltimaIntegracao,
    DropDownOptions,
    ExcluirFiltroModal,
    HoverNomeColuna,
    LinkButton,
    MarginLimitInfo,
    ModalAlterarPrecos,
    ModalPrevisaoDemanda,
    PrecoSugeridoCell,
    PrecoVigenteCell,
    ProductCell,
    QuickActionFilter,
    RadioOptionColumns,
    SalvarFiltrosModal,
    TitleDropDownOptions,
    useCompositionRuleModal,
    usePriceCompositionModal,
    useQuickActionFilter,
} from './Components';
import { BigNumbersArea } from './Components/BigNumbersArea';
import { ButtonDropdown } from './Components/ButtonDropdown';
import { RowExpanded } from './Components/RowExpanded';
import styles from './gerenciadorPrecos.module.scss';
import {
    useDataUltimaIntegracao,
    useDisabledCompetitor,
    useFiltrosState,
    useGerenciadorPrecos,
    useSalvarFiltros,
    useSelectedDatapoints,
    useTotalPrice,
    useVariationPrice,
} from './hooks';
import {
    SaveEditedDatapoint,
    getCompetitorsInfo,
    getDatapointsRevisao,
    novoCalculoPrevisao,
    salvarSimulacao,
    saveEditedDatapoint,
    savePrecificacaoBulk,
    undoEditedDatapoint,
} from './services';
import {
    ChangeMassicePrice,
    changeMassivePrice,
    getPriceEventHistory,
} from './services_ts';
import { getValidData } from './utils';

const { Column, HeaderCell, Cell, Pagination } = Table;

const sortColumnsTypes = {
    name: 'description',
};

const rowKey = 'productsToBePricedId';

const handleMoveFocusToNextColumnInput = (e) => {
    const targetClass = e.target.className.replace(' ', '.');
    if (
        e.key === 'Enter' &&
        (targetClass.match(/novo-preco/) ||
            targetClass.match(/nova-competitividade/) ||
            targetClass.match(/nova-margem/))
    ) {
        const nodeList = e.currentTarget.querySelectorAll(`.${targetClass}`);
        const currentIndex = Array.from(nodeList).indexOf(e.target);
        const nextIndex = currentIndex + 1;
        if (nextIndex < nodeList.length) {
            nodeList[nextIndex].focus();
            nodeList[nextIndex].select();
        }
    }
};

const initialSort: Sort = {
    type: 'competitorsPrice',
    orderBy: 'asc',
};

const RevisaoPrecos = ({ history }) => {
    const { modals: reduxModals, filtros } = useSelector(
        selectorGerenciadorPrecos,
    );

    const {
        modals: [modals, { onOpen: onOpenModal, onHide: onCloseModal }],
    } = useGerenciadorPrecos();

    const dispatch = useDispatch();

    const { promiseInProgress: isLoading } = usePromiseTracker({
        area: 'get-datapoints-revisao',
    });

    const [data, setData] = useState<{
        content: RowData[];
        number: number;
        totalElements: number;
    }>({
        content: [],
        number: 0,
        totalElements: 0,
    });

    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
    const [showModalPrevisaoDemanda, setShowModalPrevisaoDemanda] =
        useState(false);
    const [sort, setSort] = useState<Sort>(initialSort);
    const [previsao, setPrevisao] = useState({});
    const [previsaoSelecionada, setPrevisaoSelecionada] = useState(null);
    const [isDataKeyProtuto, setIsDataKeyProtuto] = useState('productId');
    const { promiseInProgress } = usePromiseTracker({
        area: 'get-datapoints-revisao',
    });
    const [updateBigNumbers, setUpdateBigNumbers] = useState(false);

    const [
        ,
        {
            toggleMany: toggleRowLoading,
            omit: omitRowLoading,
            check: checkLoadingRow,
        },
    ] = useLoadingRows();

    const { getModelFiltros, isEmptyFilters, savedFilter } =
        useFiltrosState('gerenciadorPrecos');

    const {
        state: quickActionFilters,
        handleToggleChangedPrice,
        handleSelectFilterLimits,
        handleSelectFilterLimitsClick,
        getQuickActionFilterModel,
        handleCleanQuickActionFlters,
        handleTogglePriceCPI,
        handleChageLastCpiDays,
        handleToggleQuickActionFilter,
        handleChangeMinDateCompetitorPriceSource,
    } = useQuickActionFilter();

    const {
        disabledCompetitors,
        handleDisableCompetitors,
        handleRemoveCompetitor,
        clearCompetitorsFromRow,
    } = useDisabledCompetitor();

    const {
        data: selectedDatapoints,
        handleCheck,
        handleCheckAll,
        handleSelectAllFromAllPages,
        handleUnselectAll,
        getSelectedDatapointModel,
        isToggleChecked,
        isSelectedDatapointsEmpty,
        handleUpdateSelectedDatapointValue,
        handleAddDatapoint,
        findDatapoint,
        resetSelectedDatapointsState,
    } = useSelectedDatapoints();

    const IS_EMPTY_FILTERS = isEmptyFilters({
        ...getModelFiltros(),
        ...getQuickActionFilterModel(),
    });

    const {
        isOpen: isPriceCompositionModalOpen,
        setIsOpen: setIsPriceCompositionModalOpen,
        handleOpen: handleOpenPriceCompositionModal,
        data: ipaPriceComposition,
    } = usePriceCompositionModal();

    const {
        isOpen: isCompositionRuleModalOpen,
        handleOpen: handleOpenCompositionRuleModal,
        handleClose: handleCloseCompositionRuleModal,
        data: ipaRuleComposition,
    } = useCompositionRuleModal();

    const [cpiFilter, setCpiFilter] = useState(
        quickActionFilters.minDateCompetitorPrice.value,
    );

    const { variationPriceData, variationPriceLoading, variationPriceRefetch } =
        useVariationPrice({
            ...getModelFiltros(),
            ...getQuickActionFilterModel(),
        });

    const { totalPriceData, totalPriceLoading, totalPriceRefetch } =
        useTotalPrice({
            ...getModelFiltros(),
            ...getQuickActionFilterModel(),
        });

    const { totalElements, content } = data;

    const [contextMenuDatapoint, setContextMenuDatapoint] =
        useState<null | RowData>(null);

    const {
        activePage,
        pageSize,
        lastValidPage,
        handleChangePage,
        handleChangeLength,
        isOnInvalidLastIndex,
    } = usePagination({
        initialState: { pageSize: 25 },
        totalElements,
    });

    const getItensRevisao = (search = '', pageNumber = null) => {
        const params = {
            ...getModelFiltros({
                sort,
                sortColumnsTypes,
                pageSize,
                pageNumber: pageNumber ? pageNumber - 1 : 0,
            }),
            ...getQuickActionFilterModel(),
        };
        setData({ content: [] });

        getDatapointsRevisao(search, params).then((response) => {
            if (response) {
                setData({
                    ...response,
                    content: response.content.map((item) => ({
                        ...item,
                        expirationDate: format(
                            addDays(new Date(), 1),
                            'yyyy-MM-dd',
                        ),
                        novaMargem: item.newMargin,
                        novaCompetitividade: item.competitorsPrice
                            ? (item.price / item.competitorsPrice) * 100
                            : null,
                        revenueForecastSevenDays: 13000,
                    })),
                });
            }
        });
        variationPriceRefetch();
        totalPriceRefetch();
    };

    function updateCompetitorData(productsToBePricedId, response) {
        setData((oldState) => ({
            ...oldState,
            content: data.content.map((item) => {
                if (item.productsToBePricedId === productsToBePricedId) {
                    return {
                        ...item,
                        competitorsInfo: response,
                    };
                }
                return item;
            }),
        }));
    }

    const getInfoCompetitorStores = ({
        storeId,
        productId,
        loadingArea,
        productFamilyId,
        competitorStoreId,
        productsToBePricedId,
    }) => {
        getCompetitorsInfo({
            productId,
            storeId,
            loadingArea,
            productFamilyId,
            competitorStoreId,
        }).then((response) => {
            if (response) {
                updateCompetitorData(productsToBePricedId, response);
            }
        });
    };

    const getInfoCompetitors = (competititorInfos) => {
        const {
            storeId,
            productId,
            productFamilyId,
            competitorsPrice,
            productsToBePricedId,
            productFamily,
        } = competititorInfos;

        if (competitorsPrice !== 0 || productFamilyId) {
            if (
                data.content.find(
                    (item) =>
                        item.productsToBePricedId === productsToBePricedId &&
                        item.competitorsInfo?.length > 0,
                )
            ) {
                return false;
            }

            return getCompetitorsInfo({
                productId,
                storeId,
                loadingArea: `competitor-row-${storeId}-${productId}`,
                productFamilyId: productFamily ? productFamilyId : null,
            }).then((response) => {
                if (response) {
                    updateCompetitorData(productsToBePricedId, response);
                }
            });
        }
        return null;
    };

    const handleSwitchOn = (store, rowData) => {
        const { storeId, productId, productsToBePricedId, productFamilyId } =
            rowData;
        const { competitorStoreId } = store;

        handleRemoveCompetitor({ productId, storeId, competitorStoreId });

        const updatedDisabledCompetitors = disabledCompetitors[
            `${productId}-${storeId}`
        ].filter((competitor) => competitor !== competitorStoreId);

        getInfoCompetitorStores({
            storeId,
            productId,
            productsToBePricedId,
            loadingArea: `competitor-row-${storeId}-${productId}`,
            productFamilyId,
            competitorStoreId: updatedDisabledCompetitors,
        });
    };

    const handleSwitchOff = (store, rowData) => {
        const {
            storeId,
            productId,
            productsToBePricedId,
            productFamilyId,
            productFamily,
        } = rowData;

        const { competitorStoreId } = store;

        handleDisableCompetitors({ productId, competitorStoreId, storeId });

        const updatedCompetitors = disabledCompetitors[
            `${productId}-${storeId}`
        ]
            ? [
                  ...disabledCompetitors[`${productId}-${storeId}`],
                  competitorStoreId,
              ]
            : competitorStoreId;

        return getInfoCompetitorStores({
            storeId,
            productId,
            productsToBePricedId,
            loadingArea: `competitor-row-${storeId}-${productId}`,
            productFamilyId: productFamily ? productFamilyId : null,
            competitorStoreId: updatedCompetitors,
        });
    };

    const handleUpdateCampo = (item, index, campo, value) => {
        const newValue = parseFloat(value.replace('.', '').replace(',', '.'));
        const dataContent = data?.content;

        if (newValue !== 0) {
            if (campo === 'novoPreco') {
                dataContent[index] = {
                    ...dataContent[index],
                    price: newValue,
                    novaMargem:
                        ((newValue -
                            newValue *
                                ((item.salesTaxPercentage +
                                    item.expensesPercent) /
                                    100) -
                            item.cost) /
                            newValue) *
                        100,
                    novaCompetitividade:
                        (newValue / item.competitorsPrice) * 100,
                    novoCalculo: true,
                    priceType: 'EDITADO',
                };
            }

            if (campo === 'novaMargem') {
                const price = item.cost;
                const newPrice =
                    (price /
                        (100 -
                            newValue -
                            (item.expensesPercent + item.salesTaxPercentage))) *
                    100;
                dataContent[index] = {
                    ...dataContent[index],
                    price: newPrice < 0 ? null : newPrice,
                    novaMargem: newValue,
                    novaCompetitividade:
                        (newPrice / item.competitorsPrice) * 100,
                    novoCalculo: true,
                    priceType: 'EDITADO',
                };
            }

            if (campo === 'novaCompetitividade') {
                const newPrice = (newValue / 100) * item.competitorsPrice;
                dataContent[index] = {
                    ...dataContent[index],
                    price: newPrice,
                    novaMargem: ((newPrice - item.pmz) / newPrice) * 100,
                    novaCompetitividade: newValue,
                    novoCalculo: true,
                    priceType: 'EDITADO',
                };
            }

            setData((oldState) => ({ ...oldState, content: dataContent }));

            const { productId, storeId, price, productsToBePricedId } =
                dataContent[index];

            if (!findDatapoint(productsToBePricedId, 'productsUnSelect')) {
                const datapoint = {
                    productId,
                    storeId,
                    price,
                    productsToBePricedId,
                };

                if (selectedDatapoints.selectedAllProducts) {
                    handleAddDatapoint(datapoint);
                    handleUpdateSelectedDatapointValue(
                        productsToBePricedId,
                        datapoint,
                    );
                }
                handleUpdateSelectedDatapointValue(
                    productsToBePricedId,
                    datapoint,
                );
            }
        } else {
            dataContent[index] = {
                ...dataContent[index],
                price: newValue,
                novaMargem: 0,
                novaCompetitividade: 0,
            };

            setData((oldState) => ({ ...oldState, content: dataContent }));
        }
    };

    const handleCalculoCompetitiviness = (
        newPriceCompetitiviness,
        rowData,
        competitivenessIndicator,
    ) => {
        const dataContent = data.content;
        const newCompetitividade =
            (rowData.price / newPriceCompetitiviness) * 100;
        const indexPricecompetitiviness = dataContent.findIndex(
            (index) =>
                index.productsToBePricedId === rowData.productsToBePricedId,
        );
        dataContent[indexPricecompetitiviness] = {
            ...dataContent[indexPricecompetitiviness],
            novaCompetitividade: newCompetitividade,
            competitivenessIndicator,
            competitorsPrice: newPriceCompetitiviness,
        };
        setData((oldState) => ({ ...oldState, content: dataContent }));
    };

    const handleCalculoPreco = (
        newPriceCompetitiviness,
        rowData,
        competitivenessIndicator,
    ) => {
        const dataContent = data.content;
        const newCompetitividade =
            (newPriceCompetitiviness / newPriceCompetitiviness) * 100;
        const newMargem =
            ((newPriceCompetitiviness -
                newPriceCompetitiviness *
                    ((rowData.salesTaxPercentage + rowData.expensesPercent) /
                        100) -
                rowData.cost) /
                newPriceCompetitiviness) *
            100;

        const indexPricecompetitiviness = dataContent.findIndex(
            (item) =>
                item.productsToBePricedId === rowData.productsToBePricedId,
        );

        dataContent[indexPricecompetitiviness] = {
            ...dataContent[indexPricecompetitiviness],
            novaCompetitividade: newCompetitividade,
            novaMargem: newMargem,
            competitivenessIndicator,
            competitorsPrice: newPriceCompetitiviness,
            price: newPriceCompetitiviness,
            novoCalculo: true,
        };
        setData((oldState) => ({ ...oldState, content: dataContent }));
    };

    const handleSalvarSimulacao = async () => {
        const dataContent = data?.content;
        const dataSimulacao = {
            product: {
                id: dataContent[previsaoSelecionada].productId,
                description: dataContent[previsaoSelecionada].description,
                store: {
                    id: dataContent[previsaoSelecionada].storeId,
                    description: dataContent[previsaoSelecionada].storeName,
                },
            },
            ...dataContent[previsaoSelecionada].demandForecast,
        };

        const resp = await salvarSimulacao(dataSimulacao);
        if (resp.status === 201) {
            Notification.success({
                title: 'Notificação',
                duration: 6000,
                description: (
                    <div>
                        <p>Simulação salva com sucesso.</p>
                    </div>
                ),
            });

            setShowModalPrevisaoDemanda(false);
        }
    };

    const handleExpanded = (rowData) => {
        let open = false;
        const nextExpandedRowKeys = [];

        expandedRowKeys.forEach((key) => {
            if (key === rowData[rowKey]) {
                open = true;
            } else {
                nextExpandedRowKeys.push(key);
            }
        });
        if (open) {
            clearCompetitorsFromRow(rowData.productId, rowData.storeId);
        }
        if (!open) {
            nextExpandedRowKeys.push(rowData[rowKey]);
            getInfoCompetitors(rowData);
        }

        setExpandedRowKeys(nextExpandedRowKeys);
    };

    const handleSortColumn = (sortColumn, sortType) => {
        setSort({
            type: sortColumn === 'retailPrice' ? 'priceRetail' : sortColumn,
            orderBy: sortType,
        });
    };

    const handleOpenModalPrevisao = (previsao, index) => {
        setPrevisao(previsao);
        setPrevisaoSelecionada(index);
        setShowModalPrevisaoDemanda(!showModalPrevisaoDemanda);
    };

    const confirmarRevisao = (applyToFamily) => {
        let model = getSelectedDatapointModel();

        if (selectedDatapoints.selectedAllProducts) {
            const filters = getValidData({
                ...getModelFiltros({}),
                ...getQuickActionFilterModel(),
            });
            model = getSelectedDatapointModel(filters);
        }

        savePrecificacaoBulk(model, applyToFamily)
            .then((response) => {
                if (response.status === 200) {
                    Alert.success('Precificação salva com sucesso!');
                }
            })
            .catch((err) => {
                Alert.error(err.response.data._embedded.errors[0].message);
            })
            .finally(() => {
                getItensRevisao();
                handleUnselectAll();
            });
    };

    const handleDatakeysTable = (sortColumn, sortType) => {
        if (sortColumn) {
            setIsDataKeyProtuto(sortColumn);
        }
        handleSortColumn(sortColumn, sortType);
    };

    const calcularRevisao = async (item, index) => {
        const dataContent = data?.content;

        dataContent[index] = {
            ...dataContent[index],
            loadingCalc: true,
        };

        setData((oldState) => ({ ...oldState, content: dataContent }));

        const response = await novoCalculoPrevisao(
            item.retailPrice,
            item.price,
            item.storeId,
            item.productId,
            item.pmz,
        );

        if (response?.data) {
            dataContent[index] = {
                ...dataContent[index],
                novoCalculo: false,
                loadingCalc: false,
                demandForecast: response.data,
            };
        } else {
            dataContent[index] = {
                ...dataContent[index],
                loadingCalc: false,
            };
        }

        setData((oldState) => ({ ...oldState, content: dataContent }));
    };

    const goToPrevisao = () => {
        const item = data?.content[previsaoSelecionada];

        history.push(
            `/ipa/simulacoes-analises/previsao-demanda?productId=${item.productId}&storeId=${item.storeId}&price=${item.price}`,
        );
    };

    function getSelectLimitsSkin() {
        let skin = 'neutral';
        if (quickActionFilters.brokenLimit.isActive) {
            if (quickActionFilters.brokenLimit.value === true) {
                skin = 'error';
            } else if (quickActionFilters.brokenLimit.value === false) {
                skin = 'primary';
            }
        }
        return skin;
    }

    function getPriceCpiSkin() {
        let skin = 'neutral';
        if (quickActionFilters.competitorPrices.isActive) {
            skin = 'primary';
        }
        if (quickActionFilters.minDateCompetitorPrice.isActive) {
            skin = 'primary';
        }
        return skin;
    }

    const QUICK_ACTION_FILTERS = [
        {
            id: 'PRICE_CPI',
            placeholder: 'Filtrar preços concorrentes',
            type: 'selectInterval',
            icon: 'MdStore',
            prefixLabel: 'Filtrar preço concorrente dos últimos',
            unitLabel: 'dias',
            menuStyle: { width: '300px' },
            onClick: handleTogglePriceCPI,
            onClose: () => handleChageLastCpiDays(cpiFilter),
            onChange: setCpiFilter,
            maxValue: 7,
            defaultValue: quickActionFilters.minDateCompetitorPrice.value,
            skin: getPriceCpiSkin(),
            competitorPriceSources: {
                value: quickActionFilters.minDateCompetitorPrice
                    .competitorPriceSources,
                onChange: handleChangeMinDateCompetitorPriceSource,
            },
        },
        {
            id: 'CHANGED_PRICE',
            placeholder: 'Somente preços alterados',
            type: 'button',
            icon: 'MdPriceChange',
            onClick: handleToggleChangedPrice,
            skin: quickActionFilters.changedPrices.isActive
                ? 'primary'
                : 'neutral',
        },
        {
            id: 'SELECT_LIMITS',
            placeholder: 'Filtrar limites',
            type: 'select',
            icon: 'MdReport',
            skin: getSelectLimitsSkin(),
            options: [
                {
                    id: 'BROKEN_LIMITS',
                    value: true,
                    label: 'Somente limites quebrados',
                },
                {
                    id: 'UNBROKEN_LIMITS',
                    value: false,
                    label: 'Somente limites não quebrados',
                },
            ],
            onSelect: handleSelectFilterLimits,
            onClick: handleSelectFilterLimitsClick,
        },
        {
            id: 'DERIVED_PRODUCTS',
            placeholder: 'Filtrar produtos derivados',
            type: 'button',
            icon: <MdStars />,
            onClick: () => {
                if (quickActionFilters.isProductMaster.isActive) {
                    setSort(initialSort);
                } else {
                    setSort({ type: 'productFamilyMaster', orderBy: 'desc' });
                }
                handleToggleQuickActionFilter('isProductMaster');
            },
            skin: quickActionFilters.isProductMaster.isActive
                ? 'primary'
                : 'neutral',
        },
    ];

    const totalProducts = (() => {
        let total = data.totalElements ?? 0;
        if (selectedDatapoints.selectedAllProducts) {
            total -= selectedDatapoints.productsUnSelect.value.length;
        } else if (
            !selectedDatapoints.selectedAllProducts &&
            selectedDatapoints.productsSelect.value.length > 0
        ) {
            total = selectedDatapoints.productsSelect.value.length;
        }
        return total;
    })();

    const handleBlurSuccess = React.useCallback(
        (children: SaveEditedDatapoint['response']['data']) => {
            const dataContent = content;

            children.forEach((child) => {
                const index = dataContent.findIndex((datapoint) => {
                    return (
                        datapoint.productsToBePricedId ===
                        child.productsToBePricedId
                    );
                });

                if (index === -1) return;

                dataContent[index] = {
                    ...dataContent[index],
                    price: child.price,
                    novaMargem: child.newMargin,
                    novaCompetitividade: child.newCompetitivenessPrice,
                    priceType: child.priceType,
                };
            });

            setData((oldValue) => ({
                ...oldValue,
                content: dataContent,
            }));
        },
        [content, setData],
    );

    const handleBlur = React.useCallback(
        async ({
            novoCalculo,
            productId,
            storeId,
            price,
            productsToBePricedId,
            isProductMaster,
            productFamilyId,
        }: RowData) => {
            if (!novoCalculo) return;

            const childrenData: RowData[] = [];

            const childrenIds = [];

            if (isProductMaster && productFamilyId) {
                for (let i = 0; i < content.length; i += 1) {
                    const item = content[i];
                    if (
                        item.productFamilyId === productFamilyId &&
                        item.storeId === storeId
                    ) {
                        childrenData.push(item);
                        childrenIds.push(item.productsToBePricedId);
                    }
                }
            } else {
                childrenIds.push(productsToBePricedId);
            }

            try {
                toggleRowLoading(childrenIds as string[], true);

                const res = await saveEditedDatapoint({
                    productId,
                    storeId,
                    price,
                    productsToBePricedId,
                });

                if (res.status !== 200) return;

                setUpdateBigNumbers(true);

                if (!res.data?.length) return;

                handleBlurSuccess(res.data);
            } catch {
                Alert.error('Erro ao salvar alteração de preço');
            } finally {
                omitRowLoading(childrenIds as string[]);
            }
        },
        [
            content,
            handleBlurSuccess,
            saveEditedDatapoint,
            toggleRowLoading,
            omitRowLoading,
            setUpdateBigNumbers,
        ],
    );

    function handleUndoEditedDatapoint({
        productsToBePricedId,
        productId,
        storeId,
    }) {
        undoEditedDatapoint({
            productId,
            storeId,
            productsToBePricedId,
        }).then((response) => {
            if (response.status !== 200) return;

            const dataContent = data.content;
            const index = dataContent.findIndex(
                (datapoint) =>
                    datapoint.productsToBePricedId === productsToBePricedId,
            );

            dataContent[index] = {
                ...dataContent[index],
                ...response.data,
                price: response.data.price,
                novaMargem: response.data.newMargin,
                novaCompetitividade: response.data.competitorsPrice
                    ? (response.data.price / response.data.competitorsPrice) *
                      100
                    : null,
            };

            setData((oldValue) => ({
                ...oldValue,
                content: dataContent,
            }));

            Alert.success('Alteração de preço desfeita com sucesso');
        });
    }

    const handleUpdateBigNumbers = () => {
        setUpdateBigNumbers(false);
        variationPriceRefetch();
        totalPriceRefetch();
    };

    useEffect(() => {
        searchSegmentos();
    }, []);

    useEffect(() => {
        getItensRevisao('', isOnInvalidLastIndex ? lastValidPage : activePage);
    }, [filtros, sort, quickActionFilters, activePage, pageSize]);

    const { salvarFiltros, editarFiltros, excluirFiltroSalvo } =
        useSalvarFiltros({
            ...getModelFiltros({}),
            ...getQuickActionFilterModel(),
        });

    const handleLimparFiltros = () => {
        handleCleanQuickActionFlters();
        handleUnselectAll();
        dispatch(resetFilters());
        dispatch(resetSavedFilter());
    };

    const handleInputFOcus = (e) => e.target.select();

    const { data: engineStatusData } = useDataUltimaIntegracao();

    const tableContainer = document.getElementById('table-revisao-precos');

    useEffect(() => {
        if (tableContainer) {
            tableContainer.addEventListener(
                'keydown',
                handleMoveFocusToNextColumnInput,
            );
        }
        return () => {
            if (tableContainer) {
                tableContainer.removeEventListener(
                    'keydown',
                    handleMoveFocusToNextColumnInput,
                );
            }
        };
    }, [tableContainer]);

    const handleChangeMassivePrice = async (formData: AlterarPrecosSchema) => {
        const filters = {
            ...getModelFiltros({
                sort,
                sortColumnsTypes,
                pageSize,
                pageNumber:
                    (isOnInvalidLastIndex ? lastValidPage : activePage) - 1,
            }),
            ...getQuickActionFilterModel(),
        } as ChangeMassicePrice['filters'];

        const model: ChangeMassicePrice = {
            ...formData,
            filters,
            products: {
                selectedIds: selectedDatapoints.productsSelect.value,
                excludedIds: selectedDatapoints.productsUnSelect.value,
            },
            selectedAll: selectedDatapoints.selectedAllProducts,
        };

        try {
            const data = await changeMassivePrice(model);

            if (data) {
                onCloseModal('alterar-precos');

                resetSelectedDatapointsState();

                getItensRevisao();

                if (data.productsFailed) {
                    Alert.error(
                        `Houve uma falha na aplicação de preços de ${data.productsFailed} produtos. Os demais foram aplicados normalmente.`,
                        9000,
                    );
                    return;
                }

                Notification.success({
                    description: 'Preços alterados!',
                });
            }
        } catch (err) {
            if (axios.isAxiosError(err)) {
                throw new Error('Erro durante a alteração de preços');
            }
        }
    };

    return (
        <>
            <Layout
                className={classNames(
                    styles['gerenciador-page'],
                    'revisao-page',
                )}
            >
                <Layout.Header>
                    <Layout.Title>Gerenciador de preços</Layout.Title>
                    <DataUltimaIntegracao />
                    <Layout.Subtitle>Revisar todos os produtos</Layout.Subtitle>
                </Layout.Header>
                <Layout.Body>
                    <Layout.Section>
                        <EngineWarning
                            variant={engineStatusData?.engineStatus}
                        />
                    </Layout.Section>
                    <Layout.Section>
                        <BigNumbersArea
                            variationPriceData={variationPriceData}
                            totalPriceData={totalPriceData}
                            variationLoading={variationPriceLoading}
                            totalLoading={totalPriceLoading}
                            update={updateBigNumbers}
                            onClickUpdate={handleUpdateBigNumbers}
                            className={styles['gerenciador-page__big-numbers']}
                        />
                    </Layout.Section>
                    <Layout.Section padded>
                        <GlobalContext.Provider value={filtros}>
                            <NewFilterBox
                                handleClearFilters={handleLimparFiltros}
                                isEmpty={IS_EMPTY_FILTERS}
                            />
                        </GlobalContext.Provider>
                    </Layout.Section>
                    <Layout.Section>
                        <TableHeading isSelected={!isSelectedDatapointsEmpty}>
                            <TableHeading.Container>
                                <TableHeading.TotalProducts
                                    total={totalProducts}
                                />
                                {(!IS_EMPTY_FILTERS ||
                                    selectedDatapoints.selectedAllProducts) && (
                                    <LinkButton
                                        className="aplicar-precos-container__link-button"
                                        onClick={
                                            !selectedDatapoints.selectedAllProducts
                                                ? () => {
                                                      handleSelectAllFromAllPages(
                                                          data.content,
                                                      );
                                                  }
                                                : handleUnselectAll
                                        }
                                    >
                                        {selectedDatapoints.selectedAllProducts
                                            ? 'Limpar seleção'
                                            : 'Selecionar tudo'}
                                    </LinkButton>
                                )}
                            </TableHeading.Container>
                            <TableHeading.Container>
                                {isSelectedDatapointsEmpty && (
                                    <QuickActionFilter>
                                        {QUICK_ACTION_FILTERS.map(
                                            ({ type, id, ...filter }) => {
                                                switch (type) {
                                                    case 'select':
                                                        return (
                                                            <QuickActionFilter.Select
                                                                key={id}
                                                                {...filter}
                                                            />
                                                        );
                                                    case 'selectInterval':
                                                        return (
                                                            <QuickActionFilter.SelectInterval
                                                                key={id}
                                                                {...filter}
                                                            />
                                                        );
                                                    default:
                                                        return (
                                                            <QuickActionFilter.Button
                                                                key={id}
                                                                {...filter}
                                                            />
                                                        );
                                                }
                                            },
                                        )}
                                    </QuickActionFilter>
                                )}
                                {!isSelectedDatapointsEmpty && (
                                    <ButtonPrimary
                                        id="alterar-precos"
                                        theme="ghost"
                                        skin="blue"
                                        onClick={() =>
                                            onOpenModal('alterar-precos')
                                        }
                                    >
                                        ALTERAR PREÇO
                                    </ButtonPrimary>
                                )}

                                <ButtonDropdown
                                    disabled={
                                        (!selectedDatapoints.selectedAllProducts &&
                                            !selectedDatapoints.productsSelect
                                                .value?.length) ||
                                        promiseInProgress
                                    }
                                    onClick={() => confirmarRevisao(false)}
                                    onSelectOption={(itemId) => {
                                        if (itemId === 'APPLY_TO_FAMILY')
                                            confirmarRevisao(true);
                                    }}
                                    skin="blue"
                                    options={[
                                        {
                                            id: 'APPLY_TO_FAMILY',
                                            label: 'Aplicar na Família',
                                            value: 'APPLY_TO_FAMILY',
                                            disabled:
                                                selectedDatapoints.selectedAllProducts ||
                                                selectedDatapoints
                                                    .productsSelect.value
                                                    ?.length !== 1 ||
                                                content.find(
                                                    (item) =>
                                                        item.productsToBePricedId ===
                                                        selectedDatapoints
                                                            .productsSelect
                                                            .value[0],
                                                )?.productFamilyId === null ||
                                                promiseInProgress,
                                            placeholder:
                                                selectedDatapoints.selectedAllProducts ||
                                                selectedDatapoints
                                                    .productsSelect.value
                                                    ?.length !== 1
                                                    ? 'Selecione apeans um produto para aplicar preços a família'
                                                    : content.find(
                                                            (item) =>
                                                                item.productsToBePricedId ===
                                                                selectedDatapoints
                                                                    .productsSelect
                                                                    .value[0],
                                                        )?.productFamilyId ===
                                                        null
                                                      ? 'O produto selecionado não possui código de família.'
                                                      : 'Não é possível aplicar o preço a família.',
                                        },
                                    ]}
                                >
                                    Aplicar preço
                                </ButtonDropdown>
                            </TableHeading.Container>
                        </TableHeading>
                    </Layout.Section>
                    <Layout.Section>
                        <div style={{ height: 0 }}>
                            <DropDownOptions
                                styleClass="dropdown-option-revisao"
                                title={<TitleDropDownOptions />}
                            >
                                <RadioOptionColumns
                                    sortColumn={isDataKeyProtuto}
                                    sort={handleDatakeysTable}
                                />
                            </DropDownOptions>
                        </div>
                        <ContextMenu>
                            <ContextMenuTrigger>
                                <Table
                                    data={data.content}
                                    autoHeight
                                    id="table-revisao-precos"
                                    headerHeight={46}
                                    rowHeight={62}
                                    minHeight={600}
                                    className={classNames(
                                        'table',
                                        styles['gerenciador-page__table'],
                                    )}
                                    expandedRowKeys={expandedRowKeys}
                                    rowKey={rowKey}
                                    rowClassName={classNames(
                                        'revisao-row',
                                        styles['gerenciador-page__table__row'],
                                    )}
                                    rowExpandedHeight={200}
                                    sortColumn={sort.type}
                                    sortType={sort.orderBy}
                                    onSortColumn={handleSortColumn}
                                    loading={isLoading}
                                    virtualized
                                    renderRowExpanded={(rowData) => (
                                        <RowExpanded
                                            rowData={rowData}
                                            handleCalculoPreco={
                                                handleCalculoPreco
                                            }
                                            handleCalculoCompetitiviness={
                                                handleCalculoCompetitiviness
                                            }
                                            onToggleCompetitor={(
                                                checked,
                                                competitor,
                                            ) => {
                                                return checked
                                                    ? handleSwitchOn(
                                                          competitor,
                                                          rowData,
                                                      )
                                                    : handleSwitchOff(
                                                          competitor,
                                                          rowData,
                                                      );
                                            }}
                                        />
                                    )}
                                >
                                    <Column align="center" width={60}>
                                        <HeaderCell>
                                            <Checkbox
                                                checked={
                                                    !!(
                                                        selectedDatapoints
                                                            .productsSelect
                                                            .value.length ===
                                                            data.content
                                                                ?.length &&
                                                        selectedDatapoints
                                                            .productsSelect
                                                            .value.length !== 0
                                                    ) ||
                                                    selectedDatapoints.selectedAllProducts
                                                }
                                                indeterminate={
                                                    (selectedDatapoints
                                                        .productsSelect.value
                                                        .length > 0 &&
                                                        selectedDatapoints
                                                            .productsSelect
                                                            .value.length <
                                                            data.content
                                                                ?.length) ||
                                                    selectedDatapoints
                                                        .productsUnSelect.value
                                                        .length > 0
                                                }
                                                onChange={(_, checked) => {
                                                    handleCheckAll({
                                                        checked,
                                                        content: data.content,
                                                    });
                                                }}
                                            />
                                        </HeaderCell>
                                        <Cell>
                                            {(rowData: RowData) => (
                                                <BaseCell
                                                    className={
                                                        styles[
                                                            'gerenciador-page__check-cell'
                                                        ]
                                                    }
                                                    onContextMenu={() => {
                                                        setContextMenuDatapoint(
                                                            rowData,
                                                        );
                                                    }}
                                                >
                                                    <Checkbox
                                                        value={
                                                            rowData.productsToBePricedId
                                                        }
                                                        onChange={(
                                                            value,
                                                            checked,
                                                        ) => {
                                                            handleCheck(
                                                                value,
                                                                checked,
                                                                rowData,
                                                            );
                                                        }}
                                                        checked={isToggleChecked(
                                                            rowData,
                                                        )}
                                                    />
                                                </BaseCell>
                                            )}
                                        </Cell>
                                    </Column>
                                    <Column sortable flexGrow={2}>
                                        <HeaderCell
                                            className={classNames(
                                                'product-dropdown',
                                                styles[
                                                    'gerenciador-page__table__header-cell'
                                                ],
                                            )}
                                            style={{
                                                paddingLeft: 14,
                                            }}
                                        >
                                            Produto
                                        </HeaderCell>
                                        <Cell dataKey={isDataKeyProtuto}>
                                            {(rowData: any) => (
                                                <div
                                                    onContextMenu={() => {
                                                        setContextMenuDatapoint(
                                                            rowData,
                                                        );
                                                    }}
                                                >
                                                    <ProductCell
                                                        product={{
                                                            productId:
                                                                rowData.productId,
                                                            description:
                                                                rowData.description,
                                                            productFamilyId:
                                                                rowData.productFamilyId,
                                                            isProductMaster:
                                                                rowData.isProductMaster,
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </Cell>
                                    </Column>
                                    <Column sortable width={80}>
                                        <HeaderCell
                                            className={
                                                styles[
                                                    'gerenciador-page__table__header-cell'
                                                ]
                                            }
                                        >
                                            Loja
                                        </HeaderCell>
                                        <Cell dataKey="storeId">
                                            {(rowData: RowData) => (
                                                <BaseCell
                                                    onContextMenu={() => {
                                                        setContextMenuDatapoint(
                                                            rowData,
                                                        );
                                                    }}
                                                >
                                                    {rowData.storeName ? (
                                                        <Whisper
                                                            delayShow={0.2}
                                                            trigger="hover"
                                                            placement="right"
                                                            speaker={
                                                                <Tooltip>
                                                                    {
                                                                        rowData.storeName
                                                                    }
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <span className="font-size-100-semibold">
                                                                {
                                                                    rowData.storeId
                                                                }
                                                            </span>
                                                        </Whisper>
                                                    ) : null}
                                                </BaseCell>
                                            )}
                                        </Cell>
                                    </Column>
                                    <Column sortable minWidth={78}>
                                        <HeaderCell
                                            className={
                                                styles[
                                                    'gerenciador-page__table__header-cell'
                                                ]
                                            }
                                        >
                                            PMZ
                                        </HeaderCell>
                                        <Cell dataKey="pmz">
                                            {(rowData) => (
                                                <BaseCell
                                                    onContextMenu={() => {
                                                        setContextMenuDatapoint(
                                                            rowData,
                                                        );
                                                    }}
                                                >
                                                    {rowData.pmz ? (
                                                        <CurrencyFormat
                                                            fixedDecimalScale
                                                            decimalScale={2}
                                                            value={rowData?.pmz}
                                                            displayType="text"
                                                            prefix="R$"
                                                            decimalSeparator=","
                                                            thousandSeparator="."
                                                            style={{
                                                                fontWeight: 600,
                                                            }}
                                                        />
                                                    ) : (
                                                        '--'
                                                    )}
                                                </BaseCell>
                                            )}
                                        </Cell>
                                    </Column>
                                    <Column sortable minWidth={78}>
                                        <HeaderCell
                                            className={
                                                styles[
                                                    'gerenciador-page__table__header-cell'
                                                ]
                                            }
                                        >
                                            <div>
                                                <p>Margem</p>
                                                <p>Objetiva</p>
                                            </div>
                                        </HeaderCell>
                                        <Cell dataKey="objectiveMargin">
                                            {(rowData: any) => {
                                                const isDisruptive =
                                                    rowData.novaMargem <
                                                    rowData.objectiveMargin;
                                                return (
                                                    <BaseCell
                                                        onContextMenu={() => {
                                                            setContextMenuDatapoint(
                                                                rowData,
                                                            );
                                                        }}
                                                    >
                                                        {rowData.objectiveMargin ? (
                                                            <div
                                                                className={
                                                                    styles[
                                                                        'table__cell__margem-objetiva'
                                                                    ]
                                                                }
                                                            >
                                                                <MdFlag
                                                                    width={11}
                                                                    className={classNames(
                                                                        isDisruptive &&
                                                                            'color-disruptive',
                                                                    )}
                                                                />
                                                                <CurrencyFormat
                                                                    fixedDecimalScale
                                                                    decimalScale={
                                                                        1
                                                                    }
                                                                    value={
                                                                        rowData?.objectiveMargin
                                                                    }
                                                                    displayType="text"
                                                                    suffix="%"
                                                                    decimalSeparator=","
                                                                    className={classNames(
                                                                        'font-size-100-semibold',
                                                                        isDisruptive &&
                                                                            'color-disruptive',
                                                                    )}
                                                                />
                                                            </div>
                                                        ) : (
                                                            '--'
                                                        )}
                                                    </BaseCell>
                                                );
                                            }}
                                        </Cell>
                                    </Column>
                                    <Column sortable minWidth={124}>
                                        <HeaderCell
                                            className={
                                                styles[
                                                    'gerenciador-page__table__header-cell'
                                                ]
                                            }
                                        >
                                            <div>
                                                <p>Preço</p>
                                                <p>vigente</p>
                                            </div>
                                        </HeaderCell>
                                        <Cell dataKey="priceRetail">
                                            {(rowData: any) => (
                                                <PrecoVigenteCell
                                                    onContextMenu={() => {
                                                        setContextMenuDatapoint(
                                                            rowData,
                                                        );
                                                    }}
                                                    rowData={rowData}
                                                />
                                            )}
                                        </Cell>
                                    </Column>
                                    <Column sortable width={127}>
                                        <HeaderCell
                                            className={
                                                styles[
                                                    'gerenciador-page__table__header-cell'
                                                ]
                                            }
                                        >
                                            <div>
                                                <p>Preço</p>
                                                <p>concorrente</p>
                                            </div>{' '}
                                        </HeaderCell>
                                        <Cell dataKey="competitorsPrice">
                                            {(rowData: RowData) => (
                                                <CompetitorPriceCell
                                                    onContextMenu={() => {
                                                        setContextMenuDatapoint(
                                                            rowData,
                                                        );
                                                    }}
                                                    competitorsPrice={
                                                        rowData?.competitorsPrice
                                                    }
                                                    competitivenessIndicator={
                                                        rowData?.competitivenessIndicator
                                                    }
                                                    onOpenRow={() => {
                                                        handleExpanded(rowData);
                                                    }}
                                                    isOpen={expandedRowKeys.includes(
                                                        rowData.productsToBePricedId,
                                                    )}
                                                />
                                            )}
                                        </Cell>
                                    </Column>
                                    <Column sortable width={132}>
                                        <HeaderCell
                                            className={
                                                styles[
                                                    'gerenciador-page__table__header-cell'
                                                ]
                                            }
                                        >
                                            Preço sugerido
                                        </HeaderCell>
                                        <Cell
                                            className="table__input-group"
                                            dataKey="price"
                                        >
                                            {(
                                                rowData: RowData,
                                                index: number,
                                            ) => (
                                                <PrecoSugeridoCell
                                                    name="novoPreco"
                                                    value={rowData.price}
                                                    rowData={rowData}
                                                    onBlur={() => {
                                                        handleBlur(rowData);
                                                    }}
                                                    onFocus={handleInputFOcus}
                                                    onChangeEvent={(
                                                        _,
                                                        value,
                                                    ) => {
                                                        handleUpdateCampo(
                                                            rowData,
                                                            index,
                                                            'novoPreco',
                                                            value,
                                                        );
                                                    }}
                                                />
                                            )}
                                        </Cell>
                                    </Column>
                                    <Column sortable width={116}>
                                        <HeaderCell
                                            className={
                                                styles[
                                                    'gerenciador-page__table__header-cell'
                                                ]
                                            }
                                        >
                                            <HoverNomeColuna
                                                placement="bottom"
                                                text="CPI"
                                                tooltip="Competitividade"
                                            />
                                        </HeaderCell>
                                        <Cell
                                            className="table__input-group"
                                            dataKey="cpi"
                                        >
                                            {(
                                                rowData: RowData,
                                                index: number,
                                            ) => (
                                                <BaseCell
                                                    onContextMenu={() => {
                                                        setContextMenuDatapoint(
                                                            rowData,
                                                        );
                                                    }}
                                                >
                                                    <BaseCell.MainContainer>
                                                        <InputGroup>
                                                            <InputCurrency
                                                                skin={
                                                                    !!rowData?.competitorsPrice &&
                                                                    rowData?.competitorsPrice >
                                                                        0 &&
                                                                    ((rowData?.maxCpi &&
                                                                        rowData?.novaCompetitividade >
                                                                            rowData?.maxCpi *
                                                                                100) ||
                                                                        (rowData?.minCpi &&
                                                                            rowData?.novaCompetitividade <
                                                                                rowData?.minCpi *
                                                                                    100))
                                                                        ? 'red'
                                                                        : 'gray'
                                                                }
                                                                className="nova-competitividade"
                                                                disabled={
                                                                    !rowData.competitorsPrice
                                                                }
                                                                name="novaCompetitividade"
                                                                decimalSeparator=","
                                                                thousandSeparator="."
                                                                precision="2"
                                                                allowEmpty
                                                                allowNegative={
                                                                    false
                                                                }
                                                                onChangeEvent={(
                                                                    _,
                                                                    value,
                                                                ) => {
                                                                    handleUpdateCampo(
                                                                        rowData,
                                                                        index,
                                                                        'novaCompetitividade',
                                                                        value,
                                                                    );
                                                                }}
                                                                value={
                                                                    rowData.novaCompetitividade
                                                                        ? rowData.novaCompetitividade
                                                                        : 0
                                                                }
                                                                onBlur={() => {
                                                                    handleBlur(
                                                                        rowData,
                                                                    );
                                                                }}
                                                                onFocus={
                                                                    handleInputFOcus
                                                                }
                                                            />
                                                            <Whisper
                                                                trigger="click"
                                                                placement="bottomEnd"
                                                                speaker={
                                                                    <CompetitivenessLimitInfo
                                                                        content={
                                                                            rowData
                                                                        }
                                                                        margin={
                                                                            rowData.novaMargem
                                                                        }
                                                                        cpi={
                                                                            rowData.novaCompetitividade
                                                                        }
                                                                    />
                                                                }
                                                            >
                                                                <InputAddon>
                                                                    %
                                                                </InputAddon>
                                                            </Whisper>
                                                        </InputGroup>
                                                    </BaseCell.MainContainer>

                                                    <BaseCell.SubContainer>
                                                        <VariationBoxIndicator
                                                            positiveValueIsGood={
                                                                false
                                                            }
                                                            className="box-indicator"
                                                            value={
                                                                (((rowData.price ??
                                                                    0) /
                                                                    rowData.competitorsPrice) as number) *
                                                                    100 -
                                                                ((rowData.retailPrice as number) /
                                                                    (rowData.competitorsPrice as number)) *
                                                                    100
                                                            }
                                                        />
                                                    </BaseCell.SubContainer>
                                                </BaseCell>
                                            )}
                                        </Cell>
                                    </Column>
                                    <Column sortable width={116}>
                                        <HeaderCell
                                            className={
                                                styles[
                                                    'gerenciador-page__table__header-cell'
                                                ]
                                            }
                                        >
                                            Margem
                                        </HeaderCell>
                                        <Cell
                                            className="table__input-group"
                                            dataKey="newMargin"
                                        >
                                            {(
                                                rowData: RowData,
                                                index: number,
                                            ) => (
                                                <BaseCell
                                                    onContextMenu={() => {
                                                        setContextMenuDatapoint(
                                                            rowData,
                                                        );
                                                    }}
                                                >
                                                    <BaseCell.MainContainer>
                                                        <InputGroup>
                                                            <InputCurrency
                                                                className="nova-margem"
                                                                skin={
                                                                    (rowData.price &&
                                                                        rowData?.maxMargin &&
                                                                        rowData?.novaMargem >=
                                                                            rowData?.maxMargin *
                                                                                100) ||
                                                                    (rowData.price &&
                                                                        rowData?.minMargin &&
                                                                        rowData?.novaMargem <=
                                                                            rowData?.minMargin *
                                                                                100)
                                                                        ? 'red'
                                                                        : 'gray'
                                                                }
                                                                name="novaMargem"
                                                                decimalSeparator=","
                                                                thousandSeparator="."
                                                                precision="2"
                                                                allowEmpty
                                                                allowNegative
                                                                onChangeEvent={(
                                                                    _,
                                                                    value,
                                                                ) => {
                                                                    handleUpdateCampo(
                                                                        rowData,
                                                                        index,
                                                                        'novaMargem',
                                                                        value,
                                                                    );
                                                                }}
                                                                value={
                                                                    rowData.novaMargem
                                                                }
                                                                onBlur={() => {
                                                                    handleBlur(
                                                                        rowData,
                                                                    );
                                                                }}
                                                                onFocus={
                                                                    handleInputFOcus
                                                                }
                                                            />
                                                            <Whisper
                                                                trigger="click"
                                                                placement="bottomEnd"
                                                                speaker={
                                                                    <MarginLimitInfo
                                                                        content={
                                                                            rowData
                                                                        }
                                                                        margin={
                                                                            rowData.novaMargem
                                                                        }
                                                                        cpi={
                                                                            rowData.novaCompetitividade
                                                                        }
                                                                    />
                                                                }
                                                            >
                                                                <InputAddon>
                                                                    %
                                                                </InputAddon>
                                                            </Whisper>
                                                        </InputGroup>
                                                    </BaseCell.MainContainer>

                                                    <BaseCell.SubContainer>
                                                        {rowData.originalMargin && (
                                                            <VariationBoxIndicator
                                                                positiveValueIsGood
                                                                className="box-indicator"
                                                                value={
                                                                    rowData.novaMargem -
                                                                    rowData.originalMargin
                                                                }
                                                            />
                                                        )}
                                                    </BaseCell.SubContainer>
                                                </BaseCell>
                                            )}
                                        </Cell>
                                    </Column>
                                    <Column minWidth={140}>
                                        <HeaderCell
                                            className={
                                                styles[
                                                    'gerenciador-page__table__header-cell'
                                                ]
                                            }
                                        >
                                            Previsão
                                            <Whisper
                                                placement="bottom"
                                                speaker={
                                                    <Tooltip>
                                                        Previsão de receita para
                                                        7 dias
                                                    </Tooltip>
                                                }
                                            >
                                                <span>
                                                    <Icon
                                                        icon="info"
                                                        style={{
                                                            fontSize: '12px',
                                                            marginLeft: '2px',
                                                        }}
                                                    />
                                                </span>
                                            </Whisper>
                                        </HeaderCell>
                                        <Cell dataKey="demandForecast">
                                            {(
                                                rowData: RowData,
                                                index: number,
                                            ) => (
                                                <>
                                                    <BaseCell
                                                        onClick={() =>
                                                            !rowData.novoCalculo &&
                                                            rowData.demandForecast
                                                                ? handleOpenModalPrevisao(
                                                                      rowData.demandForecast,
                                                                      index,
                                                                  )
                                                                : null
                                                        }
                                                        aria-hidden="true"
                                                        onContextMenu={() => {
                                                            setContextMenuDatapoint(
                                                                rowData,
                                                            );
                                                        }}
                                                    >
                                                        <div className="previsao-demanda">
                                                            {rowData.novoCalculo ? (
                                                                <span className="calculo">
                                                                    {rowData.loadingCalc ? (
                                                                        <Loader content="Carregando..." />
                                                                    ) : (
                                                                        <Button
                                                                            title="Calcular"
                                                                            appearance="primary"
                                                                            block
                                                                            onClick={() =>
                                                                                calcularRevisao(
                                                                                    rowData,
                                                                                    index,
                                                                                )
                                                                            }
                                                                        >
                                                                            Calcular
                                                                        </Button>
                                                                    )}
                                                                </span>
                                                            ) : rowData
                                                                  .demandForecast
                                                                  ?.new_price
                                                                  ?.forecast
                                                                  ?.revenue &&
                                                              !rowData.novoCalculo ? (
                                                                <>
                                                                    <CurrencyFormat
                                                                        className="currency"
                                                                        fixedDecimalScale
                                                                        decimalScale={
                                                                            2
                                                                        }
                                                                        value={
                                                                            rowData
                                                                                ?.demandForecast
                                                                                ?.new_price
                                                                                ?.forecast
                                                                                ?.revenue
                                                                        }
                                                                        displayType="text"
                                                                        prefix="R$"
                                                                        decimalSeparator=","
                                                                        thousandSeparator="."
                                                                    />
                                                                    {rowData
                                                                        ?.demandForecast
                                                                        ?.new_price
                                                                        ?.forecast
                                                                        ?.revenue >
                                                                    rowData
                                                                        .demandForecast
                                                                        ?.current_price
                                                                        ?.forecast
                                                                        ?.revenue ? (
                                                                        <ArrowUpwardRoundedIcon
                                                                            className="positive"
                                                                            style={{
                                                                                fontSize: 16,
                                                                            }}
                                                                        />
                                                                    ) : rowData
                                                                          ?.demandForecast
                                                                          ?.new_price
                                                                          ?.forecast
                                                                          ?.revenue <
                                                                      rowData
                                                                          .demandForecast
                                                                          ?.current_price
                                                                          ?.forecast
                                                                          ?.revenue ? (
                                                                        <ArrowDownwardRoundedIcon
                                                                            className="negative"
                                                                            style={{
                                                                                fontSize: 16,
                                                                            }}
                                                                        />
                                                                    ) : null}
                                                                </>
                                                            ) : (
                                                                '--'
                                                            )}
                                                        </div>
                                                    </BaseCell>
                                                    <LoadingSpinerArea
                                                        size="sm"
                                                        height="62px"
                                                        isLoading={checkLoadingRow(
                                                            rowData.productsToBePricedId,
                                                        )}
                                                    />
                                                </>
                                            )}
                                        </Cell>
                                    </Column>
                                </Table>
                            </ContextMenuTrigger>
                            {contextMenuDatapoint && (
                                <ContextMenuContent>
                                    {contextMenuDatapoint?.priceType.match(
                                        /EDITADO/,
                                    ) && (
                                        <>
                                            <ContextMenuItem
                                                onClick={() =>
                                                    handleUndoEditedDatapoint({
                                                        productsToBePricedId:
                                                            contextMenuDatapoint.productsToBePricedId,
                                                        productId:
                                                            contextMenuDatapoint.productId,
                                                        storeId:
                                                            contextMenuDatapoint.storeId,
                                                    })
                                                }
                                            >
                                                Desfazer alteração de preço
                                            </ContextMenuItem>
                                            <ContextMenuSeparator />
                                        </>
                                    )}
                                    <ContextMenuItem
                                        onClick={() =>
                                            handleOpenPriceCompositionModal(
                                                contextMenuDatapoint.productId,
                                                contextMenuDatapoint.storeId,
                                            )
                                        }
                                    >
                                        Composição do preço
                                    </ContextMenuItem>
                                    {contextMenuDatapoint?.ruleId && (
                                        <ContextMenuItem
                                            onClick={() =>
                                                handleOpenCompositionRuleModal(
                                                    contextMenuDatapoint.ruleId,
                                                    contextMenuDatapoint.productsToBePricedId,
                                                )
                                            }
                                        >
                                            Regra aplicada ao produto
                                        </ContextMenuItem>
                                    )}
                                    <ContextMenuSeparator />
                                    <ContextMenuItem
                                        onClick={() => {
                                            onOpenModal('price-event-history');
                                        }}
                                    >
                                        Resumo de alteração de preço
                                    </ContextMenuItem>
                                </ContextMenuContent>
                            )}
                        </ContextMenu>
                        <Pagination
                            lengthMenu={[
                                {
                                    value: 25,
                                    label: 25,
                                },
                                {
                                    value: 50,
                                    label: 50,
                                },
                                {
                                    value: 100,
                                    label: 100,
                                },
                            ]}
                            activePage={activePage}
                            displayLength={pageSize}
                            total={totalElements}
                            onChangePage={handleChangePage}
                            onChangeLength={handleChangeLength}
                        />
                    </Layout.Section>
                </Layout.Body>
            </Layout>
            <ModalPrevisaoDemanda
                handleSalvarSimulacao={handleSalvarSimulacao}
                goToPrevisao={goToPrevisao}
                show={showModalPrevisaoDemanda}
                closeModal={() => setShowModalPrevisaoDemanda(false)}
                previsao={previsao}
            />
            <ComposicaoPrecoModal
                show={isPriceCompositionModalOpen}
                onHide={() => setIsPriceCompositionModalOpen(false)}
                priceComposition={ipaPriceComposition}
            />
            <SalvarFiltrosModal
                show={reduxModals.salvarFiltros.isOpen}
                actions={{
                    POST: salvarFiltros,
                    PUT: editarFiltros,
                }}
                onHide={() => {
                    dispatch(
                        closeModal({
                            name: 'salvarFiltros',
                        }),
                    );
                }}
                onChange={(value) => {
                    dispatch(
                        setSavedFilterInputName({
                            value,
                        }),
                    );
                }}
            />
            <ExcluirFiltroModal
                show={reduxModals.excluirFiltro.isOpen}
                filterName={savedFilter.filterName}
                onConfirm={() => {
                    excluirFiltroSalvo(handleLimparFiltros);
                }}
                onHide={() => {
                    dispatch(
                        closeModal({
                            name: 'excluirFiltro',
                        }),
                    );
                }}
            />
            <CompositionRuleModal
                show={isCompositionRuleModalOpen}
                onHide={handleCloseCompositionRuleModal}
                ruleComposition={ipaRuleComposition}
                history={history}
            />
            <ModalAlterarPrecos
                show={modals['alterar-precos']}
                onHide={() => onCloseModal('alterar-precos')}
                total={totalProducts}
                onSubmit={handleChangeMassivePrice}
            />
            <ModalPriceEventHistory
                show={modals['price-event-history']}
                onHide={() => onCloseModal('price-event-history')}
                queryFn={
                    contextMenuDatapoint?.productsToBePricedId
                        ? () => {
                              return getPriceEventHistory(
                                  contextMenuDatapoint.productsToBePricedId,
                              );
                          }
                        : undefined
                }
            />
        </>
    );
};

export default withRouter(RevisaoPrecos);

import React from 'react';
import { Button, Popover } from 'rsuite';

export const ButtonsCompetitorPrice = ({
    className,
    style,
    handleCalculoCompetitiviness,
    handleCalculoPreco,
    classNamecompetitor,
}) => (
    <Popover className={`popover-competitors ${className}`} style={style}>
        <div className={classNamecompetitor}>
            <Button
                className="competitor-button"
                onClick={handleCalculoCompetitiviness}
            >
                Aplicar preço concorrente
            </Button>
            <Button className="competitor-button" onClick={handleCalculoPreco}>
                Aplicar novo preço
            </Button>
        </div>
    </Popover>
);

import React from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import { Loader } from 'rsuite';
import { LoaderProps } from 'rsuite/lib/Loader';

export const LoadingSpiner = ({ size }) => {
    const { promiseInProgress } = usePromiseTracker();

    return (
        <div>
            {promiseInProgress === true ? (
                <Loader className="global-loader" size={size} />
            ) : null}
        </div>
    );
};

export const LoadingSpinerWithMessage = ({ area, message, size }) => {
    const { promiseInProgress } = usePromiseTracker({ area });

    return (
        <div>
            {promiseInProgress === true ? (
                <>
                    <div className="wrapper-message-loader">
                        <p className="message-loader">{message}</p>
                    </div>
                    <Loader className="global-loader" size={size} />
                </>
            ) : null}
        </div>
    );
};

type LoadingSpinerAreaProps = React.HtmlHTMLAttributes<HTMLElement> &
    LoaderProps & {
        area?: string;
        height?: string;
        isLoading?: boolean;
    };

export const LoadingSpinerArea = ({
    area,
    height = '100%',
    style,
    isLoading = false,
    ...props
}: LoadingSpinerAreaProps) => {
    const { promiseInProgress } = usePromiseTracker({ area });

    return (
        [isLoading, promiseInProgress].includes(true) && (
            <div>
                <Loader
                    className="global-loader"
                    style={{ height, ...style }}
                    {...props}
                />
            </div>
        )
    );
};

export const LoadingSpinerComponent = ({ area, size, styleClass }) => {
    const { promiseInProgress } = usePromiseTracker({ area });

    return (
        <div>
            {promiseInProgress === true ? (
                <Loader className={styleClass} size={size} />
            ) : null}
        </div>
    );
};

export const InProgress = () => {
    const { promiseInProgress } = usePromiseTracker();

    if (promiseInProgress === true) {
        return true;
    }
    return false;
};

export const LoadingMenu = ({ area }) => {
    const { promiseInProgress } = usePromiseTracker({ area });

    return (
        <div>
            {promiseInProgress === true ? (
                <div style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                    <Loader size="xs" />
                </div>
            ) : null}
        </div>
    );
};

export const LoadingSpinerLocal = ({ loading, size, height, position }) => (
    <div>
        {loading === true ? (
            <Loader
                className="area-loader"
                style={{ height: height || '100%', position }}
                size={size}
            />
        ) : null}
    </div>
);

import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Alert } from 'rsuite';
import {
    generateReport,
    saveEditedReport,
    setGenerateReportData,
} from '../../../../../actions/actionsModuloRelatorio';

import {
    dimensionsSelectionMissingText,
    moduleReportEnumsType,
} from '../utils';

export const useGenerateReport = () => {
    const { consolidadoData, moduloRelatorioData } = useSelector(
        (state) => ({
            consolidadoData: state.moduloRelatorioDataReducer?.consolidadoData,
            moduloRelatorioData:
                state.moduloRelatorioDataReducer?.moduloRelatorioData,
        }),
        shallowEqual,
    );

    const dispatch = useDispatch();

    const { id } = useParams();

    const validateConsolidadoData = (type) => {
        const generateDataBody = {
            ...moduloRelatorioData,
            recorrencia: false,
            relatorioSalvo: false,
        };

        if (
            consolidadoData.produto.length &&
            consolidadoData.local.length &&
            (consolidadoData.preco.length ||
                consolidadoData.quantidade.length ||
                consolidadoData.comFiltro.length)
        ) {
            dispatch(
                type === 'generate'
                    ? generateReport(generateDataBody)
                    : saveEditedReport(id, moduloRelatorioData),
            );
        } else {
            Alert.warning(dimensionsSelectionMissingText, 5000);
        }
    };

    const setDimensoesList = () => {
        const allList = [];

        const dimensoesSelectedList = allList
            .concat(
                consolidadoData.produto,
                consolidadoData.local,
                consolidadoData.preco,
                consolidadoData.quantidade,
                consolidadoData.comFiltro,
            )
            .filter((item) => item.column === '2')
            .map((item) => ({
                dimensao: {
                    name: moduleReportEnumsType[item.name],
                },
                consolidado: false,
            }));

        dispatch(setGenerateReportData('dimensoes', dimensoesSelectedList));
    };

    return {
        setDimensoesList,
        validateConsolidadoData,
    };
};

import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Icon, Nav, Tooltip, Whisper } from 'rsuite';

export const IPALiteMenu = ({ activeKeyMenu }) => (
    <Nav className="sidebar-desktop">
        <Nav.Item
            componentClass={Link}
            to="/ipa/lite/gerenciador-de-precos"
            eventKey="4"
            icon={<Icon icon="check-square-o" />}
        >
            Gerenciador de preços
        </Nav.Item>

        <Whisper
            placement="right"
            speaker={<Tooltip>Estratégia de preços</Tooltip>}
        >
            <Dropdown
                activeKey={activeKeyMenu}
                eventKey="1"
                title="Estratégia de preços"
                icon={<Icon icon="magic" />}
                disabled
            >
                <Dropdown.Item eventKey="1-1">Regras de preço</Dropdown.Item>
                <Dropdown.Item eventKey="1-2">Limites de preço</Dropdown.Item>
                <Dropdown.Item
                    eventKey="1-3"
                    componentClass={Link}
                    to="/ipa/preco-psicologico"
                >
                    Preço psicológico
                </Dropdown.Item>
                <Dropdown.Item eventKey="1-4">Tabela de preços</Dropdown.Item>
            </Dropdown>
        </Whisper>
        <Whisper placement="right" speaker={<Tooltip>Precificação</Tooltip>}>
            <Dropdown
                activeKey={activeKeyMenu}
                eventKey="2"
                title="Precificação"
                icon={<Icon icon="building2" />}
                disabled
            >
                <Dropdown.Item eventKey="2-1">Produtos</Dropdown.Item>
            </Dropdown>
        </Whisper>
        <Nav.Item eventKey="8" icon={<Icon icon="tags" />} disabled>
            Campanhas
        </Nav.Item>
        <Nav.Item eventKey="9" icon={<Icon icon="line-chart" />} disabled>
            Simulações e Análises
        </Nav.Item>
        <Nav.Item eventKey="5" icon={<Icon icon="file-download" />} disabled>
            Extração de preços
        </Nav.Item>
    </Nav>
);

export const IpaMenu = ({ activeKeyMenu, user }) => {
    const contratosAdicionais = user?.tipoAdicionalContratos?.IPA ?? [];

    const allowerUsersToAcessIpaNegotiation =
        contratosAdicionais.includes('MODULO_NEGOCIACAO');

    return (
        <Nav className="sidebar-desktop">
            <Nav.Item
                componentClass={Link}
                to="/ipa/gerenciador-de-precos"
                eventKey="4"
                icon={<Icon icon="check-square-o" />}
            >
                Gerenciador de preços
            </Nav.Item>

            <Whisper
                placement="right"
                speaker={<Tooltip>Estratégia de preços</Tooltip>}
            >
                <Dropdown
                    activeKey={activeKeyMenu}
                    eventKey="1"
                    title="Estratégia de preços"
                    icon={<Icon icon="magic" />}
                >
                    <Dropdown.Item
                        eventKey="1-1"
                        componentClass={Link}
                        to="/ipa/regras-preco"
                    >
                        Regras de preço
                    </Dropdown.Item>
                    <Dropdown.Item
                        eventKey="1-2"
                        componentClass={Link}
                        to="/ipa/limites-preco"
                    >
                        Limites de preço
                    </Dropdown.Item>
                    <Dropdown.Item
                        eventKey="1-3"
                        componentClass={Link}
                        to="/ipa/preco-psicologico"
                    >
                        Preço psicológico
                    </Dropdown.Item>
                    <Dropdown.Item
                        eventKey="1-4"
                        componentClass={Link}
                        to="/ipa/tabela-precos"
                    >
                        Tabela de preços
                    </Dropdown.Item>
                    <Dropdown.Item
                        eventKey="1-5"
                        componentClass={Link}
                        to="/ipa/estrategia/margem-objetiva"
                    >
                        Margem Objetiva
                    </Dropdown.Item>
                </Dropdown>
            </Whisper>
            <Whisper
                placement="right"
                speaker={<Tooltip>Precificação</Tooltip>}
            >
                <Dropdown
                    activeKey={activeKeyMenu}
                    eventKey="2"
                    title="Precificação"
                    icon={<Icon icon="building2" />}
                >
                    <Dropdown.Item
                        eventKey="2-1"
                        componentClass={Link}
                        to="/ipa/precificacao-produto"
                    >
                        Produtos
                    </Dropdown.Item>
                </Dropdown>
            </Whisper>
            <Nav.Item
                componentClass={Link}
                to="/ipa/campanhas"
                eventKey="8"
                icon={<Icon icon="tags" />}
            >
                Campanhas
            </Nav.Item>
            {allowerUsersToAcessIpaNegotiation && (
                <Whisper
                    placement="right"
                    speaker={<Tooltip>Negociações</Tooltip>}
                >
                    <Dropdown
                        activeKey={activeKeyMenu}
                        eventKey="3"
                        title="Negociações"
                        icon={<Icon icon="handshake-o" />}
                    >
                        <Dropdown.Item
                            eventKey="3-1"
                            componentClass={Link}
                            to="/ipa/negociacoes/fornecedor"
                        >
                            Negociação fornecedor
                        </Dropdown.Item>
                        <Dropdown.Item
                            eventKey="3-2"
                            componentClass={Link}
                            to="/ipa/negociacoes/salvas"
                        >
                            Negociações salvas
                        </Dropdown.Item>
                    </Dropdown>
                </Whisper>
            )}
            <Nav.Item
                componentClass={Link}
                to="/ipa/simulacoes-analises"
                eventKey="9"
                icon={<Icon icon="line-chart" />}
            >
                Simulações e Análises
            </Nav.Item>
            <Nav.Item
                componentClass={Link}
                to="/ipa/extracao-preco"
                eventKey="5"
                icon={<Icon icon="file-download" />}
            >
                Extração de preços
            </Nav.Item>
        </Nav>
    );
};

export const IsaMenu = ({ user }) => (
    <Nav className="sidebar-desktop">
        <Nav.Item
            eventKey="1"
            componentClass={Link}
            to="/isa/historico-preco"
            icon={<Icon icon="line-chart" />}
        >
            Histórico de preço
        </Nav.Item>
        {user?.servicoColeta?.relatoriosCustomizados ? (
            <Nav.Item
                eventKey="2"
                componentClass={Link}
                to="/isa/download-relatorio"
                icon={<Icon icon="file-download" />}
            >
                Download de relatório
            </Nav.Item>
        ) : null}
        <Nav.Item
            componentClass={Link}
            to="/isa/extracao-dados"
            eventKey="3"
            icon={<Icon icon="cloud-download" />}
        >
            Extração de dados
        </Nav.Item>
        <Nav.Item
            eventKey="4"
            componentClass={Link}
            to="/isa/gestao-retorno"
            icon={<Icon icon="attribution" />}
        >
            Gestão do Retorno
        </Nav.Item>
        <Nav.Item
            eventKey="5"
            componentClass={Link}
            to="/isa/acompanhamento-coletas"
            icon={<Icon icon="clock-o" />}
        >
            Acompanhamento de Coletas
        </Nav.Item>
        <Nav.Item
            eventKey="6"
            componentClass={Link}
            to="/isa/revisao-lista"
            icon={<Icon icon="views-authorize" />}
        >
            Revisão de Lista
        </Nav.Item>
        <Nav.Item
            eventKey="7"
            componentClass={Link}
            to="/isa/lojas-bloqueadas"
            icon={<Icon icon="views-unauthorize" />}
        >
            Lojas Bloqueadas
        </Nav.Item>
    </Nav>
);

export const InfoPanelMenu = ({ user }) => (
    <Nav className="sidebar-desktop">
        <Nav.Item
            eventKey="1"
            componentClass={Link}
            to="/isa/painel-geral"
            icon={<Icon icon="logo-analytics" />}
        >
            Panel
        </Nav.Item>
        <Nav.Item
            eventKey="3"
            componentClass={Link}
            to="/isa/modulo-relatorio"
            icon={<Icon icon="building2" />}
            renderItem={() => (
                <Link
                    to="/isa/modulo-relatorio"
                    className="rs-nav-item-content"
                >
                    <Icon icon="building2" className="custom-icon" />
                    Módulo de Relatório
                </Link>
            )}
        >
            Módulo de Relatório
        </Nav.Item>
        <Nav.Item
            eventKey="4"
            componentClass={Link}
            to="/isa/analise-competitividade/varejo"
            icon={<Icon icon="trophy" />}
            renderItem={() => (
                <Link
                    to="/isa/analise-competitividade/varejo"
                    className="rs-nav-item-content"
                >
                    <Icon icon="trophy" className="custom-icon" />
                    Análise de Competitividade
                </Link>
            )}
        >
            Análise de Competitividade
        </Nav.Item>
        {user?.servicoPanel?.pacotePainel.name !== 'FREE' &&
        user?.login?.includes('infoprice.co') ? (
            <Nav.Item
                eventKey="2"
                componentClass={Link}
                to="/isa/solicitacao-ondemand"
                icon={<Icon icon="retention" />}
            >
                On-Demand
            </Nav.Item>
        ) : null}
        <Nav.Item
            eventKey="5"
            componentClass={Link}
            to="/isa/lojas-concorrentes"
            icon={<Icon icon="shopping-basket" />}
        >
            Lojas concorrentes
        </Nav.Item>
    </Nav>
);

export const IraMenu = () => (
    <Nav className="sidebar-desktop">
        <Nav.Item
            componentClass={Link}
            to="/ira"
            eventKey="1"
            icon={<Icon icon="home" />}
        >
            Home
        </Nav.Item>
        <Nav.Item
            componentClass={Link}
            to="/ira/competitividade-geral"
            eventKey="2"
            icon={<Icon icon="trophy" />}
        >
            Competitividade geral
        </Nav.Item>
        <Nav.Item
            componentClass={Link}
            to="/ira/margem-praticada-geral"
            eventKey="3"
            icon={<Icon icon="usd" />}
        >
            Margem praticada geral
        </Nav.Item>
        <Nav.Item
            componentClass={Link}
            to="/ira/vendas-gerais"
            eventKey="4"
            icon={<Icon icon="charts-line" />}
        >
            Vendas gerais
        </Nav.Item>
    </Nav>
);

export const PaineisCustomizadosMenu = ({ paineis }) => (
    <Nav>
        {paineis?.length
            ? paineis.map((item, index) => (
                  <Nav.Item
                      key={item.codigo}
                      eventKey={(index + 1).toString()}
                      componentClass={Link}
                      to={
                          item.nome
                              ? `/analise/${item.codigo}`
                              : `/acompanhamento/${item.codigo}`
                      }
                      icon={<Icon icon="user-analysis" />}
                  >
                      {item.nome || item.titulo}
                  </Nav.Item>
              ))
            : null}
    </Nav>
);

export const CalculadoraPrevisaoDemandaMenu = () => (
    <Nav className="sidebar-desktop">
        <Nav.Item
            componentClass={Link}
            to="/ipa/calculadora-demanda"
            eventKey="1"
            icon={<Icon icon="home" />}
        >
            Calculadora de previsão de demanda
        </Nav.Item>
        <Nav.Item
            componentClass={Link}
            to="/ipa/cenarios-salvos"
            eventKey="2"
            icon={<Icon icon="save" />}
        >
            Cenários salvos
        </Nav.Item>
    </Nav>
);

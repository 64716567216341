/* eslint-disable react/jsx-one-expression-per-line */
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { format, parseISO } from 'date-fns';
import React from 'react';
import CurrencyFormat from 'react-currency-format';
import { Alert, Modal } from 'rsuite';
import { ModalProps } from 'rsuite/lib/Modal';
import { ButtonPrimary } from '../../pages/IPA/Lite';
import {
    PriceEvent,
    PriceEventHistory,
} from '../../pages/IPA/RevisaoPrecos/services_ts';
import { Link } from '../Link';
import styles from './ModalPriceEventHistory.module.scss';

type EventItemProps = React.HTMLAttributes<HTMLLIElement> & {
    noBullet?: boolean;
};

const EventItem = ({ noBullet, className, ...props }: EventItemProps) => (
    <li
        className={classNames(
            styles['event-item'],
            noBullet && styles['no-bullet'],
            className,
        )}
        {...props}
    />
);

const getEventComponent = (ocurrence: PriceEvent) => {
    switch (ocurrence.eventType) {
        case 'ERP':
            return (
                <EventItem>
                    <span className={styles.title}>
                        Preço vigente recebido{' '}
                        <strong>
                            <CurrencyFormat
                                decimalScale={2}
                                prefix="R$ "
                                decimalSeparator=","
                                value={ocurrence.price || undefined}
                                displayType="text"
                                thousandSeparator="."
                                fixedDecimalScale
                            />
                        </strong>
                    </span>
                    <span className={styles.subtitle}>por ERP</span>
                </EventItem>
            );
        case 'RULE':
            return (
                <EventItem>
                    <span className={styles.title}>
                        Novo preço sugerido{' '}
                        <strong>
                            <CurrencyFormat
                                decimalScale={2}
                                prefix="R$ "
                                decimalSeparator=","
                                value={ocurrence.price || undefined}
                                fixedDecimalScale
                                displayType="text"
                                thousandSeparator="."
                            />
                        </strong>
                    </span>
                    <span className={styles.subtitle}>
                        por Regra{' '}
                        <Link
                            className={styles.link}
                            to={`/ipa/regras-preco/${ocurrence.author.id}`}
                        >
                            {ocurrence.author.name}
                        </Link>
                    </span>
                </EventItem>
            );
        case 'LIMIT':
            return (
                <EventItem>
                    <span className={styles.title}>
                        {ocurrence.brokenPrice
                            ? 'Preço com quebra de limite'
                            : 'Preço não possui quebra de limite'}
                    </span>
                    <span className={styles.subtitle}>
                        por Limite{' '}
                        <Link
                            className={styles.link}
                            to={`/ipa/limites-preco/${ocurrence.author.id}`}
                        >
                            {ocurrence.author.name}
                        </Link>
                    </span>
                </EventItem>
            );
        case 'MIN_VARIATION':
            return (
                <EventItem>
                    <span className={styles.title}>
                        {ocurrence.brokenPrice
                            ? 'Preço sugerido excede a variação mínima para alteração de preço'
                            : 'Preço sugerido ficou dentro variação mínima para alteração de preço'}
                    </span>
                </EventItem>
            );
        case 'RANGE':
            return (
                <EventItem>
                    <span className={styles.title}>
                        Aplicação de preço psicológico para{' '}
                        <strong>
                            <CurrencyFormat
                                decimalScale={2}
                                prefix="R$ "
                                decimalSeparator=","
                                value={ocurrence.price || undefined}
                                fixedDecimalScale
                                displayType="text"
                                thousandSeparator="."
                            />
                        </strong>
                    </span>
                    <span className={styles.subtitle}>
                        por Preço psicológico
                    </span>
                </EventItem>
            );
        case 'PRICE_LIST':
            return (
                <EventItem>
                    <span className={styles.title}>
                        {ocurrence.brokenPrice
                            ? 'Preço fora dos limites da tabela'
                            : 'Preço dentro dos limites da tabela de preços'}
                    </span>
                    <span className={styles.subtitle}>
                        por Tabela de Preços
                    </span>
                </EventItem>
            );
        case 'PRICE':
            return (
                <EventItem noBullet>
                    <span className={styles.title}>
                        <strong>
                            {ocurrence.hour} - Preço disponível do gerenciador
                            de preços{' '}
                            <strong>
                                <CurrencyFormat
                                    decimalScale={2}
                                    prefix="R$ "
                                    decimalSeparator=","
                                    fixedDecimalScale
                                    displayType="text"
                                    thousandSeparator="."
                                    value={ocurrence.price || undefined}
                                />
                            </strong>
                        </strong>
                    </span>
                </EventItem>
            );
        case 'EDITED_PRICE':
            return (
                <EventItem>
                    <span className={styles.title}>
                        {ocurrence.hour} Edição do preço para{' '}
                        <strong>
                            <CurrencyFormat
                                decimalScale={2}
                                prefix="R$ "
                                decimalSeparator=","
                                value={ocurrence.price || undefined}
                                displayType="text"
                                thousandSeparator="."
                                fixedDecimalScale
                            />
                        </strong>
                    </span>
                    <span className={styles.subtitle}>
                        por {ocurrence.author.name}
                    </span>
                </EventItem>
            );
        case 'APPLIED_PRICE':
            return (
                <EventItem>
                    <span className={styles.title}>
                        {ocurrence.hour} Aplicação do preço{' '}
                        <strong>
                            <CurrencyFormat
                                decimalScale={2}
                                prefix="R$ "
                                decimalSeparator=","
                                value={ocurrence.price || undefined}
                                displayType="text"
                                thousandSeparator="."
                                fixedDecimalScale
                            />
                        </strong>
                    </span>
                    <span className={styles.subtitle}>
                        por {ocurrence.author.name}
                    </span>
                </EventItem>
            );
        default:
            return null;
    }
};

const EventList = ({
    className,
    ...props
}: React.HTMLAttributes<HTMLUListElement>) => {
    return (
        <ul
            {...props}
            className={classNames(styles['event-list'], className)}
        />
    );
};

type ModalPriceEventHistoryProps = ModalProps & {
    queryFn?: () => Promise<PriceEventHistory>;
};

export const ModalPriceEventHistory = ({
    className,
    show = false,
    queryFn,
    onHide,
    ...props
}: ModalPriceEventHistoryProps) => {
    if (!queryFn) return null;

    const { data } = useQuery({
        queryKey: ['priceEventHistory'],
        queryFn: async () => {
            try {
                const res = await queryFn();
                return res;
            } catch {
                Alert.error('Erro ao buscar histórico de preços');
                throw new Error('Erro ao buscar histórico de preços');
            }
        },
        enabled: !!show,
        initialData: [],
    });

    return (
        <Modal
            className={classNames(
                'info-modal',
                styles['modal-price-event-history'],
                className,
            )}
            show={show}
            onHide={onHide}
            {...props}
        >
            <Modal.Header>
                <Modal.Title>Resumo de alteração de preço</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={styles['modal-price-event-history__content']}>
                    {data.map(({ date, occurrences }) => (
                        <div key={date}>
                            <span className={styles.date}>
                                {format(parseISO(date), 'dd/MM/yyyy')}
                            </span>
                            <EventList>
                                {occurrences.map((occurrence) => {
                                    return getEventComponent(occurrence);
                                })}
                            </EventList>
                        </div>
                    ))}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <ButtonPrimary theme="ghost" onClick={onHide}>
                    fechar
                </ButtonPrimary>
            </Modal.Footer>
        </Modal>
    );
};

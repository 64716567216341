import classNames from 'classnames';
import React from 'react';
import styles from './BaseCell.module.scss';

export type BaseCellProps = React.HtmlHTMLAttributes<HTMLDivElement>;

const BaseCell = ({ className, ...props }: BaseCellProps) => {
    return (
        <div
            className={classNames(styles.container, className)}
            aria-hidden
            {...props}
        />
    );
};

const MainContainer = ({
    className,
    ...props
}: React.HtmlHTMLAttributes<HTMLDivElement>) => {
    return (
        <div
            className={classNames(styles.container__main, className)}
            {...props}
        />
    );
};

const SubContainer = ({
    className,
    ...props
}: React.HtmlHTMLAttributes<HTMLDivElement>) => {
    return (
        <div
            className={classNames(styles.container__sub, className)}
            {...props}
        />
    );
};

BaseCell.MainContainer = MainContainer;
BaseCell.SubContainer = SubContainer;

export default BaseCell;

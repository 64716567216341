import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type QuickActionFilterLimitsProps =
    | 'brokenLimit'
    | 'changedPrices'
    | 'competitorPrices'
    | 'minDateCompetitorPrice'
    | 'isProductMaster';

export type GerenciadorPrecosQuickActionFiltersStateProps = Record<
    QuickActionFilterLimitsProps,
    Record<string, any>
>;

const initialState: GerenciadorPrecosQuickActionFiltersStateProps = {
    brokenLimit: {
        isActive: false,
        value: false,
    },
    changedPrices: {
        isActive: false,
    },
    competitorPrices: {
        isActive: false,
    },
    minDateCompetitorPrice: {
        isActive: false,
        value: 7,
        competitorPriceSources: [],
    },
    isProductMaster: {
        isActive: false,
    },
};

const slice = createSlice({
    name: 'filtros-state',
    initialState,
    reducers: {
        toggleQuickFilterOn(
            state,
            action: PayloadAction<{ name: QuickActionFilterLimitsProps }>,
        ) {
            state[action.payload.name].isActive = true;
        },
        toggleQuickFilterOff(
            state,
            action: PayloadAction<{ name: QuickActionFilterLimitsProps }>,
        ) {
            state[action.payload.name].isActive = false;
        },
        toggleQuickFilter(
            state,
            action: PayloadAction<{ name: QuickActionFilterLimitsProps }>,
        ) {
            state[action.payload.name].isActive =
                !state[action.payload.name].isActive;
        },
        changeQuickFilterValue(
            state,
            action: PayloadAction<{
                name: QuickActionFilterLimitsProps;
                value: unknown;
            }>,
        ) {
            state[action.payload.name].value = action.payload.value;
        },
        togglePriceCPI(state) {
            state.competitorPrices.isActive = !state.competitorPrices.isActive;
        },
        resetQuickActionFilter() {
            return initialState;
        },
        toggleQuickActionFilter(
            state,
            action: PayloadAction<{ key: QuickActionFilterLimitsProps }>,
        ) {
            state[action.payload.key].isActive =
                !state[action.payload.key].isActive;
        },
        setMinDateCompetitorPriceSource(
            state,
            action: PayloadAction<string[]>,
        ) {
            state.minDateCompetitorPrice.competitorPriceSources =
                action.payload;
        },
    },
});

const {
    toggleQuickFilterOn,
    toggleQuickFilterOff,
    toggleQuickFilter,
    changeQuickFilterValue,
    resetQuickActionFilter,
    togglePriceCPI,
    toggleQuickActionFilter,
    setMinDateCompetitorPriceSource,
} = slice.actions;

export {
    changeQuickFilterValue,
    resetQuickActionFilter,
    setMinDateCompetitorPriceSource,
    togglePriceCPI,
    toggleQuickActionFilter,
    toggleQuickFilter,
    toggleQuickFilterOff,
    toggleQuickFilterOn,
};

export default slice.reducer;

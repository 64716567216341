import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import {
    Table, Container, Content, FlexboxGrid, Icon, InputGroup, Input, Button, Modal, Form,
    FormControl, FormGroup, ControlLabel, HelpBlock, IconButton, Alert, Col, CheckPicker,
} from 'rsuite';
import { Grid, Button as BackButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { LoadingSpiner } from '../../../components/LoadingSpinner';
import { changeSideMenu } from '../../../actions/actionsMenu';
import {
    getUsuariosContrato, addUsuariosContrato, getAcessos, editUsuariosContrato, deleteUsuariosContrato, getDominiosContrato,
} from '../../../services/ContratoService';
import { getClienteCompleto } from '../../../services/UsuarioService';

const { Column, HeaderCell, Cell } = Table;

class ConfiguracaoUsuarios extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            show: false,
            codigo: null,
            usuario: null,
            nome: '',
            email: '',
            acessos: '',
            editUsuario: false,
            errors: {},
            showConfirmacao: false,
            itemToDisabled: false,
            dominios: [],
            tiposAcesso: [{
                label: 'Administrador',
                value: 'ADM',
                acesso: true,
                role: 'admin',
            }],
        };

        this.selectAcessoNovoUsuario = this.selectAcessoNovoUsuario.bind(this);
        this.clearSelection = this.clearSelection.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
        this.getAllAcessos = this.getAllAcessos.bind(this);
        this.getUsuarios = this.getUsuarios.bind(this);
        this.getDominios = this.getDominios.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.close = this.close.bind(this);
        this.closeConfirmacao = this.closeConfirmacao.bind(this);
        this.openConfirmacao = this.openConfirmacao.bind(this);
        this.openNewUsuario = this.openNewUsuario.bind(this);
        this.handleCreateUsuario = this.handleCreateUsuario.bind(this);
        this.handleDisabled = this.handleDisabled.bind(this);
        this.backPage = this.backPage.bind(this);
    }

    componentDidMount() {
        this.getUserInformation();
        this.getUsuarios();
        this.getAllAcessos();
        this.getDominios();
    }

    handleValidation() {
        const {
            nome, email, dominios, acessos, usuario,
        } = this.state;
        const errors = {};
        let formIsValid = true;

        if (!nome.length) {
            formIsValid = false;
            errors.nome = 'Campo obrigatório';
            const nome = document.getElementsByName('nome')[0];
            if (nome) {
                nome.classList.add('form__input_invalid');
            }
        }

        if (!email.length) {
            formIsValid = false;
            errors.email = 'Campo obrigatório';
            const email = document.getElementsByName('email')[0];
            if (email) {
                email.classList.add('form__input_invalid');
            }
        }
        if (email.length && !email.match('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$')) {
            formIsValid = false;
            errors.email = 'E-mail inválido';
            const email = document.getElementsByName('email')[0];
            if (email) {
                email.classList.add('form__input_invalid');
            }
        }

        if (usuario?.servicoPanel?.pacotePainel?.name !== 'FREE' && email.length && !dominios.some((item) => email.includes(item))) {
            formIsValid = false;
            errors.email = 'Domínio de e-mail sem acesso ao portal do cliente';
            const email = document.getElementsByName('email')[0];
            if (email) {
                email.classList.add('form__input_invalid');
            }
        }

        if (!acessos.length) {
            formIsValid = false;
            errors.acessos = 'Campo obrigatório';
            const acessos = document.getElementsByClassName('rs-picker-toggle')[0];
            if (acessos) {
                acessos.classList.add('form__input_invalid');
            }
        }

        this.setState({ errors });
        return formIsValid;
    }

    handleChange(value, e) {
        const { target } = e;
        this.setState({
            [target.name]: value,
        }, () => this.formValidation(target.name));
    }

    handleCreateUsuario() {
        const {
            codigo, email, nome, acessos, editUsuario,
        } = this.state;

        if (this.handleValidation()) {
            const data = {
                usuario: {
                    codigo,
                    login: email,
                    nome,
                },
                administrador: acessos.some((item) => item === 'ADM'),
                servicos: acessos.map((item) => ({ name: item })).filter((item) => item.name !== 'ADM'),
            };

            if (editUsuario) {
                editUsuariosContrato(JSON.parse(localStorage.getItem('cliente'))?.codigo, data).then((response) => {
                    if (response) {
                        Alert.success('Usuário editado com sucesso');
                        this.getUsuarios();
                        this.setState({
                            email: '',
                            nome: '',
                            acessos: '',
                            show: false,
                            editUsuario: false,
                            errors: {},
                        });
                    }
                });
            } else {
                addUsuariosContrato(JSON.parse(localStorage.getItem('cliente'))?.codigo, data).then((response) => {
                    if (response) {
                        Alert.success('Usuário cadastrado com sucesso');
                        this.getUsuarios();
                        this.setState({
                            email: '',
                            nome: '',
                            acessos: '',
                            show: false,
                            errors: {},
                        });
                    }
                });
            }
        }
    }

    handleDisabled() {
        const { itemToDisabled } = this.state;
        const model = {
            usuario: {
                codigo: itemToDisabled?.usuario?.codigo,
            },
        };
        deleteUsuariosContrato(JSON.parse(localStorage.getItem('cliente'))?.codigo, model).then(() => {
            Alert.success('Usuário desativado com sucesso');
            this.getUsuarios();
            this.closeConfirmacao();
        });
    }

    handleEdit(data) {
        this.setState({
            codigo: data.usuario.codigo,
            nome: data.usuario.nome,
            email: data.usuario.login,
            acessos: data.administrador ? data.servicos.map((item) => item.name).concat('ADM') : data.servicos.map((item) => item.name),
            show: true,
            editUsuario: true,
        });
    }

    getUserInformation() {
        getClienteCompleto(JSON.parse(localStorage.getItem('usuario'))?.email).then((response) => {
            if (response) {
                this.setState({ usuario: response });
            }
        });
    }

    getAllAcessos() {
        const { tiposAcesso } = this.state;

        getAcessos(JSON.parse(localStorage.getItem('cliente'))?.codigo).then((response) => {
            if (response) {
                const acessos = tiposAcesso;
                response.forEach((item) => {
                    acessos.push({
                        label: item.servico.descricaoAcesso,
                        value: item.servico.name,
                        acesso: item.acesso,
                        role: 'single',
                    });
                });

                this.setState({
                    tiposAcesso: acessos,
                    itensDisabled: acessos.filter((item) => item.acesso === false).map((acesso) => acesso.value),
                });
            }
        });
    }

    getUsuarios(query) {
        getUsuariosContrato({ cliente: JSON.parse(localStorage.getItem('cliente'))?.codigo, query: query || '' }).then((data) => {
            this.setState({
                data: data.map((item) => ({
                    usuario: item.usuario,
                    acessos: item.administrador
                        ? item.servicos.map((item) => item.descricaoAcesso).concat('Administrador').sort().join(', ')
                        : item.servicos.map((item) => item.descricaoAcesso).join(', '),
                    servicos: item.servicos,
                    administrador: item.administrador,
                    ultimoLogin: item.ultimoLogin ? moment(item.ultimoLogin).format('DD/MM/YYYY') : ' - ',
                })),
            });
        });
    }

    getDominios() {
        getDominiosContrato(JSON.parse(localStorage.getItem('cliente'))?.codigo).then((data) => {
            if (data) {
                this.setState({
                    dominios: data,
                });
            }
        });
    }

    clearSelection() {
        this.setState({ acessos: '' });
    }

    selectAcessoNovoUsuario(value) {
        const { tiposAcesso, itensDisabled } = this.state;

        this.setState({ acessos: value }, this.validAcessosInput);

        if (value.some((item) => item === 'ADM')) {
            this.setState({
                acessos: tiposAcesso.filter((item) => item.acesso === true).map((itemMap) => itemMap.value),
            }, this.validAcessosInput);
        }
        if (value.some((item) => item === 'ADM')
            && value.length > 1 && value.length === (tiposAcesso.length - (itensDisabled.length + 1))) {
            this.setState({
                acessos: value.filter((item) => item !== 'ADM'),
            }, this.validAcessosInput);
        }
    }

    validAcessosInput() {
        const { errors, acessos } = this.state;
        const inputAcessos = document.getElementsByClassName('rs-picker-toggle')[0];
        const error = errors;
        let formIsValid = true;

        if (!acessos.length) {
            formIsValid = false;
            error.acessos = 'Campo obrigatório';
            if (inputAcessos) {
                inputAcessos.classList.add('form__input_invalid');
            }
        } else {
            formIsValid = true;
            error.acessos = null;
            if (inputAcessos) {
                inputAcessos.classList.remove('form__input_invalid');
            }
        }

        this.setState({ errors: error });
        return formIsValid;
    }

    validNameInput() {
        const { errors, nome } = this.state;
        const inputNome = document.getElementsByName('nome')[0];
        const error = errors;
        let formIsValid = true;

        if (!nome.length) {
            formIsValid = false;
            error.nome = 'Campo obrigatório';
            if (inputNome) {
                inputNome.classList.add('form__input_invalid');
            }
        } else {
            formIsValid = true;
            error.nome = null;
            if (inputNome) {
                inputNome.classList.remove('form__input_invalid');
            }
        }

        this.setState({ errors: error });
        return formIsValid;
    }

    validEmailInput() {
        const { email, errors } = this.state;
        const inputEmail = document.getElementsByName('email')[0];
        const error = errors;
        let formIsValid = true;

        if (!email.length) {
            formIsValid = false;
            error.email = 'Campo obrigatório';
            if (inputEmail) {
                inputEmail.classList.add('form__input_invalid');
            }
        } else {
            formIsValid = true;
            error.email = null;
            if (inputEmail) {
                inputEmail.classList.remove('form__input_invalid');
            }
        }

        this.setState({ errors: error });
        return formIsValid;
    }

    formValidation(e) {
        if (e === 'nome') {
            this.validNameInput();
        }
        if (e === 'email') {
            this.validEmailInput();
        }
    }

    close() {
        this.setState({
            email: '',
            nome: '',
            acessos: '',
            editUsuario: false,
            show: false,
            errors: {},
        });
    }

    closeConfirmacao() {
        this.setState({
            showConfirmacao: false,
            itemToDisabled: null,
        });
    }

    openConfirmacao(data) {
        this.setState({
            showConfirmacao: true,
            itemToDisabled: data,
        });
    }

    openNewUsuario() {
        this.setState({
            show: true,
        });
    }

    backPage() {
        this.props.history.goBack();
    }

    render() {
        const {
            nome, email, acessos, show, tiposAcesso, editUsuario, showConfirmacao, itensDisabled, data,
            errors,
        } = this.state;

        return (
            <>

                <Container className="main-container administrador-usuario-page">
                    <LoadingSpiner size="md" />
                    <Grid item xs={12}>
                        <BackButton onClick={this.backPage} startIcon={<ArrowBackIcon />} className="back-btn">
                            Voltar
                        </BackButton>
                    </Grid>

                    <Content>
                        <h2 className="page-title">Pessoas</h2>

                        <hr />
                        <FlexboxGrid>
                            <FlexboxGrid.Item colspan={24}>

                                <FlexboxGrid>
                                    <FlexboxGrid.Item componentClass={Col} lg={21} md={18} sm={24} xs={24}>
                                        <InputGroup>
                                            <Input placeholder="Pesquise por nome ou e-mail" onChange={this.getUsuarios} />
                                            <InputGroup.Addon>
                                                <Icon icon="search" />
                                            </InputGroup.Addon>
                                        </InputGroup>
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item componentClass={Col} lg={3} md={6} sm={24} xs={24} style={{ paddingLeft: '18px' }}>
                                        <Button
                                            title="Adicione usuário"
                                            className="btn-add-user"
                                            onClick={this.openNewUsuario}
                                            appearance="primary"
                                            block
                                        >
                                            Adicionar usuário

                                        </Button>
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                                <div>
                                    <Table
                                        height={400}
                                        data={data}
                                        autoHeight
                                    >

                                        <Column flexGrow={2}>
                                            <HeaderCell>Nome</HeaderCell>
                                            <Cell>{(rowData) => <span>{rowData.usuario.nome}</span>}</Cell>
                                        </Column>

                                        <Column flexGrow={2}>
                                            <HeaderCell>E-mail</HeaderCell>
                                            <Cell>{(rowData) => <span>{rowData.usuario.login}</span>}</Cell>
                                        </Column>
                                        <Column flexGrow={2}>
                                            <HeaderCell>Acessos</HeaderCell>
                                            <Cell dataKey="acessos" />
                                        </Column>
                                        <Column flexGrow={2}>
                                            <HeaderCell>Último acesso</HeaderCell>
                                            <Cell dataKey="ultimoLogin" />
                                        </Column>

                                        <Column width={120} fixed="right">
                                            <HeaderCell />

                                            <Cell className="actions">
                                                {(rowData) => (
                                                    <div>
                                                        <span className="option" title="Editar Usuário">
                                                            <IconButton
                                                                onClick={() => this.handleEdit(rowData)}
                                                                icon={<Icon icon="edit2" />}
                                                                size="md"
                                                            />
                                                        </span>
                                                        <span className="option" title="Desativar Usuário">
                                                            <IconButton
                                                                onClick={() => this.openConfirmacao(rowData)}
                                                                icon={<Icon icon="trash" />}
                                                                size="md"
                                                            />

                                                        </span>
                                                    </div>
                                                )}
                                            </Cell>
                                        </Column>
                                    </Table>
                                    <LoadingSpiner size="md" />
                                </div>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Content>
                </Container>
                <Modal className="administrador-usuario-page-modal" backdrop="false" size="md" show={show} onHide={this.close}>
                    <Modal.Header>
                        <Modal.Title>Novo Usuário</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form fluid className="form">
                            <FormGroup>
                                <ControlLabel>Nome*</ControlLabel>
                                <FormControl
                                    className="form__input"
                                    name="nome"
                                    value={nome}
                                    onChange={this.handleChange}
                                />
                                <HelpBlock
                                    className="form__message form__message_invalid"
                                    name="erroNome"
                                >
                                    {errors?.nome}

                                </HelpBlock>
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>E-mail*</ControlLabel>
                                <FormControl
                                    className="form__input"
                                    disabled={editUsuario}
                                    name="email"
                                    value={email}
                                    onChange={this.handleChange}
                                    type="email"
                                />
                                <HelpBlock
                                    className="form__message form__message_invalid"
                                    name="erroEmail"
                                >
                                    {errors?.email}

                                </HelpBlock>
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>Acessos*</ControlLabel>
                                <CheckPicker
                                    className="form__input inf-picker"
                                    block
                                    searchable={false}
                                    placeholder=" "
                                    disabledItemValues={itensDisabled}
                                    onSelect={this.selectAcessoNovoUsuario}
                                    onClean={this.clearSelection}
                                    menuClassName="select-acessos"
                                    groupBy="role"
                                    data={tiposAcesso}
                                    name="acessos"
                                    value={acessos}
                                    onChange={this.handleChange}
                                    type="email"
                                    renderMenuGroup={() => (
                                        <div />
                                    )}
                                    onOpen={() => {
                                        document.getElementsByClassName('rs-picker-check-menu-group-title')[0].style.display = 'none';
                                        document.getElementsByClassName('rs-picker-check-menu-group-title')[1].style.display = 'none';
                                    }}
                                />
                                <HelpBlock className="form__message form__message_invalid" name="erroAcessos">{errors?.acessos}</HelpBlock>
                            </FormGroup>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleCreateUsuario} appearance="primary">
                            {editUsuario ? 'Salvar' : 'Criar'}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal backdrop="static" show={showConfirmacao} size="xs">
                    <Modal.Body>
                        Tem certeza que deseja desativar este usuário?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleDisabled} appearance="primary">
                            Desativar
                        </Button>
                        <Button onClick={this.closeConfirmacao} appearance="subtle">
                            Cancelar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>

        );
    }
}
const mapStateToProps = (store) => ({
    codigoCliente: store.clienteDataReducer.codigoCliente,
});

export default withRouter(connect(mapStateToProps, { changeSideMenu })(ConfiguracaoUsuarios));

import { format } from 'date-fns';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectorGerenciadorPrecos } from '../../../../../../reducers/gerenciadorPrecos';
import {
    QuickActionFilterLimitsProps,
    changeQuickFilterValue,
    resetQuickActionFilter,
    setMinDateCompetitorPriceSource,
    togglePriceCPI,
    toggleQuickActionFilter,
    toggleQuickFilter,
    toggleQuickFilterOff,
    toggleQuickFilterOn,
} from '../../../../../../reducers/gerenciadorPrecos/quickActionsFilter';
import { getValidData } from '../../../utils';

export const useQuickActionFilter = () => {
    const { quickActionsFilters: state } = useSelector(
        selectorGerenciadorPrecos,
    );

    const dispatch = useDispatch();

    function handleToggleOnChangedPrices() {
        dispatch(
            toggleQuickFilterOn({
                name: 'changedPrices',
            }),
        );
    }

    function handleToggleChangedPrice() {
        dispatch(
            toggleQuickFilter({
                name: 'changedPrices',
            }),
        );
    }

    function handleSelectFilterLimitsClick(
        event?: React.SyntheticEvent<Element, Event>,
    ) {
        event?.preventDefault();
        dispatch(
            toggleQuickFilter({
                name: 'brokenLimit',
            }),
        );
    }

    function handleToggleOnBrokenLimit() {
        dispatch(
            toggleQuickFilterOn({
                name: 'brokenLimit',
            }),
        );
    }

    function handleTogglePriceCPI() {
        dispatch(togglePriceCPI());
        dispatch(
            toggleQuickFilterOff({
                name: 'minDateCompetitorPrice',
            }),
        );
    }

    function handleToggleMinDateCompetitorPrice() {
        dispatch(
            toggleQuickFilterOn({
                name: 'minDateCompetitorPrice',
            }),
        );
    }

    function handleChageLastCpiDays(value: any) {
        dispatch(
            changeQuickFilterValue({
                name: 'minDateCompetitorPrice',
                value,
            }),
        );
        handleToggleMinDateCompetitorPrice();
    }

    function handleSelectFilterLimits(eventKey: any) {
        dispatch(
            changeQuickFilterValue({
                name: 'brokenLimit',
                value: eventKey,
            }),
        );
        handleToggleOnBrokenLimit();
    }

    function getMinDate(lastDays: any) {
        const today = new Date(Date.now());
        const day = format(
            new Date(today - (+lastDays - 1) * 24 * 60 * 60 * 1000),
            'yyyy-MM-dd',
        );

        return day;
    }

    function getQuickActionFilterModel() {
        const data = {
            brokenLimit: state.brokenLimit.isActive
                ? state.brokenLimit.value
                : null,
            changedPrices: state.changedPrices.isActive || null,
            competitorPrices: state.competitorPrices.isActive,
            minDateCompetitorPrice: state.minDateCompetitorPrice.isActive
                ? getMinDate(state.minDateCompetitorPrice.value)
                : null,
            ...(state.isProductMaster.isActive && {
                isProductMaster: state.isProductMaster.isActive,
            }),
            competitorPriceSources:
                state.minDateCompetitorPrice.competitorPriceSources,
        };
        return getValidData<typeof data>(data);
    }

    function handleCleanQuickActionFlters() {
        dispatch(resetQuickActionFilter());
    }

    function handleToggleQuickActionFilter(key: QuickActionFilterLimitsProps) {
        dispatch(toggleQuickActionFilter({ key }));
    }

    function handleChangeMinDateCompetitorPriceSource(value: string[]) {
        dispatch(setMinDateCompetitorPriceSource(value));
    }

    return {
        state,
        handleToggleChangedPrice,
        handleSelectFilterLimits,
        handleSelectFilterLimitsClick,
        getQuickActionFilterModel,
        handleToggleOnChangedPrices,
        handleCleanQuickActionFlters,
        handleToggleOnBrokenLimit,
        handleChageLastCpiDays,
        handleTogglePriceCPI,
        handleToggleQuickActionFilter,
        handleChangeMinDateCompetitorPriceSource,
    };
};

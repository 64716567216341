import classNames from 'classnames';
import React from 'react';
import History from '../../../../../utils/History';
import { ButtonPrimary } from '../../../Lite';
import styles from './MargemObjetivaFallback.module.scss';

export const MargemObjetivaFallback = ({
    className,
    ...props
}: Omit<React.HtmlHTMLAttributes<HTMLDivElement>, 'children'>) => {
    return (
        <div
            className={classNames(styles.margem_objetiva_fallback, className)}
            {...props}
        >
            <div>
                <h6>Não é possível acessar a lista de margem objetiva</h6>
                <p>
                    Antes você precisa configurar qual o nível de categoria será
                    usado para a definição de margem objetiva
                </p>
            </div>
            <ButtonPrimary
                theme="ghost"
                onClick={() => History.push('/ipa/gerenciador-de-precos')}
            >
                configurar nível de categoria
            </ButtonPrimary>
        </div>
    );
};

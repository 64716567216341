import React from 'react';
import { Table, Tooltip, Whisper } from 'rsuite';
import { Icon } from '../../../../../RevisaoPrecos/Components/QuickActionFilter';
import { ButtonPrimary } from '../../../atoms';

export type HeaderCellProps = React.HtmlHTMLAttributes<HTMLElement> & {
    defaultEditableStatue?: boolean;
    contentEditable?: boolean;
    tooltip?: string;
};

const HeaderCell = ({
    children,
    className = '',
    contentEditable,
    tooltip,
    ...props
}: HeaderCellProps) => (
    <Table.HeaderCell
        className={['gerenciador-table__header-cell', className].join(' ')}
        {...props}
    >
        {children}
        {tooltip && (
            <Whisper placement="bottom" speaker={<Tooltip>{tooltip}</Tooltip>}>
                <span>
                    <Icon icon="MdInfo" />
                </span>
            </Whisper>
        )}
        {contentEditable && <ButtonPrimary size="xsmall">Editar</ButtonPrimary>}
    </Table.HeaderCell>
);

HeaderCell.Button = ButtonPrimary;

export { HeaderCell };

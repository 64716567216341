import { useState } from 'react';

export type HandlerParams = Record<
    'productId' | 'competitorStoreId',
    string
> & { storeId: number };

export const useDisabledCompetitor = () => {
    const [disabledCompetitors, setDisabledCompetitors] = useState<{
        [key: string]: string[];
    }>({});

    const handleDisableCompetitors = ({
        productId,
        storeId,
        competitorStoreId,
    }: HandlerParams) => {
        if (!competitorStoreId) return;

        setDisabledCompetitors((oldValue) => {
            const existingValues = oldValue[`${productId}-${storeId}`] || [];
            const updatedValues = Array.from(
                new Set([...existingValues, competitorStoreId])
            );
            return {
                ...oldValue,
                [`${productId}-${storeId}`]: updatedValues,
            };
        });
    };

    const handleRemoveCompetitor = ({
        productId,
        storeId,
        competitorStoreId,
    }: HandlerParams) => {
        if (!competitorStoreId) return;

        setDisabledCompetitors((oldValue) => {
            const existingValues = oldValue[`${productId}-${storeId}`] || [];
            const updatedValues = existingValues.filter(
                (id) => id !== competitorStoreId
            );
            return {
                ...oldValue,
                [`${productId}-${storeId}`]: updatedValues,
            };
        });
    };

    const handleClearAllCompetitors = () => {
        setDisabledCompetitors({});
    };

    const clearCompetitorsFromRow = (productId: string, storeId: number) => {
        setDisabledCompetitors((oldValue) => {
            return {
                ...oldValue,
                [`${productId}-${storeId}`]: [],
            };
        });
    };

    return {
        disabledCompetitors,
        handleDisableCompetitors,
        handleRemoveCompetitor,
        handleClearAllCompetitors,
        clearCompetitorsFromRow,
    };
};

import { combineReducers } from '@reduxjs/toolkit';
import filtrosSlice, { GerenciadorPrecosFiltrosStateProps } from './filtros';
import modalsSlice, { GerenciadorPrecosModalsStateProps } from './modals';
import quickActionsFilters, {
    GerenciadorPrecosQuickActionFiltersStateProps,
} from './quickActionsFilter';
import savedFilter, { GerenciadorPrecosSavedFilterProps } from './savedFilters';

export type GerenciadorPrecosStateProps = {
    modals: GerenciadorPrecosModalsStateProps;
    filtros: GerenciadorPrecosFiltrosStateProps;
    savedFilter: GerenciadorPrecosSavedFilterProps;
    quickActionsFilters: GerenciadorPrecosQuickActionFiltersStateProps;
};

const rootReducer = combineReducers({
    filtros: filtrosSlice,
    modals: modalsSlice,
    savedFilter,
    quickActionsFilters,
});

export const selectorGerenciadorPrecos = (
    state: Record<'gerenciadorPrecosReducer', GerenciadorPrecosStateProps>,
) => state.gerenciadorPrecosReducer;

export default rootReducer;

import classNames from 'classnames';
import React from 'react';
import { Icon } from 'rsuite';
import './Callout.style.scss';
import * as T from './Callout.types';

const VARIANTS = {
    warning: {
        icon: <Icon icon="exclamation-triangle" />,
    },
    info: {
        icon: <Icon icon="exclamation" />,
    },
} as const;

const Callout = ({
    children,
    className,
    variant = 'warning',
    ...props
}: T.CalloutProps) => (
    <div
        className={classNames(
            'callout',
            `callout-variant-${variant}`,
            className,
        )}
        {...props}
    >
        {VARIANTS[variant].icon}
        <div>{children}</div>
    </div>
);

const Description = ({
    children,
    className,
    ...props
}: React.HtmlHTMLAttributes<HTMLParagraphElement>) => (
    <p className={classNames('callout-description', className)} {...props}>
        {children}
    </p>
);

const Title = ({
    children,
    className,
    ...props
}: React.HtmlHTMLAttributes<HTMLHeadingElement>) => (
    <h6 className={classNames('callout-title', className)} {...props}>
        {children}
    </h6>
);

Callout.Title = Title;

Callout.Description = Description;

export { Callout };
